<div class="title" #title>
    <div class="container container-max-width">
      <div>
        <h4>PF Capacity Plans</h4>
        <p>Onboard the plan for your service team if not exist</p>
      </div>
      <div class="action-container">
        <div class="form-check form-check-inline ml-4 toggle">
          <input type="checkbox"
                 class="form-check-input"
                 id="showMyServiceTeamPlansOnlySwitch"
                 [(ngModel)]="showMyServiceTeamPlansOnly"
                 (change)="onShowMyServiceTeamPlansOnlyChanged()" />
          <label class="form-check-label" for="showMyServiceTeamPlansOnlySwitch">Show my service team plans only</label>
        </div>
        <button type="submit" class="btn btn-primary" (click)="showOnboardingDialog()" data-test="OnboardNewPlan">
          Onboard new PF Plan
        </button>
        <a
            href="javascript:void(0)"
            class="topbar-item"
            (click)="exportExcel()"
            placement="top-right"
            container="body"
            ngbTooltip="Export to Excel"
            aria-label="Export to Excel"
          >
            <span class="far fa-file-excel fa-lg"></span>
          </a>
      </div>
    </div>
  </div>
  <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main mt-3">
      <ag-grid-angular
        #agGrid
        id="ViewGrid"
        style="width: 100%; height: 540px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [excelStyles]="headerStyle"
        [rowData]="rowData"
        pagination="true"
        (gridReady)="onGridReady($event)"
        (filterChanged)="onFilterChanged($event)"
      >
      </ag-grid-angular>
    </div>
  </div>
  