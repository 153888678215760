<div class="modal-header">
  <h4 class="modal-title">{{ this.title }}</h4>
</div>
<ul class="edit-modal-ul">
  <li *ngFor="let data of formData" [hidden]="data.hide">
    <span class="edit-modal-key">{{ data.name }}</span>
    <ng-container *ngIf="data.isEditable; else elseBlock" [ngSwitch]="data.type">
      <ng-select
        class="edit-modal-ng-select"
        *ngIf="data.options"
        [items]="data.options"
        [multiple]="false"
        [clearable]="data.isNullable"
        [name]="data.key"
        [(ngModel)]="data.value"
        bindLabel="label"
        bindValue="value"
      >
      </ng-select>
      <input class="edit-modal-value" name="data.key" *ngSwitchCase="'string'" [(ngModel)]="data.value" />
      <input type="number" class="edit-modal-value" *ngSwitchCase="'number'" [(ngModel)]="data.value" [name]="data.key" />
      <div class="edit-modal-upn" *ngSwitchCase="'upn'">
        <input [(ngModel)]="data.value" />
        <em *ngIf="!isUpnPatternValid">Please enter an valid UPN.</em>
      </div>
      <ng-select
        class="edit-modal-ng-select"
        *ngSwitchCase="'boolean'"
        [items]="boolItems"
        [name]="data.key"
        [(ngModel)]="data.value"
        [clearable]="false"
      >
      </ng-select>
      <ng-select
        class="edit-modal-ng-select"
        *ngSwitchCase="'RequestSource'"
        [name]="data.key"
        [(ngModel)]="data.value"
        [items]="data.options.requestSource"
      >
      </ng-select>
      <ng-select class="edit-modal-ng-select" *ngSwitchCase="'roles'" [name]="data.key" [(ngModel)]="data.value" [items]="roles">
      </ng-select>
      <ng-select
        class="edit-modal-ng-select"
        *ngSwitchCase="'Internal'"
        [name]="data.key"
        [(ngModel)]="data.value"
        [items]="data.options.internal"
      >
      </ng-select>
      <ng-select
        class="edit-modal-ng-select"
        *ngSwitchCase="'requestTypes'"
        [name]="data.key"
        [(ngModel)]="data.value"
        [items]="requestTypes"
      >
      </ng-select>
      <textarea
        class="form-control edit-modal-value edit-modal-textarea"
        rows="4"
        type="text"
        *ngSwitchCase="'textarea'"
        [(ngModel)]="data.value"
      ></textarea>
    </ng-container>
    <ng-template #elseBlock>
      <span class="edit-modal-value" [title]="data.value">{{ data.displayName || data.value }}</span>
    </ng-template>
  </li>
</ul>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="disabled()">Yes</button>
  <button type="button" class="btn btn-warning" (click)="onCancel()">No</button>
</div>
