import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Region } from "src/app/generated-models";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { allowedCommonRegionStatuses } from "../../../../npr-request.model";

export interface PlanNewFileModalOptions {
  cloudOverrideExist: boolean;
  regionTypeOverrideExist: boolean;
  overrideNameList: string[];
}

export interface AvailableFileName {
  overridePrefix: string;
  type: string;
  value: string;
  label: string;
  disabled: boolean;
}
@Component({
  templateUrl: "./plan-new-file-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanNewFileModalComponent implements OnInit {
  @Input() options: PlanNewFileModalOptions;

  regionList: Region[];
  availableFileNames: AvailableFileName[];
  selectedFileName: AvailableFileName;
  level2Mode: boolean;
  mergeMode: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    this.availableFileNames = [];
    this.level2Mode = false;
    this.mergeMode = false;
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);
    // includeDisabled = true, includeAirGapped = true
    this.regionList = (await this.apiService.getRegionList(true, true).toPromise()).filter(region => allowedCommonRegionStatuses.includes(region.Status));
    this.bindAvailableFileNamesSelect();
    this.loadingService.setLoading(false);
  }

  bindAvailableFileNamesSelect() {
    const fileNames: AvailableFileName[] = [];

    if (this.level2Mode) {
      this.regionList.forEach((region) =>
        fileNames.push({
          type: "Region",
          overridePrefix: "region",
          label: region.RegionName,
          value: region.ArmRegionName,
          disabled: this.options.overrideNameList.includes(region.ArmRegionName)
        } as AvailableFileName)
      );
    } else {
      var clouds = this.regionList
        .filter((region, i, arr) => arr.findIndex((r) => r.CloudName === region.CloudName) === i)
        .map((r) => r.CloudName);

      var regionTypes = this.regionList
        .filter((region, i, arr) => arr.findIndex((r) => r.RegionType === region.RegionType) === i)
        .map((r) => r.RegionType)
        .sort();

      let disableCloudOverride = true;
      let disableRegionTypeOverride = true;
      if (this.options.cloudOverrideExist && this.options.regionTypeOverrideExist) {
        console.error("cloudOverrideExist and regionTypeOverrideExist can't be true in the same time.");
      } else if (this.options.cloudOverrideExist) {
        disableCloudOverride = false;
      }
      else if (this.options.regionTypeOverrideExist) {
        disableRegionTypeOverride = false;
      } else {
        disableCloudOverride = false;
        disableRegionTypeOverride = false;
      }

      clouds.forEach((cloud) => fileNames.push({
        type: "Cloud",
        overridePrefix: "cloud",
        label: cloud,
        value: cloud.toLowerCase(),
        disabled: disableCloudOverride || this.options.overrideNameList.includes(cloud.toLowerCase())
      } as AvailableFileName));

      regionTypes.forEach((regionType) => fileNames.push({
        type: "Region Type",
        overridePrefix: "type",
        label: regionType,
        value: regionType.toLowerCase(),
        disabled: disableRegionTypeOverride || this.options.overrideNameList.includes(regionType.toLowerCase())
      } as AvailableFileName));
    }

    this.availableFileNames = fileNames;
  }

  onOverrideLevelChanged(_params: any): void {
    this.selectedFileName = null;

    this.bindAvailableFileNamesSelect();
  }

  async submit() {
    if (!this.selectedFileName) {
      this.notificationService.warning(`Please select a file name.`);
      return;
    } else {
      try {
        if (this.mergeMode) {
          this.activeModal.close(`${this.selectedFileName.value}.${this.selectedFileName.overridePrefix}-override`);
        } else {
          this.activeModal.close(this.selectedFileName.value);
        }
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }
}
