<div class="modal-header">
  <h4 class="modal-title">Create New Request</h4>
</div>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
<div class="modal-body">
  <p>
    Create {{ finishedCount }}/{{ this.uiRequests.length }}
    Request(s):
  </p>
  <ul *ngFor="let item of aggregatedResult">
    <li [innerHtml]="item"></li>
  </ul>
  <p *ngIf="showMessageForDuplication">
    {{ messageForDuplication }}
  </p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()" *ngIf="redirectBtnVisible">Go To My Request Page</button>
  <button type="button" class="btn btn-primary" (click)="proceed()" *ngIf="proceedBtnVisible" data-test="Proceed">Proceed</button>
  <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()" *ngIf="closeBtnVisible">Close</button>
  <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()" *ngIf="editBtnVisible">Edit</button>
</div>
