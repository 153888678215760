<div class="modal-header">
  <p [innerHtml]="message"></p>
</div>

<div class="modal-body" style="height: 200px">
  <input class="form-control" name="inputText" id="inputText" type="text" required [(ngModel)]="returnText" />
</div>

<!-- buttons -->
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close(this.selectedOption + '$' + this.returnText)">Yes</button>
  <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()">No</button>
</div>
