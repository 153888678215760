<div class="modal-header">
  <h4 class="modal-title">Resubmiting Region Prep</h4>
</div>
<div class="modal-body">
  <h6>For {{ regionNames() }}, on-demand Region prep for the following services will be triggered. Please confirm.</h6>
  <ag-grid-angular
    style="height: 400px;"
    class="ag-theme-balham"
    [gridOptions]="gridOptions"
    [rowData]="status"
    [columnDefs]="columnDefs"
    [masterDetail]="true"
    [detailRowHeight]="145"
    pagination="true"
  >
  </ag-grid-angular>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">
    Yes
  </button>
  <button type="button" class="btn btn-warning" (click)="activeModal.close(false)">
    No
  </button>
</div>
