import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IEditModalOption, Roles, PolicyName, RequestType, IUserProfile } from "../../npr-request.model";
import { ApiService } from "src/app/services";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./edit-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class EditModalComponent implements OnInit {
  @Input() title = "";
  @Input() tableName = "";
  @Input() updateRowFunc: any;
  @Input() editableList: string[] = [];
  @Input() formData: IEditModalOption[] = [];
  @Input() action = "";

  public roles = Object.values(Roles);
  public policyNames = Object.values(PolicyName);
  public requestTypes = Object.values(RequestType);
  public boolItems = [true, false];
  private userProfile: IUserProfile = null;
  public disableBtn = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  ngOnInit() {
    console.log("modal init fo option:", this.formData);
    this.apiService.getUserProfile().then((response) => {
      this.userProfile = response;
    });
    this.formData.map((data) => {
      if (data.options && this.action === "Insert") {
        if (data.isNullable) {
          // For nullable column, set default value to null
          data.value = null;
        } else if (data.value === null || data.value === undefined) {
          // Only set default value when Insert row and current value is null or undefined
          data.value = data.options[0];
        }
      }
    });
  }

  async onSave() {
    const data = {};
    this.disableBtn = true;
    this.formData.map((d) => (data[d.key] = d.value));
    try {
      await this.apiService.setEditModalData(data, this.updateRowFunc);
      this.disableBtn = false;
    } catch (err) {
      this.activeModal.dismiss(err);
      console.log("Failed to save the change for ", this.tableName, "error:", err);
      return;
    }
    this.activeModal.close(true);
    console.log("Change for ", this.tableName, " has been saved.");
  }

  onCancel() {
    this.activeModal.close(false);
  }

  disabled() {
    return !this.isUpnPatternValid || this.disableBtn;
  }

  get isUpnPatternValid(): boolean {
    const upn = this.formData.find(d => d.type === "upn");
    if (upn && this.userProfile){
      const regexp = new RegExp(environment.requiredUpnPattern);
      return regexp.test(upn.value);
    }
    return true;
  }
}
