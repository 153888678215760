<div class="title container container-max-width btn-toolbar justify-content-between">
  <h4>Planned Quota Request Details</h4>
  <div class="btn-group">
    <button *ngIf="isAdmin" type="submit" class="btn btn-primary mr-1" (click)="retry()" [disabled]="!isRetryable()">Retry</button>
  </div>
</div>
<div class="container ags-forms-container container-content container-max-width">
  <div class="form-content" *ngIf="errorInfo && request">
    <fieldset class="fieldsetstyle">
      <h5 class="font-weight-bold">{{ errorInfo }}</h5>
    </fieldset>
  </div>
  <div class="form-content" *ngIf="request && !isLoading && !errorInfo">
    <form novalidate class="form-horizontal metadata-validator ng-untouched ng-pristine ng-valid">
      <!--section 1: basic info of the request-->
        <fieldset class="fieldsetstyle">
            <div class="form-group row mb-0">
                <div class="col-sm-6 popover-container">
                    <h5 class="font-weight-bold">
                        {{ requestId }} [<label [ngClass]="request.Status" data-test="RequestStatus">{{ request.Status }}</label>]
                    </h5>
                </div>
            </div>

            <hr class="mt-0" />

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3">Region Name</td>
                        <td class="col-sm-6 form-control-plaintext">
                            {{ request.Region }}
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Team</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            <span>{{ request.Team }}({{ request.ServiceTreeId }})</span>
                            <span *ngIf="request.RingLevel" class="ml-2">| {{ request.RingLevel }}</span>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Subscription ID</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            <a href="javascript:void(0)" class="topbar-item" (click)="getSubscriptionDetails()" container="body">
                                {{ request.SubscriptionId }}
                            </a>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Subscription Name In Plan</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            {{ request.SubscriptionKey }}
                        </td>
                    </tr>
                </table>
            </div>

            <div *ngIf="request.PropertyBindings">
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Property Bindings</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            {{ propertyBindingsJsonString }}
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Submitter</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            {{ request.ContactEmail }}
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Source</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            <a href="{{ generateCisJobLink() }}" *ngIf="needToRenderCisJobLink()">{{ request.RequestSource }}</a>
                            <a *ngIf="!(needToRenderCisJobLink())">{{ request.RequestSource }}</a>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Plan Version</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            <a *ngIf="planVersionDetailLink !== ''; else userTemplate"
                               href="{{ planVersionDetailLink }}"
                               class="topbar-item"
                               target="_blank">
                                {{ request.PlanVersion }}
                            </a>
                            <ng-template #userTemplate>{{ request.PlanVersion }}</ng-template>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Stage</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            {{ request.Stage }}
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Created Time</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            {{ createdTime }}
                        </td>
                    </tr>
                </table>
            </div>

            <div *ngIf="request.CompletedTime">
                <table class="content-table">
                    <tr class="content-table-row">
                        <td class="col-form-label col-sm-3 content-table-data-key">Completed Time</td>
                        <td class="col-sm-6 form-control-plaintext content-table-data-value">
                            {{ completedTime }}
                        </td>
                    </tr>
                </table>
            </div>
        </fieldset>

      <fieldset class="fieldsetstyle" *ngIf="rowData && rowData.length > 0">
        <h5>History</h5>
        <hr />
        <ag-grid-angular
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          pagination="true"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </fieldset>
    </form>
  </div>
  <h5 class="font-weight-bold" *ngIf="!request">Not Found</h5>
</div>
