import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminViewComponent } from "./admin-view.component";
import { TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { DataTypes } from "./admin-view-data-types";
import { SubscriptionThreshold } from "src/app/generated-models/SubscriptionThreshold";
import { RegionalQuotaLimit } from "src/app/generated-models/RegionalQuotaLimit";
import * as CDs from "src/app/utility/commonColDef";
import { SharedDataService } from "../../services/sharedDataService";

const additionalMessage = `\
The regional limit has 3 dimensions: Region, Requestor, and Limit. \
Wildcard value * can be used on Region and Requestor to match any values. \
When there are multiple matches, the row with more exact matches will take priority. \
On tie, the maximal value among the tied rows will be used.`;

@Component({
  selector: "admin-view/config",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewRegionalQuotaLimitComponent extends AdminViewComponent<RegionalQuotaLimit> implements OnInit {
  mainTitle = "Regional Quota Limit";
  subTitle = "Configure how many regional quota can be auto approved per requestor and per region";
  type = "Regional Limit";
  tableName = TableName.Config;
  columnDef = [
    { ...CDs.Id, valueType: "number", hide: true },
    { ...CDs.RegionColDef("Region"), valueType: "region", wildcard: true },
    {
      headerName: "Requestor",
      field: "TeamOid",
      valueType: "requestor",
      wildcard: true,
      cellRenderer: (params) => {
        return this.dataTypes.teamNameOidMap[params.value];
      },
    },
    { headerName: "Limit", field: "Limit", isEditable: true, valueType: "number", filter: "agNumberColumnFilter" },
  ];
  keyFilterStats = "filter:admin-view-regional-quota-limit";

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    dataTypes: DataTypes,
    sharedDataService: SharedDataService
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.additionalMessage = additionalMessage;
    this.addRowEnable = true;
    this.updateRowFunc = (data: RegionalQuotaLimit) => {
      if (data.Region && data.TeamOid && data.Limit) {
        const payload = data;
        return apiService.updateRegionalQuotaLimit(payload).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.addRowFunc = (data: RegionalQuotaLimit) => {
      if (data.Region && data.TeamOid && data.Limit) {
        const payload = data;
        data.Id = null;
        return apiService.insertRegionalQuotaLimit(payload).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.deleteRowEnable = true;
    this.deleteRowFunc = (data: SubscriptionThreshold) => {
      return apiService.deleteRegionalQuotaLimit(data.Id).toPromise();
    };
  }

  refreshData() {
    this.apiService.getRegionalQuotaLimitList().subscribe((response: RegionalQuotaLimit[]) => {
      this.rowData = response;
      this.setupFilterByLocalData();
    });
  }
}
