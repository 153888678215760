import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "region-name-cell",
  template: `<a href="javascript:void(0)" (click)="invokeMethod()">link</a>`,
})
export class RegionNamesRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public invokeMethod() {
    this.params.context.ShowRegionNamesModal(Array(this.params.value));
  }

  refresh(): boolean {
    return false;
  }
}
