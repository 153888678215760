<div class="modal-header">
    <h4 class="modal-title" style="text-transform: capitalize;">Please Confirm</h4>
  </div>
    <div class="modal-body">
      <p>
        Are you sure to Re-process the plan with version <b>{{ payload.Version }}</b> while latest version is <b>{{ latestVersion }}</b>?
      </p>
    </div>  
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit()">
      Yes
    </button>
    <button type="button" class="btn btn-warning" (click)="close()">
      No
    </button>
  </div>