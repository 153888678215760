import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  module: "Billing Meter" | "New Product Onboarding" | "Lionrock" | "Planned" | "Administrator" | "SelfService" | "FFP" | "PF";

  constructor(private router: Router) {
    router.events.subscribe((evt) => {
      function match(evt: NavigationEnd, start: string) {
        return evt.urlAfterRedirects.startsWith(start);
      }

      if (evt instanceof NavigationEnd && !match(evt, "/404")) {
        if (match(evt, "/prep/")) this.module = "Billing Meter";
        else if (match(evt, "/product-onboard-status")) this.module = "Billing Meter";
        else if (match(evt, "/planned/") || match(evt, "/plans")) this.module = "Planned";
        else if (match(evt, "/admin-view/") || match(evt, "/incidents-view/") || match(evt, "/approver-admin"))
          this.module = "Administrator";
        else if (match(evt, "/self-service/")) this.module = "SelfService";
        else if (match(evt, "/ffp/")) this.module = "FFP";
        else if (match(evt, "/pf/")) this.module = "PF";
        else this.module = "Lionrock";
      }
    });
  }
}
