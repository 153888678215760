<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Approve Regional Plans</h4>
      <p>All approvable plans with region information</p>
    </div>
    <div class="action-container">
      <div class="flex-center" *ngIf="showBatchApproval()">
        <span>{{ selectedRowCount }} of {{ rowData.length }} selected &nbsp;&nbsp;|&nbsp;&nbsp;</span>
      </div>
      <div class="form-check form-check-inline ml-4 toggle">
          <input type="checkbox"
                 class="form-check-input"
                 id="showApproveableOnlySwitch"
                 [(ngModel)]="showApproveableOnly"
                 (change)="onShowApproveableOnlyChanged()" />
          <label class="form-check-label" for="showApproveableOnlySwitch">Show Approveable Only</label>
      </div>
      <button type="submit" class="btn btn-primary" (click)="refreshData()">Refresh</button>&nbsp;
      <button type="submit" class="btn btn-success" [disabled]="!selectedRowCount" (click)="batchApprovePlan()" *ngIf="showBatchApproval()" >Approve</button>
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel()"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main mt-3">
    <ag-grid-angular
      #agGrid
      id="ViewGrid"
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [excelStyles]="headerStyle"
      pagination="true"
      rowSelection="multiple"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged()"
      (filterChanged)="onFilterChanged($event)">
    </ag-grid-angular>
  </div>
</div>
