<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Email Details</h2>
  </div>
  <form #ngForm="ngForm">
    <div class="modal-body  editor-container">
      <monaco-editor theme="vs" (ready)="onEditorReady($event)"></monaco-editor>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="activeModal.close()">OK</button>
    </div>
  </form>
</div>
