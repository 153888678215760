import * as CDs from "src/app/utility/commonColDef";
import { JsonCellRenderer } from "../../../utility/gridCellRenderer";
export const SubRequestColumnDefinition = [
  {
    ...CDs.RequestId,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: (params) => params.data.RequestLink,
    minWidth: 150
  },
  { ...CDs.Requestor, minWidth: 350 },
  { ...CDs.Email, minWidth: 200 },
  CDs.CreatedTime,
  { ...CDs.Status, tooltipField: "Status", minWidth: 100 },
  { ...CDs.RegionColDef("Region"), minWidth: 150 },
  CDs.RequestDisplayServiceType,
  CDs.SubscriptionId("SubscriptionId"),
  CDs.SKU,
  CDs.Submitter,
  CDs.Dependencies,
  CDs.RequestSource,
];

export const ParentRequestColumnDefinition = [
  {
    ...CDs.RequestId,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  { ...CDs.Status, tooltipField: "Status" },
  CDs.Email,
  CDs.RegionColDef("Region"),
  CDs.Notes,
  CDs.SubscriptionId("SubscriptionId"),
  CDs.RequestSource,
  CDs.Submitter,
  CDs.Requestor,
  CDs.CreatedTime,
];

export const ExpandParentRequestColumnDefinition = [
  {
    headerName: "Parent Request Info",
    children: [
      {
        ...CDs.RequestId,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: {
          suppressCount: true, // turn off the row count
          suppressDoubleClickExpand: true, // turn off double click for expand
          innerRenderer: (params) => params.data.RequestLink, // provide an inner renderer
        },
      },
      CDs.Requestor,
      CDs.Submitter,
      CDs.RegionColDef("Region"),
      CDs.SubscriptionId("SubscriptionId"),
      CDs.IsExternalDisplay,
      { ...CDs.Status, tooltipField: "Status" },
      CDs.CreatedTime,
      { ...CDs.CompletedTime, hide: true },
      CDs.Email,
      { headerName: "Enable", field: "Details.Enable" },
      { headerName: "SKU", field: "Details.VmSkus" },
      {
        headerName: "VMQuota",
        field: "Details.VmQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      { headerName: "RDFE VM Quota", field: "Details.RdfeVmQuota", filter: 'agNumberColumnFilter' },
      {
        headerName: "Storage Account Quota",
        field: "Details.StorageAccountQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        headerName: "SQL Quota",
        field: "Details.SqlQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        ...CDs.Justification,
        tooltipField: "Justification",
        maxWidth: 500,
      },
      {
        ...CDs.Notes,
        tooltipField: "Notes",
      },
    ],
  },
];

export const NestedSubRequestColumnDefinition = [
  {
    headerName: "ID",
    field: "RequestLink",
    checkboxSelection: false,
    headerCheckboxSelection: false,
    cellRenderer: (param) => {
      return param.value;
    },
  },
  { ...CDs.Status, tooltipField: "Status" },
  CDs.RequestDisplayServiceType,
  CDs.Quota,
  CDs.PriorityString,
  CDs.CreatedTime,
  CDs.CompletedTime,
  CDs.Notes,
];
