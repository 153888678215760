import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";
import { AgsAuthService } from "@ags/core";
import { ApplicationInsights, IPageViewTelemetry } from "@microsoft/applicationinsights-web";
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../environments/environment";
import { MenuService } from "./shared/menu.service";
import { AppInsightService } from "./shared/appinsight.service";
import { ApiService } from "./services";
import { SharedDataService } from "./services/sharedDataService";
import { AgsSideNavItem } from "@ags/common-ui";
import { IUserProfile } from "./npr-request.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  betEnabled = environment.betEnabled;
  isTestEnv = environment.isTestEnv;
  moduleNavItems: AgsSideNavItem[];
  userProfile: IUserProfile;

  constructor(
    private titleService: Title,
    private menuService: MenuService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private router: Router,
    private authService: AgsAuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    protected appInsightService: AppInsightService,
    private readonly appInsights: ApplicationInsights
  ) {
    this.initUserProfile();
    this.ngbTooltipConfig.triggers = "hover focus";
    this.ngbTooltipConfig.closeDelay = 100;
    if (!environment.appInsightsInstrumentationKey) {
      return;
    }

    this.appInsights.config.instrumentationKey = environment.appInsightsInstrumentationKey;
    this.appInsights.loadAppInsights();
    this.authService.getAccount().then((user) => {
      if (user) {
        this.appInsights.setAuthenticatedUserContext(user.username);
      } else {
        this.appInsights.setAuthenticatedUserContext("anonymous");
      }
    });
  }

  initUserProfile() {
    this.apiService.getUserProfile().then((res) => {
      this.userProfile = res;
      this.sharedDataService.setUserProfile(res);
    });
  }

  getModuleNavItems() {
    return this.menuService.getModuleNavItems();
  }

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((_) => {
      const title = this.getTitleFromRouteData("title");
      this.titleService.setTitle(`${title} - Region Access and Quota`);
      if (this.router.routerState.snapshot.root && this.router.routerState.snapshot.root.component) {
        this.logPageView(this.getTitleData(), this.getUrl());
      }

      document.querySelector("a.navbar-brand").textContent = "Azure Core";
    });
  }

  ngAfterViewChecked() {
    // To fix accessibility: autocomplete-valid
    document.querySelectorAll("input[autocomplete]").forEach((t) => t.removeAttribute("autocomplete"));

    // To fix accessibility: aria-required-children
    document.querySelectorAll("div.ag-full-width-container > div[comp-id]").forEach((t) => {
      t.removeAttribute("role");
      t.removeAttribute("aria-rowindex");
    });

    document.querySelectorAll("div.ag-header-container > .ag-header-row.ag-header-row-column").forEach((t) => {
      t.removeAttribute("role");
      t.removeAttribute("aria-rowindex");
    });

    document.querySelectorAll("div.ag-header-cell").forEach((t) => {
      t.removeAttribute("role");
      t.removeAttribute("aria-sort");
      t.removeAttribute("aria-colindex");
    });

    document.querySelectorAll("div.ag-header-container").forEach((t) => t.removeAttribute("role"));

    // To fix accessibility: aria-allowed-attr
    document
      .querySelectorAll("div.ag-center-cols-container > div[comp-id][aria-expanded]")
      .forEach((t) => t.removeAttribute("aria-expanded"));

    // To fix aria-allowed-attr for ags tags (right-menu)
    document.querySelector("div.right-menu").removeAttribute("role");
    document.querySelectorAll("div[ng-reflect-router-link-active]").forEach((t) => t.removeAttribute("aria-selected"));
  }

  getFAQ(): string {
    const isPlannedQuota = window.location.href.includes("/quota/plan");
    return isPlannedQuota ? "https://aka.ms/catseye-docs" : "https://aka.ms/lionrockdocs";
  }

  private getTitleFromRouteData(name: string, routeSnapshot = this.router.routerState.snapshot.root): string {
    let value = (routeSnapshot.data && routeSnapshot.data[name]) || null;
    if (routeSnapshot.firstChild) {
      value = this.getTitleFromRouteData(name, routeSnapshot.firstChild) || value;
    }
    return value;
  }

  public logPageView(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number
  ) {
    const pageView: IPageViewTelemetry = {
      name: name,
      uri: url,
      properties: { ...properties, ...measurements, duration },
    };
    this.appInsights.trackPageView(pageView);
  }

  private getTitleData(): string {
    const title = this.getTitleFromRouteData("title");
    return (title && `${title} - Region Access and Quota`) || "Region Access and Quota";
  }

  private getUrl(): string {
    return location.href.split(location.hash || "#")[0];
  }
}
