import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { ApiService } from "src/app/services";
import { PlanRegionOverallApproval } from "src/app/generated-models/PlanRegionOverallApproval";

@Component({
  templateUrl: "./plan-batch-approval.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanBatchApprovalComponent implements OnInit {
  @Input() title: string;
  @Input() planRegions: PlanRegion[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) { }
  async ngOnInit(): Promise<void> {
    await this.batchApprovalPlanRegions();
  }

  close(): void {
    this.activeModal.close(true);
  }

  async batchApprovalPlanRegions() {
    await Promise.all(
      this.planRegions.map(async (planRegion) => {
        try {
          const planRegionOverallApproval: PlanRegionOverallApproval = {
            ApprovedVersion: planRegion.Version,
            Stage: planRegion.Stage,
          } as PlanRegionOverallApproval;

          await this.apiService.approvePlanRegion(planRegion.ServiceTreeId, planRegion.Region, planRegionOverallApproval).toPromise();
          this.aggregatedResult.push(`Approve plan region <b>${planRegion.ServiceTreeId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Approve plan region <b>${planRegion.ServiceTreeId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }
}
