<div class="title">
  <div class="container">
    <h4>Product Status Track</h4>
    <p>This page provides the detail information related to products or billing meter requests which can used to trouble-shoot some problems happened during the product lifecycle.</p>
    <p>Click <a target="_blank" href="https://aka.ms/billingmeterfaq">here</a> for FAQ’s.</p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isUserAuthorized()">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && isUserAuthorized() && !userCanViewStatus">
  <h5>Permission is required!</h5>
  <h5>Please Contact <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add the permission </h5>
</div>
<div class="container track-container-content" *ngIf="isLoadingFinished && userCanViewStatus">
  <div class="main-content">
    <div class="form-group">
    <h4>Select Cloud and Region to get started</h4>
    </div>
    <div class="region-select">
    <div class="form-group">
        <h6>Cloud</h6>
        <label style="width: 100%">
        <ng-select
            [items]="dataService.clouds"
            bindLabel="CloudName"
            placeholder="Select Cloud"
            (change)="onCloudChange($event)"
            [(ngModel)]="dataService.selectedCloud"
            name="CloudName"
        >
            <ng-template ng-notfound-tmp>
            <label class="ng-option disabled">Loading...</label>
            </ng-template>
        </ng-select>
        </label>
    </div>
    <div class="form-group">
        <h6>Region</h6>
        <label style="width: 100%">
        <ng-select
            [items]="cloudRegions"
            bindLabel="Name"
            placeholder="Select Region"
            (change)="onRegionChange()"
            [(ngModel)]="dataService.selectedRegion"
            name="Name"
            [disabled]="dataService.selectedCloud == null"
        >
            <ng-template ng-notfound-tmp>
            <label class="ng-option disabled">Region not found</label>
            </ng-template>
        </ng-select>
        </label>
    </div>
    <br />
    <div class="form-group">
        <h6>ProductOid or CaymanProductId</h6>
        <input [(ngModel)]="key" (keyup.enter)="onFetchProductStatusTrack()" title="product" style="width: 100%;" />
    </div>
    </div>

    <div class="form-group">
    <button type="button" class="btn btn-primary mr-1" [disabled]="dataService.selectedRegion == null || key == '' || key == null" (click)="onFetchProductStatusTrack()">
        Search
    </button>
    </div>

    <hr/>
    <div *ngIf="productStatusTrackInfo?.product != null">
        <h5>Product Info</h5>
        <p>This section shows the basic info about the product.</p>
        <div *ngIf="getProductInfo() != null" class="product-track-info">
            <div class="form-row">
                <div class="form-group col-6" >
                    <h6>ProductOid</h6>
                    <label>
                        {{getProductInfo().ProductOid}}
                    </label>
                </div>
                <div class="form-group col-6">
                    <h6>ProductName</h6>
                    <label>
                        {{getProductInfo().ProductName}}
                    </label>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <h6>ProductType</h6>
                    <label>
                        {{getProductInfo().ProductType}}
                    </label>
                </div>
                <div class="form-group col-6">
                    <h6>ProductRing</h6>
                    <label>
                        {{getProductInfo().ProductRing}}
                    </label>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <h6>DeploymentScope</h6>
                    <label>
                        {{getProductInfo().DeploymentScope}}
                    </label>
                </div>
                <div class="form-group col-6">
                    <h6>Lifecycle</h6>
                    <label>
                        {{getProductInfo().Lifecycle}}
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="getProductInfo() == null" class="track-error">
            <p>Product not found, please check if the ProductOid or CaymanProductId is correct.</p>
        </div>
    </div>
    <div *ngIf="productStatusTrackInfo?.productCayman.columnDefs.length > 0" style="margin-top: 20px;">
        <h5>Product Cayman Info</h5>
        <p>This section shows the cayman info related to the product.</p>
        <div class="track-info">
            <p>If the cayman product info is not found, you may need to onboard the product first through <a href="https://global.azure.com/product-catalog/ring-onboarding" target="_blank">Onboarding Form.</a></p>
        </div>
        <div class="nofloat" style="width: 100%">
            <ag-grid-angular
                style="height: 300px; margin-bottom: 10px"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="productStatusTrackInfo.productCayman.data"
                [columnDefs]="getProductCaymanColumnDef()"
                [masterDetail]="true"
                [detailRowHeight]="145"
                pagination="true"
            >
            </ag-grid-angular>
        </div>
    </div>
    <div *ngIf="productStatusTrackInfo?.availabilityCayman.columnDefs.length > 0 && !showBillingMeterRequestsOnly" style="margin-top: 20px;">
        <h5>Product Inscope Info</h5>
        <p>This section shows whether the product is in the scope of the BET system.</p>
        <div class="track-info">
            <p><b>Inscope</b> means whether the product will be cared and handled in the BET system. <b>Inscope</b> is true if the below conditions are both met: </p>
            <p>1. The product: <code>CurrentState in ('NeedsPlan','Internal','Preview','Planned')</code></p>
            <p>2. The product: <code>(DeploymentScope == 'GlobalEachCloud' or DeploymentScope == 'GlobalAllClouds' or DeploymentScope == 'Geographical') and Lifecycle == 'GA'</code></p>
            <p>If the product inscope info is not shown in the table, possible reason is that the availability info is not recorded in the PAM, check <a href="https://global.azure.com/product-availability/roadmap" target="_blank">link</a> for more info.</p>
        </div>
        <div class="nofloat" style="width: 100%">
            <ag-grid-angular
                style="height: 300px; margin-bottom: 10px"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="productStatusTrackInfo.availabilityCayman.data"
                [columnDefs]="getProductAvailabilityColumnDef()"
                [masterDetail]="true"
                [detailRowHeight]="145"
                pagination="true"
            >
            </ag-grid-angular>
        </div>
    </div>
    <div *ngIf="productStatusTrackInfo?.onboarding.columnDefs.length > 0 && !showBillingMeterRequestsOnly" style="margin-top: 20px;">
        <h5>Product Onboarding Requests Info</h5>
        <p>This section lists the onboarding requests related to the product.</p>
        <div class="nofloat" style="width: 100%">
            <ag-grid-angular
                style="height: 300px; margin-bottom: 10px"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="productStatusTrackInfo.onboarding.data"
                [columnDefs]="getProductOnboardingColumnDef()"
                [masterDetail]="true"
                [detailRowHeight]="145"
                pagination="true"
            >
            </ag-grid-angular>
        </div>
    </div>
    <div *ngIf="productStatusTrackInfo?.billingMeter.columnDefs.length > 0 && dataService.selectedRegion != null" style="margin-top: 20px;">
        <h5>Product Billing Meter Requests Info</h5>
        <p>This section list the billing meter requests related to the product.</p>
        <div class="track-info">
            <p>If billing meter requests for the product are not found, there are several possible reasons you can consider: </p>
            <p>1. The product is not in scope for region {{dataService.selectedRegion.Name}}, check the Product Inscope Info section for more info.</p>
            <p>2. The initial billing meter has not started in this region.</p>
            <p>3. The product is not in the automation allow list which needs to be submitted manually through <a href="/quota/prep/incremental">Incremental Billing Meter</a> or <a href="/quota/prep/ondemand">On-Demand Billing Meter</a></p>
        </div>
        <div class="nofloat" style="width: 100%">
            <ag-grid-angular
                style="height: 300px; margin-bottom: 10px"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="productStatusTrackInfo.billingMeter.data"
                [columnDefs]="getBillingMeterColumnDef()"
                [masterDetail]="true"
                [detailRowHeight]="145"
                pagination="true"
            >
            </ag-grid-angular>
        </div>
    </div>
  </div>
</div>
