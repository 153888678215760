<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Assign FFP Plan to Project</h4>
      <p>Assign services to one project or assign a psl to one project</p>
    </div>
    <div class="action-container">
        <div class="form-check form-check-inline ml-4 toggle">
            <input type="checkbox"
                   class="form-check-input"
                   id="hideAlreadyAssignedSwitch"
                   [(ngModel)]="hideAlreadyAssigned"
                   (change)="onHideAlreadyAssignedChanged()" />
            <label class="form-check-label" for="hideAlreadyAssignedSwitch"> Hide non-assignable</label>
        </div>
        <button type="button"
                class="btn btn-primary"
                [disabled]="!canAssign"
                (click)="showFfpPlanAssignmentDialog()"
                data-test="AssignFfpPlan">
            Assign
        </button>
        <!--
        &nbsp;
        <button type="button"
                class="btn btn-primary"
                [disabled]="!selectedProject"
                (click)="showFfpPlanAssignmentWithPSLDialog()"
                data-test="AssignFfpPlan">
            Assign With PSL
        </button>
        -->
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main" *ngIf="assignPlansToOneRegion">
    <div class="top-panel" style="width: 100%">
        <ng-select name="Project"
                   bindLabel="Label"
                   [(ngModel)]="selectedProject"
                   (ngModelChange)="onProjectChanged()"
                   placeholder="Select a project"
                   [items]="projects"
                   groupBy="Status"
                   required
                   data-test="AssignPlansToOneProject">
        </ng-select>
    </div>

    <div>
        <ag-grid-angular #agGrid
                         id="ViewGrid"
                         style="width: 100%; height: 540px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="gridOptionsForPlan"
                         [rowData]="rowDataForPlan"
                         pagination="true"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onSelectionChanged($event)"
                         (filterChanged)="onFilterChangedRegion($event)">
        </ag-grid-angular>
    </div>
  </div>
</div>
