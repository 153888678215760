import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { PfPlan } from "src/app/generated-models/PfPlan";

@Component({
  templateUrl: "./pf-plan-batch-approval-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PfPlanBatchApprovalModalComponent implements OnInit {
  @Input() title: string;
  @Input() pfPlans: PfPlan[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) { }
  async ngOnInit(): Promise<void> {
    await this.batchApprovalPfPlans();
  }

  close(): void {
    this.activeModal.close(true);
  }

  async batchApprovalPfPlans() {
    await Promise.all(
      this.pfPlans.map(async (pfPlan) => {
        try {
          await this.apiService.approvePfPlan(pfPlan.ServiceTreeId, pfPlan.Version).toPromise();
          this.aggregatedResult.push(`Approve pf plan <b>${pfPlan.ServiceTreeId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Approve pf plan <b>${pfPlan.ServiceTreeId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }
}
