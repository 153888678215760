<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Plans Assignment</h2>
  </div>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body">
        <div *ngIf="!this.hasPurpose">
            <span>Choose a purpose for selected plan(s)</span>
            <ng-select class="mt-2"
                       [items]="planPurposeEnumMap"
                       [clearable]="false"
                       bindValue="key"
                       bindLabel="key"
                       [(ngModel)]="purpose"
                       name="purpose"
                       required
                       data-test="PlanAssignPurpose">
            </ng-select>
            <br />
        </div>
      <div>
          <span>Needed By</span>
          <em-datepicker class="mt-2" [(ngModel)]="startDate" [minDate]="minDate" name="StartDate"> </em-datepicker>
          <label class="info-box">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span>The date you can commit to start buildout and finish in 7 days.</span>
          </label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid" data-test="Submit">OK</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  </form>
</div>
