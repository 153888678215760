import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ExcelStyle, GridOptions } from "ag-grid-community";
import { ErrorLevel, TicketPriorityEnum, IParentRequest, RequestOperation } from "../../../npr-request.model";
import { ApiService } from "../../../services";
import { LoadingScreenService } from "../../../shared/loading-screen.service";
import { ModalService } from "../../../shared/modal.service";
import { ExpandParentRequestColumnDefinition, NestedSubRequestColumnDefinition } from "./error-requests-columns";
import { CustomTooltipComponent } from "../../../shared/auxillary-components/custom-tooltip.component";
import { RequestViewBase } from "../../../shared/request-view-base";
import { PreprocessDateTime } from "../../../utility/view-field-mapping";
import { ServiceTypeRendererComponent } from "../../../shared/az-mapping-modal/service-type-renderer.component";
import { VmSku } from "../../../generated-models";
import { SharedDataService } from "../../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
@Component({
  templateUrl: "./error-requests.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class ActiveParentRequestsComponent extends RequestViewBase implements OnInit {
  errorLevel: string = ErrorLevel.ParentRequest;
  @Input() refreshRowFunc: () => any;
  @Input() retryFunc: (row: IParentRequest) => any;

  gridOptions: GridOptions;
  rowData: IParentRequest[] = [];
  initPageSize = 15;
  selectedRowCount = 0;
  skuMapping: Map<string, string> = new Map();
  selectAllFlag = false;
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
    this.refreshRowFunc = async () => await this.getAndRenderErrorParentRequests();
  }

  async getAndRenderErrorParentRequests() {
    const requestData = await this.apiService.getAllParentRequests(false).toPromise();
    const newData = [];
    requestData.sort((a, b) => new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
    requestData.forEach((element: IParentRequest) => {
      element.Placeholder = "";
      element.IsExternalDisplay = element.IsExternal ? "Yes" : "No";
      if (element.Details && element.Details.VmSkus) {
        element.Details.VmSkus = element.Details.VmSkus.map((elem) => this.skuMapping.get(elem));
      }
      element.CreatedTime = PreprocessDateTime(element.CreatedTime);
      if (element.CompletedTime) {
        element.CompletedTime = PreprocessDateTime(element.CompletedTime);
      }
      element.RequestLink = ApiService.generateParentRequestHyperLink(element.RequestId);

      newData.push(element);
    });
    return newData;
  }

  selectAllRowsOnCurrentPage(selected: boolean) {
    const lastGridIndex = this.gridApi.getDisplayedRowCount() - 1;
    const currentPage = this.gridApi.paginationGetCurrentPage();
    const pageSize = this.gridApi.paginationGetPageSize();
    const startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      if (rowNode.group === false) {
        rowNode.setSelected(selected, false);
      }
    }
  }

  onChecked(isChecked: boolean) {
    this.selectAllRowsOnCurrentPage(isChecked);
  }

  ngOnInit() {
    // base method for ag-grid angular binding
    super.InitAgGrid(ExpandParentRequestColumnDefinition);
    this.gridOptions.detailCellRendererParams = {
      detailGridOptions: {
        defaultColDef: {
          filter: "agSetColumnFilter",
          resizable: true,
          sortable: true,
        },
        context: this,
        frameworkComponents: {
          // register angular component for customized column header
          // https://www.ag-grid.com/javascript-grid-header-rendering/#example-header-component
          customTooltip: CustomTooltipComponent,
          serviceTypeRenderer: ServiceTypeRendererComponent,
        },
        rowClass: "ticket-row",
        columnDefs: NestedSubRequestColumnDefinition,
        onGridReady: function (params) {
          // make detailGrid height automatically
          params.api.setDomLayout("autoHeight");
        },
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
        },
      },
      getDetailRowData: (params) => {
        this.apiService.getSubRequestsByParentRequestId(params.data.RequestId).subscribe((response) => {
          if (response) {
            params.data.requestData = [];
            response.forEach((element) => {
              element.RequestDisplayServiceType = ApiService.fetchServiceTypeName(element.RequestServiceType, element.SKU);
              element.Placeholder = "";
              element.RequestId = `${element.ParentRequestId}-${element.SubRequestId}`;
              let priorityNumber = element.Priority;
              priorityNumber = !priorityNumber ? 3 : priorityNumber;
              element.PriorityString = `${priorityNumber} - ${TicketPriorityEnum[priorityNumber]}`;
              element.RequestLink = ApiService.generateSubRequestHyperLink(element.ParentRequestId, element.SubRequestId.toString());
              element.CreatedTime = PreprocessDateTime(element.CreatedTime);
              if (element.CompletedTime) {
                element.CompletedTime = PreprocessDateTime(element.CompletedTime);
              }
              params.data.requestData.push(element);
            });
            // set detail row height dynamically
            params.successCallback(params.data.requestData);
            params.node.setRowHeight(null); // set null to enable auto adjustment
            this.gridApi.onRowHeightChanged(); // this triggers getRowHeight silently
          } else {
            params.data.requestData = null;
            params.successCallback(params.data.requestData);

            params.node.setRowHeight(null);
            this.gridApi.onRowHeightChanged();
          }
          this.gridColumnApi.autoSizeAllColumns();
        });
      },
    };
  }

  prepareData(requestData: IParentRequest[]) {
    // Must init for the first cache fillup
    if (this.rowData.length === 0) {
      this.rowData = [];
    }
    const newData: IParentRequest[] = [];
    requestData.sort((a, b) => new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
    requestData.forEach((element: IParentRequest) => {
      element.Placeholder = "";
      element.IsExternalDisplay = element.IsExternal ? "Yes" : "No";
      if (element.Details && element.Details.VmSkus) {
        element.Details.VmSkus = element.Details.VmSkus.map((elem) => this.skuMapping.get(elem));
      }
      element.CreatedTime = PreprocessDateTime(element.CreatedTime);
      if (element.CompletedTime) {
        element.CompletedTime = PreprocessDateTime(element.CompletedTime);
      }

      newData.push(element);
    });
    this.rowData = newData;
    // delay the auto-size to make sure data has been retrieved
    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
      this.loadingService.setLoading(false);
    }, 1000);
  }

  refreshData() {
    this.apiService.getVmSkus().subscribe((response: VmSku[]) => {
      if (response && response.length > 0) {
        response.forEach((elem) => this.skuMapping.set(elem.CrpVmSku, elem.PortalVmSku));
        this.gridApi?.showLoadingOverlay();
        this.refreshRowFunc()
          .then((response) => {
            this.prepareData(response);
          })
          .catch((error) => {
            this.gridApi?.hideOverlay();
            this.message = "Failed to get requests submitted by you. Error: " + error;
          });
      }
    });
  }

  onSelectionChanged(event) {
    this.selectedRowCount = event.api.getSelectedNodes().length;
  }

  async retryRequest() {
    const selectedRows: IParentRequest[] = this.gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      this.message = `Please select ${this.errorLevel}(s)`;
      return;
    }

    const needRefresh: boolean = await this.modalService.parentRequestResponseModal(
      "Retry Parent Ruquests",
      RequestOperation.Retry,
      selectedRows
    );
    if (needRefresh) {
      this.refreshData();
    }
  }

  async cancelRequest() {
    const selectedRows: IParentRequest[] = this.gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      this.message = `Please select ${this.errorLevel}(s)`;
      return;
    }

    const needRefresh: boolean = await this.modalService.parentRequestResponseModal(
      "Cancel Parent Ruquests",
      RequestOperation.Cancel,
      selectedRows
    );
    if (needRefresh) {
      this.refreshData();
    }
  }

  onGridReady(params: GridOptions) {
    // base method for ag-grid api setup
    super.SetAgGridContext(params);
    // base method to load data with authentication
    // the implementation is defined in derived refreshData()
    super.LoadData();

    // add your code here for handle onGridReady event
  }
  onPaginationChanged() {
    this.selectAllFlag = false;
  }

  exportExcel() {
    const fileName = `ActiveParentRequest-` + getExportedExcelFileNameSuffix();
    const sheetName = `Active Parent Request`;

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
