import { Component, OnInit, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { IGenericListRecord } from "../../npr-request.model";

@Component({
  templateUrl: "./datepicker-modal.component.html",
})
export class DatepickerModalComponent implements OnInit {
  @Input() message: string;
  @Input() options: string[];
  @Output() date: Date;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
