import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Approval } from "src/app/generated-models"
import { ApiService } from "src/app/services";

@Component({
  templateUrl: "./previous-approvals-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class PreviousApprovalsModalComponent implements OnInit {
  @Input() requestId: string;
  @Input() subrequestId: number;
  previousApprovals = new Map<string, Approval[]>();
  approvals: Approval[];
  contacts: { [key: string]: string; };
  message = "";

  constructor(
    public activeModal: NgbActiveModal,
    protected apiService: ApiService,
    protected notificationService: ToastrService) {}

  ngOnInit() {
    if (this.requestId && this.subrequestId) {
      this.apiService.getSubRequestApprovals(this.requestId, this.subrequestId).subscribe(
        (rep) => {
          this.approvals = rep.Approvals;
          this.contacts = rep.Contacts;
          if (rep.PrevApprovals) {
            rep.PrevApprovals.forEach((app: Approval) => {
              var approvals = [];
              if (this.previousApprovals.has(app.Type)) {
                approvals = this.previousApprovals.get(app.Type);
              }
              const iAt = app.By.indexOf("@");
              if (iAt > 0) {
                app.By = app.By.substring(0, iAt);
              }
              approvals.push(app);
              this.previousApprovals.set(app.Type, approvals);
            });
          }
        },
        (err) => {
          this.message = err.message;
          this.notificationService.error(this.message);
        }
      );
    } else {
      this.message = "Invalid subrequestId, Please check..."
      this.notificationService.error(this.message);
    }
  }
}
