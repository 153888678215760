<div class="input-group">
  <input
    [attr.aria-labelledby]="labelId"
    class="form-control"
    placeholder="mm/dd/yyyy"
    ngbDatepicker
    firstDayOfWeek="7"
    (blur)="onBlur()"
  />
  <button type="button" class="input-group-append p-0 border-0 rounded-right" (click)="toggleDatePicker()" [disabled]="disabled" title="calendar">
    <span class="input-group-text d-block">
      <i class="far fa-calendar-alt"></i>
    </span>
  </button>
</div>
