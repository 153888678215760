<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">FFP Plan History</h2>
  </div>
  <div class="modal-body">
    <ag-grid-angular
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      (gridReady)="onGridReady($event)"
      class="ag-theme-balham grid grid-large"></ag-grid-angular>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()">OK</button>
  </div>
</div>
