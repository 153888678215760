<div class="modal-header">
  <h4 class="modal-title">Please select</h4>
</div>
<div class="modal-body" style="height: 200px">
  <p [innerHtml]="message"></p>

  <ng-select
    name="options"
    labelForId="options"
    [items]="optionList"
    bindLabel="item_text"
    bindValue="item_text"
    [multiple]="false"
    [(ngModel)]="selectedOption"
  >
  </ng-select>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close(this.selectedOption)">Yes</button>
  <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()">No</button>
</div>
