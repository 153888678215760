import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { ApiService } from "../../services";
import { AccessRequestServiceType, RequestServiceType, allowedCommonRegionStatuses } from "../../npr-request.model";
import { Region, Team, VmSku, EdgeZone } from "../../generated-models";
import { map } from "rxjs/operators";

@Injectable()
export class DataTypes {
  private options: { [key: string]: (string | { label: string; value: string })[] } = {};
  // this map contains both oid-> name and name-> oid
  teamNameOidMap: { [key: string]: string } = {};
  regionMap: { [key: string]: string } = {};
  skuMap: { [key: string]: string } = {};
  constructor(private apiService: ApiService) {
    this.regions();
    this.edgezones();
    this.options.serviceType = Object.keys(RequestServiceType);
    this.options.accessServiceType = Object.keys(AccessRequestServiceType);
    this.options.channel = ["Lionrock", "CIS"];
    this.options.serviceHandling = ["NotAvailable", "Manual", "Automatable", "ForceAutomation", "Disabled"];
    this.options.approvalType = ["Ag", "GCT", "HDInsight", "Batch", "DNS", "CosmosDB"];
    this.options.internal = ["Internal", "External"];
    this.options.requestSource = ["EV2", "LionrockUI", "CIS"];
    this.options.regionType = ["Hero", "Hub", "Satellite", "Micro"];
    this.options.hoboAutoApprovalSupportedServiceTypes = ["ArmVmQuota", "ArmStorageQuota"];
    this.options.skipAutoApproval = ["None", "All", "Quota", "Access"];
  }

  initTeamRegion(teams: Team[], regions: Region[]): void {
    this.options.Teams = [];
    _.forEach(teams, team => {
      this.options.Teams.push({ label: `${team.Name}(${team.Oid})`, value: team.Oid });
      this.teamNameOidMap[team.Oid] = team.Name;
    });
    _.forEach(regions, region => {
      this.regionMap[region.RegionName] = region.RegionName;
    });
    this.regionMap["*"] = "*";
    this.options.requestor = teams.map((team) => {
      return { label: team.Name, value: team.Oid };
    });
    this.teamNameOidMap["*"] = "*";
    this.options.requestor["*"] = "*";
    if (this.options.region[0] !== "*") {
      this.options.region.unshift("*");
    }
  }

  initVmSku(vmsku: VmSku[]): void {
    this.options.sku = vmsku.map((sku) => {
      return { label: sku.PortalVmSku, value: sku.CrpVmSku };
    });
    vmsku.map((sku) => {
      this.skuMap[sku.CrpVmSku] = sku.PortalVmSku;
    });
  }

  regions(): void {
    this.apiService.getRegionList()
      .pipe(map(regions => regions.filter(region => allowedCommonRegionStatuses.includes(region.Status))))
      .subscribe((regions: Region[]) => (this.options.region = regions.map((r) => r.RegionName)));
  }

  edgezones(): void {
    this.apiService.getEdgeZoneList().subscribe((edgezones: EdgeZone[]) => (this.options.edgezone = edgezones.map((r) => r.EdgeZoneId)));
  }

  getOptions(type: string): (string | { label: string; value: string })[] {
    return this.options[type] || null;
  }
}
