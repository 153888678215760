<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Plan Version History</h4>
      <p>View region agnostic plan history version detailed content</p>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main" style="display: flex">
    <div class="card mb-2 mt-4 mr-1 col-md-6" style="float: left; padding: 0">
      <div class="card-header flex-row">
        <div class="float-left">
          <span>Source plan files</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular
          #agGrid
          id="ViewGrid"
          style="width: 100%; height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowSourceData"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div class="card mb-2 mt-4 ml-1 col-md-6" style="float: right; padding: 0">
      <div class="card-header flex-row">
        <div class="float-left">
          <span>Effective plan files</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular
          #agGrid
          id="ViewGrid"
          style="width: 100%; height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptionsMerged"
          [rowData]="rowMergedData"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
