<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Plan Detail</h4>
      <p>See plan detail of latest or approved version</p>
    </div>
    <div class="action-container" *ngIf="showRequeueButton">
      <ng-template #reprocessContent>
        <div class="text-left" [innerHTML]="reprocessTooltip"></div>
      </ng-template>
      <ng-template #refreshContent>
        <div class="text-left" [innerHTML]="refreshTooltip"></div>
      </ng-template>
      <button [ngbTooltip]="reprocessContent" container="body" placement="left" type="submit" class="btn btn-primary" (click)="requeue()">
        Re-process
      </button>
      &nbsp;&nbsp;
      <button [ngbTooltip]="refreshContent" container="body" placement="left" type="submit" class="btn btn-primary" (click)="refresh()">
        Refresh
      </button>
      &nbsp;&nbsp;
      <button *ngIf="actionRequired && showCapacityOrders" type="submit" class="btn btn-danger mr-1" (click)="unBlockAction()">
        Unblock Action
      </button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main" *ngIf="planRegion">
    <div class="top-panel" style="height: fit-content">
      <ul class="list-container col-md-10">
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Region</div>
          <div class="col-md-10 value">{{ planRegion.Region }}&nbsp;({{ region?.CloudName }}/{{ region?.Status }})</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Service Team</div>
          <div class="col-md-10 value">{{ planRegion.ServiceTeam }}&nbsp;({{ planRegion.ServiceTreeId }})</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Plan Version</div>
          <div class="col-md-10 value">
            {{ planVersion }} &nbsp;
            <b *ngIf="planVersion != latestVersion; else notLatestVersionTemplate">
              (Latest version is
              <a href="{{ planLatestVersionDetailLink }}" class="topbar-item" target="_blank">
                {{ latestVersion }}
              </a>
              )
            </b>
            <ng-template #notLatestVersionTemplate>(Latest version)</ng-template>
          </div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Stage</div>
          <div class="col-md-10 value">{{ stage }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Status</div>
          <div class="col-md-10 value">
            <b *ngIf="actionRequired; else noAction">
              <span [style.color]="'red'">{{ planRegion.Status }}</span> &nbsp;&nbsp;
              <i class="fa fa-info-circle" placement="right" [ngbPopover]="Popover"> </i>
              <ng-template #Popover>Blocked by CommunicationNeed order, See the order details page for more information.</ng-template>
            </b>
            <ng-template #noAction>{{ planRegion.Status }}</ng-template>
          </div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Need By Date</div>
          <div class="col-md-10 value">{{ processedNeedByDate }}</div>
        </li>
        <li class="flex-box mb-3" *ngIf="planRegion.Status === 'Approved'">
          <div class="col-md-2 label">Valid To Date</div>
          <ng-container *ngIf="isAdmin; else userTemplate">
              <ng-select style="width: 120px"
                         [items]="planValidityEnumMap"
                         [clearable]="false"
                         bindValue="key"
                         [disabled]="!isAdmin"
                         [(ngModel)]="planRegion.ValidTo"
                         name="planRegion.ValidTo">
                  <ng-template ng-label-tmp let-item="item">
                      {{ GetValidTo(item) }}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                      {{ GetValidTo(item) }}
                  </ng-template>
              </ng-select>
            <br />
            <div *ngIf="planRegion.ValidTo === 'Custom'" class="p-l-10">
              <em-datepicker [disabled]="!isAdmin" [(ngModel)]="planRegion.ValidToDate" name="validToDate"> </em-datepicker>
            </div>
            <button type="submit" class="btn btn-primary ml-2" [disabled]="IsUpdateButtonDisabled()" (click)="SubmitChanges()">
              Update
            </button>
          </ng-container>
          <ng-template #userTemplate>
            <div class="col-md-10 value">
              {{ planRegion.ValidTo }} <span *ngIf="planRegion.ValidToDate">&nbsp;| {{ processedValidToDate }} </span>
            </div>
          </ng-template>
        </li>
      </ul>
    </div>
    <div class="card mb-4">
      <div class="card-header" style="display: flex; justify-content: space-between;">
        <div>
          <span class="bold-font">Approvals</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular #agGrid
                         id="planRegionApprovalsGrid"
                         style="width: 100%; height: 100px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="planRegionApprovalsGridOptions"
                         [rowData]="planRegionApprovalsRowData"
                         pagination="true"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div class="card mb-4" style="clear: both" *ngIf="showCapacityOrders">
      <div class="card-header flex-row">
        <div class="float-left">
          <span class="bold-font">Capacity Orders</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular
          #agGrid
          id="capacityOrderGrid"
          style="width: 100%; height: 100px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="capacityOrderRowData"
          (gridReady)="onCapacityOrderGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-header">
        <div>
          <span class="bold-font">Plan Details</span>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular #agGrid
                         id="planDetailGrid"
                         style="width: 100%; height: 200px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="planDetailGridOptions"
                         [rowData]="planDetailRowData"
                         pagination="true"
                         (gridReady)="onPlanDetailGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div class="card mb-2 mt-4" style="clear: both">
      <div class="card-header flex-row">
        <div class="float-left">
          <span class="bold-font">{{ planFileName }}</span>
          <span>
            is chosen for {{ planRegion.Region }} in the order of [regionName].json -> [regionType].json or [cloudName].json ->
            default.json.</span
          >
        </div>
      </div>
      <div class="editor-container">
        <monaco-editor theme="vs" language="json" isReadonly="true" [code]="code" (ready)="onEditorReady($event)"></monaco-editor>
      </div>
    </div>
  </div>
</div>
