import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  templateUrl: "./plan-commit-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../plan.scss"],
})
export class PlanCommitModalComponent implements OnInit {
  comment: string;

  constructor(public activeModal: NgbActiveModal, private notificationService: ToastrService) {}

  async ngOnInit() {}

  async submit() {
    const result = this.comment;
    this.activeModal.close(result);
  }
}
