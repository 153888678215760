<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <p [innerHtml]="message"></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()">
    Ok
  </button>
</div>
