export const UserRoleColumnDefinition = [
  {
    headerName: "Role",
    field: "Role",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Upn",
    field: "Upn",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Regions",
    field: "Regions",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Types",
    field: "Types",
    resizable: true,
    sortable: true,
  },
];
