<div class="modal-header">
  <h4 class="modal-title">Ack Error Billing Meter Requests</h4>
</div>
<div class="modal-body">
  <h6>You are going to ack the {{errorBillingMeterRequestsCount}} error billing meter requests</h6>
  <p></p>
  <div class="form-group">
        <h6>Reason</h6>
        <label style="width: 100%">
        <ng-select
            [items]="reasons"
            bindLabel=""
            placeholder="Select Reason"
            [(ngModel)]="selectedReason"
            name="Reason"
        >
        </ng-select>
        </label>
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalConfirm()">
    Confirm
  </button>
  <button type="button" class="btn btn-warning" (click)="activeModal.close('cancel')">
    Cancel
  </button>
</div>