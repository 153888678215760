import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Region } from "src/app/generated-models";
import { ApiService } from "src/app/services";
import { ModalService } from "../modal.service";
import { allowedCommonRegionStatuses } from "../../npr-request.model";

@Component({
  selector: "service-type-cell",
  template: `<a href="javascript:void(0)" (click)="invokeMethod()" *ngIf="needClickEvent">{{ displayName }} </a
    ><span *ngIf="!needClickEvent">{{ displayName }}</span>`,
})
export class ServiceTypeRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public displayName: string;
  public needClickEvent: boolean;

  constructor(private apiService: ApiService, private modalService: ModalService) { }

  agInit(params: any): void {
    this.params = params;
    this.displayName = ApiService.fetchServiceTypeName(params.data.RequestServiceType, params.data.SKU);
    this.needClickEvent = params.data.RequestServiceType === "AZ";
  }

  public invokeMethod() {
    const subId = this.params.data.SubscriptionId;
    const region = this.params.data.Region;
    this.apiService.getRegionList().subscribe((response: Region[]) => {
      const regionList = response.filter(region => allowedCommonRegionStatuses.includes(region.Status));
      const currentRegion = regionList.find((r) => r.RegionName === region);
      this.modalService.azMappingsModal(region, subId, currentRegion.CloudName);
    });
  }

  refresh(): boolean {
    return false;
  }
}
