import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ColDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { Region } from "src/app/generated-models";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import { PlanAssignResultEnum, PlanAssignmentCSVRecord, PlanValidationErrorCode, altDocUrl } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { PlanPurposeEnum } from "src/app/shared/enums/plan-enums";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ModalService } from "src/app/shared/modal.service";
import { exportExcel, fillPlanAssignmentWarningMessageFromException, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { getKeyValuePairs } from "src/app/utility/enums";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { DateColumnFormatter, PreprocessDateTime } from "src/app/utility/view-field-mapping";

@Component({
  templateUrl: "./plan-assignment-csv-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../plan.scss"],
})
export class PlanAssignmentCSVModalComponent implements OnInit {
  @Input() rowData: PlanAssignmentCSVRecord[];
  @Input() region: Region;

  purpose: string;

  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;

  isInProgress = false;
  isCompleted = false;
  requireSkuAvailableInCrp = true;
  retried = false;

  assignResultLabel: string;

  readonly planPurposeEnumMap = getKeyValuePairs(PlanPurposeEnum);
  constructor(
    private notificationService: ToastrService,
    private loadingService: LoadingScreenService,
    protected apiService: ApiService,
    protected modalService: ModalService,
    public activeModal: NgbActiveModal
  ) { }

  columnDefinition: ColDef[] = [
    {
      headerName: "ServiceTree Id",
      field: "ServiceTreeId",
      maxWidth: 270,
    },
    {
      headerName: "Need By Date",
      field: "NeedByDate",
      maxWidth: 120,
      valueFormatter: (params) => PreprocessDateTime(params.value, DateColumnFormatter),
    },
  ];

  async ngOnInit() {
    this.gridOptions = extendDefaultOptions({
      columnDefs: this.columnDefinition,
      animateRows: true,
      sideBar: false,
      statusBar: {
        statusPanels: [{ statusPanel: "agTotalRowCountComponent", align: "right" }],
      },
      onFirstDataRendered: (params) => {
        params.api.sizeColumnsToFit();
      },
    });
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  async submit() {
    this.isInProgress = true;

    let succeed = 0;
    let failed = 0;
    let skipped = 0;
    let warning = 0;

    const selectedRows = this.gridApi.getSelectedRows() as PlanAssignmentCSVRecord[];
    // if (selectedRows.length === 0) {
    //   this.notificationService.warning(`Please select at least one row.`);
    //   return;
    // }
    if (!this.purpose) {
      this.notificationService.warning(`Please select a purpose.`);
      return;
    }

    const assignResultCellRender = (params: ColParams<any, void>) => {
      const row = params.data as PlanAssignmentCSVRecord;
      if (row.Result === PlanAssignResultEnum.Succeed) {
        return `<i class="fas fa-check-circle fa-lg green-text"></i><span>Success</span>`;
      } else if (row.Result === PlanAssignResultEnum.Failed) {
        return `<i class="fas fa-times-circle fa-lg red-text"></i><span>Failed</span>`;
      } else if (row.Result === PlanAssignResultEnum.Skipped) {
        return `<i class="fas fa-info-circle fa-lg gray-text"></i><span>Skipped</span>`;
      } else if (row.Result === PlanAssignResultEnum.Warning) {
        return `<i class="fas fa-exclamation-circle fa-lg yellow-text"></i><span>Warning</span>`;
      }
    };

    const messageCellRender = (params: ColParams<any, void>) => {
      const row = params.data as PlanAssignmentCSVRecord;
      return row.Message;
    };

    this.gridOptions.api!.setColumnDefs([
      ...this.columnDefinition,
      {
        headerName: "Service Name",
        field: "ServiceName",
        wrapText: true,
        autoHeight: true,
        maxWidth: 150,
      },
      {
        headerName: "Assign Result",
        field: "Result",
        maxWidth: 120,
        cellRenderer: assignResultCellRender,
      },
      {
        headerName: "Message",
        field: "Message",
        cellRenderer: messageCellRender,
        wrapText: true,
        autoHeight: true,
      },
    ]);

    let progress = 0;
    this.gridApi.forEachNode(async (rowNode) => {
      var rowData = rowNode.data as PlanAssignmentCSVRecord;
      var planSubmission = {
        ServiceTreeId: rowData.ServiceTreeId,
        ServiceTeam: rowData.ServiceName,
        StartDate: rowData.NeedByDate,
        Version: 0,
        Stage: "GA",
        Region: this.region.RegionName,
        Purpose: this.purpose,
      } as PlanSubmission;

      try {
        planSubmission.RequireSkuAvailableInCrp = this.requireSkuAvailableInCrp;

        var response = await this.apiService.assignPlanToRegion(planSubmission).toPromise();
        rowData.Result = response.Result;
        rowData.ServiceName = response.ServiceName;
        rowData.Message = response.Message;
        if (response.Result == PlanAssignResultEnum.Warning) {
          this.notificationService.warning("Warning: " + response.Message);
        }
        if (response.Message.includes("Assignment reconfirmation")) {
          this.modalService.planAssignmentReconfirmModal(planSubmission).then(
            (r) => {
              if (r == "cancelled") {
                rowData.Message += `Assign cancelled.`;
              } else if (r == "successful") {
                rowData.Message += `Assign successfully.`;
              } else {
                rowData.Message += `Assign failed with error : ${r}.`;
              }
            },
            (err) => {
              rowData.Message += `Invalid operation: ${err}`;
            }
          );
        }
      } catch (ex) {
        rowData.Result = ex?.error.Result;
        rowData.ServiceName = ex?.error.ServiceName;

        var warningMessage = "";
        if (ex?.error?.Code == PlanValidationErrorCode.PlanValidationCrpSkuNotAvailable) {
          this.requireSkuAvailableInCrp = false;
          if (!this.retried) {
            warningMessage = fillPlanAssignmentWarningMessageFromException(ex, warningMessage);
            warningMessage += `<b>Close</b> and add some <a href="${altDocUrl}" target="_blank" >alternatives</a> available in the region before re-assigning.<br/> Or <b>[Proceed]</b> to acknowledge the risk and continue assigning with the current plan.<br/>`;
          }
        } else {
          warningMessage = ex?.error.Message;
        }

        rowData.Message = `<font color="red">${warningMessage}</font>`;
      }

      rowNode.setData(rowData);

      if (rowData.Result === PlanAssignResultEnum.Succeed) {
        succeed++;
      } else if (rowData.Result === PlanAssignResultEnum.Failed) {
        failed++;
      } else if (rowData.Result === PlanAssignResultEnum.Skipped) {
        skipped++;
      } else if (rowData.Result === PlanAssignResultEnum.Warning) {
        warning++;
      }

      progress++;
      if (progress === this.rowData.length) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
          //this.gridColumnApi.autoSizeAllColumns();
        }, 100);

        this.isInProgress = false;
        this.isCompleted = true;

        this.assignResultLabel = `Succeed: ${succeed}, Failed: ${failed}, Skipped: ${skipped}, Warning: ${warning}`;
      }
    });

    // try {
    //   this.activeModal.close({
    //     purpose: this.purpose,
    //     rows: selectedRows,
    //   });
    // } catch (e) {
    //   this.notificationService.error(e);
    // }
  }

  async continue() {
    this.retried = true;
    this.assignResultLabel = "";
    await this.submit();
  }

  exportExcel() {
    var fileName = `Assign result-` + getExportedExcelFileNameSuffix();
    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, fileName);
    this.loadingService.setLoading(false);
  }
}
