import * as CDs from "src/app/utility/commonColDef";

export const RegionColumnDefinitionForAdmin = [
  { ...CDs.RegionColDef("RegionName"), valueType: "region" },
  {
    ...CDs.Enabled,
    isEditable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["true", "false"],
    },
    valueType: "boolean",
  },
  { headerName: "TicketAssignTo", field: "TicketAssignTo", isEditable: true },
  { headerName: "TicketChannel", field: "TicketChannel", isEditable: true },
  { headerName: "ConstraintNote", field: "ConstraintNote", isEditable: true, valueType: "textarea" },
  {
    headerName: "Flighting",
    field: "IsFlighting",
    isEditable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["true", "false"],
    },
    valueType: "boolean",
  },
  {
    headerName: "Enabled in UI",
    field: "EnabledInUI",
    isEditable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["true", "false"],
    },
    valueType: "boolean",
  },
  { headerName: "SkipAutoApproval", field: "SkipAutoApproval", isEditable: true, valueType: "skipAutoApproval" },
  {
    headerName: "EnabledForCustomerEA",
    field: "EnabledForCustomerEA",
    isEditable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["true", "false"],
    },
    valueType: "boolean",
  },
  {
    headerName: "EnabledBetOnDemand",
    field: "EnabledBetOnDemand",
    isEditable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["true", "false"],
    },
    valueType: "boolean",
  },
  {
    headerName: "IsCappedForCapacityOrder",
    field: "IsCappedForCapacityOrder",
    isEditable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["true", "false"],
    },
    valueType: "boolean",
  },
  {
    headerName: "Default PSL",
    field: "DefaultPSL",
    cellRenderer: (params) => {
      return params.value
        ? '<a href="https://global.azure.com/product-roadmap/psl/' + params.value + '" target="_blank">' + params.value + "</a>"
        : "";
    },
    isEditable: true,
    valueType: "number",
  },
];

export const EdgeZoneColumnDefinitionForAdmin = [
  { ...CDs.EdgeZoneColDef("EdgeZoneId"), valueType: "edgezone" },
  {
    headerName: "EnabledBetOnDemand",
    field: "EnabledBetOnDemand",
    isEditable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["true", "false"],
    },
    valueType: "boolean",
  },
];

export const DefaultColumnDefinitionForAdmin = {
  resizable: true,
  sortable: true,
  filter: "agSetColumnFilter",
  tooltipComponent: "customTooltip",
};

export const AutoApprovalRuleColumnDefinitionForAdmin = [
  { ...CDs.Id, valueType: "number", hide: true },
  { ...CDs.RequestServiceType, valueType: "serviceType" },
  { ...CDs.SubscriptionType, wildcard: true, valueType: "internal" },
  { ...CDs.RequestSource, wildcard: true, valueType: "requestSource" },
  { ...CDs.ApprovalType, valueType: "approvalType" },
  { ...CDs.RegionColDef("Region"), wildcard: true, valueType: "region" },
  { ...CDs.Quota, isEditable: true, valueType: "number" },
];

export const UserRoleColumnDefinitionForAdmin = [
  { headerName: "Role", field: "Role", valueType: "roles" },
  { headerName: "UPN", field: "Upn", valueType: "upn" },
  { headerName: "Regions", field: "Regions", isEditable: true, wildcard: true, valueType: "region" },
  { headerName: "Types", field: "Types", isEditable: true },
];

export const ManatreeColumnDefinitionForAdmin = [
  { headerName: "ManatreeName", field: "ManatreeName", valueType: "string" },
  { headerName: "ServiceTreeId", field: "ServiceTreeId", valueType: "Teams" },
];

export const ConfigColumnDefinitionForAdmin = [
  { ...CDs.Id, valueType: "number", hide: true },
  { headerName: "ConfigName", field: "ConfigKey" },
  { headerName: "ConfigType", field: "ConfigType" },
  { headerName: "Value", field: "Value", isEditable: true },
  { headerName: "Flighting Value", field: "FlightingValue", isEditable: true },
];

export const ServiceHandlingColumnDefinitionForAdmin = [
  { ...CDs.Id, valueType: "number", hide: true },
  { headerName: "Region", field: "ConfigType", valueType: "region" },
  { headerName: "Service Type", field: "ConfigKey", valueType: "serviceType" },
  { headerName: "Handling", field: "Value", isEditable: true, valueType: "serviceHandling", isNullable: true },
  { headerName: "Flighting Value", field: "FlightingValue", isEditable: true, valueType: "serviceHandling", isNullable: true },
];

export const AzColumnDefinitionForAdmin = [
  { headerName: "AZ Name", field: "AvailabilityZoneName", valueType: "availabilityZoneName" },
  { headerName: "Region Name", field: "RegionName", valueType: "regionName" },
  { headerName: "DCMT Region Id", field: "DCMTRegionId", valueType: "dcmtRegionId" },
  { headerName: "Business Lifecycle", field: "BusinessLifecycle", valueType: "businessLifecycle" },
  { headerName: "State", field: "State", valueType: "state" },
  { headerName: "IsLive", field: "IsLive", valueType: "boolean" },
  { headerName: "Is supported in Geneva Action", field: "IsSupportedInGenevaAction", valueType: "boolean" },
  { headerName: "AFEC flag", field: "RequiredFeature" },
];

export const HdiSkuColumnDefinitionForAdmin = [
  { headerName: "Region Name", field: "RegionName", valueType: "regionName" },
  { headerName: "CrpVmSku", field: "CrpVmSku", valueType: "crpVmSku" },
];

export const ServiceReadinessColumnDefinitionForAdmin = [
  { headerName: "Region Name", field: "Region", valueType: "region" },
  { headerName: "Service", field: "Service", valueType: "service" },
  { headerName: "Is service ready", field: "IsServiceReady", valueType: "isServiceReady" },
];

export const ServiceReadinessStageColumnDefinitionForAdmin = [
  { headerName: "Service", field: "Service", valueType: "service" },
  { headerName: "Region Name", field: "Region", valueType: "region" },
  { ...CDs.Stage, valueType: "stage" },
  { headerName: "Updated time", field: "UpdatedTime", valueType: "updatedTime" },
];

export const SqlColumnDefinitionForAdmin = [
  { headerName: "Region Name", field: "Region", valueType: "region" },
  { headerName: "Sql Regional Feature Value", field: "SqlRegionalFeatureValue", valueType: "sqlRegionalFeatureValue" },
];

export const ArmResourceTypeColumnDefinitionForAdmin = [
  { headerName: "Namespace", field: "Namespace", valueType: "namespace" },
  { headerName: "Resource Type Name", field: "ResourceTypeName", valueType: "resourceTypeName" },
  { headerName: "Location", field: "Location", valueType: "location" },
  { headerName: "Feature To Assign", field: "FeatureToAssign", valueType: "featureToAssign" },
  { headerName: "Required Features", field: "RequiredFeatures", valueType: "requiredFeatures" },
  { headerName: "Required Features Policy", field: "RequiredFeaturesPolicy", valueType: "requiredFeaturesPolicy" },
  { headerName: "Endpoint Uri", field: "EndpointUri", valueType: "endpointUri" },
];

export const VmDiskColumnDefinitionForAdmin = [
  { headerName: "Disk Quota Type Name", field: "QuotaName" },
  { headerName: "Disk Quota Type", field: "QuotaType" },
  { headerName: "Max Disk Quota Limit", field: "MaxQuotaLimit", valueType: "number" },
  { headerName: "Location", field: "Location" },
];

export const VmSkuColumnDefinitionForAdmin = [
  { headerName: "Region Name", field: "RegionName", valueType: "regionName" },
  { headerName: "CrpVmSku", field: "CrpVmSku", valueType: "crpVmSku" },
  { headerName: "Supported In Crp", field: "SupportedInCrp", valueType: "boolean" },
  { headerName: "Is Internal", field: "IsInternal", valueType: "boolean" },
  { headerName: "Required Features", field: "RequiredFeatures" },
];

export const RegionTableColumnDefinitionForAdmin = [
  { ...CDs.RegionColDef("RegionName"), valueType: "region" },
  { headerName: "Arm Region Name", field: "ArmRegionName", valueType: "armRegionName" },
  { headerName: "Required Features", field: "RequiredFeatures" },
  { headerName: "AZ Feature Flag", field: "AZFeatureName", valueType: "AZFeatureName" },
  { headerName: "Buildout Arm Feature Flag", field: "BuildoutArmFeatureFlag", valueType: "buildoutArmFeatureFlag" },
  { headerName: "Cloud Name", field: "CloudName", valueType: "cloudName" },
  { headerName: "Region Type", field: "RegionType", valueType: "RegionType" },
  { headerName: "Customer Arm Feature Flag", field: "CustomerArmFeatureFlag", valueType: "customerArmFeatureFlag" },
  { headerName: "Dcmt Id", field: "DcmtId", valueType: "dcmtId" },
  { headerName: "Dcmt Region Id", field: "DCMTRegionId", valueType: "dcmtRegionId" },
  { headerName: "Status", field: "Status", valueType: "status" },
  { headerName: "Centralized Arm Feature Flag", field: "CentralizedArmFeatureFlag", valueType: "centralizedArmFeatureFlag" },
  { headerName: "Current Architecture", field: "CurrentArchitecture", valueType: "currentArchitecture" },
  { headerName: "Short Id", field: "ShortId", valueType: "shortId" },
  { headerName: "MA Launch Date", field: "MALaunchDate", valueType: "maLaunchDate" },
  { headerName: "Is capacity order supported", field: "IsCapacityOrderSupported", valueType: "isCapacityOrderSupported"},
  { headerName: "PSL", field: "PSL" },
];

export const ProjectColumnDefinitionForAdmin = [
  { ...CDs.ProjectOidColDef("ProjectOid"), valueType: "projectOid" },
  { headerName: "Project Name", field: "Title", valueType: "projectName" },
  { headerName: "RegionOid", field: "RegionOid", valueType: "regionOid" },
  { headerName: "Project Status", field: "ProjectStatus", valueType: "projectStatus" },
  { headerName: "Plan Type", field: "PlanType", valueType: "planType" },
  { headerName: "Business Scenario", field: "BusinessScenario", valueType: "businessScenario" },
  { headerName: "Buildout PSL", field: "BuildoutPSL", valueType: "buildoutPSL" },
  { headerName: "MA Launch Date", field: "MALaunchDate", valueType: "maLaunchDate" },
  { headerName: "Project Architecture", field: "ProjectArchitecture", valueType: "projectArchitecture" },
  { headerName: "Opportunity Type", field: "OpportunityType", valueType: "opportunityType" },
];
