import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IParentRequest, RequestOperation, RequestStatus } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";

@Component({
  templateUrl: "./parentrequests-response-modal.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class ParentRequestsResponseModalComponent implements OnInit {
  @Input() title: string;
  @Input() requestOperation: RequestOperation;
  @Input() parentRequests: IParentRequest[];

  public forceRetry = false;
  public aggregatedResult: string[] = [];
  public isCompleted = false;
  public inProgress = false;
  public confirmation = true;
  public message = "";
  public isRetry = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  ngOnInit() {
    this.confirm();
  }

  confirm() {
    this.inProgress = false;
    this.confirmation = true;
    this.title = "Please Confirm";
    this.message = `Are you sure want to ${this.requestOperation} parent request(s)?`;
    if (this.requestOperation == RequestOperation.Retry) {
      this.isRetry = true;
    }
  }

  async start() {
    this.inProgress = true;
    this.confirmation = false;
    this.title = `${this.requestOperation} parent request(s)`;

    switch (this.requestOperation) {
      case RequestOperation.Retry:
        await this.retryParentRequests();
        break;
      case RequestOperation.Cancel:
        await this.cancelParentRequests();
        break;
      case RequestOperation.SkipDependencies:
        await this.skipDependenciesOfParenetRequests();
        break;
    }
  }

  close() {
    if (this.confirmation) {
      this.activeModal.close(false);
    } else {
      this.activeModal.close(true);
    }
  }

  async retryParentRequests() {
    await Promise.all(
      this.parentRequests.map(async (req) => {
        try {
          await this.apiService.retryParentRequests(req.RequestId, this.forceRetry).toPromise();
          this.aggregatedResult.push(`Retry request <b>${req.RequestId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Retry request <b>${req.RequestId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }

  async cancelParentRequests() {
    await Promise.all(
      this.parentRequests.map(async (req) => {
        try {
          await this.apiService.cancelParentRequests(req.RequestId).toPromise();
          this.aggregatedResult.push(`Cancel request <b>${req.RequestId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Cancel request <b>${req.RequestId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }

  async skipDependenciesOfParenetRequests() {
    await Promise.all(
      this.parentRequests.map(async (req) => {
        try {
          await this.apiService.skipDependenciesOfParentRequests(req.RequestId).toPromise();
          this.aggregatedResult.push(`Dependencies of parent request <b>${req.RequestId}</b> have completed.`);
        } catch (ex) {
          this.aggregatedResult.push(`Dependencies of parent request <b>${req.RequestId}</b> are failed due to error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }
}
