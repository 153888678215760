import { Component, OnInit } from "@angular/core";
import { ColumnApi, GridApi, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-community";
import { ViewCellComponent, ViewCellComponentParams } from "src/app/shared/grid/view-cell.component";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { BaseComponent } from "../../shared/base.component";
import { ToastrService } from "ngx-toastr";
import { Email } from "../../generated-models/Email";
import { extendDefaultOptions } from "../../utility/gridHelper";
import * as CDs from "src/app/utility/commonColDef";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { LionrockEmailType } from "../../shared/enums/plan-enums";
import { ModalService } from "src/app/shared/modal.service";
import { EmailDetailModalOptions } from "src/app/shared/email-detail-modal/email-detail-modal.component";

@Component({
  templateUrl: "./admin-view-emails-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewEmailsViewComponent extends BaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  rowModelType;
  serverSideStoreType;
  paginationPageSize;
  cacheBlockSize;
  rowData: Email[] = [];
  initPageSize = 15;

  constructor(private modalService: ModalService, private apiService: ApiService, private loadingService: LoadingScreenService, private notificationService: ToastrService) {
    super();
    this.rowModelType = "serverSide";
    this.serverSideStoreType = "partial";
    this.paginationPageSize = 15;
    this.cacheBlockSize = 15;
  }

  ngOnInit() {
    const detailViewCellComponentParams: ViewCellComponentParams<Email> = {
      viewFunc: this.viewEmailDetail.bind(this),
    };
    this.gridOptions = extendDefaultOptions({
      columnDefs: [
        {
          ...CDs.RequestId,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          }
        },
        {
          ...CDs.EmailId,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          }
        },
        {
          ...CDs.SubscriptionId("SubscriptionId"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.Type,
          filter: "agSetColumnFilter",
          filterParams: {
            values: Object.values(LionrockEmailType),
          },
        },
        {
          ...CDs.Cc,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.EmailBatchID,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.MessageId,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.MessageStatus,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.Details,
          cellRendererFramework: ViewCellComponent,
          cellRendererParams: detailViewCellComponentParams,
        },
        {
          ...CDs.CreatedTime,
          cellRenderer: (params) => PreprocessDateTime(params.value),
        },
        {
          ...CDs.CompletedTime,
          cellRenderer: (params) => PreprocessDateTime(params.value),
        },
      ],
      paginationPageSize: this.initPageSize,

      getRowHeight: (params) => {
        const defaultRowHeight = params.node.rowHeight;
        if (params.data.detailRows) {
          const numOfRows = Number(params.data.detailRows.length);
          const defaultColumnHeight = defaultRowHeight * 1.2;
          const spareSpace = defaultRowHeight * 0.5;
          const detailRowsHeight = Math.max(2, numOfRows) * defaultRowHeight;
          return defaultRowHeight + detailRowsHeight + defaultColumnHeight + spareSpace;
        } else {
          return defaultRowHeight;
        }
      },
    });
    this.loadingService.setLoading(true);
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    window.setTimeout(() => {
      const colIds = this.gridColumnApi.getAllColumns().map((c) => c.getColId());
      this.gridColumnApi.autoSizeColumns(colIds);
    }, 50);
    params.api.setServerSideDatasource(this.dataSource);
    this.loadingService.setLoading(false);
  }

  dataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.apiService
        .getEmailsPagination(
          params.request.startRow,
          params.request.endRow,
          params.request.sortModel,
          params.request.filterModel
        )
        .subscribe((response) => {
          setTimeout(function () {
            if (response.Success) {
              params.success({
                rowData: response.Emails,
                rowCount: response.TotalCount,
              });
            } else {
              params.fail();
            }
          }, 200);
        });
    },
  };

  getColumnSelections(params) {
    this.apiService?.getEmailsColumnSelection(params.colDef.field).subscribe(
      (response: string[]) => {
        setTimeout(function () {
          params.success(response);
        }, 500);
      },
      () => {
        this.loadingService.setLoading(false);
      }
    );
  }

  viewEmailDetail(email: Email) {
    const options: EmailDetailModalOptions = {
      model: email,
      isReadonly: true
    };
    this.modalService.emailDetailModal(options);
  }
}
