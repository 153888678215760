<div class="title">
  <div class="container container-max-width">
    <div>
      <h4>Bindings</h4>
      <p>Subscription and resource bindings of my services</p>
    </div>
    <div class="action-container">
      <button type="submit" class="btn btn-primary" (click)="refresh()">Refresh</button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" *ngIf="!notFound">
  <div class="mt-3">
    <b>Subscription bindings</b>
    <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel('Subscription')"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
    </a>
    <ag-grid-angular
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="subscriptionBindingGridOptions"
      [rowData]="subscriptionBindings"
      [excelStyles]="headerStyle"
      pagination="true"
      (gridReady)="onGridReadySub($event)"
      (filterChanged)="onFilterChangedSub($event)"
    >
    </ag-grid-angular>
  </div>
  <div class="mt-3">
    <b>Resource bindings</b>
    <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel('Resource')"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
    </a>
    <ag-grid-angular
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="propertyBindingGridOptions"
      [rowData]="propertyBindings"
      [excelStyles]="headerStyle"
      pagination="true"
      (gridReady)="onGridReadyProp($event)"
      (filterChanged)="onFilterChangedProp($event)"
    >
    </ag-grid-angular>
  </div>
</div>
<h5 class="font-weight-bold" *ngIf="notFound">Not Found</h5>
