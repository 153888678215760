<div class="title" #title>
  <div class="container container-max-width">
    <h4>Revoke access and quota (self service)</h4>
    <p style="line-height: 20px">
      Only service tree admin/owners can perform the operation on the subscriptions
      <br />
    </p>
  </div>
</div>

<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <form #SelfServiceInfoRequestForm="ngForm" autocomplete="off" style="height: 800px" novalidate>
      <!--Subscription ID-->
      <div class="form-group">
        <h6>Subscription:</h6>
        <textarea
          class="form-control"
          name="subscriptionId"
          title="subscriptionId"
          id="subscriptionId"
          rows="1"
          placeholder="subscription id"
          [(ngModel)]="subscriptionId"
          placeholder="Enter in this format: 987e33bf-8713-45cf-93c5-7055c9cca413"
          required
        ></textarea>
        <em
          *ngIf="SelfServiceInfoRequestForm.controls.subscriptionId?.invalid && SelfServiceInfoRequestForm.controls.subscriptionId?.touched"
          >Required</em
        >
        <em *ngIf="!SelfServiceInfoRequestForm.controls.subscriptionId?.invalid && this.subValidationError?.length != 0">{{
          this.subValidationError
        }}</em>
      </div>
      <!--Region-->
      <div class="form-group">
        <h6>Region:</h6>
        <label style="width: 100%">
          <ng-select
            [(ngModel)]="region"
            name="Region"
            [items]="regionList"
            groupBy="cloud"
            (change)="onRegionChanged()"
            placeholder="Enter region name"
            required>
          </ng-select>
        </label>
      </div>
      <!--Service Type-->
      <div class="form-group">
        <h6>Service Type:</h6>
        <label style="width: 100%">
          <ng-select [(ngModel)]="serviceType" name="ServiceType" [items]="serviceTypeList" placeholder="Enter service type name" required>
          </ng-select>
        </label>
      </div>
      <!--SKU-->
      <div *ngIf="isSkuVisible" class="form-group">
        <h6>SKU:</h6>
        <label style="width: 100%">
          <ng-select [(ngModel)]="sku" [items]="vmSkuList" [multiple]="false" name="Sku" required> </ng-select>
        </label>
      </div>
      <!--Quota-->
      <div *ngIf="isQuotaVisible" class="form-group">
        <h6>New Quota:</h6>
        <input type="number" class="form-control" [(ngModel)]="quota" [ngModelOptions]="{ standalone: true }" placeholder="New quota" title="quota" />
        <em *ngIf="!isQuotaValid">New quota is required and must be greater than 0</em>
      </div>
      <!--Submit Button-->
      <div class="form-group nofloat">
        <button type="submit" class="btn btn-primary mr-1" [disabled]="SelfServiceInfoRequestForm.invalid" (click)="submitRequest()">
          Submit
        </button>
      </div>
      <hr />
      <br />
    </form>
  </div>
</div>
