import { Component, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NprConfiguration } from "src/app/app.configuration";
import { PlannedQuotaRequest, Region, Team } from "src/app/generated-models";
import { ITeamListRecord, IUserProfile, allowedCommonRegionStatuses } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { BaseComponent } from "src/app/shared/base.component";
import { RegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { isNonEmptyString } from "src/app/utility/common-helper";

@Component({
  selector: "create-planned-quota-request",
  templateUrl: "./create-planned-quota-request.component.html",
  styleUrls: ["../create-request/create-request.scss", "../../styles.scss"],
})
export class CreatePlannedQuotaRequestComponent extends BaseComponent implements OnInit {
  userProfile: IUserProfile;
  request: PlannedQuotaRequest = {
    RequestId: "",
    RequestSource: "",
    SubscriptionId: "",
    ServiceTeamName: "",
    ServiceTreeId: null,
    Ring: "",
    ContactEmail: "",
    Region: null,
    SubscriptionKey: "",
    Stage: "",
    Status: "",
    SubscriptionBindingKey: "",
    CreatedTime: new Date(),
    SkipUnsupportedSilently: false,
    PlanVersion: null,
    PropertyBindings: null,
    CompletedTime: null,
    CisJobType: "",
    CisJobId: "",
    CloudName: "",
  };
  teamList: ITeamListRecord[] = [];
  regionList: Region[] = [];
  isOnboardEnabled: boolean;

  regionEnum = RegionStatusEnum;

  guidRegExpression = NprConfiguration.guidRegExpression;
  emailRegexp = new RegExp(NprConfiguration.emailRegExpression);

  message: string;

  constructor(private apiService: ApiService, private loadingService: LoadingScreenService, private notificationService: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.apiService
      .getUserProfile()
      .then((response) => {
        if (response) {
          this.userProfile = response;
          this.isOnboardEnabled = this.userProfile.IsAdmin || this.userProfile.UserRoles.some((_) => _.Services.length > 0);
          this.getTeamsList();
          this.getRegionList();
        } else {
          this.notificationService.error("user profile returns null, no data was loaded.");
        }
      })
      .catch((error) => {
        this.notificationService.error(`unable to get user profile, error: ${error.message}`);
      });
  }

  isTouchedOrDirty(control: NgModel): boolean {
    return control && (control.touched || control.dirty);
  }

  getTeamsList(): void {
    this.loadingService.setLoading(true);
    // Team List
    this.apiService?.getTeamList().subscribe(
      (response) => {
        let teams: Team[] = [];
        if (response) {
          if (this.userProfile.IsAdmin) {
            teams = response;
          } else {
            response.map((r) => {
              if (this.userProfile.UserRoles.some((_) => _.Services.includes(r.Oid))) {
                teams.push(r);
              }
            });
          }
        }

        const regionlessTeam = [];
        teams.map((r) => {
          if (r.Ring) {
            this.teamList.push({
              label: `${r.Name} (${r.Oid})`,
              value: 0,
              ring: r.Ring,
              oid: r.Oid,
            } as ITeamListRecord);
          } else {
            regionlessTeam.push({
              label: `${r.Name} (${r.Oid})`,
              value: 0,
              ring: "No Ring",
              oid: r.Oid,
            } as ITeamListRecord);
          }
        });
        this.teamList = this.teamList
          .sort((a, b) => {
            if (a.label === null) {
              console.log(a);
            }
            return a.ring.localeCompare(b.ring) || a.label.localeCompare(b.label);
          })
          .concat(regionlessTeam.sort((a, b) => a.label.localeCompare(b.label)));
        this.loadingService.setLoading(false);
      },
      (e: unknown) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      }
    );
  }

  getRegionList(): void {
    // Region List
    this.apiService?.getRegionList().subscribe((response: Region[]) => {
      if (!response) {
        console.warn("No region is obtained from api server.");
      } else {
        this.regionList = response
          .filter(region => allowedCommonRegionStatuses.includes(region.Status))
          .filter((r) => r.IsEnabled)
          .sort((region1, region2) => {
            return this.regionEnum[region1.Status.replace(/\s/g, "")] - this.regionEnum[region2.Status.replace(/\s/g, "")];
          });
      }
    });
  }

  onTeamSelected(team: ITeamListRecord): void {
    if (team) {
      this.request.ServiceTreeId = team.oid;
    }
  }

  onRegionSelected(region: Region): void {
    if (region) {
      this.request.Region = region.RegionName;
    }
  }

  isReadyToCreate(): boolean {
    return (
      this.isOnboardEnabled &&
      this.isTeamValid() &&
      this.isEmailValid &&
      this.isRegionValid() &&
      this.isSubIdPatternValid() &&
      this.isSubKeyValid()
    );
  }

  isTeamValid(): boolean {
    return this.request.ServiceTreeId && this.teamList.some((team) => team.oid === this.request.ServiceTreeId);
  }

  get isEmailValid(): boolean {
    return (
      isNonEmptyString(this.request.ContactEmail) &&
      this.request.ContactEmail.split(/[,;]/).every((emailItem) => this.emailRegexp.test(emailItem))
    );
  }

  isRegionValid(): boolean {
    return this.regionList.some((region) => region.RegionName === this.request.Region);
  }

  isSubIdPatternValid(): boolean {
    return new RegExp(this.guidRegExpression).test(this.request.SubscriptionId);
  }

  isSubKeyValid(): boolean {
    return this.request.SubscriptionKey && this.request.SubscriptionKey.trim().length > 0 && !this.request.SubscriptionKey.startsWith(" ");
  }

  createPlannedQuotaRequest(): void {
    this.loadingService.setLoading(true);

    this.apiService.createPlannedQuotaRequest(this.request).subscribe(
      () => {
        this.message = `New Planned Quota Request has been created successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err: unknown) => {
        this.notificationService.error(err as string);
        this.loadingService.setLoading(false);
      }
    );
  }
}
