<div class="modal-header modal-header-sub">
  <h5 class="modal-title">View Zone Mappings</h5>
  <h6>{{ errorMessage }}</h6>
</div>
<div class="modal-body">
  <mat-progress-bar [mode]="'indeterminate'" *ngIf="isLoading"> </mat-progress-bar>
  <ul *ngFor="let info of azMappings">
    <li>
      <td>Logical Zone: {{ info.LogicalZone }}:</td>
      <td>{{ info.PhysicalZone }}</td>
    </li>
  </ul>
</div>
<a class="modal-header-close" href="javascript:void(0)" (click)="activeModal.close({})" container="body" aria-label="Close">
  <span class="fa fa-times fa-lg"></span>
</a>
