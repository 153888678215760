<div class="title" #title>
  <div class="container container-max-width">
    <h4>Active Sub Requests</h4>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <!-- selections -->
    <br />
    <div class="hint leftalign">
      <br />
      {{ selectedRowCount }} of {{ rowData.length }} selected
    </div>

    <div class="sbutton float-right">
      <button type="submit" class="btn btn-success mr-1" (click)="retrySubRequests()">Retry</button>
      <button type="submit" class="btn btn-danger" (click)="cancelSubRequests()">Cancel Selected Requests</button>
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel()"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
    </div>

    <div class="nofloat">
      <p class="message">{{ this.message }}</p>
    </div>

    <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
    <form id="TicketListForm" #ticketListForm="ngForm" autocomplete="off" novalidate>
      <div class="nofloat">
        <ag-grid-angular
          style="width: 100%; height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          [excelStyles]="headerStyle"
          [masterDetail]="true"
          pagination="true"
          rowSelection="multiple"
          (gridReady)="onGridReady($event)"
          (rowGroupOpened)="onRowGroupOpened($event)"
          (paginationChanged)="onPaginationChanged()"
          (selectionChanged)="onSelectionChanged($event)"
          (filterChanged)="onFilterChanged($event, 'filter:active-subrequest')"
        >
        </ag-grid-angular>
      </div>
    </form>
  </div>
</div>
