import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ModalService } from "../../shared/modal.service";

@Component({
  selector: "subscription-cell",
  template: `<a href="javascript:void(0)" (click)="invokeMethod()">{{ params.value }}</a
    >&nbsp;<span>{{ additionalMsg }}</span>`,
})
export class SubscriptionRendererComponent implements ICellRendererAngularComp {
  public params: any;
  additionalMsg: string = "";

  constructor(private modalService: ModalService) {}

  agInit(params: any): void {
    this.params = params;
    if (params.data.SubscriptionsCount && params.data.SubscriptionsCount > 1) {
      // More than one sub, format message to [first subscription] and [Count - 1] more
      this.additionalMsg = ` and ${params.data.SubscriptionsCount - 1} more`;
    }
  }
  // other parts of the component

  public invokeMethod() {
    this.getSubscriptionDetails(this.params.value);
  }

  refresh(): boolean {
    return false;
  }

  private getSubscriptionDetails(subscriptionId: string) {
    this.modalService.subscriptionDetailsModal("Subscription Details", subscriptionId);
  }
}
