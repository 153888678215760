<div class="title">
  <div class="container">
    <h4>New Region Billing Meters</h4>
    <p>
      This UI is used only by Region Buildout PM's for meter submission during region buildouts. Contact <a href="mailto:bowloper@microsoft.com">Lionrock Support</a> to request access if you are a Region Buildout PM. All other requests will be rejected.
    </p>
    <p>
      Service owners: Click <a href="/quota/prep/ondemand">here</a> to submit service level meter requests. Click <a target="_blank" href="https://aka.ms/billingmeterfaq">here</a> for FAQ’s.
    </p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isAuthorizedUser">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && isAuthorizedUser && !userCanInitialBuildout">
  <h5>Permission is required!</h5>
  <h5>Please Contact <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add the permission </h5>
</div>
<div class="container container-content" *ngIf="isLoadingFinished && userCanInitialBuildout">
  <div class="main-content">
    <div class="form-group">
      <h4>Select Cloud and Region to get started</h4>
    </div>
    <div class="region-select">
      <div class="form-group" style="float: left">
        <h6>Cloud</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.clouds"
            bindLabel="CloudName"
            placeholder="Select Cloud"
            (change)="onCloudChange($event)"
            [(ngModel)]="dataService.selectedCloud"
            name="CloudName"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Cloud not found</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Region</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.initialRegions"
            bindLabel="Name"
            placeholder="Select Region"
            (change)="onRegionChange()"
            [(ngModel)]="dataService.selectedRegion"
            [disabled]="dataService.selectedCloud == null"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Region not found</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Edge Zone</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.regionEdgeZones"
            bindLabel="EdgeZoneName"
            placeholder="Select Edge Zone"
            (change)="onEdgeZoneChange()"
            [(ngModel)]="dataService.selectedEdgeZone"
            name="EdgeZoneName"
            [disabled]="dataService.selectedRegion == null || dataService.regionEdgeZones.length == 0"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Edge Zone not found</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
    </div>
    <div class="form-group">
      <button
        type="button"
        class="btn btn-primary mr-1"
        [disabled]="disableFetchInScopeService()"
        (click)="onInScopeServicesFetched()"
      >
        Fetch in-scope Offerings
      </button>
    </div>

    <hr />
    <div class="form-group">
      <button type="button" class="btn btn-link" (click)="bulkSubmissionCollapse=!bulkSubmissionCollapse" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
        <i class="fa" [ngClass]="{'fa-angle-down': !bulkSubmissionCollapse, 'fa-angle-up': bulkSubmissionCollapse}"></i>
        Custom Bulk Billing Meter Submission
      </button>
      <div *ngIf="bulkSubmissionCollapse">
        <textarea
          class="form-control"
          [(ngModel)]="product"
          name="product"
          id="product"
          type="text"
          placeholder="Please input the CaymanProductIds, ProductOids or ProductNames you want to submit with comma or newline separated.&#10;For example:&#10;7e1fb574-eb4a-45d7-8db2-c85445aac53f&#10;938a0b4e-849d-4967-8d0d-84a2dce13065&#10;or&#10;7e1fb574-eb4a-45d7-8db2-c85445aac53f,938a0b4e-849d-4967-8d0d-84a2dce13065"
          rows="10"
          required
        >
        </textarea>
        <button
          style="margin-top: 20px"
          type="button"
          class="btn btn-primary mr-1"
          [disabled]="product == ''"
          (click)="bulkSubmitPreview()"
        >
        Bulk Submit Billing Meter Requests
      </button>
      <div style="margin-top: 10px" class="form-group" *ngIf="productMessage">
        <h6>{{ productMessage }}</h6>
      </div>
      </div>
    </div>

    <p *ngIf="dataService.selectedRegionIsInitialized()" class="error-message">
      Billing meter request has been initiated. Use the
      <a href="javascript:void(0)" (click)="toOnDemandBillingMeter()">On-Demand</a>
      billing meter pages to submit additional requests for the region.
    </p>
    <p *ngIf="dataService.selectedRegion && dataService.availabilityRegionOrEdgeZone() && !dataService.selectedRegionIsInitialized()">
      The selected region is not a buildout region, please select a EdgeZone.
    </p>

    <hr />
    <h4>Review Services</h4>
    <p style="margin-bottom: 0px">
      To make any modifications to the list please visit
      <a href="https://aka.ms/pamurl">aka.ms/pamurl</a>
    </p>
    <div style="width: 100%; text-align: right">
      <button type="button" class="btn btn-primary mr-1" (click)="onExportExcel()">Export Request Details</button>
    </div>
    <div class="nofloat">
      <ag-grid-angular
        style="height: 540px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="inScopeServices"
        [columnDefs]="columnDefs"
        (gridReady)="onInScopeServicesGridReady($event)"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
    <div>
      <hr />
      <button type="button" class="btn btn-primary mr-1" [disabled]="disableNewRegionBillingMeter()" (click)="onNewRegionBillingMeter()">
        Submit Billing Meter Requests
      </button>
    </div>
    <!--/form-->
  </div>
</div>
