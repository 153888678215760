import { ArmResourceType, AZ, HdiSku, Plan, RegionTable, ServiceReadiness, ServiceReadinessStage, Sql } from "./generated-models";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { VmDisk } from "./generated-models/VmDisk";
import { PlanFileStatus } from "./generated-models/PlanFileStatus";
import { FfpPlan } from "./generated-models/FfpPlan";
import { Message } from "./generated-models/Message";
import { Project } from "./generated-models/Project";
import { ProjectBuildout } from "./generated-models/ProjectBuildout";
import { PlanRegion } from "./generated-models/PlanRegion";

export interface RequestStatusDescription {
  status: string;
  name: string;
  description: string;
}

export interface PlanRegionStatusDescription {
  status: string;
  name: string;
  description: string;
}

export interface SubOrderStatusDescription {
  status: string;
  name: string;
  description: string;
}

export interface AutoApprovalRuleDisplayModel {
  Id: number;
  RequestServiceType: string;
  SubscriptionType: string;
  RequestSource: string;
  ApprovalType: string;
  Region: string;
  Quota: number;
}

export interface ISqlQuota {
  SQL: number;
  SQLMI: number;
  SqlMiSubNet: number;
  SQL_ByDtu: number;
  SQLServerCount: number;
}

export interface IStorageAccountQuota {
  ARM: number;
  RDFE: number;
}

export enum DeploymentScope {
  Empty = "Empty",
  Regional = "Regional",
  Zonal = "Zonal",
}

export interface IRequestDetails {
  VmSkus: string[];
  VMQuota: { [key: string]: number };
  VmZones: { [key: string]: string[] };
  RdfeVmQuota: number;
  StorageAccountQuota: IStorageAccountQuota;
  SqlQuota: ISqlQuota;
  Enable: string[];
  DeploymentScope: DeploymentScope;
  SqlDtuQuota: { [key: string]: number };
  HdiQuota: number;
  Info: IRequestDetailsInfo;
  DnsEntryQuota: IDnsEntryQuota;
  BatchQuota: IBatchQuota;
  CosmosDbQuota: ICosmosDbQuota;
  AppServiceQuotas: { [key: string]: IAppServiceQuota };
  EnableArmWithNoQuota: boolean;
  RPFrontload: IRPFrontloadInfo[];
  ArmResourceTypes: string[];
  VmDisksQuota: { [key: string]: number };
}

export interface IRequestDetailsInfo {
  HdiSku: string;
  Justification: string;
  RdfeVmSku: string;
  Purpose: string;
  Zones: string;
}

export interface IBatchQuota {
  TotalAccounts: number;
  IsUserManaged: boolean;
  PerAccountQuotas: { [key: string]: IPerAccountQuota };
}

export interface IPerAccountQuota {
  VmQuota: { [key: string]: number };
  LowPriorityCoreQuota: number;
  PoolQuota: number;
  JobQuota: number;
}

export interface IUIRequest {
  Requestor: string;
  Email: string;
  TeamOid: string;
  SubscriptionId: string;
  Region: string;
  Justification: string;
  IsExternal: boolean;
  Details: IRequestDetails;
  UtilizeDate: Date;
  IsFlighting: boolean;
}

export interface ISubRequest {
  // this comes from SubRequest
  Placeholder: string;
  ParentRequestId: string;
  SubRequestId: number;
  RequestId: string;
  RequestLink: string;
  Region: string;
  SubscriptionId: string;
  OfferDetails: IOfferDetails;
  Submitter: string;
  Email: string;
  RequestSource: string;
  IsExternal: boolean;
  IsHobo?: boolean;
  IsExternalDisplay: string;
  IsHoboDisplay: string;
  Requestor: string;
  TeamOid: string;
  RingLevel: string;
  Status: string;
  CreatedTime: string;
  Priority: number;
  PriorityString: string;
  Notes: string;
  RequestServiceType: RequestServiceType;
  RequestDisplayServiceType: string;
  DeploymentType: string;
  SKU: string;
  Quota: string;
  FulfillTime: string;
  CompletedTime: string;
  ApprovedBy: string;
  UtilizeDate: Date;
  IsFlighting: boolean;
  Dependencies: string;
  IgnoreDependencies: boolean;
  DeploymentScope: string;
  FulfillChannel: string;
  RegionStatus: string;
  Cloud: string;
  ServiceParams: object;
  TicketIds: number[];
}

export interface IApprovalData {
  isReject: boolean;
  Comment: string;
}

export interface IParentRequest {
  Placeholder: string;
  RequestId: string;
  RequestLink: string;
  Submitter: string;
  SubscriptionId: string;
  Region: string;
  Requestor: string;
  TeamOid: string;
  Email: string;
  IsExternal: boolean;
  IsHobo?: boolean;
  IsExternalDisplay: string;
  IsHoboDisplay: string;
  RequestSource: string;
  Status: string;
  Details: IRequestDetails;
  Justification: string;
  Notes?: string;
  CreatedTime: string;
  CompletedTime?: string;
  IsFlighting: boolean;
  HasBacklogged?: boolean;
  HasForelogged?: boolean;
  HasActionRequired?: boolean;
  RegionStatus: string;
}

export interface MyRequestAndWorkItems {
  Requests: IParentRequest[];
  Tickets: IWorkItemResponse[];
}

export interface ISubscriptionInfoInput {
  SubscriptionIds: string[];
  Regions: string[];
}

export interface ISelfServiceInfoInput {
  SubscriptionId: string;
  Region: string;
  ServiceType: RequestServiceType;
  SKU: string;
  Quota: number;
}

export interface SubscriptionZoneInfo {
  SubscriptionId: string;
  Location: string;
  ZoneMappings: ZoneMappings[];
}

export interface IOfferDetails {
  SubscriptionEnv: string;
  CostCategory: string;
  OfferId: string;
  OfferType: string;
  OfferName: string;
}

export interface ISubscriptionDetails {
  CreatedTime: string;
  Status: string;
  CloudType: string;
  OfferDetails: IOfferDetails;
}

export interface ZoneMappings {
  LogicalZone: string;
  PhysicalZone: string;
}

export interface IUserProfile {
  Name: string;
  Email: string;
  UserRoles: IEffectiveUserRole[];
  IsAdmin: boolean;
  IsOperator: boolean;
  IsApproverAdmin: boolean;
  IsApprover: boolean;
  IsGETApprover: boolean;
  IsBetAdmin: boolean;
  IsBetServiceOwner: boolean;
  IsFfpAdmin: boolean;
  IsPfAdmin: boolean;
}

export interface IEffectiveUserRole {
  Role: string;
  Regions: string;
  Types: string;
  Services: string;
}

export interface IApproverInput {
  UserAccounts: string;
  Role: string;
  Regions: IRegionGroupListRecord[];
  Types: string[];
  IsAllRegionsSelected: boolean;
  IsAllTypesSelected: boolean;
}

export enum Roles {
  Administrator = "Administrator",
  Operator = "Operator",
  ApproverAdmin = "ApproverAdmin",
  Approver = "Approver",
  BetServiceOwner = "BetServiceOwner",
  BetAdmin = "BetAdmin",
  ServiceOwner = "ServiceOwner",
  FfpAdmin = "FfpAdmin",
  PfAdmin = "PfAdmin",
}

export enum LionrockUserRoles {
  Administrator = "Administrator",
  User = "User",
  RolePrefix = "Service",
}

export enum RequestType {
  Internal = "Internal",
  External = "External",
}

export enum RequestSource {
  LionrockUI = "LionrockUI",
  EV2 = "EV2",
  CIS = "CIS",
  ARM = "ARM",
}

export enum PolicyName {
  AzEnablement = "AzEnablement",
  QuotaIncrease = "QuotaIncrease",
  Ev2AutoApprove = "Ev2AutoApprove",
}

export enum TicketPriorityEnum {
  Critical = 1,
  High = 2,
  Standard = 3,
}

export interface IContinuationToken {
  NextPartitionKey: string;
  NextRowKey: string;
  NextTableName: string;
  TargetLocation: string;
}

export interface ISegReqResponse {
  ContinuationToken: IContinuationToken;
  Requests: ISubRequest[];
}

/*
 * reference to src\Lionrock\service\Lionrock.DataContracts\Cis\CisJobStateEnum.cs
 */
export enum CisJobStateEnum {
  Undefined = "Undefined",
  Creating = "Creating",
  NotStarted = "NotStarted",
  Starting = "Starting",
  InProgress = "InProgress",
  Transitioning = "Transitioning",
  Pausing = "Pausing",
  Paused = "Paused",
  Aborting = "Aborting",
  Aborted = "Aborted",
  Finished = "Finished",
  /* placeholder */
  Blocked = "Blocked", // not part of cis.sdk
  Postponed = "Postponed", // not part of cis.sdk
  Cancelled = "Cancelled",
}

/*
 * reference to src\Lionrock\service\Lionrock.DataContracts\Cis\CisTaskStateEnum.cs
 */
export enum CisTaskStateEnum {
  Undefined = "Undefined",
  NotStarted = "NotStarted",
  Ready = "Ready",
  InProgress = "InProgress",
  Blocked = "Blocked",
  Finished = "Finished",
  Skipped = "Skipped",
  Empty = "",
}

export enum PlanAssignResultEnum {
  Succeed = "Succeed",
  Failed = "Failed",
  Skipped = "Skipped",
  Warning = "Warning",
}

export interface IWorkItemResponse {
  Id: string;
  SubscriptionID: string;
  DiscussionHistory: IDiscussionHistory[];
  TicketNumber: string;
  Region: string;
  ArmRegionName: string;
  State: string;
  AssignedTo: string;
}

export interface IDiscussionHistory {
  Comment: string;
  Submitter: string;
  ChangedDate: string;
}

export interface IStatusResponse {
  Status: string;
  RequestId: string;
  Region: string;
  Message: string;
  LastUpdatedTime: string;
}

export interface ISubRequestUpdateData {
  Quota: number;
  Priority: number;
  UtilizeDate: Date;
  Comment: string;
  ServiceParamsString: string;
}

export interface ISubscriptionInfoRowData {
  SubscriptionId: string;
  RegionName: string;
  AZAFEC: string;
  HasRegionAccess: boolean;
  ZoneMapping: string[];
  OfferId: string;
  OfferName: string;
  OfferType: string;
  QuotaDetails: string;
  IsAZRegistered: string;
}

export interface IListResponse {
  PartitionKey: string;
  RowKey: string;
}

export interface ISqlTypeListResponse {
  PartitionKey: string;
  RowKey: string;
  HwGeneration: string;
  VCores: number;
}

export interface IAdminViewListRow { }

export interface ITListResponse {
  PartitionKey: string;
  RowKey: string;
  Name: string;
  Oid: string;
  Ring: string;
}

export interface IGenericListRecord {
  item_id: number;
  item_text: string;
}

export interface IRegionGroupListRecord {
  item_id: number;
  region: string;
  cloud: string;
  regionSupportAz: boolean;
  isGA: boolean;
  constraintNote: string;
}

export interface ITeamListRecord {
  value: number;
  ring: string;
  label: string;
  oid: string;
}

export interface ISubscription {
  Id: string;
}

export interface IUpdatedQuotaRequest {
  region: string;
  originalVM: string;
  changedVM: string;
  originalStorageDeployment: string;
  changedStorageDeployment: string;
  originalVMQuota: number;
  newVMQuota: number;
  originalStorageQuota: number;
  newStorageQuota: number;
}

export interface IServiceSideQuotaInfoUpdate {
  Region: string;
  VMFamily: string;
  Quota: number;
}

export interface IServiceSideStorageQuotaInfoUpdate {
  Region: string;
  QuotaType: string;
  Quota: number;
}

export interface IQuotaImplicationsData {
  ResourceType: string;
  Sku?: string;
  DeploymentSystem: string;
  PreApprovedQuota: number;
  StampedQuota: number;
  Quota?: number;
  hide: boolean;
}

export interface IFeatureFlags { }

export enum ResourceType {
  Compute = "Compute",
  Storage = "Storage",
  Hdi = "HD Insights",
  Batch = "Batch",
  ArmResourceType = "Arm Resource Type",
  VmDisk = "Virtual Machine Disk",
}

export enum RequestServiceType {
  ARM = "ARM",
  RDFE = "RDFE",
  ArmVmQuota = "ArmVmQuota",
  RdfeVmQuota = "RdfeVmQuota",
  ArmStorageQuota = "ArmStorageQuota",
  RdfeStorageQuota = "RdfeStorageQuota",
  SQL = "SQL",
  SQLMI = "SQLMI",
  AZ = "AZ",
  CAS = "CAS",
  DNSEntry = "DNSEntry",
  BatchQuota = "BatchQuota",
  HDInsight = "HDInsight",
  CosmosDB = "CosmosDB",
  KustoQuota = "KustoQuota",
  InternalVm = "InternalVm",
  RPFrontload = "RPFrontload",
  ArmNoQuota = "ArmNoQuota",
  AppService = "AppService",
  Kusto = "Kusto",
  ArmResourceType = "ArmResourceType",
  VmDisk = "VmDisk",
}

export enum EnableType {
  AZ = "AZ",
  SQL = "SQL",
  CosmosDB = "CosmosDB",
  CosmosDBAz = "CosmosDBAz",
  RPFrontload = "RPFrontload",
  ARM = "ARM",
  RDFE = "RDFE",
  Kusto = "Kusto",
}

export enum AccessRequestServiceType {
  ARM = "ARM",
  RDFE = "RDFE",
  AZ = "AZ",
  CAS = "CAS",
}

export enum TableName {
  Region = "Regions",
  EdgeZone = "Edge Zones",
  CloudConfig = "Cloud Configs",
  UserRole = "User Roles",
  AutoApprovalRule = "Auto Approval Rules",
  Config = "Config",
  CisJob = "CIS Job",
  HoboSubscriptionAutoApprovalRule = "Hobo Subscription Auto Approval Rules",
  ServiceTeamUser = "Service Team user",
  TeamConfig = "Team Config",
  Manatree = "Manatree"
}

export enum ApiString {
  Region = "regions",
  CloudConfig = "cloudconfigs",
  UserRole = "userroles",
  AutoApprovalRule = "autoapprovalrules",
  Config = "configs",
}

export enum ErrorLevel {
  Ticket = "Ticket",
  SubRequest = "Sub Request",
  ParentRequest = "Parent Request",
}

export interface IEditModalOption {
  key: string;
  name: string;
  value: string;
  type: string;
  options?: Array<string>;
  isEditable?: boolean;
  isNullable?: boolean;
  hide: boolean;
  displayName: string;
}

export enum DeploymentSystem {
  ARM = "ARM",
  RDFE = "RDFE",
}

export enum RegionStatus {
  GA = "Production SLA",
  Buildout = "Buildout",
}

export enum TicketStatus {
  InComplete = "Incomplete",
  InTriage = "In Triage",
  Cancelled = "Cancelled",
  Completed = "Completed",
  ActionRequired = "Action Required",
}

export enum RequestStatus {
  Completed = "Completed",
  Cancelled = "Cancelled",
  Created = "Created",
  Error = "Error",
  InProgress = "InProgress",
  Rejected = "Rejected",
  Waiting = "Waiting",
  WaitingDependency = "WaitingDependency",
}

export enum AssignTo {
  AssignToCM = "CM 24x7",
  AssignToCIS = "Lionrock System Service Account",
  AssignToLionrock = "Lionrock Prod",
  AssignToLionrockNonProd = "Lionrock NonProd",
}

export interface CommentUpdate {
  Comment: string;
}

export enum DetailsDataType {
  CISJob = "CISJob",
  CISTask = "CISTask",
  Ticket = "Ticket",
}

export enum TicketChannel {
  ADO = "ADO",
  ICM = "ICM",
}

export interface UnsupportedVmSku {
  Region: string;
  VmSku: string;
}

export interface TableViewDataInfo {
  TableName: string;
  TableTitle: string;
  ColumnDefs: (ColDef | ColGroupDef)[] | null;
  rowData: (AZ | HdiSku | ServiceReadiness | ServiceReadinessStage | Sql | ArmResourceType | VmDisk | RegionTable | Project)[];
}

export interface PlansRisksViewDataInfo {
  TableName: string;
  ColumnDefs: (ColDef | ColGroupDef)[] | null;
  RowData: (PlanFileStatus | Plan | IPlansUnsupportedSkusRowData)[];
}
export interface IHdiSkus {
  value: string;
  label: string;
}

export interface IVmDiskQuotaInfo {
  value: string;
  label: string;
  maxQuotaLimit: number;
}

export interface IRequestItems {
  TeamList: ITeamListRecord[];
  RegionList: IRequestRegion[];
  SqlMiTypeList: number[];
  SqlMiSubNetList: number[];
  VMSkuList: IRequestVmSku[];
  DeploymentScopeList: string[];
  HdiSkuList: IHdiSkus[];
  BatchSkuList: IBatchSku[];
}

export interface IRequestRegion {
  value: number;
  label: string;
  cloud: string;
  regionSupportAz: boolean;
  isGA: boolean;
  constraintNote: string;
  dcmtRegionId: string;
  isManagedRegion: boolean;
  isEnabled: boolean;
  isFlighting: boolean;
  isEnabledInUI?: boolean;
  isEnabledForCustomerEA: boolean;
  isManagedAZ: boolean;
}

export interface IRequestVmSku {
  value: string;
  label: string;
  checked: boolean;
  quota: number;
  disabled: boolean;
  skuType: SkuType;
  vmFamily?: string;
  zones: string[];
}

export interface IRequestServiceType {
  value: string;
  label: string;
}

export enum SkuType {
  Internal = "Internal",
  Compute = "Standard",
  Disabled = "Disabled",
}

export interface IRequestSqlQuota {
  value: number;
  label: string;
  vcore: number;
  quota: number;
}

export interface IPlansUnsupportedSkusRowData {
  // Plan related data
  ServiceTreeId: string;
  SubscriptionName: string;
  Version: number;
  Stage: string;
  // Region related data
  RegionName: string;
  // Sku related data
  SkuName: string;
  ServiceType: string;
}

export enum SqlMiType {
  Default = "Default Quota",
  TotalvCores = "Provide Total vCores",
  DetailInstances = "Provide Detail Instances",
}

export enum BatchQuotaType {
  Account = "Batch Account",
  Compute = "Compute",
}

export enum BatchRequestType {
  Dedicated = "Dedicated",
  LowPriority = "Low Priority",
  PoolQuota = "Pool Quota",
  JobQuota = "Job Quota",
}

export enum SqlPurchaseModelType {
  DTUs = "DTUs",
  VCores = "vCores",
}

export enum RequestOperation {
  Cancel = "cancel",
  Retry = "retry",
  SkipDependencies = "skip dependencies of",
}

export enum FfpPlanTypeEnum {
  NewRegion = "New Region",
  EnableZones = "Availability Zone Expansion",
  DCCapacityExpansion = "DC Capacity Expansion",
  NetworkDesignChange = "Network Design Change",
  Decom = "Decom",
  ZoneEnablement = "ZoneEnablement",
  RegionPromotion = "RegionPromotion",
  GT3Zones = ">3AZ",
}

export interface IBatchSku {
  region: string;
  requestType: string;
  label: string;
  sku: string;
  quota: number;
  disabled: boolean;
}

export interface IBasicInfo {
  isExternal: boolean;
  region: IRequestRegion;
  team: ITeamListRecord;
  otherTeam: string;
  otherTeamOid: string;
  customer: string;
  email: string;
  subId: string;
  isRegionChanged: boolean;
  isNextBtnDisabled: () => boolean;
  subscriptions: string;
  customerRequest: boolean;
  subscriptionsByCSV: boolean;
}

export interface IDetailsInfo {
  azEnablement: boolean;
  regionEnablement: boolean;
  regionEnablementARM: boolean;
  regionEnablementRDFE: boolean;
  sql: boolean;
  sqlPurchaseModel: SqlPurchaseModelType;
  sqlQuota: number;
  sqlDtu: boolean;
  sqlServerName: string;
  sqlDtuQuota: number;
  sqlServer: boolean;
  sqlServerQuota: number;
  sqlMI: boolean;
  sqlMiType: SqlMiType;
  sqlMiGPvCores: number;
  sqlMiGPCount: number;
  sqlMiBCvCores: number;
  sqlMiBCCount: number;
  sqlMiTotalvCores: number;
  sqlMiSubNet: number;
  hdi: boolean;
  hdiQuota: number;
  hdiSku: IHdiSkus;
  dns: IDns;
  requireComputeArmVmQuota: boolean;
  vmQuota: IRequestVmSku[];
  deploymentScope: DeploymentScope;
  requireComputeArmSharedQuota: boolean;
  computeArmSharedQuota: number;
  requireComputeArmLowPriorityQuota: boolean;
  computeArmLowPriorityQuota: number;
  requireComputeRdfeVmQuota: boolean;
  computeRdfeVmQuota: number;
  rdfeVmSku: IRequestVmSku;
  additionalStorage: boolean;
  storageQuotaARM: number;
  storageQuotaRDFE: number;
  batchQuotaType: BatchQuotaType;
  batchTotalAccounts: number;
  batchAccountName: string;
  batchComputeQuota: IBatchSku[];
  requireBatchAccounts: boolean;
  requireBatchComputeQuota: boolean;
  azEntities: string[];
  cosmosDB: ICosmosDB;
  kusto: boolean;
  appServiceQuota: IAppServiceQuotaUI[];
  requireAppServiceQuota: boolean;
  serviceStatus: { [key: string]: string };
  rpFrontload: boolean;
  enableArmWithNoQuota: boolean;
  isValidateBtnDisabled: () => boolean;
  serviceTreeId: string;
  namespaces: string;
  requireArmResourceType: boolean;
  armResourceTypes: string[];
  requireVmDisks: boolean;
  vmDisksSku: IVmDiskQuotaInfo;
  vmDisksQuota: number;
  zones: string[];
}

export interface IDnsEntryQuota {
  quota: number;
  recordSetQuota: number;
  perZoneQuotas: { [key: string]: IDnsZoneQuota };
}

export interface IDnsZoneQuota {
  resourceGroup: string;
  zoneRecordSetQuota: number;
}

export interface IDns {
  IsSubsriptionQuota?: boolean;
  IsZoneQuota?: boolean;
  maxZoneQuota?: number;
  maxRecordSetQuota?: number;
  zoneName?: string;
  resourceGroup?: string;
  zoneRecordSetQuota?: number;
}

export interface IJustification {
  Question: string;
  Answer: string;
  IsRequired: boolean;
  Type?: string;
  Options?: string[];
}

export interface ISummaryInfo {
  utilizeDate: Date;
  justification: IJustification[];
  subIds: string;
  newSubToCreate: number;
  subDisplayName: string;
  subTenantId: string;
  subOwnerObjId: string;
  subServiceTreeId: string;
  subServiceTree2ndLevelId: string;
  subPcCode: string;
  pamApprovalState: IPamApprovalState;
  isSubmitBtnDisabled: () => boolean;
}

export interface IPamApprovalState {
  ApprovalState: string;
  CurrentState: string;
}

export interface ICosmosDbAccountQuota {
  StorageQuota: number;
  ThroughputLimit: number;
}

export interface ICosmosSku {
  label: string;
  sku: string;
  quota: number;
}

export interface ICosmosDB {
  IsAccess?: boolean;
  IsAzEnablement?: boolean;
  IsSubscriptionAccounts?: boolean;
  IsAccountQuota?: boolean;
  SubscriptionAccounts?: number;
  AccountName?: string;
  AccountQuotas?: ICosmosSku[];
  CosmosSkuList?: ICosmosSku[];
}

export interface ICosmosDbQuota {
  SubscriptionAccounts: number;
  PerAccountQuotas: { [key: string]: ICosmosDbAccountQuota };
}

export interface IAppServiceQuota {
  WindowsVmQuota: number;
  LinuxVmQuota: number;
}

export interface IAppServiceQuotaUI {
  offering: string;
  windowsVmQuota: number;
  linuxVmQuota: number;
}

export interface ISubRequestWithTicket extends ISubRequest {
  TicketId: number;
  TicketState: string;
  Url: string;
  TicketCreatedTime: string;
  TicketFinishedTime?: string;
}

export enum JustificationDisplayMode {
  DefaultTextArea,
  QuestionList,
}

export interface IRPFrontloadInfo {
  Namespace: string;
  ServiceTreeId: string;
}

export enum PlanValidationErrorCode {
  PlanValidationCrpSkuNotAvailable = 400137,
}

export interface PlanListRecord extends Plan {
  Label: string;
}

export interface PlanAssignmentCSVRecord {
  ServiceTreeId: string;
  NeedByDate: Date;
  ServiceName: string;
  Result: string;
  Message: string;
}

export interface OkMessage {
  Message: string;
}

export interface FfpPlanListRecord extends FfpPlan {
  Label: string;
}

export interface IPfCapacityPlan {
  ServiceTeam: string;
  ServiceTreeId: string;
  Ring: string;
  Version: number;
  Status: string;
  CreatedBy: string;
  CreatedTime: Date;
  Comment: string;
}

export interface ISubOrderDetail {
  OrderId: string,
  SubOrderId: string,
  ResourceProvider: string,
  Location: string,
  ResourceName: string,
  Limits: number,
  Unit: string,
  ProviderSpecificProperties: string,
  ApprovedResourceName: string,
  ApprovedLimits: number,
  Status: string,
  Messages: Message[]
}

export interface IPlanRegionWithApprovalStatus extends PlanRegion {
  IsPendingHDInsight: boolean;
  IsPendingDns: boolean;
  IsPendingAppService: boolean;
  IsPendingVmDisk: boolean;
  IsPendingGCT: boolean;
}

export interface ProjectListRecord extends Project {
  Label: string;
}

export interface ProjectBuildoutListRecord extends ProjectBuildout {
  Label: string;
}

export const altDocUrl =
  "https://eng.ms/docs/cloud-ai-platform/azure-core/azure-networking/microsoft-capacity-infrastructure-services-mjubran/azure-build-out-automation/azureglobal-buildoutautomation-wiki/catseye/userguide/quota_plan_file_hierarchy_syntax#detailed-quota-demands";

export const allowedCommonRegionStatuses = ['Buildout', 'Approved', 'Production SLA'];
export const allowedFfpRegionStatuses = ['Proposed', 'Approved'];
export const allowedPfRegionStatuses = ['Proposed', 'Approved', 'Buildout', 'Production SLA'];
