import { Component } from "@angular/core";
import { ColumnApi, ExcelExportParams, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { ModalService } from "../../shared/modal.service";
import { SharedDataService } from "../../services/sharedDataService";
import { getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { ApprovablePfPlansColumnDefinition } from "./ag-grid-column-definition";
import { PfPlan } from "src/app/generated-models/PfPlan";
import { PfPlanOperationTypeEnum, PfPlanStatusEnum } from "src/app/shared/enums/pf-enums";
import { IPfCapacityPlan } from "src/app/npr-request.model";

@Component({
  templateUrl: "./pf-plans-approvable.component.html",
  styleUrls: ["../../styles.scss", "./pf.scss"],
})
export class PfPlansApprovableComponent extends GridViewBaseComponent {
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridColumnApi: ColumnApi;
  initPageSize: number = 15;
  showRejected: boolean = false;
  keyFilterStats = "filter:approve-pf-plans";
  rowData: IPfCapacityPlan[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  selectedRowCount = 0;
  loadCheckBox = false;

  constructor(
    protected apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    protected sharedDataService: SharedDataService,
    protected modalService: ModalService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    ApprovablePfPlansColumnDefinition.push({
      colId: "customFilter",
      headerName: "Custom Filter",
      valueGetter: (params: ColParams<PfPlan, void>) => {
        if (!this.showRejected) {
          const row = params.data as PfPlan;
          return row.Status === PfPlanStatusEnum.Approvable;
        }

        return true;
      },
      filterParams: {
        values: ["true", "false"],
        newRowsAction: "keep",
      },
      hide: true,
    });
    super.InitAgGrid(ApprovablePfPlansColumnDefinition);
  }

  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  onShowRejectedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  refreshData() {
    this.selectedRowCount = 0;
    this.loadingService.setLoading(true);
    this.apiService.getApprovablePfPlans().subscribe(
      (response) => {
        const plans: IPfCapacityPlan[] = [];
        response.forEach((plan) => {
          var row: IPfCapacityPlan = {
            ServiceTeam: plan.ServiceTeam,
            ServiceTreeId: plan.ServiceTreeId,
            Ring: plan.Ring,
            Version: plan.Version,
            Status: plan.Status,
            CreatedBy: "",
            CreatedTime: new Date(0),
            Comment: "",
          };
          var createOperation = plan.Operations.find(p => p.Operation == PfPlanOperationTypeEnum.Create);
          if (createOperation) {
            row.CreatedBy = createOperation.By;
            row.CreatedTime = createOperation.At;
          }
          plans.push(row);
        })
        this.rowData = plans;
        this.loadingService.setLoading(false);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
    this.setActionCheckBoxAttributes();
  }

  async batchApprovePfPlan(formValues?: PfPlan[]) {
    const selectedPfPlan: PfPlan[] = formValues ? formValues : this.gridApi.getSelectedRows();
    if (!selectedPfPlan && selectedPfPlan.length === 0) {
      this.message = "Please select PF Plan to approve";
      this.notificationService.warning(this.message);
      return;
    }
    const needRefresh: boolean = await this.modalService.pfPlanBatchApprovalModal("Approve PF Plan(s)", selectedPfPlan);
    if (needRefresh) {
      this.refreshData();
    }
  }

  onSelectionChanged() {
    this.selectedRowCount = this.gridApi.getSelectedNodes().length;
  }

  showPfBatchApproval() {
    if (this.userProfile) {
      return this.userProfile.IsAdmin;
    }
    return false;
  }

  // setActionCheckBoxAttributes call only once by loadCheckBox flag
  setActionCheckBoxAttributes() {
    if (this.showPfBatchApproval() && ApprovablePfPlansColumnDefinition?.length && !this.loadCheckBox) {
      const columnDefs = [];
      this.gridOptions.columnDefs.map((map) => {
        if (map.headerName === "Action") {
          columnDefs.push({
            ...map,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
          });
        } else {
          columnDefs.push(map);
        }
      });

      this.gridOptions.api.setColumnDefs(columnDefs);
      this.loadCheckBox = true;
    }
  }

  exportExcel() {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "ApprovablePfPlans-" + getExportedExcelFileNameSuffix(),
      sheetName: "Approvable Regional Plans",
      columnKeys: this.gridOptions.columnApi.getAllColumns().filter((c) => c.getColDef().colId !== "action"),
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }
}
