<div class="modal-header">
  <h4 class="modal-title">FFP Plan assignment</h4>
</div>

<div>
  <mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
  <div class="modal-body">
      <p *ngIf="this.ffpPlanSubmissions.length">In progress {{ this.aggregatedResult.length }}/{{ this.ffpPlanSubmissions.length }} :</p>
    <ul *ngFor="let item of aggregatedResult">
      <li [innerHtml]="item"></li>
    </ul>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="close()">Close</button>
</div>
