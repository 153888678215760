import * as CDs from "src/app/utility/commonColDef";
import { QuotaCellRenderer } from "src/app/utility/gridCellRenderer";

export const RequestColumnDefinitionForAllRequests = [
  {
    ...CDs.RequestId,
    checkboxSelection: true,
    headerCheckboxSelection: false,
    filter: "agSetColumnFilter",
  },
  {
    ...CDs.SubscriptionId,
    minWidth: 250,
    filter: "agSetColumnFilter",
  },
  {
    ...CDs.RequestServiceType,
    filter: "agSetColumnFilter",
  },
  {
    ...CDs.SKU,
    filter: "agSetColumnFilter",
  },
  {
    ...CDs.Quota,
    filter: "agSetColumnFilter",
    cellRenderer: (params) => QuotaCellRenderer(params),
  },
];

export const RequestColumnDefinitionForImplications = [
  {
    ...CDs.ResourceType,
    minWidth: 117,
    filter: "agSetColumnFilter",
  },
  {
    ...CDs.Sku,
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Deployment System",
    field: "DeploymentSystem",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Pre-approved from catalog",
    field: "PreApprovedQuota",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
    cellRenderer: (params) => {
      return params.value || 0;
    },
  },
  {
    headerName: "Already stamped",
    field: "StampedQuota",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
    cellRenderer: (params) => {
      return params.value || 0;
    },
  },
  {
    headerName: "Current/Posted request",
    field: "Quota",
    resizable: true,
    sortable: true,
    filter: "agSetColumnFilter",
    cellRenderer: (params) => {
      return params.value || 0;
    },
  },
];
