/// <reference path="../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "src/app/shared/modal.service";
import { ColDef, GridApi, GridOptions } from "ag-grid-community";
import { PlanFile } from "src/app/generated-models/PlanFile";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { PlanEditorModalOptions } from "./modals/pf-plan-editor-modal/pf-plan-editor-modal.component";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PlanFileTypeEnum } from "src/app/shared/enums/plan-enums";
import { ActionCellComponent, ActionCellComponentParams } from "src/app/shared/grid/action-cell.component";
import { PfPlanFile } from "src/app/generated-models/PfPlanFile";

@Component({
  templateUrl: "./pf-plan-history.component.html",
  styleUrls: ["../../styles.scss", "./pf.scss"],
})
export class PfPlanHistoryComponent extends BaseComponent implements OnInit {
  editorModal: NgbModalRef;
  serviceTreeId: string;
  version: number;
  allPfPlanFiles: PfPlanFile[] = [];
  rowSourceData: PfPlanFile[] = [];
  gridOptions: GridOptions;
  gridApi: GridApi;
  initPageSize: number = 15;
  rowMergedData: PfPlanFile[] = [];
  gridOptionsMerged: GridOptions;
  message: string;

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];
    this.version = this.route.snapshot.params["version"];

    const planFilesColumnDefinition: ColDef[] = [
      {
        headerName: "Action",
        sortable: false,
        suppressMenu: true,
        cellRendererFramework: ActionCellComponent,
        cellRendererParams: {
          text: "View",
          actionFunc: this.viewPfPlanFile.bind(this),
        } as ActionCellComponentParams<PlanFile>,
      },
      {
        headerName: "Name",
        field: "FileName",
      },
      {
        headerName: "Last Modified",
        field: "LastModified",
        cellRenderer: (params) => PreprocessDateTime(params.value),
      },
    ];

    const planFilesMergedColumnDefinition: ColDef[] = [
      {
        headerName: "Action",
        sortable: false,
        suppressMenu: true,
        cellRendererFramework: ActionCellComponent,
        cellRendererParams: {
          text: "View",
          actionFunc: this.viewPfPlanFile.bind(this),
        } as ActionCellComponentParams<PlanFile>,
      },
      {
        headerName: "Name",
        field: "FileName",
      },
      {
        headerName: "Last Modified",
        field: "LastModified",
        cellRenderer: (params) => PreprocessDateTime(params.value),
      },
      {
        headerName: "File Type",
        field: "PlanFileType",
      },
    ];

    this.gridOptions = extendDefaultOptions({
      columnDefs: planFilesColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      sideBar: null,
    });

    this.gridOptionsMerged = extendDefaultOptions({
      columnDefs: planFilesMergedColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      sideBar: null,
    });

    this.getPfPlanFiles();
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
  }

  getPfPlanFiles(): void {
    this.loadingService.setLoading(true);

    this.apiService.getPfPlanFiles(this.serviceTreeId, this.version).subscribe(
      (response) => {
        const original: PfPlanFile[] = [];
        const generated: PfPlanFile[] = [];
        response.forEach((plan) => {
          if (plan.FileName === "default.json") {
            original.push(plan);
            generated.push(plan);
          } else if (plan.FileName.includes("override")) {
            original.push(plan);
          } else {
            if (plan.PlanFileType === PlanFileTypeEnum.Generated) {
              generated.push(plan);
            } else {
              original.push(plan);
              generated.push(plan);
            }
          }
        });
        this.rowSourceData = original;
        this.rowMergedData = generated;
        this.allPfPlanFiles = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridOptions.columnApi.autoSizeAllColumns();
          this.gridOptionsMerged.columnApi.autoSizeAllColumns();
        }, 100);
      },
      (e: unknown) => {
        this.rowSourceData = [];
        this.rowMergedData = [];
        this.allPfPlanFiles = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      }
    );
  }

  viewPfPlanFile(planFile: PlanFile) {
    this.showPfPlanEditorDialog(planFile);
  }

  showPfPlanEditorDialog(planFile: PlanFile) {
    const options: PlanEditorModalOptions = {
      model: planFile,
      isReadonly: true,
      save: null,
      schema: null,
    };
    this.editorModal = this.modalService.pfPlanEditorModal(options);
  }
}
