import { Component, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { NprConfiguration } from "src/app/app.configuration";
import { IDetailsInfo, IRequestVmSku, ISummaryInfo, IUIRequest, IJustification, IBasicInfo } from "src/app/npr-request.model";
import { environment } from "src/environments/environment";
import { CreateRequestService } from "../create-request.service";

enum JustificationDisplayMode {
  DefaultTextArea,
  QuestionList,
}

enum SummaryDetailsField {
  AccessEnabled = "Access Enabled",
  HdInsight = "HD Insights",
  ComputeRdfe = "Compute RDFE",
  ComputeVm = "Compute VM",
  AdditionalStorageQuota = "Additional Storage Account Quota",
  BatchQuota = "Batch Quota",
  Sql = "SQL",
  Dns = "DNS",
  CosmosDB = "Cosmos DB",
  AppService = "App Service",
  ArmResourceType = "Arm Resource Type",
  VmDisk = "Virtual Machine Disk"
}

interface QuestionItem {
  Question: string;
  IsRequired: boolean;
  Type?: string;
  Options?: string[];
}

const CanaryExternalJustificationQuestions: QuestionItem[] = [
  {
    Question: "Best MSFT Contact Email for this EXTERNAL customer:",
    IsRequired: true,
  },
  {
    Question: "Microsoft Service external Customer is partnering with:",
    IsRequired: true,
  },
  {
    Question: "External Customer Contact email:",
    IsRequired: true,
  },
  {
    Question: "Goal of this partnership in EUAP regions?",
    IsRequired: true,
  },
  {
    Question: "What is the minimal viable footprint needed? (VM Type and minimal number of VM's, or is this for storage?)",
    IsRequired: true,
  },
  {
    Question:
      "What is the testing/feedback agreement with the Microsoft Service and Customer? (i.e. feedback via calls, direct emails, monthly or quarterly report, etc.)",
    IsRequired: false,
  },
  {
    Question: "How will the Customer be paying for Region usage? Pay as you go, EA or? (Note EUAP billing is like Production)",
    IsRequired: false,
  },
  {
    Question: "Do you expect your minimal viable footprint to grow? If yes, what is the peak footprint and by what date?",
    IsRequired: false,
  },
  {
    Question: "What is the complete set of services you plan to use in EUAP?",
    IsRequired: false,
  },
];
const STGJustificationQuestions: QuestionItem[] = [
  {
    Question: "Which cluster will your service test the scenario?",
    IsRequired: true,
    Type: "select",
    Options: [
      "US East Stage - PilotFish - STG01S",
      "US East Stage - PilotFish - STG02S",
      "US East Stage - Compute/Storage - STG01 PrdApp/PrdStr",
      "US South Central Stage - PilotFish - STG03S",
      "US South Central Stage - Compute/Storage - STG03 PrdApp/PrdStr"]
  },
  {
    Question: "What scenarios is your service testing in the Stage region? Integration testing with which services?",
    IsRequired: true,
  },
  {
    Question: "What is the tentative start date to test your scenario?",
    IsRequired: true,
    Type: "date",
  },
  {
    Question: "What is the tentative end date to test your scenario? (this is for us to know how long you will need this stage environment)",
    IsRequired: true,
    Type: "date",
  },
  {
    Question: "Why is your service selecting stage region to test this scenario?",
    IsRequired: true,
  },
  {
    Question: "What is the KPI or metric you are tracking for this testing?",
    IsRequired: true,
  },
]

@Component({
  selector: "summary-info",
  templateUrl: "./summary-info.component.html",
  styleUrls: ["../create-request.scss"],
})
export class SummaryInfoComponent implements OnInit {
  summaryInfo: ISummaryInfo;
  detailsInfo: IDetailsInfo;
  uiRequest: IUIRequest;
  basicInfo: IBasicInfo;

  justificationDisplayMode = JustificationDisplayMode.DefaultTextArea;
  additionalSubIds = false;
  guidRegExpression = NprConfiguration.guidRegExpression;
  subsRegExpression = NprConfiguration.subsRegExpression;
  isRequestDate = false;
  isSubIdsDisabled = false;
  nameRegexp = new RegExp(NprConfiguration.nameRegExpression);
  summaryBasic: { [key: string]: string };
  summaryDetails: { [key: string]: object };

  constructor(private service: CreateRequestService) {
    this.detailsInfo = service.detailsInfo;
    this.summaryInfo = service.summaryInfo;
    this.uiRequest = service.uiRequest;
    this.basicInfo = service.basicInfo;
  }

  ngOnInit(): void {
    this.initSummaryInfo();
    this.initSummary();
    this.summaryInfo.isSubmitBtnDisabled = () => {
      let disabled = false;
      if (this.summaryInfo.subIds) {
        disabled = !new RegExp(this.subsRegExpression).test(this.summaryInfo.subIds);
      }

      if (this.isExternalCanaryRequest() || this.isSTGRegion()) {
        disabled = !this.isJustificationValid();
      }

      if (this.getSubscriptionsCount() > 100) {
        return true;
      }

      return disabled;
    };
  }

  isJustificationValid(): boolean {
    return this.summaryInfo.justification.every((_) => {
      if (_.IsRequired) {
        return _.Answer;
      }

      return true;
    });
  }

  isValidObject<T>(value: T): boolean {
    return Object.prototype.toString.call(value) === "[object Object]" && Object.keys(value).length > 0;
  }

  isValidArray<T>(value: T): boolean {
    return Object.prototype.toString.call(value) === "[object Array]" && Object.keys(value).length > 0;
  }

  shouwAdditionalSubIds(): void {
    this.additionalSubIds = true;
  }

  isTouchedOrDirty(control: NgModel): boolean {
    return control && (control.touched || control.dirty);
  }

  displayQuestionList(): boolean {
    return this.justificationDisplayMode === JustificationDisplayMode.QuestionList;
  }

  initSummary(): void {
    const detailsInfo = this.detailsInfo;
    const summary = this.uiRequest;

    this.summaryBasic = {
      "Contact Email": summary.Email,
      "Is External": String(summary.IsExternal),
      Region: summary.Region,
      "Service Name": summary.Requestor,
      "Subscription(s)": this.getSummarySubscription(),
    };

    this.summaryDetails = {};

    // Service enablement
    this.summaryDetails[SummaryDetailsField.AccessEnabled] = {};
    if (summary.Details.Enable?.length > 0) {
      this.summaryDetails[SummaryDetailsField.AccessEnabled]["Enabled"] = summary.Details.Enable.join(", ");
    }

    // HD Insight
    this.summaryDetails[SummaryDetailsField.HdInsight] = {};
    if (detailsInfo.hdi) {
      this.summaryDetails[SummaryDetailsField.HdInsight]["Quota"] = detailsInfo.hdiQuota;
      this.summaryDetails[SummaryDetailsField.HdInsight]["Sku"] = detailsInfo.hdiSku.label;
    }

    // Compute RDFE quota
    this.summaryDetails[SummaryDetailsField.ComputeRdfe] = {};
    if (detailsInfo.requireComputeRdfeVmQuota) {
      this.summaryDetails[SummaryDetailsField.ComputeRdfe]["Quota"] = detailsInfo.computeRdfeVmQuota;
      this.summaryDetails[SummaryDetailsField.ComputeRdfe]["Sku"] = detailsInfo.rdfeVmSku.value;
    }

    // Compute ARM quota
    this.summaryDetails[SummaryDetailsField.ComputeVm] = {};
    if (detailsInfo.requireComputeArmVmQuota) {
      const VMQuota = detailsInfo.vmQuota;
      VMQuota.map((vm: IRequestVmSku) => {
        this.summaryDetails[SummaryDetailsField.ComputeVm][vm.label] = "";
        if (vm.quota) {
          this.summaryDetails[SummaryDetailsField.ComputeVm][vm.label] += `Quota: ${vm.quota}`;
        }
        if (vm.zones) {
          this.summaryDetails[SummaryDetailsField.ComputeVm][vm.label] += `${vm.quota ? "; " : ""}Zones: ${vm.zones.join(', ')}`;
        }
      });
      if (detailsInfo.deploymentScope) {
        this.summaryDetails[SummaryDetailsField.ComputeVm]["Deployment Scope"] = detailsInfo.deploymentScope;
      }
    }

    // Compute ARM regional quota
    if (detailsInfo.requireComputeArmSharedQuota) {
      this.summaryDetails[SummaryDetailsField.ComputeVm]["Total Regional Limit"] = detailsInfo.computeArmSharedQuota;
    }

    // Compute ARM low priority quota
    if (detailsInfo.requireComputeArmLowPriorityQuota) {
      this.summaryDetails[SummaryDetailsField.ComputeVm]["Total Low Priority Limit"] = detailsInfo.computeArmLowPriorityQuota;
    }

    // SQL
    this.summaryDetails[SummaryDetailsField.Sql] = {};
    if (detailsInfo.sql) {
      this.summaryDetails[SummaryDetailsField.Sql]["Purchase Model"] = detailsInfo.sqlPurchaseModel;
      this.summaryDetails[SummaryDetailsField.Sql]["Expected Consumption"] = detailsInfo.sqlQuota;
    }

    // SQL DTU quota
    if (detailsInfo.sqlDtu) {
      this.summaryDetails[SummaryDetailsField.Sql]["DTU server name"] = detailsInfo.sqlServerName;
      this.summaryDetails[SummaryDetailsField.Sql]["DTU quota"] = detailsInfo.sqlDtuQuota;
    }

    // SQL Server quota
    if (detailsInfo.sqlServer) {
      this.summaryDetails[SummaryDetailsField.Sql]["Server quota"] = detailsInfo.sqlServerQuota;
    }

    // SQLMI
    if (detailsInfo.sqlMI) {
      this.summaryDetails[SummaryDetailsField.Sql]["MI SubNet"] = summary.Details.SqlQuota.SqlMiSubNet;
      this.summaryDetails[SummaryDetailsField.Sql]["MI Total vCores"] = summary.Details.SqlQuota.SQLMI;
    }

    // Storage quota
    this.summaryDetails[SummaryDetailsField.AdditionalStorageQuota] = {};
    if (detailsInfo.additionalStorage) {
      if (detailsInfo.storageQuotaARM) {
        this.summaryDetails[SummaryDetailsField.AdditionalStorageQuota]["ARM"] = detailsInfo.storageQuotaARM;
      }
      if (detailsInfo.storageQuotaRDFE) {
        this.summaryDetails[SummaryDetailsField.AdditionalStorageQuota]["RDFE"] = detailsInfo.storageQuotaRDFE;
      }
    }

    // Batch quota
    this.summaryDetails[SummaryDetailsField.BatchQuota] = {};
    if (detailsInfo.requireBatchAccounts) {
      this.summaryDetails[SummaryDetailsField.BatchQuota]["Total Accounts"] = detailsInfo.batchTotalAccounts;
    }

    if (detailsInfo.requireBatchComputeQuota) {
      this.summaryDetails[SummaryDetailsField.BatchQuota]["Batch Account"] = detailsInfo.batchAccountName;
      detailsInfo.batchComputeQuota.map((s) => {
        this.summaryDetails[SummaryDetailsField.BatchQuota][s.label] = s.quota || "";
      });
    }

    // DNS
    if (detailsInfo.dns.IsSubsriptionQuota || detailsInfo.dns.IsZoneQuota) {
      this.summaryDetails[SummaryDetailsField.Dns] = {
        "Zone Quota": summary.Details.DnsEntryQuota?.quota ?? 0,
        "Record Set Quota": summary.Details.DnsEntryQuota?.recordSetQuota ?? 0,
        "Number of DNS Zone": Object.keys(summary.Details.DnsEntryQuota?.perZoneQuotas).length,
        "Zone Name": detailsInfo.dns?.zoneName,
        "Resource Group": detailsInfo.dns?.resourceGroup,
        "Zone Record set Quota": detailsInfo.dns?.zoneRecordSetQuota,
      };
    }

    // Cosmos DB
    this.summaryDetails[SummaryDetailsField.CosmosDB] = {};
    if (detailsInfo.cosmosDB.IsSubscriptionAccounts) {
      this.summaryDetails[SummaryDetailsField.CosmosDB]["Number of Accounts"] = detailsInfo.cosmosDB.SubscriptionAccounts;
    }

    if (detailsInfo.cosmosDB.IsAccountQuota) {
      detailsInfo.cosmosDB.AccountQuotas.map((s) => {
        this.summaryDetails[SummaryDetailsField.CosmosDB][s.label] = s.quota;
      });
    }

    // App service quota
    if (detailsInfo.requireAppServiceQuota) {
      this.summaryDetails[SummaryDetailsField.AppService] = {};
      detailsInfo.appServiceQuota.forEach((val) => {
        if (val.linuxVmQuota > 0) {
          this.summaryDetails[SummaryDetailsField.AppService][`${val.offering} - LinuxVmQuota`] = val.linuxVmQuota;
        }

        if (val.windowsVmQuota > 0) {
          this.summaryDetails[SummaryDetailsField.AppService][`${val.offering} - WindowsVmQuota`] = val.windowsVmQuota;
        }
      });
    }

    // RPLrontload
    if (detailsInfo.rpFrontload) {
      this.summaryDetails[SummaryDetailsField.AccessEnabled]["Namespaces"] = detailsInfo.namespaces;
      this.summaryDetails[SummaryDetailsField.AccessEnabled]["ServiceTreeId"] = detailsInfo.serviceTreeId;
    }

    // Arm Resource Type
    if (detailsInfo.armResourceTypes?.length) {
      this.summaryDetails[SummaryDetailsField.ArmResourceType] = {};
      this.summaryDetails[SummaryDetailsField.ArmResourceType]["Arm Resource Type(s)"] = detailsInfo.armResourceTypes.join(", ");
    }

    // VM Disk
    if (detailsInfo.requireVmDisks) {
      this.summaryDetails[SummaryDetailsField.VmDisk] = {};
      this.summaryDetails[SummaryDetailsField.VmDisk]["VM Disk Resource Type"] = detailsInfo.vmDisksSku.label;
      this.summaryDetails[SummaryDetailsField.VmDisk]["VM Disk Resource Quota"] = detailsInfo.vmDisksQuota;
    }
  }

  initSummaryInfo(): void {
    this.initJustification();
    const today: Date = new Date();
    const detailsInfo = this.detailsInfo;
    this.summaryInfo.utilizeDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    this.summaryInfo.subTenantId = environment.tenantId;
    this.summaryInfo.subIds = "";
    this.isSubIdsDisabled = detailsInfo.sqlDtu || detailsInfo?.dns?.IsSubsriptionQuota || detailsInfo?.dns?.IsZoneQuota;
    this.isRequestDate =
      detailsInfo.requireComputeArmVmQuota ||
      detailsInfo.requireComputeRdfeVmQuota ||
      detailsInfo.additionalStorage ||
      detailsInfo.requireComputeArmSharedQuota;
  }

  initJustification(): void {
    this.summaryInfo.justification = [];
    if (this.isExternalCanaryRequest()) {
      this.justificationDisplayMode = JustificationDisplayMode.QuestionList;
      CanaryExternalJustificationQuestions.forEach((q) => {
        this.summaryInfo.justification.push({
          Question: q.Question,
          Answer: "",
          IsRequired: q.IsRequired,
          Type: q.Type || "text",
        } as IJustification);
      });
    } else if (this.isSTGRegion()) {
      this.justificationDisplayMode = JustificationDisplayMode.QuestionList;
      STGJustificationQuestions.forEach((q) => {
        this.summaryInfo.justification.push({
          Question: q.Question,
          Answer: "",
          IsRequired: q.IsRequired,
          Type: q.Type || "text",
          Options: q.Options,
        } as IJustification);
      });
    } else {
      this.justificationDisplayMode = JustificationDisplayMode.DefaultTextArea;
      this.summaryInfo.justification.push({
        Question: "",
        Answer: "",
        IsRequired: false,
      } as IJustification);
    }
  }

  isExternalCanaryRequest(): boolean {
    return this.uiRequest.Region.endsWith("EUAP") && this.uiRequest.IsExternal;
  }

  getSummarySubscription(): string {
    if (this.isCustomerRequest() && this.basicInfo.subscriptionsByCSV) {
      let subscriptions = this.basicInfo.subscriptions;
      // Display first 10 subscriptions
      if (subscriptions.length > 370) {
        subscriptions = subscriptions.substring(0, 370) + `...(${subscriptions.split(",").length} in total)`;
      } else {
        subscriptions += `(${subscriptions.split(",").length} in total)`;
      }
      return subscriptions;
    } else {
      return this.uiRequest.SubscriptionId;
    }
  }

  isCustomerRequest(): boolean {
    return this.basicInfo.isExternal && this.basicInfo.customerRequest;
  }

  isSTGRegion(): boolean {
    return this.uiRequest.Region.endsWith("STG");
  }

  getSubscriptionsCount(): number {
    return this.summaryInfo.subIds.split(',').length;
  }
}
