import { OnDestroy, ViewChild, ElementRef, Injectable } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  private destroyed = false;

  containerHeight: string = "auto";
  @ViewChild("title", { static: false }) titleView: ElementRef;
  //urls = urls;

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.destroyed = true;
  }

  protected addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  protected async timeout(timeout?: number) {
    await this.rejectOnDestroy(new Promise((resolve) => setTimeout(resolve, timeout)));
  }

  protected async rejectOnDestroy<T>(promise: Promise<T>) {
    const result = await promise;
    if (this.destroyed) {
      throw new Error("Disposed");
    }
    return result;
  }

  ngAfterViewInit() {
    // Fix error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    if (this.titleView) {
      setTimeout(() => {
        this.containerHeight = `calc(100% - ${this.titleView.nativeElement.offsetHeight}px)`;
      });
    }
  }

  onResize() {
  }
}
