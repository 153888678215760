<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Assign Plan to Region</h4>
      <p>Assign services to one region or assign a service to regions. <a href="https://aka.ms/catseye-plansyntax" target="_blank">Learn about region override</a></p>
    </div>
    <div class="action-container">
      <div class="form-check form-check-inline ml-4 toggle">
        <input
          type="checkbox"
          class="form-check-input"
          id="hideAlreadyAssignedSwitch"
          [(ngModel)]="hideAlreadyAssigned"
          (change)="onHideAlreadyAssignedChanged()" />
        <label class="form-check-label" for="hideAlreadyAssignedSwitch"> Hide non-assignable</label>
      </div>
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel()"
        placement="top-right"
        container="body"
        *ngIf="(assignPlansToOneRegion && selectedRegion) || (!assignPlansToOneRegion && selectedPlan)"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel">
        <span class="far fa-file-excel fa-lg"></span>
      </a>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!canAssign"
        (click)="showPlanAssignmentDialog()"
        data-test="AssignPlan">
        Assign
      </button>
      &nbsp;
      <div class="file-upload" *ngIf="assignPlansToOneRegion">
        <button type="button" class="btn btn-primary" (click)="fileUpload.click()" [disabled]="!selectedRegion">
          Assign from CSV file
        </button>
        <input type="file" #fileUpload (change)="onFileSelected($event)" style="display: none" accept=".csv" />
      </div>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="col-form-label">
    <div class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        type="radio"
        id="mode1"
        [(ngModel)]="assignPlansToOneRegion"
        (change)="onAssignModeChanged($event)"
        name="assignMode"
        [value]="true" />
      <label class="custom-control-label" for="mode1">Assign services to one region</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        type="radio"
        id="mode2"
        [(ngModel)]="assignPlansToOneRegion"
        (change)="onAssignModeChanged($event)"
        name="assignMode"
        [value]="false" />
      <label class="custom-control-label" for="mode2">Assign a service to regions</label>
    </div>
  </div>

  <div class="landing-main" *ngIf="assignPlansToOneRegion">
    <div class="top-panel" style="width: 100%">
      <ng-select
        name="Region"
        bindLabel="RegionName"
        [(ngModel)]="selectedRegion"
        (ngModelChange)="onRegionChanged()"
        placeholder="Select a region"
        [items]="regions"
        groupBy="Status"
        required
        data-test="AssignPlansToOneRegion">
      </ng-select>
    </div>

    <p *ngIf="assignMultiStages && hasRowSelected">
      <strong style="color: red;">Cannot assign plan with multi-stages at a time.</strong>
    </p>
    <div>
        <ag-grid-angular #agGrid
                         id="ViewGrid"
                         style="width: 100%; height: 540px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="gridOptionsForPlan"
                         [rowData]="rowDataForPlan"
                         pagination="true"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onSelectionChanged($event)"
                         (filterChanged)="onFilterChangedRegion($event)">
        </ag-grid-angular>
    </div>
  </div>

  <div class="landing-main" *ngIf="!assignPlansToOneRegion">
    <div class="top-panel" style="width: 100%">
      <ng-select
        name="Plan"
        bindLabel="Label"
        [(ngModel)]="selectedPlan"
        (ngModelChange)="onPlanChanged()"
        placeholder="Select a service"
        [items]="plans"
        groupBy="Ring"
        [virtualScroll]="true"
        required>
      </ng-select>
    </div>
    <p *ngIf="selectedPlan">
        The latest plan version for {{ selectedPlan.ServiceTeam }} is <b>{{ selectedPlan.Version }}</b>.
    </p>
    <p *ngIf="selectedPlan && assignTooManyRegions && hasRowSelected">
        <strong style="color: red;">Assign plan to at most {{maxRegionNumOneTime}} regions at a time.</strong>
    </p>
    <p *ngIf="selectedPlan && assignMultiStages && hasRowSelected">
      <strong style="color: red;">Cannot assign plan with multi-stages at a time.</strong>
    </p>
    <div>
        <ag-grid-angular #agGrid
                         id="ViewGridForRegion"
                         style="width: 100%; height: 540px"
                         class="ag-theme-balham"
                         [masterDetail]="true"
                         [gridOptions]="gridOptionsForRegion"
                         [rowData]="rowDataForRegion"
                         (gridReady)="onGridReady($event)"
                         (selectionChanged)="onSelectionChanged($event)"
                         (filterChanged)="onFilterChangedPlan($event)">
        </ag-grid-angular>
    </div>
  </div>
</div>
