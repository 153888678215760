import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

export interface EditDeleteCellComponentParams<T> {
  editFunc: (data: T) => Promise<any>;
  deleteFunc: (data: T) => Promise<any>;
}

@Component({
  templateUrl: "./edit-delete-cell.component.html",
})
export class EditDeleteCellComponent<T> implements AgRendererComponent {
  params: EditDeleteCellComponentParams<T>;
  data: T;

  constructor() {}

  refresh(): boolean {
    throw new Error("Method not implemented.");
  }

  agInit(params: ICellRendererParams & EditDeleteCellComponentParams<T>) {
    this.params = params;
    this.data = params.data;
  }

  ngOnInit() {}

  edit() {
    this.params.editFunc(this.data);
  }

  delete() {
    this.params.deleteFunc(this.data);
  }
}
