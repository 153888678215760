<div class="title">
  <div class="container container-max-width">
    <div>
      <h4>Bindings</h4>
      <p>Subscription and resource bindings of my services</p>
    </div>
  </div>
</div>
<div class="container container-content mt-3" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <form #form="ngForm" autocomplete="off" novalidate>
      <ul class="list-container">
        <li class="flex-box mb-3">
          <div class="col-md-3 required">Requesting Service Team</div>
          <label class="col-md-9">
            <ng-select name="TeamName"
                        [(ngModel)]="team"
                        [items]="teamList"
                        groupBy="ring"
                        placeholder="Select your team"
                        [virtualScroll]="true"
                        required
                        data-test="TeamName"
                        #teamName="ngModel">
              <ng-template ng-option-tmp let-item="item">
                <ng-container>
                  <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
                    {{ item.label }}
                  </label>
                </ng-container>
              </ng-template>
            </ng-select>
            <em *ngIf="isTouchedOrDirty(teamName) && teamName.errors?.required">Required</em>
          </label>
        </li>
        <div class="flex-box mb-3">
          <div class="col-md-9">
            <button type="submit" class="btn btn-primary mr-1" [disabled]="team == null" (click)="search()">
              Search
            </button>
          </div>
        </div>
      </ul>
    </form>
  </div>
</div>
