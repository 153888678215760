import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { GridOptions } from "ag-grid-community";
import { SubrequestColumnDefinitionForRequest } from "./ag-grid-column-definition";
import {
  IParentRequest,
  ISubRequest,
  IUserProfile,
  RequestOperation,
  RequestServiceType,
  RequestStatus,
  Roles,
} from "../../npr-request.model";
import { ApiService } from "../../services";
import { RequestViewBase } from "../../shared/request-view-base";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../../shared/modal.service";
import { OnColumnMoved, setColumnState } from "../../utility/common-helper";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { OperationLog } from "../../generated-models";
import { OperationLogColumnDefinition } from "./operation-log-columns";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { CustomTooltipComponent } from "../../shared/auxillary-components/custom-tooltip.component";
import { ServiceTypeRendererComponent } from "../../shared/az-mapping-modal/service-type-renderer.component";
import { ApproveRequestService } from "../../services/approveRequest.service";
import { getRequestStatusDescription } from "../../utility/common-helper";
import { SharedDataService } from "../../services/sharedDataService";

@Component({
  selector: "app-parent-request-detail",
  templateUrl: "./parent-request-detail.component.html",
  styleUrls: ["../../styles.scss", "./parent-request-detail.component.scss"],
})
export class ParentRequestDetailComponent extends RequestViewBase implements OnInit {
  public requestId: string;
  public request: IParentRequest;
  public gridOptions: GridOptions;
  public operationLogGridOptions: GridOptions;
  public isLoading = true;
  public errorInfo = null; // if 403/404/..., use this to display error info on the page.
  public isRdquota = false;
  rowData: ISubRequest[] = [];
  pendingApprovalSubrequests: ISubRequest[] = [];
  public rdQuotaContact = "cm24x7@microsoft.com";
  userProfile: IUserProfile = null;
  initPageSize = 15;
  isAdmin = false;
  canSkipDenpendencies = false;
  operationLogs: OperationLog[] = [];
  operationLogColumnDef = OperationLogColumnDefinition;

  constructor(
    private activatedRoute: ActivatedRoute,
    private title: Title,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected modalService: ModalService,
    protected notificationService: ToastrService,
    protected approveRequestService: ApproveRequestService,
    protected sharedDataService: SharedDataService
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
  }

  onOperationLogGridReady(params: GridOptions): void {
    this.operationLogGridOptions.columnApi.autoSizeAllColumns();
    setColumnState(params, "parentRequestOperationLogColumnState");
  }

  async ngOnInit(): Promise<void> {
    this.operationLogGridOptions = extendDefaultOptions({
      columnDefs: this.operationLogColumnDef,
      context: this,
      frameworkComponents: {
        customTooltip: CustomTooltipComponent,
        serviceTypeRenderer: ServiceTypeRendererComponent,
      },
      isRowSelectable: () => {
        return true;
      },
      animateRows: true,
      paginationPageSize: this.initPageSize,
      onColumnMoved: (params) => OnColumnMoved(params, "parentRequestOperationLogColumnState"),
    });

    // base method for ag-grid angular binding
    super.InitAgGrid(SubrequestColumnDefinitionForRequest);
    this.gridOptions.onColumnMoved = (params) => OnColumnMoved(params, "requestDetailsState");
    this.loadingService.setLoading(true);
    this.userProfile = await this.apiService.getUserProfile();
    if (this.userProfile?.IsAdmin) {
      this.isAdmin = true;
    }

    // This observable will not be resolved, so can not use toPromise
    this.activatedRoute.params.subscribe(async (queryParams) => {
      this.requestId = queryParams.parentReqId;
      this.title.setTitle(`Request ${this.requestId} - Region Access and Quota`);
      console.log(`requestId: ${this.requestId}`);

      try {
        this.request = await this.apiService.getParentRequest(this.requestId).toPromise();
        this.operationLogs = await this.apiService.getSubRequestsOperationHistory(this.requestId, 0).toPromise();
      } catch (errorResponse) {
        this.errorInfo = errorResponse;
        this.isLoading = false;
        this.loadingService.setLoading(false);
        return;
      }

      if (!this.request) {
        this.errorInfo = "Not Found";
        this.isLoading = false;
        this.loadingService.setLoading(false);
        return;
      }

      if (this.request.CreatedTime) {
        this.request.CreatedTime = PreprocessDateTime(this.request.CreatedTime);
      }
      if (this.request.CompletedTime) {
        this.request.CompletedTime = PreprocessDateTime(this.request.CompletedTime);
      }
      this.request.Status = this.request.Status.replace("Processed", "In Progress");
      this.request.IsHoboDisplay = this.request.IsHobo == null ? "" : this.request.IsHobo ? "Yes" : "No";

      this.rowData = await this.apiService.getSubRequestsByParentRequestId(this.requestId).toPromise();
      this.pendingApprovalSubrequests = this.rowData.filter((r) => r.Status == "Created"); // We defined 'Create' Status as 'Pending Approval' in FE, please look at getRequestStatusDescription at common-helper
      // Get rdQuota contact
      if (this.request.Region.search("China") != -1) {
        this.rdQuotaContact = "mooncake_customerops@microsoft.com";
      }

      if (this.rowData) {
        for (let subrequest of this.rowData) {
          if (subrequest.Status == RequestStatus.WaitingDependency) {
            this.canSkipDenpendencies = true;
            break;
          }
        }
        for (let subrequest of this.rowData) {
          if (subrequest.FulfillChannel == "RDQuota") {
            this.isRdquota = true;
            break;
          }
        }
        for (let subrequest of this.rowData) {
          if (subrequest.RequestServiceType == RequestServiceType.AppService) {
            subrequest.Quota = "Windows:" + subrequest.Quota;
            if (subrequest.ServiceParams != null && "LinuxVmQuota" in subrequest.ServiceParams) {
              subrequest.Quota += " Linux:" + subrequest.ServiceParams["LinuxVmQuota"];
            }
          }
        }
      }

      this.isLoading = false;
      this.loadingService.setLoading(false);
    });
  }

  async getSubscriptionDetails() {
    await this.modalService.subscriptionDetailsModal("Subscription Details", this.request.SubscriptionId).catch((err) => {
      console.error(err);
    });
  }

  async retry() {
    const needRefresh: boolean = await this.modalService.parentRequestResponseModal("Retry Ruquest", RequestOperation.Retry, [
      this.request,
    ]);
    if (needRefresh) {
      this.ngOnInit();
    }
  }

  async approveRequests() {
    const refresh = await this.approveRequestService.approveRequest(
      this.pendingApprovalSubrequests,
      "Attempt to approve *ALL* pending approval sub requests with your permission."
    );
    if (refresh) {
      this.refreshData();
      this.pendingApprovalSubrequests = [];
    }
  }

  async skipDependencies() {
    const needRefresh: boolean = await this.modalService.parentRequestResponseModal(
      "Skip Dependencies of",
      RequestOperation.SkipDependencies,
      [this.request]
    );
    if (needRefresh) {
      this.ngOnInit();
    }
  }

  onGridReady(params: GridOptions) {
    // base method for ag-grid api setup
    super.SetAgGridContext(params);
    // base method to load data with authentication
    // the implementation is defined in derived refreshData()
    super.LoadData();
    setColumnState(params, "requestDetailsState");
  }

  refreshData() {
    this.gridApi?.hideOverlay();
  }
}
