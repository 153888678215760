import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { IWorkItemResponse } from "src/app/npr-request.model";
import { LoadingScreenService } from "../loading-screen.service";

@Component({
  templateUrl: "./workitem-bulk-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class WorkitemBulkModalComponent implements OnInit {
  @Input() workitemList: IWorkItemResponse[];
  newComment: string;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService, protected loadingService: LoadingScreenService) { }

  ngOnInit() { }

  async onSave() {
    const commentString = `${this.newComment}`;
    await this.apiService
      .WorkItemsBulkUpdate(this.workitemList, commentString)
      .then(() => {
        this.activeModal.close("");
      })
      .catch((err) => {
        this.activeModal.dismiss(err);
        this.loadingService.setLoading(false);
      })
      .finally(() => {
        this.loadingService.setLoading(false);
      });
  }
}
