import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { ISubscriptionInfoInput } from "src/app/npr-request.model";

@Component({
  templateUrl: "./az-mappings-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AZMappingsModalComponet implements OnInit {
  @Input() region: string;
  @Input() subscriptionId: string;
  @Input() cloudType: string;
  isLoading = false;
  errorMessage = "";
  azMappings = [];
  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  ngOnInit() {
    this.isLoading = true;
    const subscriptionInfoRequest = <ISubscriptionInfoInput>{};
    subscriptionInfoRequest.Regions = [this.region];
    subscriptionInfoRequest.SubscriptionIds = [this.subscriptionId];

    this.apiService.getSubscriptionZoneInfo(subscriptionInfoRequest, this.cloudType).subscribe(
      (res) => {
        this.azMappings = res[0].ZoneMappings;
      },
      (error) => {
        if (error) {
          this.errorMessage = "Failed to get subscription details";
          console.error(error.message);
        }
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
