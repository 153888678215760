import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  templateUrl: "./ffp-plan-assignment-modal.component.html",
  styleUrls: ["../../ffp.scss"],
})
export class FfpPlanAssignmentModalComponent implements OnInit {
  psl: string;
  constructor(public activeModal: NgbActiveModal, private notificationService: ToastrService) { }

  ngOnInit() {
  }

  submit(): void {
    if (!this.psl) {
      this.notificationService.warning(`Please input a PSL.`);
      return;
    } else {
      try {
        this.activeModal.close({
          psl: this.psl,
        });
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }

  isValidPsl() {
    if (this.psl) {
      const regExp = /^\d+$/;
      return regExp.test(this.psl);
    }
    // undefined return false
    return false;
  }
}
