<div class="title" #title>
  <div class="container container-max-width">
    <h4>Planned Quota - Request Status</h4>
    <p>filter requests by alias or filter requests by my services</p>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="col-form-label">
    <div class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        type="radio"
        id="mode1"
        [(ngModel)]="plannedQuotaQueryRequest.type"
        (change)="onAssignModeChanged()"
        name="radioMode"
        [value]="plannedQuotaQueryRequestType.Alias" />
      <label class="custom-control-label" for="mode1">Submitted by me</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        type="radio"
        id="mode2"
        [(ngModel)]="plannedQuotaQueryRequest.type"
        (change)="onAssignModeChanged()"
        name="radioMode"
        [value]="plannedQuotaQueryRequestType.Service" />
      <label class="custom-control-label" for="mode2">Submitted for my services</label>
    </div>
    <a
      href="javascript:void(0)"
      class="topbar-item"
      (click)="exportExcel()"
      placement="top-right"
      container="body"
      ngbTooltip="Export to Excel"
      aria-label="Export to Excel">
      <span class="far fa-file-excel fa-lg"></span>
    </a>
  </div>
  <div class="landing-main mt-3">
    <ag-grid-angular
      #agGrid
      id="ViewGrid"
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [excelStyles]="headerStyle"
      pagination="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
