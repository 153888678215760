<div class="title" #title>
  <div class="container container-max-width">
    <h4>Emails</h4>
    <p>All Emails</p>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main mt-3">
    <ag-grid-angular
      #agGrid
      id="ViewGrid"
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType"
      pagination="true"
      [paginationPageSize]="paginationPageSize"
      [cacheBlockSize]="cacheBlockSize"
      [animateRows]="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
