<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <h6>{{ message }}</h6>
  <ag-grid-angular
    style="height: 400px;"
    class="ag-theme-balham"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [masterDetail]="true"
    [detailRowHeight]="145"
    pagination="true"
  >
  </ag-grid-angular>
</div>
<div class="modal-footer">
  <button type="submit" class="btn btn-primary" (click)="activeModal.close(true)">
    {{ yesButtonMessage }}
  </button>
  <button type="button" class="btn btn-warning" (click)="activeModal.close(false)">
    {{ noButtonMessage }}
  </button>
</div>
