import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./prompt-modal.component.html",
  styles: [".modal-body { max-height: 650px; overflow: scroll; }"],
})
export class PromptModalComponent {
  @Input() message: string;
  @Input() title: string;
  @Input() width: number;

  constructor(public activeModal: NgbActiveModal) {}
}
