import { Component, HostListener } from "@angular/core";
import { ColDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "src/app/shared/modal.service";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { FfpProjectBuildoutColumnDefinition } from "./ag-grid-column-definition";
import { ProjectBuildout } from "../../generated-models/ProjectBuildout";
import { Project } from "../../generated-models/Project";

@Component({
  templateUrl: "./ffp-buildout.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpRegionBuildoutComponent extends GridViewBaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  rowData: ProjectBuildout[] = [];

  keyFilterStats = "filter:ffp-buildout";
  isFfpAdmin = false;

  message: string;

  constructor(
    protected apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService,
  ) {
    super(apiService, sharedDataService);
  }

  async ngOnInit() {
    super.InitAgGrid(FfpProjectBuildoutColumnDefinition);

    this.apiService.getUserProfile().then(async (response) => {
      this.userProfile = response;
      if (response) {
        this.gridApi?.showLoadingOverlay();
        if (response?.IsFfpAdmin) {
          this.isFfpAdmin = true;
        }
      }
    });
    FfpProjectBuildoutColumnDefinition.forEach((colDef: ColDef) => {
      if (colDef.headerName === 'Custom Filter') {
        colDef.valueGetter = () => {
          return true;
        }
      }
    });

    this.gridOptions.api?.setColumnDefs(FfpProjectBuildoutColumnDefinition);
  }

  onGridReady(params: GridOptions) {
    super.onGridReady(params);
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  refreshData() {
    this.loadingService.setLoading(true);
    this.apiService.getFfpProjectBuildouts().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    setTimeout(() => {
      //this.gridApi.sizeColumnsToFit();
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  async showRegionBuildoutDialog(): Promise<void> {
    try {
      const result = await this.modalService.ffpProjectBuildoutModal();
      if (result.selectedProject) {
        await this.newRegionBuildout(result.selectedProject);
      }
    } catch {
      // For the model dialog dismiss
      return;
    }
  }

  async newRegionBuildout(project: Project): Promise<void> {
    this.loadingService.setLoading(true);
    try {
      await this.apiService.newFfpProjectBuildout(project.ProjectOid).toPromise();
      this.notificationService.success("Project buildout successfully");
      this.refreshData();
    } catch (e) {
      this.notificationService.error(e);
    } finally {
      this.loadingService.setLoading(false);
    }
  }
}
