import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ExcelStyle, GridOptions } from "ag-grid-community";
import { RequestViewBase } from "src/app/shared/request-view-base";
import { IRegionGroupListRecord, IWorkItemResponse, allowedCommonRegionStatuses } from "src/app/npr-request.model";
import { ModalService } from "src/app/shared/modal.service";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import * as CDs from "src/app/utility/commonColDef";
import { Region } from "src/app/generated-models";
import { SharedDataService } from "../../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./incomplete-workitems.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class IncompleteWorkItemsComponent extends RequestViewBase implements OnInit {
  @Input() columnDef = [
    CDs.TicketId,
    CDs.QMSError,
    CDs.DaysSinceCreated,
    CDs.RequestId,
    CDs.RequestStatus,
    CDs.Submitter,
    CDs.CreatedDate,
    CDs.LastCommentDate,
    CDs.RequestSource,
    CDs.RegionColDef("ArmRegionName"),
    CDs.TicketStatus,
    CDs.Title,
    CDs.AssignedTo,
    CDs.SubscriptionId("SubscriptionID"),
    CDs.SKU,
    CDs.FirstComments,
  ];
  /*
   * inherited property from RequestViewBase for ag-grid Angular binding
   */
  gridOptions: GridOptions;
  rowData: IWorkItemResponse[] = [];
  initPageSize = 15;
  selectAllFlag = false;
  selectedRowCount = 0;
  regionDropdownList: IRegionGroupListRecord[] = [];
  region: string = null;
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  refreshData() {
    this.gridApi?.showLoadingOverlay();
    this.getWorkItems();
  }

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
  }

  ngOnInit() {
    this.getRegionDropdownList();
    this.gridOptions = extendDefaultOptions({
      columnDefs: this.columnDef,
      context: this, // passed context for customized component callback
      isRowSelectable: () => {
        return true;
      },
      animateRows: true,
      paginationPageSize: this.initPageSize,
    });
  }

  onGridReady(params: GridOptions) {
    // base method for ag-grid api setup
    super.SetAgGridContext(params);

    // base method to load data with authentication
    // the implementation is defined in derived refreshData()
    super.LoadData();
  }

  getRegionDropdownList() {
    // Region List
    this.apiService.getRegionList().subscribe((response: Region[]) => {
      if (!response) {
        console.warn("No region is obtained from api server.");
      } else {
        var regions = response.filter(region => allowedCommonRegionStatuses.includes(region.Status))
        this.regionDropdownList = [];
        for (let i = 0; i < regions.length; i++) {
          const rec = {} as IRegionGroupListRecord;
          rec.item_id = i + 1;
          rec.region = regions[i].RegionName;
          rec.cloud = regions[i].CloudName;
          rec.regionSupportAz = !!regions[i].AZFeatureFlag;
          this.regionDropdownList.push(rec);
        }
      }
    });
  }

  getWorkItems() {
    this.selectedRowCount = 0;
    try {
      this.apiService.getIncompleteWorkItems(this.region).subscribe((response: IWorkItemResponse[]) => {
        if (this.rowData.length === 0) {
          this.rowData = [];
        }
        this.rowData = response;
        this.message = `Incomplete workitems refreshed.`;
        super.setDefaultFilter("filter:incomplete-tickets");
      });
    } catch (error) {
      this.message = `Fail to get incomplete workitems: ${error}`;
    }
  }

  async handleWorkItem() {
    const selectedRows: IWorkItemResponse[] = this.gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      this.message = `Please select workitems(s)`;
      return;
    }
    this.message = "";

    await this.modalService
      .workitemBulkModal(selectedRows)
      .then(() => this.getWorkItems())
      .catch((err) => {
        console.log("modal closed.", err);
      });

    this.message = `Your selected workitem(s) have been handled.`;
  }

  selectAllRowsOnCurrentPage(selected: boolean) {
    const lastGridIndex = this.gridApi.getDisplayedRowCount() - 1;
    const currentPage = this.gridApi.paginationGetCurrentPage();
    const pageSize = this.gridApi.paginationGetPageSize();
    const startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      if (rowNode.group === false) {
        rowNode.setSelected(selected, false);
      }
    }
  }

  onChecked(isChecked: boolean) {
    this.selectAllRowsOnCurrentPage(isChecked);
  }

  onSelectionChanged(event) {
    this.selectedRowCount = event.api.getSelectedNodes().length;
  }

  onPaginationChanged() {
    this.selectAllFlag = false;
  }

  exportExcel() {
    var fileName = `IncompleteTickets-${this.region}-` + getExportedExcelFileNameSuffix();
    var sheetName = `Incomplete Tickets`;

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
