export function JsonCellRenderer(params) {
  if (params.value) {
    const strRep = JSON.stringify(params.value);
    if (strRep !== "{}") {
      return strRep;
    }
  }
}

export function QuotaCellRenderer(params) {
  return (params.value > 0 || params.value.length) > 0 ? params.value : "";
}

export function ApprovalTypeIconRenderer(value) {
  if (value == true || value == 'true') {
    return '<i class="fa fa-clock"></i>';
  } else if (value == false || value == 'false') {
    return '<i class="fa fa-check"></i>';
  } else {
    return value;
  }
}
