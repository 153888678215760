import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, of, Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { AccountInfoExtended } from "@ags/core";
import { ModuleService } from "../../shared/module.service";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../auth/auth.service";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApiService } from "./api.service";
import { DataService } from "./data.service";

@Injectable()
export abstract class BaseComponent implements OnDestroy, OnInit {
  private readonly subscriptions: Subscription[] = [];

  private destroyed = false;
  public isLoadingFinished: boolean;
  public isAuthorizedUser = false;
  protected isPermittedUser;
  public userCanInitialBuildout: boolean;
  public userCanIncrementalBuildout: boolean;
  public userCanOnDemandBuildout: boolean;
  public userCanViewStatus: boolean;
  protected user: AccountInfoExtended;
  protected userRights: string[] = [];
  protected publicPage: boolean = false;

  protected constructor(
    private _moduleService: ModuleService,
    protected auth: AuthService,
    protected apiService: ApiService,
    public dataService: DataService,
    protected route: ActivatedRoute,
    protected loadingScreenService: LoadingScreenService
  ) {}

  //urls = urls;

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.destroyed = true;
  }

  protected addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  onGetRowHeight(params) {
    const defaultRowHeight = params.node.rowHeight;
    if (params.data.requestData) {
      return this.calcDetailRowsHeight(defaultRowHeight, params.data.requestData.length);
    } else {
      return defaultRowHeight; // default row hight
    }
  }

  calcDetailRowsHeight(rowHeight, numOfRows): number {
    const defaultRowHeight = rowHeight; // 28; //params.node.rowHeight;
    const defaultColumnHeight = defaultRowHeight * 1.2;
    const spareSpace = defaultRowHeight * 0.5;
    const detailRowsHeight = Math.max(2, numOfRows) * defaultRowHeight;
    return defaultRowHeight + detailRowsHeight + defaultColumnHeight + spareSpace;
  }

  protected async timeout(timeout?: number): Promise<void> {
    await this.rejectOnDestroy(new Promise((resolve) => setTimeout(resolve, timeout)));
  }

  protected async rejectOnDestroy<T>(promise: Promise<T>) {
    const result = await promise;
    if (this.destroyed) {
      throw new Error("Disposed");
    }
    return result;
  }

  getCisLink(cisJobId): string {
    return `${environment.cisEndpoint}/Public/AzureBuildout/JobDetails/${cisJobId}`;
  }

  isPrep(): boolean {
    return this._moduleService.module === "Billing Meter";
  }

  async loading(fn: (Function) => void) {
    this.loadingScreenService.setLoading(true);
    let finish = () => this.loadingScreenService.setLoading(false);
    await fn(finish);
  }

  async onInitTest(): Promise<void> {
    this.isAuthorizedUser = true;
    this.isPermittedUser = true;
    this.userCanInitialBuildout = true;
    this.userCanIncrementalBuildout = true;
    this.userCanOnDemandBuildout = true;
    this.userCanViewStatus = true;
    this.isLoadingFinished = true;
  }

  async ngOnInit(): Promise<void> {
    if (await this.auth.isAuthenticated()) {
      this.isAuthorizedUser = true;
      this.user = await this.auth.getUser();
    }
    this.isPermittedUser = true;
    this.userCanInitialBuildout = false;
    this.userCanIncrementalBuildout = false;
    this.userCanOnDemandBuildout = false;
    this.userCanViewStatus = false;
    await this.loading((finish) => {
      combineLatest([this.publicPage ? of(["VIEW"]) : this.apiService.CheckPermission(), this.route.queryParams]).subscribe(
        async (ret) => {
          const [res, params] = ret;
          // check permission
          this.userRights = res as string[];
          console.log(`User rights ${this.userRights}.`);
          if (this.userRights.includes("VIEW")) {
            this.userCanViewStatus = true;
          }
          if (this.userRights.includes("INITIAL")) {
            this.userCanInitialBuildout = true;
          }
          if (this.userRights.includes("INCREMENTAL")) {
            this.userCanIncrementalBuildout = true;
          }
          if (this.userRights.includes("ONDEMAND")) {
            this.userCanOnDemandBuildout = true;
          }
          // init
          try {
            await this.dataService.Params(params["cloud"], params["region"]);
          } catch (e) {
            console.log(e);
            // FIXME The server error message should be displayed
            this.isPermittedUser = false;
          }
          await this.onInit();
          this.isLoadingFinished = true;
          finish();
        },
        async (_) => {
          this.isPermittedUser = false;
          this.isLoadingFinished = true;
          finish();
        }
      );
    });
  }

  abstract onInit();
}
