<div class="title" #title>
  <div class="container container-max-width">
    <h4>Incomplete Ticket(s)</h4>
  </div>
</div>

<div class="container container-content container-max-width" (window:resize)="onResize()"
  [style.height]="containerHeight">
  <div class="landing-main">
    <br />
    <!--Region-->
    <div *ngIf="true">
      <h4>Region:</h4>
      <label style="width: 100%">
        <ng-select name="region" labelForId="region" [items]="regionDropdownList" bindLabel="region" bindValue="region"
                   groupBy="cloud" required [multiple]="false" [(ngModel)]="region" placeholder="Enter region name">
        </ng-select>
      </label>
      <p>
        *Please select a region and then click "Get Records". Otherwise, all incomplete workitem records will be
        returned, which will take a
        long time based on record counts.
      </p>
    </div>
    <br />
    <div>
      <div class="hint leftalign">
        <br />
        {{ selectedRowCount }} of {{ rowData.length }} selected
      </div>

      <div class="sbutton float-right">
        <button type="submit" class="btn btn-success mr-1" (click)="getWorkItems()">Get Records</button>
        <button type="submit" class="btn btn-edit mr-1" (click)="handleWorkItem()">Update Records</button>
        <a
          href="javascript:void(0)"
          class="topbar-item"
          (click)="exportExcel()"
          placement="top-right"
          *ngIf="region"
          container="body"
          ngbTooltip="Export to Excel"
          aria-label="Export to Excel"
        >
          <span class="far fa-file-excel fa-lg"></span>
        </a>
      </div>

      <div class="nofloat">
        <p class="message">{{ this.message }}</p>
      </div>

      <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
      <form id="TicketListForm" #ticketListForm="ngForm" autocomplete="off" novalidate>
        <div class="nofloat">
          <ag-grid-angular #agGrid id="TicketListGrid" style="width: 100%; height: 450px" class="ag-theme-balham"
                           [gridOptions]="gridOptions" [rowData]="rowData" pagination="true" rowSelection="multiple"
                           (gridReady)="onGridReady($event)" (paginationChanged)="onPaginationChanged()" [excelStyles]="headerStyle"
                           (rowGroupOpened)="onRowGroupOpened($event)" (selectionChanged)="onSelectionChanged($event)"
                           (filterChanged)="onFilterChanged($event, 'filter:incomplete-tickets')">
          </ag-grid-angular>
        </div>
      </form>
    </div>
  </div>
</div>
