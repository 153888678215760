import { Component, OnInit } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../loading-screen.service";
import { ServiceTeamUserColumnDefinition } from "./ag-grid-column-definition";
import { ModalService } from "src/app/shared/modal.service";
import { AddUserModalOptions } from "../add-user-modal/add-user.component";
import { User } from "src/app/generated-models/User";
import { IsServiceAdmin } from "src/app/utility/userprofile-helper";
import { IUserProfile } from "src/app/npr-request.model";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./service-team-user-management.component.html",
  styleUrls: ["../../styles.scss", "./service-team-user-management.component.scss"],
})
export class ServiceTeamUserManagement extends GridViewBaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  userProfile: IUserProfile;
  keyFilterStats = "filter:my-service-team-users";

  rowData: User[] = [];
  selectedRow: User;
  selectedRowsCount = 0;
  isAddable = false;
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  message: string;

  adminServiceTreeIds: string[];

  constructor(
    protected apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit(): void {
    super.InitAgGrid(ServiceTeamUserColumnDefinition);
    this.gridOptions.rowSelection = "single";
    this.apiService.getMyServiceTeamServiceTreeIds().subscribe(
      (response) => {
        this.adminServiceTreeIds = response;
      },
      (err: unknown) => {
        this.adminServiceTreeIds = [];
        this.notificationService.error(err as string);
      }
    );

    this.apiService
      .getUserProfile()
      .then((response) => {
        if (response) {
          this.userProfile = response;
          this.isAddable = IsServiceAdmin(this.userProfile);
        } else {
          this.notificationService.error("user profile returns null, no data was loaded.");
        }
      })
      .catch((error) => {
        this.notificationService.error(`unable to get user profile, error: ${error.message}`);
      });
  }

  onSelectionChanged(event: GridOptions): void {
    const nodes = event.api.getSelectedNodes();
    if (nodes && nodes.length > 0) {
      this.selectedRowsCount = nodes.length;
      this.selectedRow = nodes[0].data;
    } else {
      this.selectedRowsCount = 0;
      this.selectedRow = null;
    }
  }

  refreshData(): void {
    this.loadingService.setLoading(true);

    this.apiService.getMyServiceTeamUser().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: unknown) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      }
    );
  }

  async showAddingNewUserDialog() {
    const options: AddUserModalOptions = {
      save: this.addNewUser.bind(this),
      adminTeamList: this.adminServiceTreeIds,
    };
    await this.modalService.planAddUserModal(options);
  }

  isDeletable(): boolean {
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows() as User[];
      for (const row of selectedRows) {
        if (row.Role !== "User" || !IsServiceAdmin(this.userProfile, row.ServiceTreeId)) {
          return false;
        }
      }
      return selectedRows.length > 0;
    }
    return false;
  }

  addNewUser(users: User[]): void {
    this.loadingService.setLoading(true);

    this.apiService.addMyServiceTeamUser(users[0].ServiceTreeId, users).subscribe(
      () => {
        this.message = `New Users have been added successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        this.notificationService.error(error as string);
        this.loadingService.setLoading(false);
      },
      () => {
        this.refreshData();
      }
    );
  }

  deleteUser(): void {
    if (!this.selectedRow) {
      this.message = "Please select one user to delete";
      return;
    }
    this.apiService.deleteMyServiceTeamUser(this.selectedRow.ServiceTreeId, this.selectedRow.Upn).subscribe(
      () => {
        this.message = "User " + this.selectedRow.Upn + " has been deleted from service " + this.selectedRow.ServiceTreeId;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        this.notificationService.info(error as string);
        this.loadingService.setLoading(false);
      },
      () => {
        this.refreshData();
      }
    );
  }

  exportExcel() {
    var fileName = "MyServiceTeamUsers-" + getExportedExcelFileNameSuffix();
    var sheetName = "My Service Team Users";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
