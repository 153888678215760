import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import { ApiService } from "src/app/services";
import { PlanAssignResultEnum, PlanValidationErrorCode, altDocUrl } from "src/app/npr-request.model";
import { ModalService } from "src/app/shared/modal.service";
import { ToastrService } from "ngx-toastr";
import { fillPlanAssignmentWarningMessageFromException } from "../../../../utility/common-helper";

@Component({
  templateUrl: "./plan-assignment-response-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanAssignmentResponseModalComponent implements OnInit {
  @Input() planSubmissions: PlanSubmission[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;
  public requireSkuAvailableInCrp = true;
  public retried = false;
  public softDeleted = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected apiService: ApiService,
    protected modalService: ModalService,
    private notificationService: ToastrService,
  ) { }

  async ngOnInit() {
    await this.assignPlans();
  }

  async assignPlans() {
    // remove invalid plan submissions. i.e: plans with same region and service in minimum and GA stage are assigned at the same time
    var filteredPlanSubmissions: PlanSubmission[] = [];
    this.planSubmissions.forEach((plan) => {
      var hasSamePlanWithGA = this.planSubmissions.some(
        (data) => data.Region === plan.Region && data.ServiceTreeId === plan.ServiceTreeId && data.Stage === "GA"
      );
      if (!(plan.Stage === "Minimum" && hasSamePlanWithGA)) {
        filteredPlanSubmissions.push(plan);
      }
    });
    this.planSubmissions = filteredPlanSubmissions;

    await Promise.all(
      this.planSubmissions.map(async (planSubmission) => {
        try {
          planSubmission.RequireSkuAvailableInCrp = this.requireSkuAvailableInCrp;
          // Only happens when reconfirming delete minimum stage & Proceed for unavailable skus
          // Just return successful if not throw exception
          if (this.softDeleted == true) {
            await this.apiService.assignPlanAndSoftDeleteOldVersions(planSubmission).toPromise();
            this.aggregatedResult.push(
              `Assign plan for <b>${planSubmission.ServiceTreeId}</b> to region <b>${planSubmission.Region}</b> successfully.`
            );
            return;
          }
          var response = await this.apiService.assignPlanToRegion(planSubmission).toPromise();
          if (response?.Result == PlanAssignResultEnum.Warning) {
            this.notificationService.warning("Warning: " + response.Message);
          }
          if (response?.Message.includes("Assignment reconfirmation")) {
            this.modalService.planAssignmentReconfirmModal(planSubmission).then(
              (r) => {
                this.softDeleted = true;
                var assignPlanMessage = `Assign plan from <b>${planSubmission.ServiceTeam}</b>, version <b>${planSubmission.Version}</b> with stage <b>${planSubmission.Stage}</b> to region <b>${planSubmission.Region}</b> `
                if (r == "cancelled") {
                  this.aggregatedResult.push(assignPlanMessage + "cancelled.");
                } else if (r == "successful") {
                  this.aggregatedResult.push(assignPlanMessage + "successfully.");
                } else {
                  var warningMessage = "";
                  if (r.includes("Plan validation failed")) {
                    this.requireSkuAvailableInCrp = false;
                    if (!this.retried) {
                      warningMessage = r;
                    }
                  } else if (r != null) {
                    warningMessage = r;
                  }
                  
                  this.aggregatedResult.push(assignPlanMessage + `failed with error : ${r}`);
                }
              }
            );
          } else {
            this.aggregatedResult.push(
              `Assign plan for <b>${planSubmission.ServiceTreeId}</b> to region <b>${planSubmission.Region}</b> successfully with message: <br/>${response?.Message}`
            );
          }
        } catch (ex) {
          var warningMessage = "";
          if (ex?.error?.Code == PlanValidationErrorCode.PlanValidationCrpSkuNotAvailable) {
            this.requireSkuAvailableInCrp = false;
            if (!this.retried) {
              warningMessage = fillPlanAssignmentWarningMessageFromException(ex, warningMessage);
              warningMessage += `<b>Close</b> and add some <a href="${altDocUrl}" target="_blank" >alternatives</a> available in the region before re-assigning.<br/> Or <b>[Proceed]</b> to acknowledge the risk and continue assigning with the current plan.<br/>`;
            }
          } else if (ex?.error.Message != null) {
            warningMessage = ex?.error.Message;
          }
          this.aggregatedResult.push(
            `Assign plan for <b>${planSubmission.ServiceTreeId}</b> to region <b>${planSubmission.Region}</b> failed with error:<br/> <font color="red">${warningMessage}</font>`
          );
        }
      })
    );
    this.isCompleted = true;
  }

  close() {
    this.activeModal.close(true);
  }

  async continue() {
    this.retried = true;
    this.aggregatedResult = [];
    await this.assignPlans();
  }
}
