<div class="title" #title>
    <div class="container container-max-width">
      <h4>{{ this.tableName + " Table View" }}</h4>
      <p> Please select the table name </p>
      <label class="col-md-9">
        <ng-select
          name="tableName"
          [(ngModel)]="tableItem"
          [items]="tableList"
          bindLabel="TableName"
          placeholder="Please choose one table"
          (change)="onTableChanged()"
          required
        >
          <ng-template ng-option-tmp let-item="item">
            <ng-container>
              <label title="{{ item.TableName }}">
                {{ item.TableTitle }}
              </label>
            </ng-container>
          </ng-template>
        </ng-select>
      </label>
    </div>
  </div>
  <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main">
      <br />
      <div class="hint">
        <label>{{ this.rowData.length }} row(s)</label>
        <div class="nofloat">
          <p *ngIf="message" class="message">{{ this.message }}</p>
        </div>
      </div>
  
      <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
      <form #operatorForm="ngForm" autocomplete="off" novalidate>
        <div class="nofloat">
          <ag-grid-angular
            style="height: 540px"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [rowData]="rowData"
            [masterDetail]="true"
            [detailRowHeight]="145"
            pagination="true"
            (gridReady)="onGridReady($event)"
            (filterChanged)="onFilterChanged($event)"
          >
          </ag-grid-angular>
        </div>
      </form>
    </div>
  </div>
  