import { Component } from "@angular/core";
import { ApiService } from "../shared/api.service";
import { ModalService } from "../shared/modal.service";
import { ModalService as LionrockModalService } from "../../shared/modal.service";
import { DataService } from "../shared/data.service";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { AuthService } from "../../auth/auth.service";
import { Service } from "../shared/model";
import { Cloud, EdgeZone, Region } from "../shared/model";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent } from "../shared/base.component";
import { ModuleService } from "../../shared/module.service";
import * as fileSaver from "file-saver";
import { extendDefaultOptions } from "src/app/utility/gridHelper";

@Component({
  selector: "app-incremental-buildout",
  templateUrl: "./incremental-buildout.component.html",
  styleUrls: ["./incremental-buildout.component.scss", "../styles.scss"],
})
export class IncrementalBuildoutComponent extends BaseComponent {
  inScopeServices: Service[] = [];
  rowSelection = "multiple";
  incrementalRegions: Region[];
  incrementalEdgeZones: EdgeZone[];

  private gridApi;

  columnDefs = [];
  gridOptions = extendDefaultOptions({
    columnDefs: this.columnDefs,
    rowData: this.inScopeServices,
  });

  constructor(
    apiService: ApiService,
    private modalService: ModalService,
    private lionrockModalService: LionrockModalService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    private router: Router,
    route: ActivatedRoute,
    private moduleService: ModuleService
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
  }

  async onInit() {
    this.initIncrementalRegions();
    this.initIncrementalEdgeZones();
    if (this.dataService.selectedRegion) {
      await this.onInScopeServicesFetched();
    }
  }

  initIncrementalRegions() {
    let regionWithEdgeZones = this.dataService.edgeZones && this.dataService.edgeZones.map((ez) => ez.RegionName) || [];
    this.incrementalRegions =
      this.dataService.selectedCloud &&
      this.dataService.cloudRegions.filter(
        (region) => ["Production SLA"].includes(region.State) || region.Initialized || regionWithEdgeZones.includes(region.Name)
      );
    if (this.incrementalRegions && this.dataService.selectedRegions) {
      this.dataService.selectedRegions = this.dataService.selectedRegions.filter((region) =>
        this.incrementalRegions.map((r) => r.Name).includes(region.Name)
      );
    }
  }

  initIncrementalEdgeZones() {
    let selectRegionNames = this.dataService.selectedRegions?.map((r) => r.Name);
    this.incrementalEdgeZones = this.dataService.edgeZones.filter(
      (ez) => selectRegionNames?.includes(ez.RegionName) /* && ez.BillingMeter*/
    );
  }

  onCloudChange(cloud: Cloud) {
    this.dataService.changeCloud(cloud);
    this.initIncrementalRegions();
    // reset the service list.
    this.inScopeServices = [];
    // this.servicesToShow = [];
  }

  onRegionChange() {
    this.dataService.changeRegions();
    this.initIncrementalEdgeZones();
    // reset the service list.
    this.inScopeServices = [];
  }

  onEdgeZoneChanges() {
    this.dataService.changeEdgeZones();
    // reset the service list.
    this.inScopeServices = [];
  }

  rowDataChanged() {
    if (this.gridApi) {
      this.gridApi.selectAll();
    }
  }

  onInScopeServicesFetched() {
    console.log("InScopeServices fetched!");
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    this.apiService.GetIncrementalServices(this.dataService.getOnDemandLocationNames()).subscribe((result) => {
      const r = <any>result;
      this.inScopeServices = r.data;
      this.columnDefs = r.columnDef;
      this.hideOverlay();
    });
  }

  private hideOverlay() {
    if (this.gridApi) {
      this.gridApi.hideOverlay();
    }
  }

  onInScopeServicesGridReady(params) {
    this.gridApi = params.api;
    console.log("grid ready");
  }

  onPreBuildoutTriggered() {
    const confirmation = this.modalService.InitTableConfirmModal(
      "Creating Billing Meters Incrementally",
      `For ${this.dataService
        .getOnDemandLocationNames()
        .join(",")}, the following billing meters creation will be triggered. Please confirm.`,
      this.columnDefs.filter((def) => def.headerName),
      this.gridApi.getSelectedRows(),
      "Submit Request",
      "Cancel"
    );
    console.log(`XXXX: ${this.dataService.getOnDemandLocationNames()}`);
    confirmation.then((confirmed) => {
      console.log(`User confirmation on create billing meter: ${confirmed}`);
      if (confirmed) {
        const result = this.apiService.IncrementalBillingMeter(this.gridApi.getSelectedRows(), this.user);
        this.loadingScreenService.setLoading(true);
        result.subscribe((res) => {
          this.onInScopeServicesFetched();
          const r = <[{ jobId: string }]>res;
          this.loadingScreenService.setLoading(false);
          if (r instanceof Array) {
            this.lionrockModalService.informationModal(
              `Pre-buildout request has been submitted successfully! JobId: ${r.map((j) => j.jobId).join(",")}`
            );
          } else {
            this.lionrockModalService.informationModal(`Pre-buildout request is failed: ${r}`);
          }
        });
      }
    });
  }

  onKickOffBuildout() {
    this.router.navigate(["/"], {
      queryParams: {
        cloud: this.dataService.selectedCloud.CloudName,
        region: this.dataService.selectedRegions[0].Name,
      },
    });
  }

  onExportExcel() {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: true,
      onlySelectedAllPages: true,
      fileName: "In-scope Offerings",
      sheetName: "In-scope Offerings",
      columnKeys: this.gridOptions.columnApi
        .getAllColumns()
        .filter((c) => c.getColDef().headerName && c.isVisible())
        .map((c) => c.getColDef().field),
    };
    this.gridApi.exportDataAsExcel(params);
  }

  onExportExcelWithDetails() {
    console.log("Export details.");
    this.loadingScreenService.setLoading(true);
    this.apiService.exportExcelRequestWithDetails(this.gridApi.getSelectedRows(), "Incremental").subscribe((res) => {
      this.loadingScreenService.setLoading(false);
      let blob: any = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      fileSaver.saveAs(blob, "RequestsWithDetails.xlsx");
    });
  }

  isPreBuildoutButtonDisabled() {
    return this.inScopeServices == null || this.inScopeServices.length === 0;
  }

  isBuildoutButtonDisabled() {
    return true;
  }
}
