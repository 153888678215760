import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "refresh-cell",
  template: `<a href="javascript:void(0)" (click)="invokeMethod()"> Refresh </a>`,
})
export class RefreshRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public invokeMethod() {
    this.params.context.componentParent.refreshSingleEntry(this.params.data.CapacityOrderId);
  }

  refresh(): boolean {
    return false;
  }
}
