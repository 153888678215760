import { Component, OnInit, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserRole } from "src/app/generated-models";
import { IRegionGroupListRecord, Roles } from "src/app/npr-request.model";

@Component({
  templateUrl: "./approver-edit-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class ApproverEditModalComponent implements OnInit {
  @Input() message: string;
  @Input() data: UserRole;
  @Input() regionsList: IRegionGroupListRecord[];
  @Input() typesList: string[];

  @Output() returnUpnText = "";
  @Output() returnRoleText = "";
  @Output() selectedRegions: string[] = [];
  @Output() selectedTypes: string[] = [];
  @Output() isAllRegionsSelected = false;
  @Output() isAllTypesSelected = false;

  isApproverAdmin: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.returnUpnText = this.data.Upn;
    this.returnRoleText = this.data.Role;
    if (this.data.Role === Roles.ApproverAdmin) {
      this.isApproverAdmin = true;
    }

    if (this.data.Regions && this.data.Regions !== "*") {
      this.selectedRegions = this.data.Regions.split(",");
    } else {
      this.isAllRegionsSelected = true;
    }

    if (this.data.Types && this.data.Types !== "*") {
      this.selectedTypes = this.data.Types.split(",");
    } else {
      this.isAllTypesSelected = true;
    }
  }

  save() {
    const role = {
      Types: this.isAllTypesSelected ? "*" : this.selectedTypes.toString(),
      Regions: this.isAllRegionsSelected ? "*" : this.selectedRegions.toString(),
    };

    this.activeModal.close(role);
  }
}
