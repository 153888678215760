<div class="title">
  <div class="container">
    <h4>Incremental Billing Meters</h4>
    <p>
      This UI is used only by Region Buildout PM's for meter submission during region buildouts. Contact <a href="mailto:bowloper@microsoft.com">Lionrock Support</a> to request access if you are a Region Buildout PM. All other requests will be rejected.
    </p>
    <p>
      Service owners: Click <a href="/quota/prep/ondemand">here</a> to submit service level meter requests. Click <a target="_blank" href="https://aka.ms/billingmeterfaq">here</a> for FAQ’s.
    </p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isAuthorizedUser">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && isAuthorizedUser && !userCanIncrementalBuildout">
  <h5>Permission is required!</h5>
  <h5>Please Contact <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add the permission </h5>
</div>
<div class="container container-content">
  <div class="main-content" *ngIf="isLoadingFinished && userCanIncrementalBuildout">
    <div class="form-group">
      <h4>Select Cloud and Region to get started</h4>
    </div>
    <div class="region-select">
      <div class="form-group" style="float: left">
        <h6>Cloud</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.clouds"
            bindLabel="CloudName"
            placeholder="Select Cloud"
            (change)="onCloudChange($event)"
            [(ngModel)]="dataService.selectedCloud"
            name="CloudName"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Loading...</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Region</h6>
        <label style="width: 100%">
          <ng-select
            [items]="incrementalRegions"
            bindLabel="Name"
            placeholder="Select Region"
            (change)="onRegionChange()"
            [(ngModel)]="dataService.selectedRegions"
            name="Name"
            [multiple]="true"
            [closeOnSelect]="false"
            [disabled]="dataService.selectedCloud == null"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" (change)="$event.stopPropagation();" />
              {{ item.Name }}
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Edge Zone</h6>
        <label style="width: 100%">
          <ng-select
            [items]="incrementalEdgeZones"
            bindLabel="EdgeZoneName"
            placeholder="Select Edge Zone"
            (change)="onEdgeZoneChanges()"
            [(ngModel)]="dataService.selectedEdgeZones"
            name="Name"
            [multiple]="true"
            [closeOnSelect]="false"
            [disabled]="dataService.selectedRegion == null"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" (change)="$event.stopPropagation();" />
              {{ item.EdgeZoneName }}
            </ng-template>
          </ng-select>
        </label>
      </div>
    </div>
    <div class="form-group">
      <button
        type="button"
        class="btn btn-primary mr-1"
        [disabled]="dataService.selectedCloud == null"
        (click)="onInScopeServicesFetched()"
      >
        Fetch in-scope Offerings
      </button>
    </div>

    <hr />
    <h4>Review Services</h4>
    <p style="margin-bottom: 0px">
      To make any modifications to the list please visit
      <a href="https://aka.ms/pamurl">aka.ms/pamurl</a>
    </p>
    <div style="width: 100%; text-align: right">
      <button type="button" class="btn btn-primary mr-1" (click)="onExportExcel()">Export Request Details</button>
      <button type="button" class="btn btn-primary mr-1" (click)="onExportExcelWithDetails()">Export Request and Meter Details</button>
    </div>
    <div class="nofloat">
      <ag-grid-angular
        style="height: 540px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="inScopeServices"
        [rowSelection]="rowSelection"
        [rowMultiSelectWithClick]="true"
        [columnDefs]="columnDefs"
        (gridReady)="onInScopeServicesGridReady($event)"
        (rowDataChanged)="rowDataChanged()"
        [masterDetail]="true"
        [detailRowHeight]="145"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
    <div *ngIf="isPrep()">
      <hr />
      <div *ngIf="dataService.isMultipleRegionSelected() && inScopeServices != null && inScopeServices.length > 0">
        <h5>Buildout Prep will be kicked off for ALL of the selected products.</h5>
      </div>
      <button type="button" class="btn btn-primary mr-1" [disabled]="isPreBuildoutButtonDisabled()" (click)="onPreBuildoutTriggered()">
        Submit Billing Meter Request
      </button>
    </div>
  </div>
</div>
