<div class="modal-header">
  <h4 class="modal-title">Plan assignment</h4>
</div>

<div>
  <mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
  <div class="modal-body">
    <p>In progress {{ this.aggregatedResult.length }}/{{ this.planSubmissions.length }} :</p>
    <ul *ngFor="let item of aggregatedResult">
      <li [innerHtml]="item"></li>
    </ul>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="continue()" [hidden]="requireSkuAvailableInCrp || retried">Proceed</button>
  <button type="button" class="btn btn-warning" (click)="close()">Close</button>
</div>
