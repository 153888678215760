import { Component } from "@angular/core";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { SkipList } from "./model";
import { ApiService } from "../shared/api.service";
import { BaseComponent } from "../shared/base.component";
import { ModuleService } from "../../shared/module.service";
import { AuthService } from "../../auth/auth.service";
import { DataService } from "../shared/data.service";
import { ActivatedRoute } from "@angular/router";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ColDef, ColumnApi, GridApi, IAfterGuiAttachedParams, ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { ModalService as LionrockModalService } from "../../shared/modal.service";
import { Cloud } from "../shared/model";
import { timer } from "rxjs";

class CheckboxRenderer implements ICellRendererComp {
  private eGui: HTMLInputElement | undefined;

  init(params: ICellRendererParams): void {
    this.eGui = document.createElement("input");
    this.eGui.type = "checkbox";
    this.eGui.checked = params.value === true;
    this.eGui.addEventListener("change", (e) => {
      const checked = (e.target as HTMLInputElement).checked;
      params.node.setDataValue(params.column.getColId(), checked);
      // @ts-ignore
      params.onChange(e, params);
    });
  }

  getGui(): HTMLElement {
    return this.eGui!;
  }

  refresh(params: ICellRendererParams): boolean {
    if (this.eGui) {
      this.eGui.checked = params.value === true;
    }
    return true;
  }

  destroy?(): void {}

  afterGuiAttached?(_params?: IAfterGuiAttachedParams): void {}
}

@Component({
  selector: "app-home",
  templateUrl: "./skip-list.component.html",
  styleUrls: ["./skip-list.component.scss", "../styles.scss"],
})
export class SkipListComponent extends BaseComponent {
  skipList: SkipList[] = null;
  change = false;

  private gridApi: GridApi;
  private columnApi: ColumnApi;

  clouds2: Cloud[] = [
    {
      CloudId: 1,
      CloudOid: "1",
      CloudName: "Public",
      Announced: true,
      CloudIsSovereign: false,
    },
    {
      CloudId: 2,
      CloudOid: "2",
      CloudName: "Fairfax",
      Announced: true,
      CloudIsSovereign: false,
    },
    {
      CloudId: 3,
      CloudOid: "3",
      CloudName: "Mooncake",
      Announced: true,
      CloudIsSovereign: false,
    },
  ];

  columnDefs: ColDef[] = [
    { headerName: "Cayman Product Id", field: "CaymanProductId" },
    { headerName: "Cayman Product Name", field: "CaymanProductName" },
    { headerName: "Product Oid", field: "ProductOid" },
    { headerName: "Product Name", field: "ProductName" },
    { headerName: "Product Ring", field: "ProductRing" },
    {
      headerName: "All Clouds",
      field: "AllClouds",
      cellRenderer: CheckboxRenderer,
      width: 100,
      // sort: "desc",
      cellRendererParams: {
        onChange: (event, params) => {
          let data = params.node.data;
          this.dataService.clouds.forEach((cloud) => {
            data[`Skip${cloud.CloudName}`] = event.target.checked;
          });
          this.setSkipData(params);
          params.api.refreshCells();
        },
      },
    },
  ];
  gridOptions = extendDefaultOptions({
    columnDefs: this.columnDefs,
    rowData: this.skipList,
    onModelUpdated: (params) => {
      params.api.forEachNode((node) => {
        this.initSkipData(node);
      });
    },
  });

  constructor(
    apiService: ApiService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    route: ActivatedRoute,
    private lionrockModalService: LionrockModalService,
    private moduleService: ModuleService
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
  }

  onInit(): void {
    this.gridApi.setColumnDefs(
      this.columnDefs.concat(
        this.dataService.clouds.map((cloud) => ({
          headerName: cloud.CloudName,
          field: `Skip${cloud.CloudName}`,
          cellRenderer: CheckboxRenderer,
          cellRendererParams: {
            onChange: (_event, params) => {
              let data = params.node.data;
              data.AllClouds = this.dataService.clouds.every((cloud) => data[`Skip${cloud.CloudName}`]);
              this.setSkipData(params);
              params.api.refreshCells();
            },
          },
        }))
      )
    );

    this.columnApi.autoSizeColumns(
      this.columnApi
        .getAllColumns()
        .filter((column) => column.getColDef().field.startsWith("Skip"))
        .map((column) => column.getColId())
    );
    this.loadSkippedList();
  }

  loadSkippedList(): void {
    this.loading((finish) => {
      this.apiService.getSkipList().subscribe((data: SkipList[]) => {
        this.skipList = data;
        timer(1).subscribe(() => {
          this.gridApi.refreshCells();
          finish();
        });
      });
    });
  }

  private initSkipData(node) {
    let data = node.data;
    data.OriginSkipped = data.OriginSkipped ?? data.Skipped;
    if (data.Skipped === "All") {
      data.AllClouds = true;
      this.dataService.clouds.forEach((cloud) => {
        data[`Skip${cloud.CloudName}`] = true;
      });
    } else {
      data.AllClouds = false;
      let skippedClouds = data.Skipped.split(",");
      this.dataService.clouds.forEach((cloud) => {
        data[`Skip${cloud.CloudName}`] = skippedClouds.includes(cloud.CloudName);
      });
    }
  }

  private setSkipData(params) {
    let data = params.node.data;
    data.OriginSkipped = data.OriginSkipped ?? data.Skipped;
    const skipClouds = this.dataService.clouds
      .map((cloud) => data[`Skip${cloud.CloudName}`] && cloud.CloudName)
      .filter((cloudName) => cloudName);
    if (data.AllClouds) {
      data.Skipped = "All";
    } else {
      data.Skipped = skipClouds.join(",");
    }
    this.change = false;
    params.api.forEachNode((node) => {
      if (node.data.Skipped !== node.data.OriginSkipped) {
        this.change = true;
      }
    });
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    console.log("grid ready");
  }

  onSave(): void {
    console.log("Save button clicked!");
    const modifiedData = [];
    this.gridApi.forEachNode((node) => {
      let data = node.data;
      if (data.Skipped !== data.OriginSkipped) {
        modifiedData.push(data);
      }
    });

    if (modifiedData.length === 0) {
      this.lionrockModalService.informationModal("Please modify the data before saving");
    } else {
      this.loading((finish) => {
        console.log("Modified data is ", modifiedData);
        this.apiService.modifySkipList(modifiedData).subscribe(
          (_) => {
            modifiedData.forEach((data) => {
              data.OriginSkipped = data.Skipped;
            });
            finish();
            this.change = false;
            this.lionrockModalService.informationModal("Skip list data updated successfully");
          },
          (error) => {
            finish();
            this.lionrockModalService.informationModal(`Error updating skip list data: ${error.message}`);
          }
        );
      });
    }
  }

  onReset(): void {
    console.log("Reset button clicked!");
    this.gridApi.forEachNode((node) => {
      let data = node.data;
      if (data.Skipped !== data.OriginSkipped) {
        data.Skipped = data.OriginSkipped;
        this.initSkipData(node);
      }
    });
    this.gridApi.refreshCells();
    this.change = false;
  }
}
