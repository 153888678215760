import { ColDef } from "ag-grid-community";
import * as CDs from "src/app/utility/commonColDef";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";

export const ServiceTeamUserColumnDefinition: ColDef[] = [
  {
    headerName: "Service",
    field: "ServiceName",
  },
  CDs.ServiceTreeId,
  {
    headerName: "User Email",
    field: "Upn",
  },
  {
    headerName: "User Role",
    field: "Role",
  },
  CDs.CreatedBy,
  {
    ...CDs.CreatedTime,
    cellRenderer: (params) => PreprocessDateTime(params.value),
  },
];
