import { Component, Inject, InjectionToken, Type, ViewEncapsulation } from "@angular/core";
import { IHeaderParams } from "ag-grid-community";
import { paramsToken } from "./dynamic-header.component";

export interface TooltipHeaderComponentParams {
  component: Type<TooltipHeaderComponent>;
  tooltip: ((params: IHeaderParams) => string) | string;
  placement: ((params: IHeaderParams) => string) | string;
  prefix: ((params: IHeaderParams) => string) | string;
}

export interface TooltipHeaderComponentColDef {
  headerComponentParams?: TooltipHeaderComponentParams;
}

@Component({
  templateUrl: "./tooltip-header.component.html",
})
export class TooltipHeaderComponent {
  params: IHeaderParams & TooltipHeaderComponentParams;
  tooltip: string;
  placement: string;

  constructor(@Inject(paramsToken) params: any) {
    this.params = params;
    this.tooltip = typeof this.params.tooltip === "string" ? this.params.tooltip : this.params.tooltip(params);
    this.placement =
      (this.params.placement && (typeof this.params.placement === "string" ? this.params.placement : this.params.placement(params))) ||
      "top";
  }
}
