<div class="modal-header modal-header-sub">
  <h5 class="modal-title">View Subscription Details</h5>
  <h6 class="error-message">{{ errorMessage }}</h6>
</div>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="isLoading"> </mat-progress-bar>
<div class="modal-body" style="height: 240px">
  <div class="nofloat" style="margin-top: 10px; height: 50px">
    <h6>Created Time</h6>
    {{ createdTime }}
  </div>
  <div class="nofloat" style="margin-top: 10px; height: 50px">
    <h6>Status</h6>
    {{ status }}
  </div>
  <div class="nofloat" style="margin-top: 10px; height: 50px">
    <h6>Cloud Type</h6>
    {{ cloudType }}
  </div>
</div>
<a class="modal-header-close" href="javascript:void(0)" (click)="activeModal.close({})" container="body" aria-label="Close">
  <span class="fa fa-times fa-lg"></span>
</a>
