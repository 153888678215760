import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ExcelExportParams, GridOptions } from "ag-grid-community";
import { TicketPriorityEnum, ISubRequest } from "../../npr-request.model";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../../shared/modal.service";
import { RequestViewBase } from "../../shared/request-view-base";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { AllRequestedItemsColumnDefinition } from "./ag-grid-column-definition";
import { getExportedExcelFileNameSuffix, OnColumnMoved, saveFile, setColumnState } from "../../utility/common-helper";
import { SharedDataService } from "../../services/sharedDataService";

const LoadingBarInitState = "[>____]";
const BarIndicator = ">";
const BarPlaceHolder = "_";

@Component({
  templateUrl: "./operator-review.component.html",
  styleUrls: ["../../styles.scss"],
})
export class OperatorReviewComponent extends RequestViewBase implements OnInit {
  /*
   * inherited property from RequestViewBase for ag-grid Angular binding
   */
  gridOptions: GridOptions;
  rowData: ISubRequest[] = [];
  initPageSize = 15;
  showArchiveFlag = false;

  loadingIndicator = LoadingBarInitState;
  barAnimated(run: boolean) {
    if (run === false) {
      this.loadingIndicator = "Loaded";
      return;
    }

    const pos = this.loadingIndicator.indexOf(BarIndicator);
    const len = this.loadingIndicator.length - 2;
    if (pos < this.loadingIndicator.length - 2) {
      this.loadingIndicator = "[" + BarPlaceHolder.repeat(pos) + BarIndicator + BarPlaceHolder.repeat(len - pos - 1) + "]";
    } else {
      this.loadingIndicator = LoadingBarInitState;
    }
  }

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService,
    private route: ActivatedRoute
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
  }

  ngOnInit() {
    // base method for ag-grid angular binding
    super.InitAgGrid(AllRequestedItemsColumnDefinition);
    this.gridOptions.onColumnMoved = (params) => OnColumnMoved(params, "allRequestAdminColumnState");
  }

  onGridReady(params: GridOptions) {
    // base method for ag-grid api setup
    super.SetAgGridContext(params);
    // base method to load data with authentication
    // the implementation is defined in derived refreshData()
    super.LoadData();

    // add your code here for handle onGridReady event
    setColumnState(params, "allRequestAdminColumnState");
  }

  refreshData() {
    this.gridOptions.api?.showLoadingOverlay();
    this.apiService.getAllSubRequests(this.showArchiveFlag).subscribe(
      (response) => {
        this.prepareData(response);
      },
      () => {
        this.gridOptions.api?.hideOverlay();
        this.message = "Failed to get requests.";
      }
    );
  }
  OnShowArchiveFlagChanged(isChecked: boolean) {
    this.refreshData();
    this.gridColumnApi.setColumnVisible("CompletedTime", isChecked);
  }
  prepareData(requestData: ISubRequest[]) {
    // Must init for the first cache fillup
    if (this.rowData.length === 0) {
      this.rowData = [];
    }
    const newData: ISubRequest[] = [];
    requestData.sort((a, b) => new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
    requestData.forEach((element: ISubRequest) => {
      element.RequestDisplayServiceType = ApiService.fetchServiceTypeName(element.RequestServiceType, element.SKU);
      element.Placeholder = "";
      element.RequestId = `${element.ParentRequestId}-${element.SubRequestId}`;
      element.IsExternalDisplay = element.IsExternal ? "Yes" : "No";
      element.IsHoboDisplay = element.IsHobo ? "Yes" : "No";
      element.RingLevel = element.IsExternal ? "External" : element.RingLevel;
      let priorityNumber = element.Priority;
      priorityNumber = priorityNumber === 0 ? 3 : priorityNumber;
      element.PriorityString = `${priorityNumber} - ${TicketPriorityEnum[priorityNumber]}`;
      element.RequestLink = ApiService.generateSubRequestHyperLink(element.ParentRequestId, element.SubRequestId.toString());
      element.CreatedTime = PreprocessDateTime(element.CreatedTime);
      if (element.CompletedTime) {
        element.CompletedTime = PreprocessDateTime(element.CompletedTime);
      }

      newData.push(element);
    });
    this.rowData = newData;

    super.setDefaultFilter("operator-review");
  }

  getFilteredSubrequests(): string[] {
    const ids: string[] = [];
    this.gridApi.forEachNodeAfterFilter((node) => {
      ids.push(node.id);
    });
    return ids;
  }

  OnWindowResize() {}

  async exportCurrentView() {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "Requests-" + PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm", "en-US"),
      sheetName: "Requests",
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }
}
