import { Component, HostBinding } from "@angular/core";
import { ITooltipAngularComp } from "ag-grid-angular";
import { DetailsDataType } from "src/app/npr-request.model";
const greyBg = "#D3D3D3";
const statusDescriptionMapping = {
  requestStatus: {
    none: {
      message: "Request status is not recognized.",
      color: greyBg,
    },
    created: {
      message: "Request has been created in the system and awaiting Approval.",
      color: greyBg,
    },
    waiting: {
      message: "Request is in waiting status as the service is not available.",
      color: greyBg,
    },
    approved: {
      message: "Request has been approved and waiting to be picked by fulfillment team.",
      color: greyBg,
    },
    rejected: {
      message: "Request has been rejected. No further updates expected.",
      color: greyBg,
    },
    inprogress: {
      message: "Request fulfillment is in progress. Look at the individual tickets for detailed status.",
      color: greyBg,
    },
    completed: {
      message: "Request has been completed. No further updates expected.",
      color: greyBg,
    },
    completedwithnote: {
      message: "Request has been completed with note, please check Note column for more info: ",
      color: greyBg,
    },
    actionrequired: {
      message: "Request is waiting for submitter's actions. ",
      color: greyBg,
    },
    error: {
      message: "Request was processed with internal errors. ",
      color: greyBg,
    },
    cancelled: {
      message: "Request has been cancelled.",
      color: greyBg,
    },
  },
  ticketStatus: {
    pending: {
      message: "Placing ticket in the fulfillment queue.",
      color: greyBg,
    },
    submitted: {
      message: "Placed ticket in the fulfillment queue.",
      color: greyBg,
    },
    creating: {
      message: "Placing ticket in the fulfillment queue.",
      color: greyBg,
    },
    created: {
      message: "Placed ticket in the fulfillment queue.",
      color: greyBg,
    },
    starting: {
      message: "Starting the fulfillment process.",
      color: greyBg,
    },
    inprogress: { message: "Fulfillment is in progress", color: greyBg },
    aborted: {
      message: "Fulfillment is aborted. Engineers have been notified. Contact us if no updates in 4 hrs.",
      color: greyBg,
    },
    finished: {
      message: "Ticket has been completed. No further updates expected.",
      color: greyBg,
    },
    blocked: {
      message: "Fulfillment is blocked. Engineers have been notified. Contact us if no updates in 4 hrs.",
      color: greyBg,
    },
    completed: {
      message: "Ticket has been completed. No further updates expected.",
      color: greyBg,
    },
    cancelled: {
      message: "Ticket has been cancelled. No further updates expected. Contact us if this was not expected.",
      color: greyBg,
    },
    forelogged: {
      message: "Capacity currently unavailable in region.",
      color: greyBg,
    },
    backlogged: {
      message: "Capacity currently unavailable in region.",
      color: greyBg,
    },
    incomplete: {
      message: "Ticket details are incomplete. Please contact Capacity Management Team (mciocapacitymanagement) for next steps..",
      color: greyBg,
    },
    "in review": {
      message: "Ticket is being Reviewed.",
      color: greyBg,
    },
    "in triage": {
      message: "Ticket is being Triaged.",
      color: greyBg,
    },
  },
};

@Component({
  selector: "tooltip-component",
  template: `
    <div class="custom-tooltip">
      <p>
        <span>{{ data.Status }}:</span>
      </p>
      <table *ngIf="data.type === 'table'; else rawStringBlock">
        <tr>
          <th>VmFamily</th>
          <th>Limit</th>
        </tr>
        <tr *ngFor="let item of data.Desc">
          <td>{{ item.VMFamily }}</td>
          <td>{{ item.Limit }}</td>
        </tr>
      </table>

      <ng-template #rawStringBlock>
        <span>{{ data.Desc }}</span>
      </ng-template>
      <p></p>
    </div>
  `,
  styles: [
    `
      :host {
        position: absolute;
        overflow: hidden;
        pointer-events: none;
        transition: opacity 1s;
        /* word-wrap: break-word; // doesn't work */
        border-radius: 10px;
        border: solid #c0c0c0;
        color: black;
      }

      .custom-tooltip p {
        margin: 5px;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
        text-decoration: underline;
      }
    `,
  ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  private params: any;
  data: any;

  // dynamically set the host element's bg color and width
  @HostBinding("style.background-color") get bg() {
    return this.data.color;
  }
  @HostBinding("style.width") get hostWidth() {
    return this.data.width;
  }
  agInit(params): void {
    this.params = params;

    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;

    if (this.data["Type"] === DetailsDataType.CISJob) {
      return;
    }

    if (this.params.type === "quotaDetail") {
      const quotaDetail = this.data["QuotaDetails"];
      if (quotaDetail != null && quotaDetail != "") {
        try {
          this.data.type = "table";
          this.data.Desc = JSON.parse(quotaDetail);
        } catch {
          this.data.type = "text";
          this.data.Desc = quotaDetail;
        }
      }

      this.data.Status = "Quota Details";
      this.data.color = greyBg;
      return;
    }

    const type = this.data["DetailsId"] != undefined ? "ticketStatus" : this.data["RequestId"] != undefined ? "requestStatus" : undefined;
    if (type == undefined) {
      console.error(">>> unknown data type in customTooltip mapping:", this.data);
      this.data.Desc = ["unknown data type to interpret!"];
      this.data.color = "red";
    } else {
      let status = this.data.Status.toLowerCase();
      let obj = statusDescriptionMapping[type][status];
      let desc: string = obj != undefined ? obj["message"] : "unknown status: " + this.data.Status + "!";
      const color = obj != undefined ? obj["color"] : "red";
      const note = this.data["RejectedReason"]; //alias as Notes
      if (note != undefined) {
        desc += `"${note}"`;
      }

      this.data.Desc = [desc];
      this.data.color = this.params.color || color;
      this.data.width = desc.length * 10;
    }
  }
}
