<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Capacity Order Detail</h4>
      <p>See capacity order details</p>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="loading-main" *ngIf="capacityOrderDetail">
    <div class="top-panel" style="height: fit-content">
      <ul class="list-container col-md-10">
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Order Id</div>
          <div class="col-md-10 value">{{ capacityOrderDetail.Id }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Service Team</div>
          <div class="col-md-10 value">{{ serviceTeam }} ({{ capacityOrderDetail.ServiceTreeId }})</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Status</div>
          <div class="col-md-10 value" *ngIf="actionRequired; else noAction">
            <b [style.color]="'red'">{{ orderStatus }}</b
            >&nbsp;&nbsp;
            <a href="https://aka.ms/LionrockTroubleshooting" target="_blank">User Guide</a>
          </div>
          <ng-template #noAction>
            <div class="col-md-10 value">{{ orderStatus }}</div>
          </ng-template>
        </li>
        <li class="flex-box mb-3 d-none">
          <div class="col-md-2 label">Prerequisite Order Ids</div>
          <div class="col-md-10 value">{{ capacityOrderDetail.PrerequisiteOrderIds }}</div>
        </li>
        <li class="flex-box mb-3 d-none">
          <div class="col-md-2 label">On Behalf Of Customers</div>
          <div class="col-md-10 value">{{ capacityOrderDetail.OnBehalfOfCustomers }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Need By Date</div>
          <div class="col-md-10 value">{{ needByDate }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">ApprovalDate</div>
          <div class="col-md-10 value">{{ approvalDate }}</div>
        </li>
        <li class="flex-box mb-3 d-none">
          <div class="col-md-2 label">Expiry Date</div>
          <div class="col-md-10 value">{{ expiryDate }}</div>
        </li>
        <li class="flex-box mb-3 d-none">
          <div class="col-md-2 label">Eta Date</div>
          <div class="col-md-10 value">{{ etaDate }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Is HoBo</div>
          <div class="col-md-10 value">{{ capacityOrderDetail.IsHoBo }}</div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-2 label">Stage</div>
          <div class="col-md-10 value">{{ capacityOrderDetail.Stage }}</div>
        </li>
        <li class="flex-box mb-3 d-none">
          <div class="col-md-2 label">GCT State</div>
          <div class="col-md-10 value">{{ capacityOrderDetail.State }}</div>
        </li>
      </ul>
      <div class="card mb-4">
        <div class="card-header">
          <div>
            <span class="bold-font">Capacity Order Details</span>
          </div>
        </div>
        <div class="card-block p-3">
          <ag-grid-angular
            #agGrid
            id="SubOrdersGrid"
            style="width: 100%; height: 320px"
            class="ag-theme-balham"
            [masterDetail]="true"
            [gridOptions]="subOrdersGridOptions"
            [rowData]="subOrderRowData"
            (gridReady)="onSubOrdersGridReady($event)"
            pagination="true">
          </ag-grid-angular>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header">
          <div>
            <span class="bold-font">Capacity Order Metadata</span>
          </div>
        </div>
        <div class="card-block p-3">
          <ag-grid-angular
            #agGrid
            id="CapacityOrderMetadataGrid"
            style="width: 100%; height: 200px"
            class="ag-theme-balham"
            [masterDetail]="true"
            [gridOptions]="metadataGridOptions"
            [rowData]="metadataRowData"
            (gridReady)="onMetadataGridReady($event)"
            pagination="true">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
