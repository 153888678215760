import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IUIRequest } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ParseRequestId } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./create-request-response-modal.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class CreateRequestResponseModalComponent implements OnInit {
  @Input() uiRequests: IUIRequest[];
  @Output() requestIds: string[];

  public aggregatedResult: string[] = [];
  public aggregatedResultTemp: string[] = [];
  public messageForDuplication = `Do you want to proceed or edit the request?`;
  public finishedCount = 0;
  public redirectBtnVisible = false;
  public isCompleted = false;
  public isDuplicated = false;
  public proceedBtnVisible = false;
  public closeBtnVisible = true;
  public editBtnVisible = false;
  public showMessageForDuplication = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) { }

  async ngOnInit() {
    this.requestIds = [];
    // Check duplicate by parent request first, and then by sub request
    this.aggregatedResultTemp = [];
    await Promise.all(this.uiRequests.map(async (request) => await this.checkParentRequestDuplicates(request)));

    this.aggregatedResult = [...this.aggregatedResultTemp];
    if (this.isDuplicated) {
      this.isCompleted = true;
    } else {
      // Create request
      this.showMessageForDuplication = false;
      this.aggregatedResultTemp = [];

      await Promise.all(this.uiRequests.map((request) => this.createRequest(request)));
      this.aggregatedResult = [...this.aggregatedResultTemp];
      this.isCompleted = true;
    }
  }

  async checkParentRequestDuplicates(request: IUIRequest) {
    const duplicateParentRequestIds = await this.apiService.checkParentRequestDuplicates(request).toPromise();
    if (duplicateParentRequestIds && duplicateParentRequestIds.length > 0) {
      this.isDuplicated = true;
      this.showMessageForDuplication = false;
      this.proceedBtnVisible = false;
      this.editBtnVisible = true;
      this.closeBtnVisible = false;

      const parentReqLink = ApiService.generateParentRequestHyperLink(duplicateParentRequestIds[0].RequestId);
      this.aggregatedResultTemp.push(
        `<span class='red-text'>We found a same request ${parentReqLink} for subscription <b>${request.SubscriptionId}</b> and region <b>${request.Region}</b> already being processed.
        Please contact the support if you feel it's blocked for too long.
        </span>`
      );
    }
  }

  createRequest(request: IUIRequest) {
    const commonMessage = `<b>${request.Region}</b> for <b>${request.SubscriptionId}</b>`;
    return this.apiService
      .createRequest(request)
      .toPromise()
      .then(
        (response) => {
          if (!response) {
            this.aggregatedResultTemp.push(`Request failed in ${commonMessage}`);
          } else {
            this.finishedCount++;
            this.aggregatedResultTemp.push(`Request <a class="data-test-id" href="/quota/requests/${response}">${response}</a> was created successfully in ${commonMessage}`);
          }
          this.redirectBtnVisible = true;
          this.closeBtnVisible = true;
        },
        (error) => {
          this.aggregatedResultTemp.push(`Request failed in ${commonMessage}<br /><span class='red-text'>${error}</span>`);
        }
      )
      .catch((err) => {
        this.aggregatedResultTemp.push(`Request failed in ${commonMessage}, error: ${err}`);
      });
  }

  proceed() {
    this.proceedBtnVisible = false;
    this.editBtnVisible = false;
    this.closeBtnVisible = true;
    this.isCompleted = false;
    this.showMessageForDuplication = false;
    this.aggregatedResultTemp = [];
    Promise.all(this.uiRequests.map((request) => this.createRequest(request))).then(() => {
      this.aggregatedResult = [...this.aggregatedResultTemp];
      this.isCompleted = true;
    });
  }
}
