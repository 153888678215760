import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminViewComponent } from "./admin-view.component";
import { AutoApprovalRuleDisplayModel, TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { AutoApprovalRuleColumnDefinitionForAdmin } from "./ag-grid-column-definition";
import { DataTypes } from "./admin-view-data-types";
import { AutoApprovalRule } from "../../generated-models";
import { SharedDataService } from "../../services/sharedDataService";

@Component({
  selector: "admin-view/autoapprovalrule",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewAutoApprovalComponent extends AdminViewComponent<AutoApprovalRuleDisplayModel> implements OnInit {
  tableName = TableName.AutoApprovalRule;
  columnDef = AutoApprovalRuleColumnDefinitionForAdmin;
  keyFilterStats = "filter:admin-view-auto-approval";

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    dataTypes: DataTypes,
    sharedDataService: SharedDataService
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.type = "Auto Approval Rule";
    this.addRowEnable = true;
    this.updateRowFunc = (data: AutoApprovalRuleDisplayModel) => {
      const payload: AutoApprovalRule = {
        Id: data.Id,
        RequestServiceType: data.RequestServiceType,
        SubscriptionType: data.SubscriptionType,
        RequestSource: data.RequestSource,
        ApprovalType: data.ApprovalType,
        Region: data.Region,
        Quota: data.Quota,
      };
      return apiService.updateAutoApprovalRule(payload).toPromise();
    };
    this.addRowFunc = (data: AutoApprovalRuleDisplayModel) => {
      const payload: AutoApprovalRule = {
        Id: null,
        RequestServiceType: data.RequestServiceType,
        SubscriptionType: data.SubscriptionType,
        RequestSource: data.RequestSource,
        ApprovalType: data.ApprovalType,
        Region: data.Region,
        Quota: data.Quota,
      };
      return apiService.insertAutoApprovalRule(payload).toPromise();
    };
    this.deleteRowEnable = true;
    this.deleteRowFunc = (data: AutoApprovalRuleDisplayModel) => {
      return apiService.deleteAutoApprovalRule(data.Id).toPromise();
    };
  }

  refreshData() {
    this.apiService.getAutoApprovalRuleList().subscribe((response: AutoApprovalRule[]) => {
      this.rowData = response.map((p: AutoApprovalRule) => {
        const displayModel: AutoApprovalRuleDisplayModel = {
          Id: p.Id,
          RequestServiceType: p.RequestServiceType,
          SubscriptionType: p.SubscriptionType,
          RequestSource: p.RequestSource,
          ApprovalType: p.ApprovalType,
          Region: p.Region,
          Quota: p.Quota,
        };
        return displayModel;
      });
      super.setupFilterByLocalData();
    });
  }
}
