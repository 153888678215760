/// <reference path="../../../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PlanFile } from "src/app/generated-models/PlanFile";

export interface PlanEditorModalOptions {
  model: PlanFile;
  save: (model: PlanFile) => Promise<any>;
  isReadonly: boolean;
  schema: string;
}

export interface PlanJsonSchema {
  FullSchema: string;
  MergeSchema: string;
}

@Component({
  templateUrl: "./plan-editor-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../plan.scss"],
})
export class PlanEditorModalComponent implements OnInit {
  @Input() options: PlanEditorModalOptions;
  editor: monaco.editor.IStandaloneCodeEditor;
  planFile: PlanFile;

  constructor(public activeModal: NgbActiveModal, private notificationService: ToastrService) {}

  async ngOnInit() {
    this.planFile = this.options.model;
  }

  onEditorReady(editor: monaco.editor.IStandaloneCodeEditor) {
    this.editor = editor;
    this.editor.setValue(this.planFile.Content);
    monaco.editor.setModelLanguage(this.editor.getModel(), this.planFile.Format);
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      allowComments: true,
      schemas: [
        {
          uri: "http://lionrock/plan-schema.json",
          fileMatch: ["*"],
          schema: JSON.parse(this.options.schema),
        },
      ],
    });

    // delay 1sec for formatting the content
    setTimeout(() => {
      this.editor
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          this.editor.updateOptions({ readOnly: this.options.isReadonly });
        });
    }, 1000);
  }

  async submit() {
    try {
      this.planFile.Content = this.editor.getValue();
      await this.options.save(this.planFile);
    } catch (e) {
      this.notificationService.error(e);
    }
  }
}
