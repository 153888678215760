export enum PfPlanStatusEnum {
  Approvable = "Approvable",
  Approved = "Approved",
  Removed = "Removed",
}

export enum PfPlanOperationTypeEnum {
  Create = "Create",
  Approve = "Approve",
  Remove = "Remove"
}