import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { OperationLog, PlannedQuotaRequestDetail } from "../../generated-models";
import { ISubRequest, IUserProfile, RequestOperation } from "../../npr-request.model";
import { ApiService } from "../../services";
import { CustomTooltipComponent } from "../../shared/auxillary-components/custom-tooltip.component";
import { ServiceTypeRendererComponent } from "../../shared/az-mapping-modal/service-type-renderer.component";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../../shared/modal.service";
import { OnColumnMoved, setColumnState } from "../../utility/common-helper";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { PlannedQuotaRequestOperationLogColumnDefinition } from "./operation-log-columns";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { PlannedQuotaRequestStatus } from "src/app/shared/enums/plan-enums";

@Component({
  selector: "app-parent-request-detail",
  templateUrl: "./planned-quota-request-detail.component.html",
  styleUrls: ["../../styles.scss", "./planned-quota-request-detail.component.scss"],
})
export class PlannedQuotaRequestDetailComponent extends BaseComponent implements OnInit {
  public requestId: string;
  public request: PlannedQuotaRequestDetail;
  public cisJobLink : string;
  public createdTime: string;
  public completedTime: string;
  public subrequests: ISubRequest[] = [];
  public gridOptions: GridOptions;
  public isLoading = true;
  public errorInfo = null; // if 403/404/..., use this to display error info on the page.
  public isRdquota = false;
  public planEditorLink = "";
  public planVersionDetailLink = "";
  public propertyBindingsJsonString = ""; 
  private userProfile: IUserProfile = null;
  isAdmin = false;
  canSkipDenpendencies = false;
  rowData: OperationLog[] = [];
  initPageSize = 15;
  columnDef = PlannedQuotaRequestOperationLogColumnDefinition;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private title: Title
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {

    this.gridOptions = extendDefaultOptions({
      columnDefs: this.columnDef,
      context: this, // passed context for customized component callback
      frameworkComponents: {
        // register angular component for customized column header
        // https://www.ag-grid.com/javascript-grid-header-rendering/#example-header-component
        customTooltip: CustomTooltipComponent,
        serviceTypeRenderer: ServiceTypeRendererComponent,
      },
      isRowSelectable: () => {
        return true;
      },
      animateRows: true,
      paginationPageSize: this.initPageSize,
      onColumnMoved: (params) => OnColumnMoved(params, "PlannedQuotaRequestDetailColumnState")
    });

    this.loadingService.setLoading(true);
    this.userProfile = await this.apiService.getUserProfile();
    if (this.userProfile?.IsAdmin) {
      this.isAdmin = true;
    }

    // This observable will not be resolved, so can not use toPromise
    this.activatedRoute.params.subscribe(async (queryParams) => {
      this.requestId = queryParams.requestId;
      this.title.setTitle(`Planned Quota Request ${this.requestId} - Region Access and Quota`);
      console.log(`requestId: ${this.requestId}`);
      this.gridOptions.getRowHeight = function (params) {
        if (params.data.Details == null) {
          return 30;
        }
        return params.data.Details.split("\n").length * 30;
      };
      try {
        this.request = await this.apiService.getPlannedQuotaRequestById(this.requestId).toPromise();
        this.rowData = await this.apiService.getPlannedQuotaRequestHistoryById(this.requestId).toPromise();
        this.createdTime = PreprocessDateTime(this.request?.CreatedTime);
        this.completedTime = PreprocessDateTime(this.request?.CompletedTime);
        this.propertyBindingsJsonString = JSON.stringify(this.request?.PropertyBindings, null);

        if (!this.request) {
          this.errorInfo = "Not Found";
        } else {
          this.request.Status = this.request.Status.replace("Processed", "In Progress");
          if (this.request.Stage && this.request.Stage != "")
          {
            this.planVersionDetailLink = `/quota/plans/services/${this.request.ServiceTreeId}/regions/${this.request.Region}/versions/${this.request.PlanVersion}/stages/${this.request.Stage}/detail`
          }
        }
        this.cisJobLink = this.generateCisJobLink();
        this.isLoading = false;
        this.loadingService.setLoading(false);
      } catch (errorResponse) {
        this.errorInfo = errorResponse
        this.isLoading = false;
        this.loadingService.setLoading(false);
      }
    });
  }

  onGridReady(params: GridOptions): void {
    this.gridOptions.columnApi.autoSizeAllColumns();
    setColumnState(params, "PlannedQuotaRequestDetailColumnState");
  }

  onFirstDataRendered() {
    this.gridOptions.columnApi.autoSizeAllColumns();
  }

  isRetryable(): boolean {
    return this.request != null && (this.request.Status == PlannedQuotaRequestStatus.Error || this.request.Status == PlannedQuotaRequestStatus.ValidationFailed);
  }

  needToRenderCisJobLink(): boolean {
    return this.request.CisJobId &&
      this.request.CisJobType &&
      this.request.RequestSource === 'CIS'
  }

  generateCisJobLink() : string {
    return `https://azcis.trafficmanager.net/${this.request.CloudName}/${this.request.CisJobType}/Jobs/${this.request.CisJobId}`
  }

  async getSubscriptionDetails() {
    await this.modalService.subscriptionDetailsModal("Subscription Details", this.request.SubscriptionId).catch((err) => {
      console.error(err);
    });
  }

  async retry(): Promise<void> {
    const needRefresh: boolean = await this.modalService.plannedQuotaRequestConfirmationModal(RequestOperation.Retry, this.request);
    if (needRefresh) {
      this.ngOnInit();
    }
  }
}
