<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">New FFP buildout</h2>
  </div>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body">
        <span>Select a Project</span>
        <ng-select name="Project"
                   bindLabel="Label"
                   [(ngModel)]="selectedProject"
                   [items]="projects"
                   groupBy="ProjectStatus"
                   placeholder="Select a Project"
                   [virtualScroll]="true"
                   required
                   data-test="Project">
        </ng-select>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid" data-test="Submit">OK</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close()">Cancel</button>
    </div>
  </form>
</div>
