<div class="modal-header modal-header-quota">
  <h4 class="modal-title">
    Previous Approval Information
  </h4>
</div>
<a class="modal-header-close" href="javascript:void(0)" (click)="activeModal.close({})" container="body" aria-label="Close">
  <span class="fa fa-times fa-lg"></span>
</a>
<div class="modal-body">
  <ul *ngFor="let approval of approvals">
    <li class="mb-3" style="display: flex"> 
      <div class="bold-font col-sm-3">{{ approval.Type }} {{ approval.Type === "Reject" ? "" : "Approval" }}</div>
      <div>
        <ng-container *ngIf="!approval.By; else ApprovedTemplate">
          (Pending) 
          <br />
          <div *ngIf="contacts[approval.Type]; else prevApprovalTemplate">
            Contact {{ contacts[approval.Type] }} if pending for more than 1 business day
          </div>
          <ng-template #prevApprovalTemplate>
            <span *ngIf="previousApprovals.has(approval.Type) && !approval.By"> Same type of requests were previously approved by
              <span *ngFor="let prevApproval of previousApprovals.get(approval.Type).slice(0, 5); let prevIndex=index">
                  <span *ngIf="prevIndex !== previousApprovals.get(approval.Type).length - 1;else lastApprovalTemplate">{{ prevApproval.By }},&nbsp;</span>
                  <ng-template #lastApprovalTemplate>{{ prevApproval.By }}</ng-template>
              </span>
            </span>
          </ng-template>
        </ng-container>
        <ng-template #ApprovedTemplate>
          <span>Approved by {{ approval.By }}</span>
        </ng-template>
      </div>
    </li>
  </ul>
</div>
  