import { Component, ViewChild, ElementRef, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbInputDatepicker, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export type NgbMonthStruct = Pick<NgbDateStruct, "year" | "month">;

@Component({
  selector: "em-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements ControlValueAccessor {
  @ViewChild(NgbInputDatepicker, { static: true })
  private ngbInputDatepicker: NgbInputDatepicker;

  @Input() set markDisabled(value: (date: NgbDateStruct, current: NgbMonthStruct) => boolean) {
    this.ngbInputDatepicker.markDisabled = value;
  }
  @Input() set startDate(value: NgbMonthStruct) {
    this.ngbInputDatepicker.startDate = value;
  }
  @Input() set minDate(value: NgbDateStruct) {
    this.ngbInputDatepicker.minDate = value;
  }
  @Input() set maxDate(value: NgbDateStruct) {
    this.ngbInputDatepicker.maxDate = value;
  }
  @Input() disabled: boolean;
  @Input() labelId: string;
  @Input() lastSecondOfDay: boolean;

  @Output() get navigate() {
    return this.ngbInputDatepicker.navigate;
  }

  private value: Date;

  constructor(private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

  writeValue(value: Date) {
    this.value = value;
    this.ngbInputDatepicker.writeValue(this.value);
    this.changeDetectorRef.markForCheck();
  }
  registerOnChange(onChange: (value: Date) => void) {
    this.ngbInputDatepicker.registerOnChange((value: Date) => {
      if (value instanceof Date) {
        this.value = value;
        /*
                if (this.lastSecondOfDay) {
                    // Just adding (12h - 1s) won't work correct on Summer/Winter time change days.
                    this.value = this.value.addDays(1);
                    this.value = new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate());
                    this.value = this.value.addMilliseconds(-1000);
                }
                */
      } else {
        this.value = null;
      }
      onChange(this.value);
    });
  }
  registerOnTouched(fn: any) {
    this.ngbInputDatepicker.registerOnTouched(fn);
  }
  setDisabledState(isDisabled: boolean) {
    this.ngbInputDatepicker.setDisabledState(isDisabled);
    this.changeDetectorRef.markForCheck();
  }

  toggleDatePicker() {
    this.ngbInputDatepicker.toggle();
  }

  onBlur() {
    if (this.value == null) {
      this.ngbInputDatepicker.writeValue(null);
    }
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.ngbInputDatepicker.close();
    }
  }
}
