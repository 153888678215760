import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ExcelStyle, GridOptions } from "ag-grid-community";
import { ErrorLevel, ISubRequest } from "../../../npr-request.model";
import { ApiService } from "../../../services";
import { LoadingScreenService } from "../../../shared/loading-screen.service";
import { ModalService } from "../../../shared/modal.service";
import { RequestViewBase } from "../../../shared/request-view-base";
import { SubRequestColumnDefinition as ErrorRequestsColumnDefinition } from "./error-requests-columns";
import { CustomTooltipComponent } from "../../../shared/auxillary-components/custom-tooltip.component";
import { ServiceTypeRendererComponent } from "../../../shared/az-mapping-modal/service-type-renderer.component";
import { extendDefaultOptions } from "../../../utility/gridHelper";
import { SharedDataService } from "../../../services/sharedDataService";

@Component({
  templateUrl: "./error-requests.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class ErrorRequestsComponent extends RequestViewBase implements OnInit {
  @Input() errorLevel: string = ErrorLevel.ParentRequest;
  @Input() columnDef = ErrorRequestsColumnDefinition;
  @Input() refreshRowFunc: () => any;
  @Input() retryFunc: (row: ISubRequest) => any;
  /*
   * inherited property from RequestViewBase for ag-grid Angular binding
   */
  gridOptions: GridOptions;
  rowData: any = [];
  initPageSize = 15;
  selectAllFlag = false;
  selectedRowCount = 0;
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
  }

  ngOnInit() {
    this.gridOptions = extendDefaultOptions({
      columnDefs: this.columnDef,
      context: this, // passed context for customized component callback
      frameworkComponents: {
        // register angular component for customized column header
        // https://www.ag-grid.com/javascript-grid-header-rendering/#example-header-component
        customTooltip: CustomTooltipComponent,
        serviceTypeRenderer: ServiceTypeRendererComponent,
      },
      isRowSelectable: () => {
        return true;
      },
      animateRows: true,
      paginationPageSize: this.initPageSize,
    });
  }

  onGridReady(params: GridOptions) {
    // base method for ag-grid api setup
    super.SetAgGridContext(params);

    // base method to load data with authentication
    // the implementation is defined in derived refreshData()
    super.LoadData();
  }

  refreshData() {
    this.selectedRowCount = 0;
    this.rowData = [];
    this.refreshRowFunc().then((response) => {
      this.rowData = response;
      this.loadingService.setLoading(false);
    });
    console.log("Error ", this.errorLevel, " is refreshed ", this.rowData);
  }

  selectAllRowsOnCurrentPage(selected: boolean) {
    const lastGridIndex = this.gridApi.getDisplayedRowCount() - 1;
    const currentPage = this.gridApi.paginationGetCurrentPage();
    const pageSize = this.gridApi.paginationGetPageSize();
    const startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      if (rowNode.group === false) {
        rowNode.setSelected(selected, false);
      }
    }
  }

  onSelectionChanged(event) {
    this.selectedRowCount = event.api.getSelectedNodes().length;
  }

  onChecked(isChecked: boolean) {
    this.selectAllRowsOnCurrentPage(isChecked);
  }

  onPaginationChanged() {
    this.selectAllFlag = false;
  }

  retryRequest() {
    // TODO: We should re-organize these component since it is not used as a component.
    throw new Error("Not Implemented.");
  }

  cancelRequest() {
    // TODO: We should re-organize these component since it is not used as a component.
    throw new Error("Not Implemented.");
  }

  exportExcel() {
    // TODO: We should re-organize these component since it is not used as a component.
    throw new Error("Not Implemented.");
  }
}
