<div class="title">
  <div class="container">
    <h4>On-Demand Billing Meters</h4>
    <p>Use this form to submit on-demand Billing Meter request for a Service</p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isAuthorizedUser">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && !errorMessage">
  <h2>{{ errorMessage}}</h2>
</div>
<div *ngIf="isLoadingFinished && isAuthorizedUser && !userCanOnDemandBuildout">
  <h5>Permission is required!</h5>
  <h5>Only PM or Dev Owners of Services in <a href="https://servicetree.msftcloudes.com/main.html#/">ServiceTree</a> are able to create On-Demand Meter minting. If you are not assigned as one, please request your Service Admin or <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add you to be able to use this functionality. </h5>
</div>
<div class="container container-content" *ngIf="isLoadingFinished && userCanOnDemandBuildout">
  <div class="main-content">
    <div class="form-group">
      <h4>Select Cloud and Region to get started</h4>
    </div>
    <div class="region-select">
      <div class="form-group">
        <h6>Cloud</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.clouds"
            bindLabel="CloudName"
            placeholder="Select Cloud"
            (change)="onCloudChange($event)"
            [(ngModel)]="dataService.selectedCloud"
            name="CloudName"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Loading...</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Region</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.onDemandRegions"
            bindLabel="Name"
            placeholder="Select Region"
            (change)="onRegionChange()"
            [(ngModel)]="dataService.selectedRegions"
            [multiple]="true"
            [closeOnSelect]="false"
            [disabled]="dataService.selectedCloud == null"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" title="region" (change)="$event.stopPropagation();" />
              {{ item.Name }}
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Edge Zone</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.onDemandEdgeZones"
            bindLabel="EdgeZoneName"
            placeholder="Select Edge Zone"
            (change)="onEdgeZoneChanges()"
            [(ngModel)]="dataService.selectedEdgeZones"
            name="EdgeZoneName"
            [multiple]="true"
            [closeOnSelect]="false"
            [disabled]="dataService.selectedRegion == null || dataService.onDemandEdgeZones.length == 0"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" (change)="$event.stopPropagation();" />
              {{ item.EdgeZoneName }}
            </ng-template>
          </ng-select>
        </label>
      </div>

      <div style="width: 100%">
        <h6>ProductName / ProductOid / CaymanProductId</h6>
        <textarea
          style="width: 80%"
          class="form-control"
          [(ngModel)]="product"
          name="product"
          id="product"
          type="text"
          placeholder="Please input the CaymanProductIds, ProductOids or ProductNames you want to submit with comma or newline separated.&#10;For example:&#10;7e1fb574-eb4a-45d7-8db2-c85445aac53f&#10;938a0b4e-849d-4967-8d0d-84a2dce13065&#10;or&#10;7e1fb574-eb4a-45d7-8db2-c85445aac53f,938a0b4e-849d-4967-8d0d-84a2dce13065"
          rows="10"
          required
        >
        </textarea>
      </div>
      <div class="form-group" *ngIf="productMessage">
        <h6>{{ productMessage }}</h6>
      </div>
    </div>
    <div class="form-group">
      <button type="button" title="addProduct" class="btn btn-primary mr-1" [disabled]="product.trim() == '' || !dataService.selectedCloud" (click)="AddProductPreview()">
        Add Product
      </button>
    </div>
    <hr />

    <div style="width: 100%; text-align: right">
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="onExportExcel()"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
    </div>
    <!--form #reqStatusForm="ngForm" autocomplete="off" style="height: 650px" novalidate-->
    <div class="nofloat" style="width: 100%">
      <ag-grid-angular
        style="height: 540px; margin-bottom: 10px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="selectedProducts"
        [columnDefs]="columnDefs"
        (gridReady)="onInScopeServicesGridReady($event)"
        [masterDetail]="true"
        [detailRowHeight]="145"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
    <div *ngIf="dataService.isMultipleRegionSelected() && selectedProducts != null && selectedProducts.length > 0">
      <h5>Buildout Prep will be kicked off for ALL of the selected products.</h5>
    </div>
    <div class="form-group">
      <button
        type="button"
        class="btn btn-primary mr-1"
        [disabled]="isOnDemandBuildoutButtonDisabled()"
        (click)="onDemandBuildoutTriggered()"
      >
        Submit Billing Meter Request
      </button>
      <button type="button" class="btn btn-primary mr-1" [disabled]="isOnDemandBuildoutButtonDisabled()" (click)="onClearProductList()">
        Clear Product List
      </button>
    </div>
  </div>
</div>
