<div class="title" #title>
    <div class="container container-max-width">
      <h4>Plans Risks Table</h4>
      <p> List all service team with region agnostic plan problems</p>
    </div>
  </div>
  <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main">
        <div class="mt-3">
            <h6>{{ tableName }} Table</h6>
            <hr />
            <div class="hint">
                <label>{{ rowData.length }} row(s)</label>
                <a href="javascript:void(0)"
                class="topbar-item"
                (click)="exportCurrentView(tableName)"
                placement="top-right"
                container="body"
                ngbTooltip="Export to Excel"
                aria-label="Export to Excel">
                <span class="far fa-file-excel fa-lg"></span>
                </a>
            </div>
            <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
                <div class="col-form-label">
                    
                  <div class="custom-control custom-radio custom-control-inline">
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="mode1"
                      [(ngModel)]="tableRadio"
                      (change)="onRadioChanged()"
                      [value]="0" />
                    <label class="custom-control-label" for="mode1">Uncommitted Plans</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="mode2"
                      [(ngModel)]="tableRadio"
                      (change)="onRadioChanged()"
                      [value]="1" />
                    <label class="custom-control-label" for="mode2">Teams Not Onboarded Plans</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="mode3"
                      [(ngModel)]="tableRadio"
                      (change)="onRadioChanged()"
                      [value]="2" />
                    <label class="custom-control-label" for="mode3">Plans With Unavailable Skus</label>
                  </div>
                </div>
            <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
            <form #operatorForm="ngForm" autocomplete="off" novalidate>
                <div class="nofloat">
                <ag-grid-angular
                    style="height: 540px"
                    class="ag-theme-balham"
                    [columnDefs]="columnDefs"
                    [gridOptions]="gridOptions"
                    [rowData]="rowData"
                    [masterDetail]="true"
                    [detailRowHeight]="145"
                    pagination="true"
                    (gridReady)="onGridReady($event)"
                    (filterChanged)="onFilterChanged($event)"
                >
                </ag-grid-angular>
                </div>
            </form>
        </div>
    </div>
  </div>
  