import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/services";
import { Project } from "../../../../generated-models/Project";
import { ProjectListRecord } from "../../../../npr-request.model";

@Component({
  templateUrl: "./ffp-project-buildout-modal.component.html",
  styleUrls: ["../../ffp.scss"],
})
export class FfpProjectBuildoutModalComponent implements OnInit {
  projects: Project[] = [];
  selectedProject: Project;

  constructor(public activeModal: NgbActiveModal, private apiService: ApiService, private notificationService: ToastrService) { }

  async ngOnInit() {
    // Project List
    const projectListResponse = await this.apiService.getProjectList().toPromise();

    if (!projectListResponse) {
      console.warn("No project obtained from server.");
    } else {
      this.projects = projectListResponse
        .map((p) => ({ ...p, Label: `${p.Title} (${p.ProjectOid})` } as ProjectListRecord));
    }
  }

  async submit() {
    if (!this.selectedProject) {
      this.notificationService.warning(`Please select a project.`);
      return;
    } else {
      try {
        this.activeModal.close({
          selectedProject: this.selectedProject,
        });
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }
}
