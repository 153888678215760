import { Component, OnInit } from "@angular/core";
import {ColumnApi, ExcelExportParams, ExcelStyle, FilterChangedEvent, GridApi, GridOptions} from "ag-grid-community";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { PlannedQuotaPropertyBindingColumnDefinition, PlannedQuotaSubscriptionBindingColumnDefinition } from "./ag-grid-column-definition";
import { BaseComponent } from "../../shared/base.component";
import {extendDefaultOptions, setupFilterByLocalData, cacheFilterStatsLocal} from "../../utility/gridHelper";
import { forkJoin } from "rxjs";
import { PlannedQuotaSubscriptionBinding } from "src/app/generated-models/PlannedQuotaSubscriptionBinding";
import { PlannedQuotaPropertyBinding } from "src/app/generated-models/PlannedQuotaPropertyBinding";
import { NgModel } from "@angular/forms";
import { ITeamListRecord, IUserProfile } from "../../npr-request.model";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { deepCopy, exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./plan-bindings.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansBindingsComponent extends BaseComponent implements OnInit {
  propertyBindingGridOptions: GridOptions;
  subscriptionBindingGridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridApiProp: GridApi;
  gridColumnApiProp: ColumnApi;
  initPageSize = 15;
  subscriptionBindings: PlannedQuotaSubscriptionBinding[] = [];
  propertyBindings: PlannedQuotaPropertyBinding[] = [];
  oldSubscriptionBindings: PlannedQuotaSubscriptionBinding[] = [];
  oldPropertyBindings: PlannedQuotaPropertyBinding[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: 'header',
      font: {
        bold: true,
      },
    },
  ];

  keyFilterStatusSub = 'filter:subscription-binding';
  keyFilterStatusProp = 'filter:property-binding';
  teamList: ITeamListRecord[] = [];
  teamOid = null;
  notFound = false;

  private userProfile: IUserProfile = null;
  public isAdmin = false;

  constructor(
    private apiService: ApiService, 
    private loadingService: LoadingScreenService, 
    private activatedRoute: ActivatedRoute, 
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
    this.propertyBindingGridOptions = extendDefaultOptions({
      columnDefs: PlannedQuotaPropertyBindingColumnDefinition,
      paginationPageSize: this.initPageSize,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
    });
    this.subscriptionBindingGridOptions = extendDefaultOptions({
      columnDefs: PlannedQuotaSubscriptionBindingColumnDefinition,
      paginationPageSize: this.initPageSize,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
    });
  }

  async ngOnInit(): Promise<void> {
    this.userProfile = await this.apiService.getUserProfile();
    if (this.userProfile?.IsAdmin) {
      this.isAdmin = true;
    }
    this.activatedRoute.params.subscribe((queryParams) => {
      this.teamOid = queryParams.serviceTreeId;
      this.getTeamList();
      this.getBindings();
      this.title.setTitle(`${this.activatedRoute.snapshot.data.title} - ${this.teamOid}`)
    });
  }

  getBindings(): void {
    this.loadingService.setLoading(true);
    forkJoin([this.apiService.getPlannedQuotaSubscriptionBindingsByServiceTreeId(this.teamOid), this.apiService.getPlannedQuotaPropertyBindingsByServiceTreeId(this.teamOid)])
      .subscribe(([subscriptionBindings, propertyBindings]) => {
        this.subscriptionBindings = subscriptionBindings;
        this.propertyBindings = propertyBindings;
        this.subscriptionBindings.map(s => {
          if (this.IsEmptyBinding(s.SubscriptionId)){
            s.SubscriptionId = "";
          }
          return s;
        });
        this.propertyBindings.map(p => {
          if (this.IsEmptyBinding(p.BindingValue)){
            p.BindingValue = "";
          }
          return p;
        });
        setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusSub);
        setupFilterByLocalData(this.gridApiProp, this.gridColumnApiProp, this.keyFilterStatusProp);
        this.oldSubscriptionBindings = deepCopy(this.subscriptionBindings);
        this.oldPropertyBindings = deepCopy(this.propertyBindings);

        this.loadingService.setLoading(false);
    }, (err: unknown) => {
      this.subscriptionBindings = [];
      this.propertyBindings = [];
      this.oldSubscriptionBindings = [];
      this.oldPropertyBindings = [];
      this.loadingService.setLoading(false);
      this.notificationService.error(err as string);
    });
  }

  refresh(): void {
    this.getBindings();
  }

  onGridReadySub(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridReadyProp(params: GridOptions): void {
    this.gridApiProp = params.api;
    this.gridColumnApiProp = params.columnApi;
  }

  onFilterChangedProp(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusProp)
  }

  onFilterChangedSub(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusSub)
  }

  IsEmptyBinding(binding: string): boolean {
    return binding.startsWith("placeholder/");
  }

  isTouchedOrDirty(control: NgModel): boolean {
    return control && (control.touched || control.dirty);
  }

  getTeamList(): void {
    this.apiService?.getMyServiceTeamUser().subscribe((response) => {
      if (response) {
        this.notFound = !response.some(team => team.ServiceTreeId === this.teamOid);
      }
    }, (err: unknown) => {
      this.notFound = true;
      this.notificationService.error(err as string);
    });
  }

  exportExcel(name: string) {
    var fileName = `${name}Bindings-` + getExportedExcelFileNameSuffix();
    var sheetName = `${name} bindings`;
    
    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
