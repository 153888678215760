<div class="title" #title>
    <div class="container container-max-width">
        <div>
            <h4>Assign FFP Plan to Project</h4>
            <p>Assign your service to one or more projects</p>
        </div>
        <div class="action-container">
            <div class="form-check form-check-inline ml-4 toggle">
                <input type="checkbox"
                       class="form-check-input"
                       id="hideAlreadyAssignedSwitch"
                       [(ngModel)]="hideAlreadyAssigned"
                       (change)="onHideAlreadyAssignedChanged()" />
                <label class="form-check-label" for="hideAlreadyAssignedSwitch"> Hide non-assignable </label>
            </div>
            <button type="submit"
                    class="btn btn-primary"
                    [disabled]="!canAssign"
                    (click)="showFfpPlanAssignmentDialog()"
                    data-test="AssignFfpPlan">
                Assign
            </button>
        </div>
    </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main">
        <div class="top-panel" style="width: 100%">
            <ng-select name="FfpPlan"
                       bindLabel="Label"
                       [(ngModel)]="selectedPlan"
                       (ngModelChange)="onPlanChanged()"
                       placeholder="Select your service and type"
                       [items]="ffpPlans"
                       [virtualScroll]="true"
                       required>
            </ng-select>
        </div>
        <p *ngIf="selectedPlan">
            The latest plan version for {{ selectedPlan.ServiceTeam }} [{{ selectedPlan.Type }}] is <b>{{ selectedPlan.Version }}</b>.
        </p>
        <div>
            <ag-grid-angular #agGrid
                             id="ViewGridForProject"
                             style="width: 100%; height: 540px"
                             class="ag-theme-balham"
                             [masterDetail]="true"
                             [gridOptions]="gridOptionsForProject"
                             [rowData]="rowDataForProject"
                             (gridReady)="onGridReady($event)"
                             (selectionChanged)="onSelectionChanged($event)"
                             (filterChanged)="onFilterChangedPlan($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
