import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./create-request-modal.component.html",
})
export class CreateRequestModalComponent {
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) {}
}
