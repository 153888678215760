import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminViewComponent } from "./admin-view.component";
import { TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { UserRoleColumnDefinitionForAdmin } from "./ag-grid-column-definition";
import { DataTypes } from "./admin-view-data-types";
import { UserRole } from "../../generated-models/UserRole";
import { SharedDataService } from "../../services/sharedDataService";

@Component({
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewUserRoleComponent extends AdminViewComponent<UserRole> implements OnInit {
  keyFilterStats: string = "filter:admin-view-user-role";
  tableName = TableName.UserRole;
  columnDef = UserRoleColumnDefinitionForAdmin;

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    sharedDataService: SharedDataService,
    dataTypes: DataTypes
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.type = "User Role";
    this.addRowEnable = true;
    this.addRowFunc = (data: UserRole) => apiService.addUserRole(data).toPromise();
    this.updateRowFunc = (data: UserRole) => apiService.setUserRole(data).toPromise();
    this.deleteRowEnable = true;
    this.deleteRowFunc = (data: UserRole) => apiService.deleteUserRole(data).toPromise();
  }

  refreshData() {
    this.apiService.getUserRoleList().subscribe((response) => {
      this.rowData = response;
      super.setupFilterByLocalData();
    });
  }
}
