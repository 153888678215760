import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

export interface ActionCellComponentParams<T> {
  actionFunc: (data: T) => Promise<any>;
  text?: string;
}

@Component({
  templateUrl: "./action-cell.component.html",
})
export class ActionCellComponent<T> implements AgRendererComponent {
  params: ActionCellComponentParams<T>;
  data: T;
  text: string;

  constructor() {}

  refresh(): boolean {
    throw new Error("Method not implemented.");
  }

  agInit(params: ICellRendererParams & ActionCellComponentParams<T>) {
    this.params = params;
    this.data = params.data;
    this.text = params.value ?? params.text;
  }

  ngOnInit() {}

  action() {
    this.params.actionFunc(this.data);
  }
}
