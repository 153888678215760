import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Subject } from "rxjs";
import { IUserProfile } from "../npr-request.model";

@Injectable()
export class SharedDataService {
  constructor(private apiService: ApiService) {}

  public static testName = "czy";
  public static userProfileTest: IUserProfile;

  private userProfile: Subject<IUserProfile> = new Subject<IUserProfile>();

  setUserProfile(value: IUserProfile) {
    this.userProfile.next(value);
  }

  getUserProfile() {
    return this.userProfile.asObservable();
  }
}
