<div class="title" #title>
  <div class="container container-max-width">
    <h4>Region Access and Quota Management - Request Status</h4>
    <p>All Region Access and Quota Requests</p>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <div class="hint hint-flex">
      <div>
        <span>{{ this.rowData.length }} W/L request(s)</span>
      </div>
      <div class="flex-center">
        <input type="checkbox" [(ngModel)]="showArchiveFlag" (ngModelChange)="OnShowArchiveFlagChanged($event)" title="showArchiveFlag" />
        <span>Show archived (up to 10000 latest)</span>
        <button class="sbutton" title="Export current filtered results" (click)="exportCurrentView()">Export Current View</button>
      </div>
    </div>
    <p *ngIf="message" class="message">{{ this.message }}</p>

    <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>

    <form #operatorForm="ngForm" autocomplete="off" novalidate>
      <div class="nofloat">
        <ag-grid-angular
          style="height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          [masterDetail]="true"
          [detailRowHeight]="145"
          pagination="true"
          rowSelection="multiple"
          (gridReady)="onGridReady($event)"
          (rowGroupOpened)="onRowGroupOpened($event)"
          (filterChanged)="onFilterChanged($event, 'operator-review')">
        </ag-grid-angular>
      </div>
      <hr />
    </form>
  </div>
</div>
