<div class="modal-header">
  <h4 class="modal-title">Custom Bulk Billing Meter Submission</h4>
</div>
<div class="modal-body">
  <h6>Please confirm the billing meter requests will be submitted for {{regionNames()}}.</h6>
  <ag-grid-angular
    style="height: 400px"
    class="ag-theme-balham"
    [gridOptions]="gridOptions"
    [rowData]="products"
    [columnDefs]="columnDefs"
    [rowSelection]="rowSelection"
    [rowMultiSelectWithClick]="true"
    [masterDetail]="true"
    [detailRowHeight]="145"
    (gridReady)="onGridReady($event)"
    pagination="true"
  >
  </ag-grid-angular>
</div>
<div class="modal-footer">
  <div *ngIf="showUnselectableMessage" class="container">
    <p>
      If a row cannot be selected, the proper mapping from Service Tree ID to MIX First Party data does not exist.
      Please reach out to your business planner for guidance on mapping definitions and save them in <a href="https://microsoftservicetree.com/" target="_blank">Service Tree</a>.
      For any questions related to Service Tree, please reach out to <a href="mailto:servicetreesupport@microsoft.com">Service Tree Support</a>.
    </p>
    <p class="highlight">
      The highlighted row is missing availability, you can submit the billing meter request, but BET will send a billing request to MIX when availability is ready.
    </p>
  </div>
  <button type="button" class="btn btn-primary" (click)="activeModal.close(gridApi.getSelectedRows())">Yes</button>
  <button type="button" class="btn btn-warning" (click)="activeModal.close(false)">No</button>
</div>
