import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./confirmation-modal.component.html",
})
export class ConfirmationModalComponent {
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) {}
}
