import { Component } from "@angular/core";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { ExcelExportParams, GridApi } from "ag-grid-community";
import { Cloud, Job, Product, Service } from "../shared/model";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../shared/modal.service";
import { ModalService as LionrockModalService } from "../../shared/modal.service";
import { DataService } from "../shared/data.service";
import { ModuleService } from "../../shared/module.service";
import { BaseComponent } from "../shared/base.component";
import { AuthService } from "../../auth/auth.service";
import { ApiService } from "../shared/api.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss", "../styles.scss"],
})
export class HomeComponent extends BaseComponent {
  loadingData: boolean;
  inScopeServices: Service[] = null;
  jobs: Job[];
  private gridApi: GridApi;
  rowSelection = "multiple";
  firstLoad = true;

  bulkSubmissionCollapse = false;
  product = "";
  products: Product[] = [];
  errorMessage = "";
  productMessage = "";

  columnDefs = [];
  gridOptions = extendDefaultOptions({
    columnDefs: this.columnDefs,
    autoGroupColumnDef: {
      cellRendererSelector: (params) => {
        const allLeafChildren = params.node.allLeafChildren;
        if (allLeafChildren?.length == 1 && allLeafChildren?.[0].data?.CaymanProductId.length == 0) {
          return;
        }
        return { component: "agGroupCellRenderer" };
      },
      headerName: "Cayman Product",
      field: "CaymanProductId",
      minWidth: 400,
      cellRendererParams: {
        checkbox: true,
      },
    },
    rowData: this.inScopeServices,
    rowSelection: "multiple",
    groupSelectsChildren: true,
    groupSelectsFiltered: true,
    suppressAggFuncInHeader: true,
    suppressRowClickSelection: true,
    isRowSelectable: (rowNode) => rowNode.data && rowNode.data.CaymanProductId.length > 0,
    onModelUpdated: (params) => {
      console.log("grid ready");
      if (this.firstLoad) {
        params.api.forEachNode(function (node) {
          if (!node.allChildrenCount) {
            node.setSelected(!node.data.Skipped);
          }
        });
      }
      this.firstLoad = false;
    },
  });

  constructor(
    apiService: ApiService,
    private modalService: ModalService,
    private lionrockModalService: LionrockModalService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    private router: Router,
    route: ActivatedRoute,
    private moduleService: ModuleService
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
  }

  async onInit(): Promise<void> {
    if (!this.userCanInitialBuildout) {
      if (this.userCanOnDemandBuildout) {
        await this.router.navigate(["/prep/ondemand"]);
        return;
      } else if (this.userCanViewStatus) {
        await this.router.navigate(["/prep/status"]);
        return;
      }
    }
    let initializeRegion = this.dataService.selectedRegions.find((region) => this.dataService.initialRegions.includes(region));
    this.dataService.setSelectedRegions(initializeRegion ? [initializeRegion] : []);
    if (this.dataService.selectedRegion) {
      await this.onInScopeServicesFetched();
    }
    this.columnDefs = [
      { field: "ProductOid", rowGroup: true, hide: true },
      { field: "CaymanProductId", hide: true },
      { field: "ProductName", aggFunc: "first" },
      { field: "ProductType", aggFunc: "first" },
      { field: "RegionName", aggFunc: "first" },
      { field: "EdgeZoneName", aggFunc: "first" },
      { field: "Skipped" },
      { field: "CaymanProductName" },
      { field: "LocationType" },
      { field: "LocationId" },
    ];
  }

  resetData(): void {
    this.jobs = null;
    this.inScopeServices = null;
  }

  onCloudChange(cloud: Cloud): void {
    this.dataService.changeCloud(cloud);
    // reset the service list.
    this.resetData();
  }

  onRegionChange(): void {
    this.dataService.changeRegion();
    // reset the service list.
    this.resetData();
  }

  onEdgeZoneChange(): void {
    this.dataService.changeEdgeZone();
    // reset the service list.
    this.resetData();
  }

  async onInScopeServicesFetched(): Promise<void> {
    console.log("InScopeServices fetched!");
    this.loadingData = true;
    if (this.gridApi) {
      this.gridApi.setRowData([]);
      this.gridApi.showLoadingOverlay();
    }

    this.apiService.GetInScopeServices(this.dataService.getInitialLocationName()).subscribe((results) => {
      this.firstLoad = true;
      this.jobs = results[1] as Job[];
      this.inScopeServices = results[0] as Service[];
      this.loadingData = false;
      this.gridApi?.hideOverlay();
    });
  }

  onInScopeServicesGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setRowData([]);
    if (this.loadingData && this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    console.log("grid ready");
  }

  async onNewRegionBillingMeter(): Promise<void> {
    await this.lionrockModalService.confirmationModal(
      `For ${this.dataService.getInitialLocationName()}, the following billing meters creation will be triggered. Please confirm.`
    );
    await this.loading((finish) => {
      this.apiService.NewRegionBillingMeter(this.dataService.getInitialLocationName(), this.gridApi.getSelectedRows(), this.user).subscribe(
        async (res) => {
          const r = res as { jobId: string };
          if (this.dataService.selectedEdgeZone) this.dataService.selectedEdgeZone.Initialized = true;
          else this.dataService.selectedRegion.Initialized = true;
          finish();
          await this.lionrockModalService.informationModal(
            `<h6>Billing Meters request has been submitted successfully! </h6><br/>JobId: ${r.jobId}`
          );
          await this.onInScopeServicesFetched();
        },
        async (err) => {
          finish();
          await this.lionrockModalService.informationModal(`${err.error.replace(/\n/g, "<br/>")}`);
        }
      );
    });
  }

  toOnDemandBillingMeter(): void {
    this.router.navigate(["/prep/ondemand"], {
      queryParams: {
        cloud: this.dataService.selectedCloud.CloudName,
        region: this.dataService.selectedRegion?.Name,
      },
    });
  }

  onExportExcel(): void {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: true,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: true,
      onlySelected: true,
      onlySelectedAllPages: true,
      fileName: "In-scope Offerings",
      sheetName: "In-scope Offerings",
      columnKeys: this.gridOptions.columnApi
        .getAllColumns()
        .filter((c) => c.getColDef().headerName && c.isVisible())
        .map((c) => c.getColDef().field),
    } as ExcelExportParams;
    this.gridApi.exportDataAsExcel(params);
  }

  disableNewRegionBillingMeter(): boolean {
    return (
      this.inScopeServices == null || this.dataService.selectedRegionIsInitialized() || this.dataService.availabilityRegionOrEdgeZone()
    );
  }

  disableFetchInScopeService(): boolean {
    return this.dataService.selectedRegion == null || this.dataService.availabilityRegionOrEdgeZone();
  }

  bulkSubmitPreview(): void {
    this.loadingScreenService.setLoading(true);
    this.errorMessage = "";
    this.apiService.GetProductList([this.dataService.selectedRegion.Name], this.product).subscribe(
      (products) => {
        if (_.isEmpty(products)) {
          this.productMessage = "Product Not Found";
          this.loadingScreenService.setLoading(false);
          return;
        }
        this.productMessage = null;
        this.products = <Product[]>products;
        this.loadingScreenService.setLoading(false);
        const confirmation = this.modalService.onBulkSubmitConfirmModal(this.dataService.getOnDemandLocationNames(), this.products);
        confirmation.then((confirmed) => {
          console.log("User confirmed:", confirmed);
          if (confirmed?.length) {
            var selectedProducts = confirmed as Product[];
            this.onBulkSubmitTriggered(selectedProducts);
          }
        });
      },
      (err) => {
        this.errorMessage = err;
      }
    );
  }

  async onBulkSubmitTriggered(selectedProducts: Product[]) {
    if (selectedProducts.some((p) => !p.IsAvailableRegion)) {
      try {
        await this.lionrockModalService.confirmationModal(
          "The highlighted row is missing availability, you can submit the billing meter request, but BET will send a billing request to MIX when availability is ready."
        );
      } catch {
        return;
      }
    }
    this.loadingScreenService.setLoading(true);
    this.apiService.PostInitialBulkSubmit(selectedProducts, this.user).subscribe(
      (res) => {
        const r = <[{ jobId: string }]>res;
        this.loadingScreenService.setLoading(false);
        if (r instanceof Array) {
          this.lionrockModalService.informationModal(
            `Initial billing meter request has been submitted successfully! JobId: ${r.map((i) => i.jobId).join(",")}`
          );
        } else {
          this.lionrockModalService.informationModal(`Initial billing meter request failed: ${r}`);
        }
      },
      () => {
        this.loadingScreenService.setLoading(false);
        this.lionrockModalService.informationModal(
          "Your request cannot be submitted at this time since the selected products are missing MIX First Party Mappings.<br />" +
            'Please reach out to <a href="mailto:azure1ppinfosys@microsoft.com">azure1ppinfosys@microsoft.com</a> for help adding the mapping for your SKU’s MIX Product and BIGID before proceeding forward.'
        );
      }
    );
  }
}
