import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { PlanAssignResultEnum } from "src/app/npr-request.model";
import { ModalService } from "src/app/shared/modal.service";
import { ToastrService } from "ngx-toastr";
import { FfpPlanInstance } from "../../../../generated-models/FfpPlanInstance";

@Component({
  templateUrl: "./ffp-plan-reprocess-assign-response-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class FfpPlanReprocessAssignResponseModalComponent implements OnInit {
  @Input() ffpPlanInstances: FfpPlanInstance[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected apiService: ApiService,
    protected modalService: ModalService,
    private notificationService: ToastrService,
  ) { }

  async ngOnInit() {
    await this.assignFfpPlans();
  }

  async assignFfpPlans() {
    await Promise.all(
      this.ffpPlanInstances.map(async (ffpPlanInstance) => {
        try {
          var response = await this.apiService.reprocessFfpPlanInstance(ffpPlanInstance.ServiceTreeId, ffpPlanInstance.ProjectOid, ffpPlanInstance.Version).toPromise();
          if (response?.Result == PlanAssignResultEnum.Warning) {
            this.notificationService.warning("Warning: " + response.Message);
          }
          else if (response?.Result != PlanAssignResultEnum.Succeed) {
            this.aggregatedResult.push(
              `Assign plan for <b>${ffpPlanInstance.ServiceTreeId}</b> to project <b>${ffpPlanInstance.ProjectOid}</b> failed with message: <br/>${response?.Message ?? ''}`
            );
          }
          else {
            this.aggregatedResult.push(
              `Assign plan for <b>${ffpPlanInstance.ServiceTreeId}</b> to project <b>${ffpPlanInstance.ProjectOid}</b> successfully with message: <br/>${response?.Message ?? ''}`
            );
          }
        } catch (ex) {
          var warningMessage = "";
          if (ex?.error?.Message != null) {
            warningMessage = ex?.error.Message;
          }
          else {
            warningMessage = ex;
          }
          this.aggregatedResult.push(
            `Assign plan for <b>${ffpPlanInstance.ServiceTreeId}</b> to project <b>${ffpPlanInstance.ProjectOid}</b> failed with error:<br/> <font color="red">${warningMessage}</font>`
          );
        }
      })
    );

    this.isCompleted = true;
  }

  close() {
    this.activeModal.close(true);
  }
}
