import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";

import { LoadingScreenService } from "../loading-screen.service";
import { BaseComponent } from "../../shared/base.component";

@Component({
  selector: "em-loading-screen",
  templateUrl: "./loading-screen.component.html",
  styleUrls: ["./loading-screen.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingScreenComponent extends BaseComponent {
  loading = true;
  loadingVisible = true;

  constructor(loadingScreenService: LoadingScreenService, changeDetectorRef: ChangeDetectorRef) {
    super();
    this.addSubscription(
      loadingScreenService.loading.subscribe((x) => {
        this.loading = x;
        changeDetectorRef.markForCheck();
      })
    );
    this.addSubscription(
      loadingScreenService.loadingVisible.subscribe((x) => {
        this.loadingVisible = x;
        changeDetectorRef.markForCheck();
      })
    );
  }
}
