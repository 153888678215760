import { BaseComponent } from "../shared/base.component";
import { ColDef, ColGroupDef, ColumnApi, FilterChangedEvent, GridApi, GridOptions } from "ag-grid-community";
import { cacheFilterStatsLocal, extendDefaultOptions, setupFilterByLocalData } from "./gridHelper";
import { CustomTooltipComponent } from "../shared/auxillary-components/custom-tooltip.component";
import { SubscriptionRendererComponent } from "../quota/approver-review/subscription-renderer.component";
import { ServiceTypeRendererComponent } from "../shared/az-mapping-modal/service-type-renderer.component";
import { StatusTooltipComponent } from "../quota/request-status/status-tooltip.component";
import { IUserProfile } from "../npr-request.model";
import { ApiService } from "../services";
import { Component, HostListener } from "@angular/core";
import { SharedDataService } from "../services/sharedDataService";
import { Subscription } from "rxjs";

@Component({
  template: "",
})
export abstract class GridViewBaseComponent extends BaseComponent {
  abstract gridOptions: GridOptions;
  abstract initPageSize: number;
  abstract keyFilterStats: string;

  // true if filter stats retaining across page refreshing.
  protected filterRetainAfterPageRefresh = false;

  protected gridApi: GridApi;
  protected gridColumnApi: ColumnApi;
  protected subscription: Subscription;

  userProfile: IUserProfile = null;
  message = "";

  protected constructor(protected apiService: ApiService, protected sharedDataService: SharedDataService) {
    super();
    if (!ApiService.userProfile) {
      this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
        this.userProfile = data;
        if (data) {
          this.gridApi?.showLoadingOverlay();
          this.onProfileLoaded();
          this.refreshData();
        }
      });
    }
  }

  InitAgGrid(gridColDef: (ColGroupDef | ColDef)[]) {
    // initialize grid options, default options
    this.gridOptions = extendDefaultOptions({
      paginationPageSize: this.initPageSize,
      columnDefs: gridColDef,
      context: this,
      frameworkComponents: {
        customTooltip: CustomTooltipComponent,
        subscriptionRenderer: SubscriptionRendererComponent,
        serviceTypeRenderer: ServiceTypeRendererComponent,
        statusTooltip: StatusTooltipComponent,
      }
    });

    if (!this.filterRetainAfterPageRefresh) {
      localStorage.removeItem(this.keyFilterStats);
    }

    this.loadData();
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();

    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.applyColumnState({
      state: [
        {
          colId: "LastUpdatedTime",
          sort: "desc",
        },
      ],
    });
  }

  /**
   * Refresh data model of grid, invoke whenever inserting/deleting/refreshing.
   * May lead to grid view reload.
   */
  abstract refreshData();
  onProfileLoaded() {}

  loadData() {
    if (ApiService.userProfile) {
      this.apiService.getUserProfile().then((response) => {
        this.userProfile = response;
        if (response) {
          this.gridApi?.showLoadingOverlay();
          this.onProfileLoaded();
          this.refreshData();
        }
      });
    }
  }

  onFilterChanged(event: FilterChangedEvent, filterDataChangeEvent: boolean = true) {
    cacheFilterStatsLocal(event, this.keyFilterStats, filterDataChangeEvent);
  }

  setupFilterByLocalData(autoSizeColumns: boolean = true) {
    setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStats, autoSizeColumns);
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }
}
