import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Status } from "../status/model";

@Component({
  selector: "app-buildout-confirm-dialog",
  templateUrl: "./resubmit-prebuildout-confirm-dialog.component.html",
})
export class ResubmitPrebuildoutConfirmDialogComponent implements OnInit {
  @Input() regions: string[];
  @Input() status: Status[];

  columnDefs = [
    { headerName: "Region", field: "RegionName", sort: "asc" },
    { headerName: "Edge Zone", field: "EdgeZoneName", sort: "asc" },
    { headerName: "Product ID", field: "ProductOid", width: 300, sort: "asc" },
    { headerName: "Product Name", field: "ProductName" },
    { headerName: "MIX First Party Product ID", field: "CaymanProductId" },
    { headerName: "MIX First Party Product Name", field: "CaymanProductName" },
  ];
  gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
  };
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  regionNames() {
    return this.regions.join(",");
  }
}
