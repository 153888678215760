import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { ApiService } from "src/app/services";

@Component({
  templateUrl: "./plan-remove-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../plan.scss"],
})
export class PlanRemoveModalComponent implements OnInit {
  @Input() planRegions: PlanRegion[] 

  public aggregatedResult: string[] = [];
  public isCompleted = false;
  public confirmation = true;
  public inProgress = false;
  public message: string;
  public cancelButtonMsg = "No";
  public confirmInfo = "Please Confirm"

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  async ngOnInit() {}

  submit() {
    this.confirmation = true;
    this.inProgress = true;
    this.confirmation = false;
    this.planRegions.map((r) => {
        this.apiService.softDeletePlanRegion(r.ServiceTreeId, r.Region, r.Version, r.Stage).subscribe(
            () => {
              this.message = `Regional Plan (${r.ServiceTreeId}, ${r.Region}, ${r.Version}, ${r.Stage}) have been set to Removed status <b>successfully</b>.`;
              this.aggregatedResult.push(this.message);
            },
            (err) => {
              this.message = `Regional Plan failed to set Removed status. <b>Error: ${err}</b>`;
              this.aggregatedResult.push(this.message);
            },
          );
    });
    
    this.isCompleted = true;
    this.cancelButtonMsg = "Close";
    this.confirmInfo = "Set plans to removed status"
  }

  close() {
    this.activeModal.close(true);
  }
}
