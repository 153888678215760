import { environment } from "../../../environments/environment";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ExcelExportParams, GridApi, GridOptions } from "ag-grid-community";
import { TicketPriorityEnum, ISubRequest, ISubRequestUpdateData, RequestServiceType, IStatusResponse } from "../../npr-request.model";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../../shared/modal.service";
import { RequestViewBase } from "../../shared/request-view-base";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { RequestColumnDefinitionForApprover } from "./ag-grid-column-definition";
import { getExportedExcelFileNameSuffix, OnColumnMoved, ParseRequestId, saveFile, setColumnState } from "../../utility/common-helper";
import { ApproveRequestService } from "../../services/approveRequest.service";
import { SharedDataService } from "../../services/sharedDataService";

@Component({
  templateUrl: "./approver-review.component.html",
  styleUrls: ["../../styles.scss", "./approver-view.scss"],
})
export class ApproverReviewComponent extends RequestViewBase implements OnInit {
  gridOptions: GridOptions;
  rowData: ISubRequest[] = [];
  initPageSize = 15;

  toggledGrouping = false;
  selectAllFlag = false;
  selectedRowCount = 0;

  vmSkus: string[] = [];
  quotaImplicationsTeam: string = null;
  quotaImplicationsRegion: string = null;

  public customFilterReady = false;

  private regionFilterInstance;
  public regionFilterValues: string[] = [];
  public regionFilterItems: string[] = [];

  private requestorFilterInstance;
  public requestorFilterValues: string[] = [];
  public requestorFilterItems: string[] = [];

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected approveRequestService: ApproveRequestService,
    protected sharedDataService: SharedDataService
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
  }

  ngOnInit() {
    super.InitAgGrid(RequestColumnDefinitionForApprover);
    this.gridOptions.onColumnMoved = (params) => OnColumnMoved(params, "approveRequestColumnState");
  }

  onGridReady(params: GridOptions) {
    super.SetAgGridContext(params);
    super.LoadData();
    this.loadVmSkus();
    this.regionFilterInstance = this.gridApi.getFilterInstance("Region");
    this.requestorFilterInstance = this.gridApi.getFilterInstance("Requestor");
    this.customFilterReady = true;
    setColumnState(params, "approveRequestColumnState");
  }

  loadVmSkus() {
    const vmTypes: string[] = [];
    this.apiService.getVmSkus().subscribe(
      (response) => {
        if (!response) {
          console.warn("No vm sku is returned from api");
        } else {
          for (let i = 0; i < response.length; i++) {
            vmTypes.push(response[i].PortalVmSku);
          }
          vmTypes.sort();
        }

        this.vmSkus = vmTypes;
      },
      (error) => {
        this.message = `unable to retrieve vm skus, error: ${error ? error.message : "uncertainty"}`;
        this.notificationService.error(this.message);
      }
    );
  }

  refreshData() {
    this.selectedRowCount = 0;
    this.fillUpData();
  }

  async fillUpData() {
    const mergedDatas: ISubRequest[] = [];
    const requests = await this.apiService.getPendingApprovalRequests().toPromise();
    requests.sort((a, b) => new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
    for (const reqObj of requests) {
      reqObj.IsExternalDisplay = reqObj.IsExternal ? "Yes" : "No";
      reqObj.IsHoboDisplay = reqObj.IsHobo == null ? "" : reqObj.IsHobo ? "Yes" : "No";
      reqObj.RequestDisplayServiceType = ApiService.fetchServiceTypeName(reqObj.RequestServiceType, reqObj.SKU);
      let priorityNumber = reqObj.Priority;
      priorityNumber = priorityNumber === 0 ? 3 : priorityNumber;
      reqObj.PriorityString = `${priorityNumber} - ${TicketPriorityEnum[priorityNumber]}`;
      mergedDatas.push(reqObj);
    }

    this.prepareData(mergedDatas);
  }

  generateSubRequestHyperLink(requestId: string): string {
    const req = ParseRequestId(requestId);
    const href = `${environment.baseUrl}/requests/${req.parentRequestId}/sub/${req.subRequestId}`;
    return `<a href="${href}">${requestId}</a>`;
  }

  prepareData(requestData: ISubRequest[]) {
    this.rowData = [];

    requestData.forEach((element) => {
      element.Placeholder = "";
      element.RequestId = `${element.ParentRequestId}-${element.SubRequestId}`;
      element.RingLevel = element.IsExternal ? "External" : element.RingLevel;
      element.RequestLink = ApiService.generateSubRequestHyperLink(element.ParentRequestId, element.SubRequestId.toString());
      element.CreatedTime = PreprocessDateTime(element.CreatedTime);
      if (element.CompletedTime) {
        element.CompletedTime = PreprocessDateTime(element.CompletedTime);
      }
      this.rowData.push(element);
    });
    this.loadingService.setLoading(false);

    super.setDefaultFilter("approver-review");
    setTimeout(() => {
      this.regionFilterItems = this.regionFilterInstance.valueModel.allValues.filter((v) => v);
      this.requestorFilterItems = this.requestorFilterInstance.valueModel.allValues.filter((v) => v);
    }, 1000);
  }

  async approveRequest(formValues?: ISubRequest[]) {
    const selectedSubRequests: ISubRequest[] = formValues ? formValues : this.gridApi.getSelectedRows();
    const refresh = await this.approveRequestService.approveRequest(selectedSubRequests);
    if (refresh) {
      this.refreshData();
    }
  }

  async rejectRequest(formValues?: ISubRequest[]) {
    const selectedSubRequests: ISubRequest[] = formValues ? formValues : this.gridApi.getSelectedRows();
    const refresh = await this.approveRequestService.rejectRequest(selectedSubRequests, this.userProfile);
    if (refresh) {
      this.refreshData();
    }
  }

  async changeRequestPriority() {
    const selectedSubRequests: ISubRequest[] = this.gridApi.getSelectedRows();
    if (selectedSubRequests.length === 0) {
      this.message = "Please select request(s) to set priority";
      this.notificationService.warning(this.message);
      return;
    }
    this.message = "";

    const priorityList = Object.values(TicketPriorityEnum).filter((x) => typeof x === "string");
    for (let i = 0; i < priorityList.length; i++) {
      priorityList[i] = i + 1 + " - " + priorityList[i];
    }

    this.modalService
      .selectOptionModal(`Choose the priority for ${selectedSubRequests.length} selected request(s)?`, priorityList as string[])
      .then(
        async (priority) => {
          priority = priority.substr(0, 1);
          if (this.userProfile.IsApprover) {
            const changePriorityTasks: Promise<IStatusResponse>[] = selectedSubRequests.map(
              async (subRequest): Promise<IStatusResponse> => {
                const updateData = {} as ISubRequestUpdateData;
                updateData.Priority = Number(priority);
                return await this.apiService
                  .updateSubRequest(subRequest.ParentRequestId, subRequest.SubRequestId, updateData)
                  .toPromise()
                  .catch((error) => {
                    const message = `Failed to set priority for #${subRequest.RequestId}, error: ${error}`;
                    this.notificationService.error(message);
                    return {} as IStatusResponse;
                  });
              }
            );
            this.loadingService.setLoading(true);
            await Promise.all(changePriorityTasks);
            this.refreshData();
            this.loadingService.setLoading(false);
            this.message = "Selected request(s) have been set to the chosen priority.";
          } else {
            this.message = "You are not approver, you cannot set request priority.";
            this.notificationService.error(this.message);
          }
          this.refreshData();
        },
        () => {
          this.notificationService.warning("You clicked No button, no change has been applied", `Hey ${this.userProfile.Name},`);
        }
      );
  }

  selectAllRowsOnCurrentPage(selected: boolean) {
    const lastGridIndex = this.gridApi.getDisplayedRowCount() - 1;
    const currentPage = this.gridApi.paginationGetCurrentPage();
    const pageSize = this.gridApi.paginationGetPageSize();
    const startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      if (rowNode.group === false) {
        rowNode.setSelected(selected, false);
      }
    }
  }

  getFilteredSubrequests(): string[] {
    const ids: string[] = [];
    this.gridApi.forEachNodeAfterFilter((node) => {
      ids.push(node.id);
    });
    return ids;
  }

  exportExcel() {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "ApproverReview-export-" + PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm"),
      sheetName: "ApproverReview",
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }

  onChecked(isChecked: boolean) {
    this.selectAllRowsOnCurrentPage(isChecked);
  }

  getSubscriptionDetails(subscriptionId: string) {
    this.modalService
      .subscriptionDetailsModal("Subscription Details", subscriptionId)
      .then(() => {})
      .catch((err) => {
        this.message = err.message;
      });
  }

  onPaginationChanged() {
    this.selectAllFlag = false;
  }

  onSelectionChanged(event) {
    const api = event.api as GridApi;
    this.selectedRowCount = api.getSelectedNodes().length;
    const selectedRowsData: ISubRequest[] = api.getSelectedNodes().map((node) => node.data as ISubRequest);
    this.quotaImplicationsTeam = null;
    this.quotaImplicationsRegion = null;
    if (selectedRowsData.length > 0 && this.userProfile.IsApprover) {
      if (!selectedRowsData.some((d) => d.Requestor !== selectedRowsData[0].Requestor || d.Region !== selectedRowsData[0].Region)) {
        this.quotaImplicationsTeam = selectedRowsData[0].Requestor;
        this.quotaImplicationsRegion = selectedRowsData[0].Region;
      }
    }
  }

  async changeRequestUtilizeDate() {
    const selectedSubRequests: ISubRequest[] = this.gridApi.getSelectedRows();
    if (selectedSubRequests.length === 0) {
      this.message = "Please select request(s) to set utilize date";
      this.notificationService.warning(this.message);
      return;
    }
    this.message = "";

    const utilizeDate: Date = await this.modalService.datepickerModal(
      `Choose the utilize date for ${selectedSubRequests.length} selected request(s)?`
    );

    if (utilizeDate) {
      if (this.userProfile.IsApprover) {
        const changeUtilizeDateTasks: Promise<IStatusResponse>[] = selectedSubRequests.map(async (subRequest): Promise<IStatusResponse> => {
          const updateData = {} as ISubRequestUpdateData;
          updateData.UtilizeDate = utilizeDate;
          return await this.apiService
            .updateSubRequest(subRequest.ParentRequestId, subRequest.SubRequestId, updateData)
            .toPromise()
            .catch((error) => {
              const message = `Failed to set utilize date for #${subRequest.RequestId}, error: ${error}`;
              this.notificationService.error(message);
              return {} as IStatusResponse;
            });
        });
        this.loadingService.setLoading(true);
        await Promise.all(changeUtilizeDateTasks);
        this.refreshData();
        this.loadingService.setLoading(false);
        this.message = "Selected request(s) have been set to the chosen utilize date.";
      } else {
        this.message = "You are not approver, you cannot set request utilize date.";
        this.notificationService.error(this.message);
      }
      this.refreshData();
    } else {
      this.notificationService.warning("You clicked No button, no change has been applied", `Hey ${this.userProfile.Name},`);
    }
  }

  async changeRequestQuotaInfo() {
    const selectedSubRequests: ISubRequest[] = this.gridApi.getSelectedRows();
    if (selectedSubRequests.length === 0) {
      this.message = "Please select request(s) to edit quota";
      this.notificationService.warning(this.message);
      return;
    }
    this.message = "";
    this.modalService
      .selectOptionAndInputModal(`Update quota info for ${selectedSubRequests.length} selected request(s)`, this.vmSkus)
      .then(
        async (ret) => {
          const updateQuotaTasks: Promise<IStatusResponse>[] = selectedSubRequests.map(async (subRequest): Promise<IStatusResponse> => {
            if (this.isQuotaRequest(subRequest)) {
              const params = ret.split("$");
              const newVMQuota = Number(params[1]);
              const updateData = {} as ISubRequestUpdateData;
              updateData.Quota = newVMQuota;
              return await this.apiService
                .updateSubRequest(subRequest.ParentRequestId, subRequest.SubRequestId, updateData)
                .toPromise()
                .catch((error) => {
                  const message = `Failed to set quota limit for #${subRequest.RequestId}, error: ${error}.`;
                  this.notificationService.error(message);
                  return {} as IStatusResponse;
                });
            } else {
              const message = `Skipped ${subRequest.RequestId} as it's not a quota request`;
              this.notificationService.error(message);
              return {} as IStatusResponse;
            }
          });
          this.loadingService.setLoading(true);
          await Promise.all(updateQuotaTasks);
          this.refreshData();
          this.loadingService.setLoading(false);
          this.message = "Selected request(s) have been updated";
        },
        () => {
          this.notificationService.warning("You clicked No button, no change has been applied", `Hey ${this.userProfile.Name},`);
        }
      );
  }

  private isQuotaRequest(subRequest: ISubRequest) {
    if (subRequest.RequestServiceType === RequestServiceType.AppService && "LinuxVmQuota" in subRequest.ServiceParams) {
      return Number(subRequest.Quota) > 0 || Number(subRequest.ServiceParams["LinuxVmQuota"]) > 0;
    }
    return Number(subRequest.Quota) > 0;
  }

  onCustomRegionFilterChanged() {
    if (this.regionFilterValues === null || this.regionFilterValues.length === 0) {
      this.regionFilterInstance?.setModel(null);
    } else {
      this.regionFilterInstance?.setModel({
        type: "set",
        values: this.regionFilterValues,
      });
    }
    this.gridApi.onFilterChanged();
  }

  onCustomRequestorFilterChanged() {
    if (this.requestorFilterValues === null || this.requestorFilterValues.length === 0) {
      this.requestorFilterInstance?.setModel(null);
    } else {
      this.requestorFilterInstance?.setModel({
        type: "set",
        values: this.requestorFilterValues,
      });
    }
    this.gridApi.onFilterChanged();
  }

  async viewQuotaImplications() {
    const allowedServiceList = [
      RequestServiceType.ArmVmQuota,
      RequestServiceType.ArmStorageQuota,
      RequestServiceType.RdfeStorageQuota,
      RequestServiceType.RdfeVmQuota,
    ];

    const data = this.rowData.filter(
      (subRequest) =>
        subRequest.Requestor === this.quotaImplicationsTeam &&
        subRequest.Region === this.quotaImplicationsRegion &&
        allowedServiceList.includes(subRequest.RequestServiceType)
    );
    if (data.length === 0) {
      try {
        await this.modalService.confirmationModal("None quota request selected");
      } catch {
        // For the model dialog dimiss, doing nothing here
      }
    } else {
      const p = this.modalService.quotaImplicationsModal(data, this.userProfile);
      p.then((r) => {
        if (r.type === "Approve") {
          this.approveRequest(r.data);
        } else if (r.type === "Reject") {
          this.rejectRequest(r.data);
        }
      }).catch((error) => {
        // error === 1 means press ESC button to close popup
        if (error !== 1) {
          this.message = `failed to Approve or Reject, error: ${error}`;
          this.notificationService.error(this.message);
        }
      });
    }
  }
}
