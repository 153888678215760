<div class="title" #title>
  <div class="container container-max-width">
    <h4>Region Access and Quota Management - Request Status</h4>
    <p>Requests submitted by {{ userProfile?.Name }}</p>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <!-- prompt TODO: replaced with ToastrService? -->
    <div class="nofloat">
      <p class="message" data-test="Message">{{ this.message }}</p>
    </div>
    <div>
      <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
      <div *ngIf="hasWorkItem" class="hint hint-flex">
        <div>
          <h4>Tickets require your action</h4>
          <br />
          {{ selectedRowCount }} of {{ workItemRowData.length }} selected
        </div>
        <div class="flex-center">
          <button class="sbutton" (click)="handleWorkItem()">Update Records</button>
        </div>
      </div>
      <ag-grid-angular
        *ngIf="hasWorkItem"
        style="height: 270px"
        class="ag-theme-balham"
        [gridOptions]="workItemGridOptions"
        [rowData]="workItemRowData"
        [masterDetail]="true"
        [detailRowHeight]="145"
        pagination="true"
        rowSelection="multiple"
        (gridReady)="onWorkItemGridReady($event)"
        (selectionChanged)="onSelectionChanged($event)"
        (rowGroupOpened)="onRowGroupOpened($event)">
      </ag-grid-angular>
      <br *ngIf="hasWorkItem" /><br *ngIf="hasWorkItem" />
      <div class="hint hint-flex">
        <div>
          <h4>My Requests</h4>
          <label>{{ rowData.length }} records found</label>
        </div>
      </div>
      <ag-grid-angular
        style="height: 540px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [masterDetail]="true"
        [detailRowHeight]="145"
        pagination="true"
        rowSelection="multiple"
        (gridReady)="onGridReady($event)"
        (rowGroupOpened)="onRowGroupOpened($event)">
      </ag-grid-angular>
    </div>
    <hr />
  </div>
</div>
