<div class="modal-header modal-header-quota">
  <h4 class="modal-title">View Quota Implications</h4>
  <h6>Region: {{ region }}</h6>
  <h6>Team Name: {{ teamName }}</h6>
</div>
<a class="modal-header-close" href="javascript:void(0)" (click)="activeModal.close({})" container="body" aria-label="Close">
  <span class="fa fa-times fa-lg"></span>
</a>
<div class="modal-body">
  <div class="nofloat" style="margin-top: 20px">
    <ag-grid-angular
      style="height: 200px; width: 100%"
      class="ag-theme-balham"
      [gridOptions]="gridOptionsForImplications"
      [rowData]="rowData"
      (gridReady)="onGridReady($event, false)"
    >
      >
    </ag-grid-angular>
  </div>
  <div class="nofloat" style="margin-top: 20px">
    <ag-grid-angular
      #agGrid
      style="height: 200px; width: 100%"
      class="ag-theme-balham"
      [gridOptions]="gridOptionsForRequests"
      [rowData]="options"
      rowSelection="multiple"
      (gridReady)="onGridReady($event, true)"
      (selectionChanged)="onSelectionChanged($event)"
      (rowClicked)="onRowClicked($event)"
    >
      >
    </ag-grid-angular>
  </div>
  <div class="nofloat" style="margin-top: 20px">
    <h6>Quota Details: (Click a single row to view Quota Details)</h6>
    <mat-progress-bar [mode]="'indeterminate'" *ngIf="isRowSelected && !isCompleted"> </mat-progress-bar>
    <div>{{ quotaDetails }}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success mr-1" (click)="onSaveOrReject('Approve')" [disabled]="!this.userProfile?.IsApprover">
    Approve
  </button>
  <button type="button" class="btn btn-danger mr-1" (click)="onSaveOrReject('Reject')" [disabled]="!this.userProfile?.IsApprover">
    Reject
  </button>
</div>
