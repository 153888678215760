<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Approve plan</h2>
  </div>
  <form #ngForm="ngForm" *ngIf="dto" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body" style="height: 200px">
      <div>
        <span>Choose when the plan should expire</span>
        <ng-select
          class="mt-2"
          [items]="planValidityEnumMap"
          [clearable]="false"
          bindValue="key"
          bindLabel="key"
          [(ngModel)]="dto.ValidTo"
          name="dto.ValidTo">
        </ng-select>
        <br />
      </div>
      <div *ngIf="dto.ValidTo === 'Custom'">
        <span>Date</span>
        <em-datepicker class="mt-2" [(ngModel)]="dto.ValidToDate" name="dto.ValidToDate"> </em-datepicker>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-success" [disabled]="ngForm.invalid">Approve</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  </form>
</div>
