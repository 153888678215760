import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { PlanAssignResultEnum } from "src/app/npr-request.model";
import { ModalService } from "src/app/shared/modal.service";
import { ToastrService } from "ngx-toastr";
import { FfpAssignPlanToProject } from "../../../../generated-models/FfpAssignPlanToProject";

@Component({
  templateUrl: "./ffp-sync-buildout-project-response-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class FfpSyncProjectBuildoutResponseModalComponent implements OnInit {
  @Input() projectOid: string;

  public aggregatedResult: string[] = [];
  public isCompleted = false;
  public syncFfpProjectBuildoutResults: FfpAssignPlanToProject[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    protected apiService: ApiService,
    protected modalService: ModalService,
    private notificationService: ToastrService,
  ) { }

  async ngOnInit() {
    await this.syncProject();
  }

  async syncProject() {
    try {
      this.syncFfpProjectBuildoutResults = (await this.apiService.syncFfpProjectBuildout(this.projectOid).toPromise()) as FfpAssignPlanToProject[];
      if (this.syncFfpProjectBuildoutResults.length === 0) {
        this.aggregatedResult.push(
          `Project synchronization completed, no new service found.`
        );
      }
      else {
        this.syncFfpProjectBuildoutResults.forEach((element) => {
          if (element?.Result == PlanAssignResultEnum.Warning) {
            this.notificationService.warning("Warning: " + element.Message);
          }
          else if (element?.Result == PlanAssignResultEnum.Succeed) {
            this.aggregatedResult.push(
              `Assign plan for <b>${element.ServiceName}</b> to project <b>${this.projectOid}</b> successfully with message: <br/>`
            );
          }
          else {
            this.aggregatedResult.push(
              `Assign plan for <b>${element.ServiceName}</b> to project <b>${this.projectOid}</b> failed with message: <br/>${element?.Message ?? ''}`
            );
          }
        });
      }
    } catch (ex) {
      this.aggregatedResult.push(
        `Project synchronization failed with error:<br/> <font color="red">${ex}</font>`
      );
    }
    this.isCompleted = true;
  }

  close() {
    this.activeModal.close(true);
  }
}
