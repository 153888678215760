import { Component, OnInit } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";
import { IUserProfile } from "../npr-request.model";
import { ApiService } from "../services";
import { BaseComponent } from "../shared/base.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends BaseComponent implements OnInit {
  user: AccountInfo;
  isAuthorizedUser: boolean;
  userProfile: IUserProfile;

  constructor(private apiService: ApiService) {
    super();
    this.isAuthorizedUser = false;
  }

  ngOnInit() {
    console.log(window.location.href);
  }

  isUserAuthorized() {
    return this.isAuthorizedUser;
  }
}
