<div class="title" #title>
  <div class="container container-max-width">
    <!--Custom filters for region and service on the upper-right-->
    <!-- if gridApi is not ready, regionFilterInstance is undefined and will cause error-->
    <div>
      <h4>Region Access and Quota Management - Approve Requests</h4>
      <p>Requests pending for your approval</p>
    </div>
    <div>
      <label *ngIf="customFilterReady" class="filter-container">
        <ng-select
          class="select-filter ml-3"
          [items]="regionFilterItems"
          [clearable]="true"
          [(ngModel)]="regionFilterValues"
          [ngModelOptions]="{ standalone: true }"
          [multiple]="true"
          (change)="onCustomRegionFilterChanged()"
          placeholder="Filter by Region"
        >
        </ng-select>
        <ng-select
          class="select-filter ml-3"
          [items]="requestorFilterItems"
          [clearable]="true"
          [(ngModel)]="requestorFilterValues"
          [ngModelOptions]="{ standalone: true }"
          [multiple]="true"
          (change)="onCustomRequestorFilterChanged()"
          placeholder="Filter by Requestor"
        >
        </ng-select>
      </label>
      <div class="mt-3 mr-1 tool-container">
        <a
          href="javascript:void(0)"
          class="topbar-item"
          (click)="exportExcel()"
          placement="top-right"
          container="body"
          ngbTooltip="Export to Excel"
          aria-label="Export to Excel"
        >
          <span class="far fa-file-excel fa-lg"></span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <!-- selections -->
    <div class="hint hint-flex">
      <div class="flex-center">
        <span>{{ selectedRowCount }} of {{ rowData.length }} selected &nbsp;&nbsp;|&nbsp;&nbsp;</span>

        <a
          *ngIf="this.userProfile?.IsApprover"
          href="javascript:void(0)"
          class="topbar-item"
          (click)="changeRequestPriority()"
          container="body"
        >
          Set Priority
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a
          *ngIf="this.userProfile?.IsApprover"
          href="javascript:void(0)"
          class="topbar-item"
          (click)="changeRequestQuotaInfo()"
          container="body"
        >
          Edit Quota
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a
          *ngIf="this.userProfile?.IsApprover"
          href="javascript:void(0)"
          class="topbar-item"
          (click)="changeRequestUtilizeDate()"
          container="body"
        >
          Update Quota Utilization Date
        </a>
        <span *ngIf="quotaImplicationsTeam">
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="javascript:void(0)" class="topbar-item" (click)="viewQuotaImplications()" container="body">View Quota Implications </a>
        </span>
      </div>
      <div>
        <button
          type="submit"
          [disabled]="!this.userProfile?.IsApprover"
          class="btn btn-success mr-1"
          (click)="approveRequest()"
          data-test="Approve"
        >
          Approve
        </button>
        <button
          type="button"
          [disabled]="!this.userProfile?.IsApprover"
          class="btn btn-danger mr-1"
          (click)="rejectRequest()"
          data-test="Reject"
        >
          Reject
        </button>
      </div>
    </div>

    <!-- prompt TODO: replaced with ToastrService? -->
    <p *ngIf="message" class="message">{{ this.message }}</p>

    <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
    <form id="ApproverViewForm" #approvalForm="ngForm" autocomplete="off" novalidate>
      <div class="nofloat">
        <ag-grid-angular
          #agGrid
          id="ApproverViewGrid"
          style="width: 100%; height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          pagination="true"
          rowSelection="multiple"
          (gridReady)="onGridReady($event)"
          (paginationChanged)="onPaginationChanged()"
          (selectionChanged)="onSelectionChanged($event)"
          (rowGroupOpened)="onRowGroupOpened($event)"
          (filterChanged)="onFilterChanged($event, 'approver-review')"
        >
        </ag-grid-angular>
      </div>
      <hr />
    </form>
  </div>
</div>
