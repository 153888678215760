<div class="communication-modal">
    <div>
        <textarea class="form-control"
                  name="inputText"
                  id="inputText"
                  type="text"
                  required
                  [(ngModel)]="newComment"
                  placeholder="Provide a response.">
    </textarea>
    </div>
    <div *ngFor="let comment of comments">
        <b>{{ comment.Submitter }}</b>
        <p>{{ comment.ChangedDate }}</p>
        <div [innerHTML]="comment.Comment"></div>
    </div>
</div>
<div class="modal-footer">
  <p *ngIf="isRequestArchived"><b>*</b> Comment is disabled for request already finished (Completed or Cancelled)</p>
  <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="isRequestArchived || !isValidComment()">Save</button>
  <button type="button" class="btn btn-warning" (click)="activeModal.close('')">Cancel</button>
</div>
