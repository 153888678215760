import { Component, OnInit, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import { ApiService } from "src/app/services";

@Component({
  templateUrl: "./plan-reprocess-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../plan.scss"],
})
export class PlanReprocessModalComponent implements OnInit {
  @Input() payload: PlanSubmission;
  @Input() latestVersion: number;
  @Output() result: string = "";
 
  public message: string;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService, private notificationService: ToastrService,) {}

  async ngOnInit() {}

  submit() {
    this.apiService.requeuePlanRegion(this.payload).subscribe(
      () => {
        this.message = `Plan file will be processed soon.`;
        this.notificationService.info(this.message);
        this.activeModal.close("success");
      },
      () => {
        this.message = `Re-process plan file failed.`;
        this.notificationService.error(this.message);
        this.activeModal.close("error");
      },
    );
  }

  close() {
    this.activeModal.close("close");
  }
}
