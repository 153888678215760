import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import * as _ from "lodash";
import { ApiService } from "./api.service";
import { AvailabilityZone, Cloud, DummyAvailabilityZone, DummyEdgeZone, EdgeZone, PslRegion, Region } from "./model";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private apiService: ApiService) {}

  removedClouds = ["Blackforest"];
  // init meta data
  clouds: Cloud[];
  regions: Region[];
  edgeZones: EdgeZone[];
  availabilityZones: AvailabilityZone[];
  // current selected data
  selectedCloud?: Cloud;
  selectedRegion?: Region;
  selectedEdgeZone?: EdgeZone;
  selectedRegions: Region[] = [];
  selectedEdgeZones: EdgeZone[] = [];
  selectedAvailabilityZone?: AvailabilityZone;
  // current status
  cloudRegions: Region[] = [];
  regionEdgeZones: EdgeZone[] = [];
  regionAvailabilityZones: AvailabilityZone[] = [];
  // UI status
  initialRegions: Region[] = [];
  onDemandRegions: Region[] = [];
  onDemandEdgeZones: EdgeZone[] = [];
  isPermittedUser = true;
  pslList: PslRegion[] = [];
  psl: PslRegion;

  async init() {
    if (!this.clouds) {
      await forkJoin([
        this.apiService.GetClouds(),
        this.apiService.GetRegions(),
        this.apiService.GetEdgeZones(),
        this.apiService.GetAvailabilityZones(),
      ])
        .pipe(
          tap(
            ([clouds, regions, edgeZones, availabilityZones]: [Cloud[], Region[], EdgeZone[], AvailabilityZone[]]) => {
              this.clouds = clouds.filter((c) => !this.removedClouds.includes(c.CloudName)).sort((a, b) => a.CloudId - b.CloudId);
              this.regions = regions.sort((a, b) => a.Name.localeCompare(b.Name));
              this.edgeZones = edgeZones.sort((a, b) => a.EdgeZoneName.localeCompare(b.EdgeZoneName));
              this.availabilityZones = availabilityZones;
            },
            (err) => console.error(err)
          )
        )
        .toPromise();
    }
  }

  // the cloudName and regionName are url parameters.
  // The promise returns true if the parameters are correct.
  async Params(cloudName: string, regionName: string): Promise<void> {
    await this.init();
    this.selectedCloud = (cloudName && this.clouds.find((cloud) => cloud.CloudName === cloudName)) || this.selectedCloud;
    this.selectedRegion = (regionName && this.regions.find((region) => region.Name === regionName)) || this.selectedRegion;
  }

  private isBuildoutRegion(region: Region) {
    return ["Buildout", "Approved", "Ring 0 Complete"].includes(region.State);
  }

  private getEdgeZones(region: Region): EdgeZone[] {
    return this.edgeZones.filter((ez) => ez.RegionName === region.Name);
  }

  initCloudRegions(): void {
    if (this.selectedCloud) {
      this.cloudRegions = this.regions?.filter((region) => region.CloudId === this.selectedCloud?.CloudOid.toLowerCase());
      this.initialRegions = this.cloudRegions.filter((region) => this.isBuildoutRegion(region) || this.getEdgeZones(region).length > 0);
      this.onDemandRegions = this.cloudRegions.filter(
        (region) => region.EnabledOnDemand || this.getEdgeZones(region).filter((ez) => ez.EnabledOnDemand).length > 0
      );
    } else {
      this.cloudRegions = [];
    }
    this.setSelectedRegions([]);
  }

  setSelectedRegions(regions: Region[]) {
    this.selectedRegion = regions[0];
    this.selectedRegions = regions;
    this.initRegionEdgeZones();
    this.initRegionAvailabilityZones();
  }

  initRegionEdgeZones(): void {
    this.regionEdgeZones = this.selectedRegions
      .flatMap((region) => this.edgeZones?.filter((ez) => ez.RegionName === region.Name))
      .sort((r1, r2) => r1.EdgeZoneName.localeCompare(r2.EdgeZoneName));
    this.onDemandEdgeZones = this.regionEdgeZones.filter((ez) => ez?.EnabledOnDemand);
    this.setSelectedEdgeZones(this.selectedEdgeZones);
  }

  initRegionAvailabilityZones(): void {
    this.regionAvailabilityZones = this.selectedRegions
      .flatMap((region) => this.availabilityZones?.filter((ez) => ez.DCMT_RegionId === region.Id))
      .sort((r1, r2) => r1.Index - r2.Index);
  }

  private setSelectedEdgeZones(edgeZones: EdgeZone[]) {
    this.selectedEdgeZones = edgeZones.filter((edgeZone) => this.regionEdgeZones.includes(edgeZone));
    this.selectedEdgeZone = this.selectedEdgeZones[0];
  }

  changeCloud(cloud: Cloud): void {
    this.selectedCloud = cloud;
    this.initCloudRegions();
  }

  changeRegion(): void {
    this.setSelectedRegions(this.selectedRegion ? [this.selectedRegion] : []);
  }

  changeRegions(): void {
    this.setSelectedRegions(this.selectedRegions);
  }

  changeEdgeZone(): void {
    this.setSelectedEdgeZones(this.selectedEdgeZone ? [this.selectedEdgeZone] : []);
  }

  changeEdgeZones(): void {
    this.setSelectedEdgeZones(this.selectedEdgeZones);
  }

  getInitialLocationName(): string {
    return this.selectedEdgeZone?.EdgeZoneName || this.selectedRegion?.Name;
  }

  getStatusLocationNames(): string[] {
    const regions = _.isEmpty(this.selectedRegions) ? this.cloudRegions : this.selectedRegions;
    const removeEdgeZonesRegions = regions.filter((r) => this.selectedEdgeZones.every((edgeZone) => r.Name != edgeZone.RegionName));
    return removeEdgeZonesRegions.map((r) => r.Name).concat(this.selectedEdgeZones.map((r) => r.EdgeZoneName));
  }

  getOnDemandLocationNames(): string[] {
    const regions = _.isEmpty(this.selectedRegions) ? this.cloudRegions : this.selectedRegions;
    const enabledOnDemandSelectedRegions = regions.filter((region) => region.EnabledOnDemand);
    const enabledOnDemandSelectedEdgeZones = this.selectedEdgeZones.filter((edgeZone) => edgeZone.EnabledOnDemand);
    const removeEdgeZonesRegions = enabledOnDemandSelectedRegions.filter((r) =>
      enabledOnDemandSelectedEdgeZones.every((edgeZone) => r.Name != edgeZone.RegionName)
    );
    return removeEdgeZonesRegions.map((r) => r.Name).concat(enabledOnDemandSelectedEdgeZones.map((r) => r.EdgeZoneName));
  }

  isMultipleRegionSelected(): boolean {
    return this.selectedCloud && (!this.selectedRegions || _.isEmpty(this.selectedRegions) || this.selectedRegions.length > 1);
  }

  availabilityRegionOrEdgeZone(): boolean {
    return (
      this.selectedRegion &&
      !["Buildout", "Approved", "Ring 0 Complete"].includes(this.selectedRegion.State) &&
      this.selectedEdgeZone == null
    );
  }

  availabilityRegionsOrEdgeZone(): boolean {
    return (
      this.selectedRegions?.some((r) => !["Buildout", "Approved", "Ring 0 Complete"].includes(r.State)) && this.selectedEdgeZone == null
    );
  }

  selectedRegionIsInitialized(): boolean {
    return this.selectedRegion?.Initialized || this.selectedEdgeZone?.Initialized;
  }

  selectedRegionsIncludeInitializedRegion(): boolean {
    return this.selectedRegions?.some((r) => r.Initialized) || this.selectedEdgeZones?.some((r) => r.Initialized);
  }
}
