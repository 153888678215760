<div class="modal-header">
  <h4 class="modal-title">Region Names</h4>
</div>
<div class="modal-body">
  {{ regionNames }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="activeModal.close(false)">
    Close
  </button>
</div>
