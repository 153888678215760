/// <reference path="../../../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PlanFile } from "src/app/generated-models/PlanFile";

export interface PlanDiffModalOptions {
  model: PlanFile;
  isReadonly: boolean;
}

@Component({
  templateUrl: "./plan-diff-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../plan.scss"],
})
export class PlanDiffModalComponent implements OnInit {
  @Input() options: PlanDiffModalOptions;
  editor: monaco.editor.IStandaloneDiffEditor;
  planFile: PlanFile;

  constructor(public activeModal: NgbActiveModal, private notificationService: ToastrService) {}

  async ngOnInit() {
    this.planFile = this.options.model;
  }

  onEditorReady(editor: monaco.editor.IStandaloneDiffEditor) {
    this.editor = editor;

    var originalModel = monaco.editor.createModel(this.planFile.LastCommittedContent, "application/json");
    var modifiedModel = monaco.editor.createModel(this.planFile.Content, "application/json");

    this.editor.setModel({
      original: originalModel,
      modified: modifiedModel,
    });

    // delay 1sec for formatting the content
    setTimeout(() => {
      this.editor
        .getModifiedEditor()
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          this.editor.updateOptions({ readOnly: this.options.isReadonly });
        });
    }, 1000);
  }
}
