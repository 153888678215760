import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Product } from "../ondemand/model";
import { extendDefaultOptions } from "../../utility/gridHelper";

@Component({
  selector: "app-buildout-confirm-dialog",
  templateUrl: "./initial-bulk-submit-confirm-dialog.component.html",
  styleUrls: ["./initial-bulk-submit-confirm-dialog.component.scss"],
})
export class InitalBulkSubmitConfirmDialogComponent implements OnInit {
  rowSelection = "multiple";
  @Input() regions: string[];
  @Input() products: Product[];

  public gridApi;

  public showUnselectableMessage = false;

  columnDefs = [
    {
      headerName: "",
      field: "",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      lockPinned: true,
      width: 40,
    },
    { headerName: "Region", field: "RegionName" },
    { headerName: "Product ID", field: "ProductOid", width: 300 },
    { headerName: "Product Ring", field: "ProductRing", width: 100 },
    { headerName: "Product Name", field: "ProductName" },
    { headerName: "BigID", field: "CaymanProductId", width: 150 },
    { headerName: "MIX First Party Product Name", field: "CaymanProductName" },
    { headerName: "Location Type", field: "LocationType", hide: true },
    { headerName: "Is Available Region", field: "IsAvailableRegion", hide: true },
  ];
  gridOptions = extendDefaultOptions({
    columnDefs: this.columnDefs,
    isRowSelectable: rowNode => rowNode.data && rowNode.data.CaymanProductId,
    getRowStyle: params => {
      if (!params.data.IsAvailableRegion && params.data.CaymanProductId) {
        return { color: 'blue', background: 'yellow' };
      }
    },
  });

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  onGridReady(params) {
    this.gridApi = params.api;
    var showMsg = false;
    this.gridApi.forEachNode(function (rowNode, _) {
      if (!(rowNode.data && rowNode.data.CaymanProductId)) {
        showMsg = true;
      }
      rowNode.setSelected(true);
    });
    console.log("grid ready");
    this.showUnselectableMessage = showMsg;
  }

  regionNames() {
    return this.regions.join(",");
  }

  f() {
    return this.showUnselectableMessage;
  }
}
