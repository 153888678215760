/// <reference path="../../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../../shared/base.component";
import { ApiService } from "src/app/services";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { ColumnApi, GetDetailRowDataParams, GridOptions } from "ag-grid-community";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import {
  CapacityOrderMetadataColumnDefinition,
  CapacityOrderResourceColumnDefinition,
  CapacityOrderResourceDetailAlternateResourceColumnDefinition,
  CapacityOrderResourceDetailColumnDefinition,
  CapacityOrderResourceProviderColumnDefinition,
} from "../ag-grid-column-definition";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { SubmittedResourceRequestStatus } from "src/app/generated-models/SubmittedResourceRequestStatus";
import { ResourceBaseRequest } from "src/app/generated-models/ResourceBaseRequest";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { CapacityOrderLocation } from "src/app/generated-models/CapacityOrderLocation";
import { CapacityOrderDetails } from "../../../generated-models/CapacityOrderDetails";

interface ICapacityOrderResourceProviderData {
  ResourceProvider: string;
  Resources: CapacityOrderLocation[];
  ResourceNumber: number;
}

interface ICapacityOrderResourceData {
  Location: string;
  ResourceDetailNumber: number;
  ResourceDetails: SubmittedResourceRequestStatus[];
}

interface ICapacityOrderResourceDetailData {
  ResourceName: string;
  Limits: number;
  Unit: string;
  ProviderSpecificProperties: string;
  AlternateResourcesNumber: number;
  ApprovedResourceName: string;
  ApprovedLimits: number;
  Status: string;
  AlternateResources: ResourceBaseRequest[];
}

interface ICapacityOrderAlternateResourcesData {
  ResourceName: string;
  Limits: number;
  Unit: string;
  ProviderSpecificProperties: string;
}

interface ICapacityOrderMetadataData {
  MetadataKey: string;
  MetadataValue: string;
}

@Component({
  templateUrl: "./capacity-order-detail-v2.component.html",
  styleUrls: ["../../../styles.scss", "../plan.scss"],
})
export class CapacityOrderDetailV2Component extends BaseComponent implements OnInit {
  capacityOrderId: string;
  serviceTeam: string;
  capacityOrderDetail: CapacityOrderDetails;

  ordersGridOptions: GridOptions;
  ordersGridColumnApi: ColumnApi;
  capacityOrderRowData: ICapacityOrderResourceProviderData[] = [];

  metadataGridOptions: GridOptions;
  metadataGridColumnApi: ColumnApi;
  metadataRowData: ICapacityOrderMetadataData[] = [];

  approvalDate: string;
  etaDate: string;
  expiryDate: string;
  needByDate: string;

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    await this.loadCapacityOrderDetail();
    this.loadCapacityOrderMetadata();

    this.ordersGridOptions = extendDefaultOptions({
      columnDefs: CapacityOrderResourceProviderColumnDefinition,
      suppressPaginationPanel: true,
      sideBar: null,
      masterDetail: true,
      detailRowAutoHeight: true,
      detailCellRendererParams: {
        detailGridOptions: {
          masterDetail: true,
          detailRowAutoHeight: true,
          columnDefs: CapacityOrderResourceColumnDefinition,
          detailCellRendererParams: {
            detailGridOptions: {
              columnDefs: CapacityOrderResourceDetailColumnDefinition,
              masterDetail: true,
              detailRowAutoHeight: true,
              detailCellRendererParams: {
                detailGridOptions: {
                  columnDefs: CapacityOrderResourceDetailAlternateResourceColumnDefinition,
                  detailRowAutoHeight: true,
                },
                getDetailRowData: (params) => {
                  const rowData: ICapacityOrderAlternateResourcesData[] = [];
                  params.data.AlternateResources?.forEach((data: ResourceBaseRequest) => {
                    const resource: ICapacityOrderAlternateResourcesData = {
                      ResourceName: data.ResourceName,
                      Limits: data.Limits,
                      Unit: data.Unit,
                      ProviderSpecificProperties: "",
                    };
                    data.ProviderSpecificProperties.forEach((prop) => {
                      resource.ProviderSpecificProperties += `{${prop.Name} : ${prop.Nvalue}} `;
                    });
                    rowData.push(resource);
                  });
                  params.successCallback(rowData);
                },
                defaultColDef: {
                  sortable: true,
                  flex: 1,
                },
              },
            },
            getDetailRowData: (params) => {
              const rowData: ICapacityOrderResourceDetailData[] = [];
              params.data.ResourceDetails?.forEach((data: SubmittedResourceRequestStatus) => {
                const detail: ICapacityOrderResourceDetailData = {
                  ResourceName: data.RequestedResource.ResourceName,
                  Limits: data.RequestedResource.Limits,
                  Unit: data.RequestedResource.Unit,
                  ProviderSpecificProperties: "",
                  AlternateResourcesNumber: data.AlternateResources?.length ?? 0,
                  ApprovedResourceName: data.ApprovedResource?.ResourceName,
                  ApprovedLimits: data.ApprovedResource?.Limits,
                  Status: data.Status,
                  AlternateResources: data.AlternateResources,
                };
                data.RequestedResource.ProviderSpecificProperties.forEach((prop) => {
                  detail.ProviderSpecificProperties += `{${prop.Name} : ${prop.Nvalue}} `;
                });
                rowData.push(detail);
              });
              params.successCallback(rowData);
            },
          },
          defaultColDef: {
            sortable: true,
            flex: 1,
          },
        },
        getDetailRowData: (params: GetDetailRowDataParams) => {
          const rowData: ICapacityOrderResourceData[] = [];
          params.data?.Resources.forEach((data) => {
            const resource: ICapacityOrderResourceData = {
              Location: data.Location,
              ResourceDetailNumber: data.ResourceDetails.length,
              ResourceDetails: data.ResourceDetails,
            };
            rowData.push(resource);
          });
          params.successCallback(rowData);
        },
      },
    });

    this.metadataGridOptions = extendDefaultOptions({
      columnDefs: CapacityOrderMetadataColumnDefinition,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.apiService.getTeamList().subscribe((response) => {
      if (response) {
        response.map((t) => {
          if (t.Oid === this.capacityOrderDetail?.ServiceTreeId) {
            this.serviceTeam = t.Name;
          }
        });
      }
    });
  }

  async loadCapacityOrderDetail() {
    this.loadingService.setLoading(true);
    this.capacityOrderId = this.route.snapshot.params["capacityOrderId"];

    try {
      this.capacityOrderDetail = await this.apiService.refreshCapacityOrder(this.capacityOrderId).toPromise();
      this.approvalDate = PreprocessDateTime(this.capacityOrderDetail.ApprovalDate);
      this.etaDate = PreprocessDateTime(this.capacityOrderDetail.EtaDate);
      this.expiryDate = PreprocessDateTime(this.capacityOrderDetail.ExpiryDate);
      this.needByDate = PreprocessDateTime(this.capacityOrderDetail.NeedByDate);
      this.capacityOrderDetail.Orders.forEach((order) => {
        const resourceProvider: ICapacityOrderResourceProviderData = {
          ResourceProvider: order.ResourceProvider,
          Resources: order.Resources,
          ResourceNumber: order.Resources.length,
        };
        this.capacityOrderRowData.push(resourceProvider);
      });
    } catch (err) {
      this.capacityOrderDetail = null;
      this.notificationService.error(err);
    }

    this.loadingService.setLoading(false);

    setTimeout(() => {
      this.ordersGridColumnApi.autoSizeAllColumns();
      this.metadataGridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  loadCapacityOrderMetadata() {
    if (this.capacityOrderDetail !== null) {
      Object.entries(this.capacityOrderDetail.Metadata).forEach((entry) => {
        const data: ICapacityOrderMetadataData = {
          MetadataKey: entry[0],
          MetadataValue: entry[1].toString(),
        };
        this.metadataRowData.push(data);
      });
    }
  }

  onOrdersGridReady(params: GridOptions) {
    this.ordersGridColumnApi = params.columnApi;
  }

  onMetadataGridReady(params: GridOptions) {
    this.metadataGridColumnApi = params.columnApi;
  }
}
