<h4>Summary</h4>
<ul class="list-container">
  <li *ngFor="let items of summaryBasic | keyvalue" class="flex-box mb-1">
    <div class="col-md-3">{{ items.key }}</div>
    <div class="col-md-9">
      {{ items.value }}
      <div *ngIf="items.key === 'Service Name' && summaryInfo.pamApprovalState?.CurrentState === 'NotApprovedByAdmin'" class="info-box">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span
          >Your request will need to be approved manually. Click <a href="https://aka.ms/lionrockautoapproval" target="_blank">here</a> to
          know how to sign up for auto approval.</span
        >
      </div>
    </div>
  </li>
  <li class="flex-box" *ngIf="!isSubIdsDisabled">
    <div class="col-md-3"></div>
    <div class="col-md-9">
      <a *ngIf="!additionalSubIds && !isCustomerRequest()" href="javascript:void(0)" (click)="shouwAdditionalSubIds()" data-test="MoreSubs">
        + Request same access/quota for additional subscriptions
      </a>
      <ng-container *ngIf="additionalSubIds">
        <textarea
          class="form-control"
          [(ngModel)]="summaryInfo.subIds"
          name="subIds"
          title="subIds"
          #subIds="ngModel"
          [pattern]="subsRegExpression"
          placeholder="Enter in this format: 987e33bf-8713-45cf-93c5-7055c9cca413, 2a737b98-0f8d-4cc8-8a9d-3ea6d1192702, ..."
          data-test="SubIds"
        >
        </textarea>
        <ng-container *ngIf="isTouchedOrDirty(subIds)">
          <em *ngIf="subIds.errors?.pattern">Please enter valid subscription ID(s) and separate them by comma.</em>
          <em *ngIf="getSubscriptionsCount() > 100">A single batch can only support up to 100 subscriptions.</em>
        </ng-container>
      </ng-container>
    </div>
  </li>
  <li class="flex-box" *ngIf="isSubIdsDisabled">
    <div class="col-md-3"></div>
    <div class="col-md-9">
      <div class="info-box">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span>Can't add any more subscriptions when selecting SQL DTU Quota or DNS.</span>
      </div>
    </div>
  </li>
</ul>
<h4>Details</h4>
<ul class="list-container" *ngIf="uiRequest.Details.EnableArmWithNoQuota">
  <li class="flex-box">
    <div class="col-md-3">Enable ARM Without VM or Storage Quota</div>
    <div class="col-md-9">true</div>
  </li>
</ul>
<ng-container *ngFor="let item of summaryDetails | keyvalue">
  <ng-container *ngIf="isValidObject(item.value)">
    <h5>{{ item.key }}</h5>
    <ul class="list-container">
      <li class="flex-box mb-1" *ngFor="let i of item.value | keyvalue">
        <div class="col-md-3">{{ i.key }}</div>
        <div class="col-md-9">{{ i.value }}</div>
      </li>
    </ul>
  </ng-container>
</ng-container>
<hr />
<ul class="list-container">
  <!--Quota request date-->
  <li *ngIf="summaryInfo.utilizeDate" class="flex-box mb-3">
    <div class="col-md-3">Please provide the date when this quota will be utilized​</div>
    <label class="col-md-9">
      <em-datepicker class="col-md-5 pl-0" [(ngModel)]="summaryInfo.utilizeDate" name="UtilizeDate" #utilizeDate="ngModel"> </em-datepicker>
    </label>
  </li>
  <!--Request justification-->
  <li class="flex-box">
    <div class="col-md-3">Request Justification/Notes​​</div>
    <div class="col-md-9" *ngIf="!displayQuestionList()">
      <ng-container *ngFor="let item of this.summaryInfo.justification">
        <div>{{ item.Question }}</div>
        <textarea
          class="form-control"
          [(ngModel)]="item.Answer"
          name="Justification"
          title="Justification"
          rows="4"
          #justification="ngModel"
          data-test="Justification"
        ></textarea>
      </ng-container>
    </div>

    <label class="col-md-9" *ngIf="displayQuestionList()">
      <ng-container *ngFor="let item of this.summaryInfo.justification">
        <label>
          <span *ngIf="item.IsRequired" class="required"></span>
          {{ item.Question }}
        </label>
        <input *ngIf="!item.Type || item.Type === 'text'" class="form-control" type="text" [(ngModel)]="item.Answer" [required]="item.IsRequired" />
        <ng-select
          *ngIf="item.Type === 'select'"
          [(ngModel)]="item.Answer"
          [items]="item.Options">
        </ng-select>
        <em-datepicker *ngIf="item.Type === 'date'" class="col-md-5 pl-0" [(ngModel)]="item.Answer" ></em-datepicker>
      </ng-container>
      <em *ngIf="!isJustificationValid()">Please provide answers for required questions</em>
    </label>
  </li>
</ul>
