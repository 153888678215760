import { Component, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { NprConfiguration } from "src/app/app.configuration";
import { IBasicInfo, IRequestRegion } from "src/app/npr-request.model";
import { CloudName } from "src/app/shared/ticket-details/ticket-column-definition";
import { isNonEmptyString } from "src/app/utility/common-helper";
import { environment } from "src/environments/environment";
import { CreateRequestService } from "../create-request.service";

@Component({
  selector: "basic-info",
  templateUrl: "./basic-info.component.html",
  styleUrls: ["../create-request.scss"],
})
export class BasicInfoComponent implements OnInit {
  basicInfo: IBasicInfo;
  guidRegExpression = NprConfiguration.guidRegExpression;
  emailRegexp = new RegExp(NprConfiguration.emailRegExpression);
  fileError = "";
  batchUploadSubscriptions = false;

  constructor(public service: CreateRequestService) {
    this.basicInfo = this.service.basicInfo;
  }

  ngOnInit(): void {
    this.basicInfo.isNextBtnDisabled = () => {
      return (
        !this.isRegionValid ||
        !this.isSubIdValid ||
        !this.isEmailValid ||
        !this.isTeamValid ||
        !this.isOtherTeamValid ||
        !this.isOtherTeamOidValid ||
        !this.isCustomerValid ||
        !this.IsRegionEnabledInUI
      );
    };
  }

  isTouchedOrDirty(control: NgModel): boolean {
    return control && (control.touched || control.dirty);
  }

  isSubIdPatternValid(subId: string): boolean {
    return new RegExp(this.guidRegExpression).test(subId);
  }

  isTeamOidPatternValid(teamOid: string): boolean {
    return new RegExp(this.guidRegExpression).test(teamOid);
  }

  selectFile(event): void {
    const file: File = event.target.files?.item(0);
    this.fileError = "";
    this.service.csvFileName = file.name;
    this.basicInfo.subscriptions = "";

    if (this.validateFileName(file.name, "csv")) {
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        let subscriptions: string = (reader.result as string).toLowerCase();

        // Should split subscriptions with \r\n
        subscriptions = [...new Set(subscriptions.split(/\r\n/g).filter((s) => s.trim() !== ""))].join(",");
        if (new RegExp(NprConfiguration.subsRegExpression).test(subscriptions)) {
          this.basicInfo.subscriptions = subscriptions;
        } else {
          this.fileError = `One or more subscriptions are invalid in the csv file.`;
          return;
        }
      };
    } else {
      this.fileError = "File type is invalid, please upload .csv file.";
    }
  }

  validateFileName(name: string, expected: string): boolean {
    const actual = name.substring(name.lastIndexOf(".") + 1);
    if (actual.toLowerCase() === expected) {
      return true;
    } else {
      return false;
    }
  }

  get isTeamValid(): boolean {
    if (!this.basicInfo.isExternal) {
      return this.basicInfo.team && this.service.teamList.some((team) => team === this.basicInfo.team);
    }
    return true;
  }

  get constrainedMessage(): string {
    if (this.basicInfo.region?.constraintNote) {
      return `${this.basicInfo.region?.label} is constrained with message: <br />${this.basicInfo.region?.constraintNote.replace(
        /[\r\n]/g,
        "<br />"
      )}.`;
    }
    return "";
  }

  get isRegionValid(): boolean {
    return this.service.regionList.some((region) => region.label === this.basicInfo.region?.label);
  }

  get isOtherTeamValid(): boolean {
    if (this.isOtherTeam && !this.basicInfo.isExternal) {
      return isNonEmptyString(this.basicInfo.otherTeam);
    }
    return true;
  }

  get isOtherTeamOidValid(): boolean {
    if (this.isOtherTeam && !this.basicInfo.isExternal && isNonEmptyString(this.basicInfo.otherTeamOid)) {
      return this.isTeamOidPatternValid(this.basicInfo.otherTeamOid);
    }
    return true;
  }

  get isCustomerValid(): boolean {
    if (this.basicInfo.isExternal) {
      return isNonEmptyString(this.basicInfo.customer);
    }
    return true;
  }

  get isEmailValid(): boolean {
    return (
      isNonEmptyString(this.basicInfo.email) && this.basicInfo.email.split(/[,;]/).every((emailItem) => this.emailRegexp.test(emailItem))
    );
  }

  get isSubIdValid(): boolean {
    return this.isSubIdPatternValid(this.basicInfo.subId);
  }

  get isOtherTeam(): boolean {
    return this.basicInfo.team?.label === "Other";
  }

  get isRegionGaAndPublic(): boolean {
    return this.basicInfo.region?.cloud === CloudName.Public && this.basicInfo.region?.isGA;
  }

  get regionList(): IRequestRegion[] {
    if (this.basicInfo.isExternal) {
      return this.service.regionList.filter((region) => region.isManagedRegion || region.isEnabledForCustomerEA || region.isManagedAZ);
    } else {
      return this.service.regionList;
    }
  }

  get IsRegionEnabledInUI(): boolean {
    const region = this.basicInfo.region;
    if (region) {
      return region.isEnabledInUI || region.isFlighting;
    }
    return true;
  }

  changeSubType(): void {
    this.basicInfo.region = null;
  }
}
