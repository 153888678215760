<div class="title" #title>
  <div class="container">
    <h4>Region Access and Quota Submission</h4>
    <div class="btn-group">
      <button *ngIf="step > 0" class="btn btn-primary mr-1" (click)="prev()">Prev</button>
      <div *ngIf="steps[step] == 'basicInfo'" class="submit-btn mr-1">
        <button
          class="btn btn-primary mr-1"
          (click)="validateBasicInfo()"
          data-test="Next"
          [disabled]="basicInfo?.isNextBtnDisabled()"
        >
          Next
        </button>
        <label *ngIf="isAdmin" style="text-align: right">
          <input type="checkbox" [(ngModel)]="service.isFlighting" name="Flighting" (change)="service.changeFlighting()" />
          Flighting
        </label>
      </div>
      <button
        *ngIf="steps[step] == 'detailsInfo'"
        class="btn btn-primary mr-1"
        (click)="validate()"
        data-test="Validate"
        [disabled]="detailsInfo?.isValidateBtnDisabled()"
      >
        Validate
      </button>
      <div class="submit-btn mr-1">
        <button *ngIf="steps[step] == 'summaryInfo'" class="btn btn-primary" (click)="submitRequest()" [disabled]="summaryInfo?.isSubmitBtnDisabled()" data-test="Submit">
          Submit
        </button>
      </div>
    </div>
    <p>
      Use this tool to submit region access and quota requests for your subscriptions.
      <br />
      For automation options and questions, please refer to our
      <a href="https://aka.ms/raqdocs" target="_blank">wiki</a>.
    </p>
  </div>
</div>

<div class="container container-content mt-3" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <form #form="ngForm" autocomplete="off" novalidate>
      <basic-info *ngIf="steps[step] == 'basicInfo'"></basic-info>
      <request-details
        *ngIf="steps[step] == 'detailsInfo'"
        data-test-title="details"
      ></request-details>
      <summary-info *ngIf="steps[step] == 'summaryInfo'"></summary-info>
      <hr />
      <br />
    </form>
  </div>
</div>
