<div class="title" #title>
  <div class="container">
    <h4>Extended Information</h4>
  </div>
</div>
<div class="container container-content mt-3" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <form #form="ngForm" autocomplete="off" novalidate>
      <ul class="list-container">
        <li>
          <ul>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Deployment Type</div>
              <div class="col-md-9 radio">
                <label>
                  <input class="mr-1" name="IsExternal" [(ngModel)]="deploymentType" type="radio" value="ARM" />
                  <span>ARM</span>
                </label>
                <label>
                  <input class="mr-1" name="IsExternal" [(ngModel)]="deploymentType" type="radio" value="RDFE" />
                  <span>RDFE</span>
                </label>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul *ngIf="isArm(); else notArm">
            <!--Region-->
            <li class="flex-box mb-3">
              <div class="col-md-3 label required">Region</div>
              <label class="col-md-9 input">
                <ng-select
                  [(ngModel)]="region"
                  (change)="regionChange($event)"
                  name="Region"
                  [items]="allRegion"
                  groupBy="cloud"
                  placeholder="Enter region name"
                  required>
                </ng-select>
              </label>
            </li>
          </ul>
          <ul *ngIf="isArm() && showRegionInfo()">
            <!-- RegionInfo -->
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Arm Region Name</div>
              <div class="col-md-9 value">{{ currentRegion.ArmRegionName }}</div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Status</div>
              <div class="col-md-9 value">{{ currentRegion.Status }}</div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Required Feature Flags</div>
              <div class="col-md-9 value" *ngIf="hasAfecFlags()">
                <div *ngFor="let afecFlag of currentRegion.AfecFlags; last as isLast">
                  <div>{{ afecFlag }}&nbsp;<span class="or" *ngIf="!isLast">or</span></div>
                </div>
              </div>
              <div class="col-md-9 value" *ngIf="!hasAfecFlags()">N/A</div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Region Access</div>
              <div class="col-md-9 value" [innerHTML]="getAccessInfo(currentRegionAccess)"></div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Current Architecture</div>
              <div class="col-md-9 value">{{ currentRegion.CurrentArchitecture }}</div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Availability Zone</div>
              <div class="col-md-9 value" *ngIf="currentAzs?.length">
                <div *ngFor="let az of currentAzs">
                  {{ az.AvailabilityZoneName }}: {{ az.State }}
                </div>
              </div>
              <div class="col-md-9 value" *ngIf="!currentAzs?.length">N/A</div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Required Feature Flags For AZ</div>
              <div class="col-md-9 value">{{ currentRegion.AzAfecFlags || "N/A" }}</div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">AZ Access</div>
              <div class="col-md-9 value" [innerHTML]="getAccessInfo(currentAzAccess)"></div>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Service Type</div>

              <label class="col-md-9 input">
                <ng-select [(ngModel)]="serviceType"
                            (change)="serviceChange($event)"
                            name="ServiceType"
                            [items]="serviceTypes"
                            placeholder="Enter service type"
                            required>
                </ng-select>
                <label class="info-box" *ngIf="serviceNotReady && isRegionAccess">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>{{ serviceNotReady }} is not available or still in buildout on {{ region.label }}!</span>
                </label>
              </label>
            </li>
            <li class="flex-box mb-3">
              <div class="col-md-3 label">Subscription</div>
              <div class="col-md-9 input form-inline">
                <input class="form-control col-md-11"
                        [(ngModel)]="subscriptionId"
                        name="SubIdArm"
                        title="SubIdArm"
                        placeholder="Enter in this format: 987e33bf-8713-45cf-93c5-7055c9cca413"
                        #subIdArm="ngModel"
                        [pattern]="guidRegExpression" />
                <button type="button" class="btn btn-primary form-control col-md-1" (click)="subscriptionInfoARM()">Info</button>
                <ng-container>
                  <em *ngIf="subIdArm.errors?.pattern">Please enter one valid subscription ID.</em>
                </ng-container>
              </div>
            </li>
            <li class="flex-box mb-3" *ngIf="subscriptionSupportInfo?.ZoneMappings?.length">
              <div class="col-md-3 label">Zone Mapping</div>
              <div class="col-md-9 value">
                <div *ngFor="let zoneMapping of subscriptionSupportInfo.ZoneMappings">{{ zoneMapping }}</div>
              </div>
            </li>
            <li class="flex-box mb-3" *ngIf="showRegionalQuota()">
              <div class="col-md-3 label">Regional Quota</div>
              <div class="col-md-9 value">
                {{ subscriptionSupportInfoARM.RegionalVmQuota }}
              </div>
            </li>
            <li class="flex-box mb-3" *ngIf="serviceType === resourceType.Storage">
              <div class="col-md-3 label">Quota</div>
              <div class="col-md-9 value">
                {{ subscriptionRegionSupportInfo ? subscriptionRegionSupportInfo.ArmStorageQuota : subscriptionPrompt }}
              </div>
            </li>
            <li class="flex-box mb-3" *ngIf="serviceType === resourceType.Storage">
              <div class="col-md-3 label">Usage</div>
              <div class="col-md-9 value">
                {{ subscriptionRegionSupportInfo ? subscriptionRegionSupportInfo.ArmStorageCurrentUsage : subscriptionPrompt }}
              </div>
            </li>
            <li *ngIf="serviceType === resourceType.Compute && currentVmSkus != null">
              <h5>SKU</h5>
              <hr />
              <div class="mb-2 export-btn">
                <a href="javascript:void(0)"
                    class="topbar-item"
                    (click)="exportCurrentView()"
                    placement="top-right"
                    container="body"
                    ngbTooltip="Export to Excel"
                    aria-label="Export to Excel">
                  <span class="far fa-file-excel fa-lg"></span>
                </a>
              </div>
              <ag-grid-angular style="width: 100%; height: 540px; margin-bottom: 120px"
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [rowData]="currentVmSkus"
                    [masterDetail]="true"
                    [tooltipShowDelay]="tooltipShowDelay"
                    [tooltipHideDelay]="tooltipHideDelay"
                    pagination="true"
                    rowSelection="multiple"
                    (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </li>
            <li *ngIf="serviceType === resourceType.Hdi && currentHdiSkus != null">
              <h5>HD Insights SKU</h5>
              <hr />
              <div class="mb-2 export-btn">
                <a href="javascript:void(0)"
                    class="topbar-item"
                    (click)="exportCurrentView()"
                    placement="top-right"
                    container="body"
                    ngbTooltip="Export to Excel"
                    aria-label="Export to Excel">
                  <span class="far fa-file-excel fa-lg"></span>
                </a>
              </div>
              <ag-grid-angular style="width: 100%; height: 540px; margin-bottom: 120px"
                    class="ag-theme-balham"
                    [gridOptions]="hdiGridOptions"
                    [rowData]="currentHdiSkus"
                    [masterDetail]="true"
                    [tooltipShowDelay]="tooltipShowDelay"
                    [tooltipHideDelay]="tooltipHideDelay"
                    pagination="true"
                    rowSelection="multiple"
                    (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </li>
            <li *ngIf="serviceType === resourceType.Batch && currentBatchSkus != null">
              <h5>Batch SKU</h5>
              <hr />
              <div class="mb-2 export-btn">
                <a href="javascript:void(0)"
                    class="topbar-item"
                    (click)="exportCurrentView()"
                    placement="top-right"
                    container="body"
                    ngbTooltip="Export to Excel"
                    aria-label="Export to Excel">
                  <span class="far fa-file-excel fa-lg"></span>
                </a>
              </div>
              <ag-grid-angular style="width: 100%; height: 540px; margin-bottom: 120px"
                    class="ag-theme-balham"
                    [gridOptions]="hdiGridOptions"
                    [rowData]="currentBatchSkus"
                    [masterDetail]="true"
                    [tooltipShowDelay]="tooltipShowDelay"
                    [tooltipHideDelay]="tooltipHideDelay"
                    pagination="true"
                    rowSelection="multiple"
                    (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </li>
            <li *ngIf="serviceType === resourceType.ArmResourceType">
              <h5>Arm Resource Type</h5>
              <hr />
              <div class="mb-2 export-btn">
                <a href="javascript:void(0)"
                    class="topbar-item"
                    (click)="exportCurrentView()"
                    placement="top-right"
                    container="body"
                    ngbTooltip="Export to Excel"
                    aria-label="Export to Excel">
                  <span class="far fa-file-excel fa-lg"></span>
                </a>
              </div>
              <ag-grid-angular style="width: 100%; height: 540px; margin-bottom: 120px"
                    class="ag-theme-balham"
                    [gridOptions]="armResourceTypeGridOptions"
                    [rowData]="currentArmResourceTypes"
                    [masterDetail]="true"
                    [tooltipShowDelay]="tooltipShowDelay"
                    [tooltipHideDelay]="tooltipHideDelay"
                    pagination="true"
                    rowSelection="multiple"
                    (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </li>
            <li *ngIf="serviceType === resourceType.VmDisk">
              <h5>Virtual Machine Disk</h5>
              <hr />
              <div class="mb-2 export-btn">
                <a href="javascript:void(0)"
                   class="topbar-item"
                   (click)="exportCurrentView()"
                   placement="top-right"
                   container="body"
                   ngbTooltip="Export to Excel"
                   aria-label="Export to Excel">
                  <span class="far fa-file-excel fa-lg"></span>
                </a>
              </div>
              <ag-grid-angular style="width: 100%; height: 540px; margin-bottom: 120px"
                               class="ag-theme-balham"
                               [gridOptions]="vmDiskGridOptions"
                               [rowData]="currentVmDisks"
                               [masterDetail]="true"
                               [tooltipShowDelay]="tooltipShowDelay"
                               [tooltipHideDelay]="tooltipHideDelay"
                               pagination="true"
                               rowSelection="multiple"
                               (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </li>
          </ul>
        </li>
        <li>
          <ng-template #notArm>
            <ul>
              <li class="flex-box mb-3" *ngIf="deploymentType == 'RDFE' || currentRegion != null">
                <div class="col-md-3 label">Subscription</div>
                <div class="col-md-9 input form-inline">
                  <input class="form-control col-md-11"
                         [(ngModel)]="subscriptionId"
                         name="SubId"
                         placeholder="Enter in this format: 987e33bf-8713-45cf-93c5-7055c9cca413"
                         #subId="ngModel"
                         [pattern]="guidRegExpression" />
                  <button class="btn btn-primary form-control col-md-1" (click)="subscriptionInfoRDFE()">Info</button>
                  <ng-container>
                    <em *ngIf="subId.errors?.pattern">Please enter one valid subscription ID.</em>
                  </ng-container>
                </div>
              </li>
              <li class="flex-box mb-3">
                <div class="col-md-3 label">Cloud</div>

                <div class="col-md-9 input">
                  <ng-select [(ngModel)]="cloud" name="Cloud" [items]="clouds" placeholder="Enter Cloud" required> </ng-select>
                </div>
              </li>
              <li class="flex-box mb-3" *ngIf="showRdfeInfo()">
                <div class="col-md-3 label">RDFE Compute Quota</div>
                <div class="col-md-9 value">
                  {{ subscriptionSupportInfoRDFE == undefined ? subscriptionPrompt : subscriptionSupportInfoRDFE.RdfeComputeQuota }}
                </div>
              </li>
              <li class="flex-box mb-3" *ngIf="showRdfeInfo()">
                <div class="col-md-3 label">RDFE Storage Quota</div>
                <div class="col-md-9 value">
                  {{ subscriptionSupportInfoRDFE == undefined ? subscriptionPrompt : subscriptionSupportInfoRDFE.RdfeStorageQuota }}
                </div>
              </li>
            </ul>
          </ng-template>
        </li>
      </ul>
      <hr />
    </form>
  </div>
</div>
