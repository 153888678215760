import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";

@Component({
  templateUrl: "./subscription-details-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class SubscriptionDetailsModalComponent implements OnInit {
  @Input() message: string;
  @Input() subscriptionId: string;

  createdTime = "";
  status = "";
  cloudType = "";
  isLoading = false;
  errorMessage = "";

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  ngOnInit() {
    this.isLoading = true;
    this.apiService.getSubscriptionDetails(this.subscriptionId, false).subscribe(
      (res) => {
        if (res) {
          if (res.CreatedTime != null) {
            this.createdTime = PreprocessDateTime(res.CreatedTime);
          }
          this.status = res.Status;
          this.cloudType = res.CloudType;
          if (res.CreatedTime == null || res.Status == null || res.CloudType == null) {
            this.errorMessage = "Incomplete subscription details returned";
          }
        }
        else {
          this.errorMessage = "No subscription details returned";
        }
        this.isLoading = false;
      },
      (error) => {
        if (error) {
          this.errorMessage = "Failed to get subscription details";
          this.isLoading = false;
        }
      }
    );
  }
}
