import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewRef } from "@angular/core";
import { GridApi } from "ag-grid-community";
import { SetFilter } from "ag-grid-enterprise";

@Component({
  selector: "grid-filter-pills",
  templateUrl: "./grid-filter-pills.component.html",
  styleUrls: ["./grid-filter-pills.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridFilterPillsComponent implements OnInit {
  @Input() gridApi: GridApi;

  filteredRows: string[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.gridApi.addEventListener("filterChanged", () => {
      this.filteredRows = this.getFilteredRows();
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getFilteredRows() {
    const filteredRows: string[] = [];
    if (!this.gridApi) {
      return filteredRows;
    }
    const filters = this.gridApi.getFilterModel();
    if (filters) {
      for (const filteredRow of Object.keys(filters)) {
        filteredRows.push(filteredRow);
      }
    }
    return filteredRows;
  }

  getFilterDescription(filteredRow: string) {
    const filters = this.gridApi.getFilterModel();
    const filterModel = filters[filteredRow];

    if (!filterModel) {
      return;
    }

    /**
     * ag-grid support 4 filter types: set, text, number, date.
     * Also, ag-grid support custom filters.
     * Here we only implement how to get description for a set filter.
     */
    if (filterModel.filterType === "set") {
      return this.getFilterDescriptionForSetFilter(filteredRow, filterModel);
    }

    return this.getFilterDescriptionForUnknownFilter(filteredRow);
  }

  clearFilter(e, filteredRow: string) {
    /**
     * prevent the click event is passed to the parent elements.
     * Currently this is not needed. It will be needed if we add click event on the filter pill.
     */
    e.stopPropagation();

    const filterInstance = this.gridApi.getFilterInstance(filteredRow);
    filterInstance?.setModel(null);
    this.gridApi.onFilterChanged();
  }

  // how to get description for a set filter.
  private getFilterDescriptionForSetFilter(filteredRow, filterModal): string {
    const headerName: string = this.getRowHeaderName(filteredRow);
    const selectedValues: string[] = filterModal.values;

    if (selectedValues.length === 1) {
      return `${headerName} == ${selectedValues[0]}`;
    }

    const filterInstance = this.gridApi.getFilterInstance(filteredRow);

    if (filterInstance != null) {
      const setFilter = filterInstance as SetFilter;
      const valueModel = setFilter.getValueModel();
      const allPossibleValues = valueModel.getValues();
  
      return `${headerName} == ${selectedValues.length} of ${allPossibleValues.length} selected`;
    }

    return `filter on ${headerName}`;
  }

  // fallback function for how to get description for a filter.
  private getFilterDescriptionForUnknownFilter(filterKey) {
    const headerName: string = this.getRowHeaderName(filterKey);
    return `filter on ${headerName}`;
  }

  private getRowHeaderName(rowKey: string): string {
    return this.gridApi.getColumnDef(rowKey).headerName;
  }
}
