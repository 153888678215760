import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminViewComponent } from "./admin-view.component";
import { TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { ManatreeColumnDefinitionForAdmin } from "./ag-grid-column-definition";
import { DataTypes } from "./admin-view-data-types";
import { SharedDataService } from "../../services/sharedDataService";
import { Manatree } from "../../generated-models/Manatree";

@Component({
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewManatreeComponent extends AdminViewComponent<Manatree> implements OnInit {
  tableName = TableName.Manatree;
  columnDef = ManatreeColumnDefinitionForAdmin;

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    sharedDataService: SharedDataService,
    dataTypes: DataTypes
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.type = "Manatree";
    this.addRowEnable = true;
    this.addRowFunc = (data: Manatree) => apiService.addManatree(data).toPromise();
    this.editRowEnable = false;
    this.deleteRowEnable = false;
  }

  refreshData() {
    this.apiService.getManatrees().subscribe((response) => {
      this.rowData = response;
    });
  }
}
