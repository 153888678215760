import { Component } from "@angular/core";
import { IStatusPanelAngularComp } from "ag-grid-angular";
import { GridApi, GridOptions } from "ag-grid-community";

@Component({
  selector: "grid-page-size-setting-component",
  template: `
    <div class="form-group" *ngIf="isVisible()" style="margin-top: 10px">
      Page Size :
      <select style="margin-left: 5px;margin-right: 20px" [(ngModel)]="setPageSize" (ngModelChange)="onPageSizeChanged($event)">
        <option *ngFor="let o of pageOptions" [selected]="o.value == setPageSize">{{ o.name }}</option>
      </select>
    </div>
  `,
})

//
// Angular Header Component
// https://www.ag-grid.com/javascript-grid-header-rendering/#angular-header-component
//
export class CustomPageSizeSettingBarComponent implements IStatusPanelAngularComp {
  private gridApi: GridApi;
  private referenceContext: any;
  private shown = true;

  setPageSize = 15;
  pageOptions = [
    { name: "15", value: 15 },
    { name: "30", value: 30 },
    { name: "100", value: 100 },
  ];

  // equivalent of init in IHeaderComp
  // IHeaderCompParams is same as non Angular version
  agInit(params: GridOptions): void {
    this.gridApi = params.api;
    this.referenceContext = params.context;
  }

  onPageSizeChanged(selectedOptValue) {
    this.gridApi.paginationSetPageSize(Number(selectedOptValue));
  }

  isVisible(): boolean {
    return this.shown;
  }

  setVisible(flag) {
    this.shown = flag;
    this.referenceContext.autoAdjustGridHeight();
  }
}
