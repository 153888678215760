import { ColDef } from "ag-grid-community";

export const ArmResourceTypeColumnDefinition: ColDef[] = [
  { headerName: "Namespace", field: "Namespace" },
  { headerName: "Resource Type Name", field: "ResourceTypeName" },
  { headerName: "Location", field: "Location" },
  { headerName: "Feature To Assign", field: "FeatureToAssign" },
  { headerName: "Required Features", field: "RequiredFeatures" },
  { headerName: "Required Features Policy", field: "RequiredFeaturesPolicy" },
  { headerName: "Endpoint Uri", field: "EndpointUri" },
];
