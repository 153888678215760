import { Component } from "@angular/core";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import * as fileSaver from "file-saver";
import { Cloud, FieldMappings, Region, EdgeZone } from "../shared/model";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../shared/modal.service";
import { ModalService as LionrockModalService } from "../../shared/modal.service";
import { DataService } from "../shared/data.service";
import { BaseComponent } from "../shared/base.component";
import { ModuleService } from "../../shared/module.service";
import { AuthService } from "../../auth/auth.service";
import { ApiService } from "../shared/api.service";
import { Status } from "./model";

const JobTypeMap: Map<string, string> = new Map([
  ["NewRegionBillingMeter", "Initial"],
  ["IncrementalBillingMeter", "Incremental"],
  ["OnDemandBillingMeter", "On-Demand"],
  ["ResubmitBillingMeter", "On-Demand"],
  ["RingOnboardingFormBillingMeter", "Ring Onboarding Form"],
  ["AutomationBillingMeter", "Incremental"],
]);

@Component({
  selector: "app-home",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss", "../styles.scss"],
})
export class StatusComponent extends BaseComponent {
  cloudRegions: Region[];
  status: Status[] = null;
  isGridLoading: boolean;
  rowSelection = "multiple";
  product = "";
  jobId = "";
  fromDate: Date;
  fromDateMax: NgbDateStruct;
  toDate: Date;
  toDateMin: NgbDateStruct;
  toDateMax: NgbDateStruct;

  public filterByLatest = false;

  private gridApi;
  private gridColumnApi;

  columnDefs = [
    {
      headerName: "Region",
      field: "RegionName",
      checkboxSelection: false,
      headerCheckboxSelection: false,
      cellRenderer: "agGroupCellRenderer",
    },
    { headerName: "Edge Zone", field: "EdgeZoneName" },
    { headerName: "Product ID", field: "ProductOid" },
    { headerName: "Product Name", field: "ProductName" },
    { headerName: "Status", field: "Status" },
    { headerName: "MIX First Party Product ID", field: "CaymanProductId", hide: true },
    { headerName: "MIX First Party Product Name", field: "CaymanProductName" },
    { headerName: "Job ID", field: "JobId" },
    {
      headerName: "Buildout Type",
      valueGetter: (params) => {
        // https://msazure.visualstudio.com/One/_workitems/edit/7283560
        // return params.data.JobType === "NewRegionBillingMeter" ? "Initial" : "Incremental";
        if (JobTypeMap.has(params.data.JobType)) {
          return JobTypeMap.get(params.data.JobType);
        }
        return params.data.JobType;
      },
    },
    {
      headerName: "Created",
      field: "Created",
      valueFormatter: (params) => params.value == "" ? "" : moment(params.value).format("L LTS"),
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      cellRenderer: (params) => {
        if (params.data.JobType == "RingOnboardingFormBillingMeter") {
          return `<a target="_blank" href='https://global.azure.com/product-catalog/ring-onboarding/${params.data.CreatedBy}'>Ring Onboarding Form: ${params.data.CreatedBy}</a>`;
        }
        return params.data.CreatedBy;
      },
    },
    {
      headerName: "Last Updated Date",
      field: "Updated",
      valueFormatter: (params) => params.value == "" ? "" : moment(params.value).format("L LTS"),
    },
    { headerName: "Activity", valueGetter: () => "Billing Meter" },
    { headerName: "Message", field: "Message" },
    { headerName: "Status Tracking Id", field: "StatusTrackingId" },
    { headerName: "Location Type", field: "LocationType", hide: true },
    { headerName: "Location ID", field: "LocationId", hide: true },
  ];
  gridOptions = extendDefaultOptions({
    columnDefs: this.columnDefs,
    rowData: this.status,
    getRowHeight: (params) => {
      return this.onGetRowHeight(params);
    },
  });

  constructor(
    apiService: ApiService,
    private modalService: ModalService,
    private lionrockModalService: LionrockModalService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    route: ActivatedRoute,
    private moduleService: ModuleService
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
    this.isLoadingFinished = false;
    this.gridOptions.frameworkComponents = {};
    (this.gridOptions.isRowMaster = (item) => {
      return item.CaymanProductId;
    }),
      (this.gridOptions.detailCellRendererParams = {
        detailGridOptions: {
          defaultColDef: {
            filter: "agSetColumnFilter",
            resizable: true,
            sortable: true,
          },
          context: this,
          rowClass: "ticket-row",
          columnDefs: [], // column data is handled via successCallback
          onGridReady: function (params) {
            // make detailGrid height automatically
            params.api.setDomLayout("autoHeight");
          },
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
          },
        },
        getDetailRowData: (params) => {
          this.apiService.GetBillingMeterDetails(params.data.JobId, params.data.CaymanProductId).subscribe((response) => {
            if (params.node.detailGridInfo != null && response != null) {
              params.node.detailGridInfo.api.setColumnDefs(this.GenerateColumnDefs(response.columnDefs));
              params.data.requestData = response.data;
              params.node.setRowHeight(null); // set null to enable auto adjustment
              this.gridApi.onRowHeightChanged(); // this triggers getRowHeight silently
              params.successCallback(params.data.requestData);
            }
          });
        },
      });
    const now = moment();
    this.fromDateMax = {
      year: now.year(),
      month: now.month() + 1,
      day: now.date(),
    };
    this.toDateMax = {
      year: now.year(),
      month: now.month() + 1,
      day: now.date(),
    };
  }
  GenerateColumnDefs(columnMappings: FieldMappings[]) {
    const columnDefs = [];
    columnMappings.map((map) => {
      if (map.headerName === "Id") {
        columnDefs.push({
          headerName: map.headerName,
          field: map.field,
          checkboxSelection: false,
          cellRenderer: "agGroupCellRenderer",
        });
      } else if (map.headerName === "RegionNames") {
        columnDefs.push({
          headerName: map.headerName,
          field: map.field,
          cellRenderer: "regionNamesRenderer",
        });
      } else {
        columnDefs.push({
          headerName: map.headerName,
          field: map.field,
        });
      }
    });
    return columnDefs;
  }
  onInit(): void {
    this.initCloudRegions();
    this.dataService.initRegionEdgeZones();

    this.route.queryParams.subscribe((params) => {
      if (params.job_id) {
        this.jobId = params.job_id;
      }
    });

    if (this.dataService.selectedRegion || this.jobId != "") {
      this.onFetchRequestStatus();
    }
  }

  initCloudRegions(): void {
    this.cloudRegions = this.dataService.regions.filter(
      (region) =>
        this.dataService.selectedCloud &&
        region.CloudType === this.dataService.selectedCloud.CloudName &&
        ["Buildout", "Approved", "Production SLA", "Ring 0 Complete"].includes(region.State)
    );
  }

  onCloudChange(cloud: Cloud): void {
    this.dataService.changeCloud(cloud);
    this.initCloudRegions();
    // reset the service list.
    this.resetStatus();
  }

  onFromDateSelect(): void {
    this.toDateMin = {
      year: this.fromDate.getFullYear(),
      month: this.fromDate.getMonth() + 1,
      day: this.fromDate.getDate(),
    };
  }

  onToDateSelect(): void {
    this.fromDateMax = {
      year: this.toDate.getFullYear(),
      month: this.toDate.getMonth() + 1,
      day: this.toDate.getDate(),
    };
  }

  onRegionChange(): void {
    this.dataService.changeRegions();
    // reset the service list.
    this.resetStatus();
  }

  onEdgeZoneChanges(): void {
    this.dataService.changeEdgeZones();
    // reset the service list.
    this.resetStatus();
  }

  onFetchRequestStatus(): void {
    console.log("Fetch RequestStatus!");
    this.showLoadingOverlay();
    const regionNames = this.dataService.getStatusLocationNames();

    if (this.jobId != "" && !this.dataService.selectedCloud && !this.dataService.selectedRegions?.length) {
      this.apiService
        .GetRequestStatusByJobId(
          this.jobId,
          (this.fromDate && moment(this.fromDate).format("YYYY-MM-DD")) || "",
          (this.toDate && moment(this.toDate).format("YYYY-MM-DD")) || ""
        )
        .subscribe((status) => {
          this.status = <Status[]>status;
          this.hideOverlay();
        });
      return;
    }

    if (regionNames?.length) {
      this.apiService
        .GetRequestStatus(
          regionNames,
          this.product,
          (this.fromDate && moment(this.fromDate).format("YYYY-MM-DD")) || "",
          (this.toDate && moment(this.toDate).format("YYYY-MM-DD")) || ""
        )
        .subscribe((status) => {
          this.status = <Status[]>status;
          this.hideOverlay();
        });
      return;
    }

    this.status = [];
    this.hideOverlay();
  }

  private resetStatus() {
    this.status = null;
    this.filterByLatest = false;
  }

  private hideOverlay() {
    this.isGridLoading = false;
    if (this.gridApi) {
      this.gridApi.hideOverlay();
    }
  }

  private showLoadingOverlay() {
    if (this.gridApi) this.gridApi.showLoadingOverlay();
    else this.isGridLoading = true;
  }

  onInScopeServicesGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.gridColumnApi;
    if (!this.isGridLoading) this.status = null;
    console.log("grid ready");
  }

  resubmitBuildoutTriggered(): void {
    const selectedStatus = this.gridApi.getSelectedRows();
    const confirmation = this.modalService.onResubmitPrebuildoutConfirmModal(
      this.dataService.getStatusLocationNames(),
      selectedStatus
    );
    confirmation.then((confirmed) => {
      console.log("User confirmed:", confirmed);
      if (confirmed) {
        this.apiService.PostResubmitPreBuildout(selectedStatus, this.user).subscribe(
          (res) => {
            console.log(res);
            const r = <[{ jobId: string }]>res;
            if (r instanceof Array) {
              this.lionrockModalService.informationModal(
                `Resubmit Pre-buildout request has been submitted successfully! JobId: ${r.map((i) => i.jobId).join(",")}`
              );
            } else {
              this.lionrockModalService.informationModal(`Resubmit Pre-buildout request is failed: ${r}`);
            }
          },
          () => {
            this.lionrockModalService.informationModal(`Resubmit Pre-buildout request has been failed! Message: The jobs is empty`);
          }
        );
      }
    });
    console.log(this.gridApi.getSelectedRows());
  }

  downloadFullReport(): void {
    this.loadingScreenService.setLoading(true);
    this.apiService.downloadFullReport().subscribe((res) => {
      this.loadingScreenService.setLoading(false);
      const blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      fileSaver.saveAs(blob, "report.xlsx");
    });
  }

  isResubmitBuildoutButtonDisabled(): boolean {
    return this.status == null || this.status.length === 0;
  }

  isUserAuthorized(): boolean {
    return this.isAuthorizedUser;
  }
  onLatestFilterSwitched(): void {
    this.filterByLatest = !this.filterByLatest;
    const filterInstance = this.gridApi.getFilterInstance("JobId");
    let filterValues = filterInstance.valueModel.allValues;
    console.log(filterValues);
    if (!filterValues || filterValues.length === 0) {
      return;
    }
    if (this.filterByLatest) {
      filterValues = [`${Math.max(...filterValues)}`];
    }
    filterInstance.setModel({
      type: "set",
      values: filterValues,
    });
    this.gridApi.onFilterChanged();
  }

  onExportExcel(): void {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "Request Details",
      sheetName: "Request Details",
    };
    this.gridApi.exportDataAsExcel(params);
  }

  onExportExcelWithDetails(): void {
    console.log("Export details.");
    const req = [];
    this.gridApi.forEachNodeAfterFilter((row) => {
      req.push(row.data);
      console.log(row.data.StatusTrackingId);
    });
    console.log(req.length);
    this.loadingScreenService.setLoading(true);
    this.apiService.exportExcelWithDetails(req).subscribe(
      (res) => {
        this.loadingScreenService.setLoading(false);
        const blob: Blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileSaver.saveAs(blob, "RequestsWithDetails.xlsx");
      },
      (err) => {
        this.loadingScreenService.setLoading(false);
        console.error(err);
      }
    );
  }
}
