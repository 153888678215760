<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Add an override plan file</h2>
  </div>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body" style="height: 260px">
      <div>
        <span>Select an override level</span>
        <div class="col-form-label">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="level1"
              name="overrideLevel"
              (change)="onOverrideLevelChanged($event)"
              [(ngModel)]="level2Mode"
              [value]="false" />
            <label class="custom-control-label" for="level1">Level 1<br />(Cloud or Region Type)</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="level2"
              name="overrideLevel"
              (change)="onOverrideLevelChanged($event)"
              [(ngModel)]="level2Mode"
              [value]="true" />
            <label class="custom-control-label" for="level2" >Level 2<br />(Region Name)</label>
          </div>
        </div>
        <label style="width: 100%">
          <ng-select
            class="mt-2"
            bindLabel="label"
            [(ngModel)]="selectedFileName"
            [items]="availableFileNames"
            groupBy="type"
            name="fileName">
          </ng-select>
        </label>
      </div>
      <div class="mt-2">
        <span>Select an override mode</span>
        <div class="col-form-label">
          <div class="custom-control custom-radio custom-control-inline w-25">
            <input
              class="custom-control-input"
              type="radio"
              id="Replace"
              [(ngModel)]="mergeMode"
              name="overrideMode"
              [value]="false" />
            <label class="custom-control-label" ngbTooltip="Replace the plan file at the level you override" for="Replace">Replace&nbsp;<i class="fa fa-info-circle"></i></label>
          </div>
          <div class="custom-control custom-radio custom-control-inline w-25">
            <input
              class="custom-control-input"
              type="radio"
              id="merge"
              [(ngModel)]="mergeMode"
              name="overrideMode"
              [value]="true" />
            <label class="custom-control-label" ngbTooltip="Merge with the plan files at higher levels in the hierarchy" for="merge" >Merge&nbsp;<i class="fa fa-info-circle"></i
              ></label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid">OK</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close()">Cancel</button>
    </div>
  </form>
</div>
