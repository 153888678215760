import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./plan-reject-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../plan.scss"],
})
export class PlanRejectModalComponent implements OnInit {
  comment: string;

  constructor(public activeModal: NgbActiveModal) {}

  async ngOnInit() {}

  async submit() {
    const result = this.comment;
    this.activeModal.close(result);
  }
}
