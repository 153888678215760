import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ApiService } from "../../services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { VmSize } from "src/app/generated-models/VmSize";
import { VmSizeColumnDefinition } from "./ag-grid-column-definition";

@Component({
  selector: "app-admin-view-table-view",
  templateUrl: "./plans-information-vm-sizes.component.html",
  styleUrls: ["../../styles.scss"],
})
export class PlansInformationVmSizesComponent extends GridViewBaseComponent implements OnInit {
  gridOptions: GridOptions;
  rowData: VmSize[] = [];
  initPageSize: 15;
  keyFilterStats: string;

  public gridApi: GridApi;
  public columnApi: ColumnApi;

  constructor(
    protected apiService: ApiService,
    protected notificationService: ToastrService,
    protected loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit(): void {
    super.InitAgGrid(VmSizeColumnDefinition);
  }

  refreshData(): void {
    this.loadingService.setLoading(true);
    this.apiService.getVmSizes().subscribe(
      (response: VmSize[]) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
  }
}
