<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Capacity SubOrder Detail</h4>
      <p>See capacity suborder details</p>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="loading-main" *ngIf="subOrderDetail">
    <div class="top-panel" style="height: fit-content">
        <ul class="list-container col-md-10">
            <li class="flex-box mb-3">
                <div class="col-md-2 label">Order Id</div>
                <div class="col-md-10 value">{{ orderId }}</div>
            </li>
            <li class="flex-box mb-3">
                <div class="col-md-2 label">SubOrder Id</div>
                <div class="col-md-10 value">{{ subOrderId }} </div>
            </li>
            <li class="flex-box mb-3">
                <div class="col-md-2 label">Resource Provider</div>
                <div class="col-md-10 value">{{ subOrderDetail.ResourceProvider }}</div>
            </li>
            <li class="flex-box mb-3">
                <div class="col-md-2 label">Location</div>
                <div class="col-md-10 value">{{ subOrderDetail.Location }}</div>
            </li>
            <li class="flex-box mb-3">
                <div class="col-md-2 label">Resource Name</div>
                <div class="col-md-10 value">{{ subOrderDetail.ResourceName }}</div>
            </li>
            <li class="flex-box mb-3">
                <div class="col-md-2 label">Limits</div>
                <div class="col-md-10 value">{{ subOrderDetail.Limits }}</div>
            </li>
            <li class="flex-box mb-3">
                <div class="col-md-2 label">Status</div>
                <div class="col-md-10 value">{{ subOrderDetail.Status }}</div>
            </li>
        </ul>
    </div>

    <div *ngIf="this.haveMessage">
        <h5>Discussion</h5>
        <hr />
        <div class="communication-modal">
            <div *ngFor="let message of messages">
                <b>{{ message.CreatedBy }}</b>
                <p>{{ message.CreatedTime }}</p>
                <div [innerHTML]="message.Content?.replace('\n', '<br />')"></div>
            </div>
        </div>

        <div class="col-md-10 form-inline" style="display:flex;align-items:start;">
            <textarea style="vertical-align:top;" class="form-control col-md-10" name="inputMessage" required [(ngModel)]="newMessage" placeholder="{{placeHolder}}">
</textarea> &nbsp;
        </div>

        <div class="col-md-10 form-inline" style="display:flex;align-items:start;">
            <button style="vertical-align:top;" type="submit" class="btn btn-primary form-control col-md-1" [disabled]="!isValidMessage()" (click)="saveNewMessage()">Submit</button>
        </div>
    </div>
  </div>
</div>
