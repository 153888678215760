import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AgsAuthService } from "@ags/core";

import { LoadingScreenService } from "../shared/loading-screen.service";
import { ApiService } from "../services";
import { Roles, IUserProfile } from "../npr-request.model";
import { Observable } from "rxjs/internal/Observable";
import { SharedDataService } from "../services/sharedDataService";
import { Subscription } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LandingGuard implements CanActivate {
  constructor(private readonly authService: AgsAuthService, private readonly loadingScreenService: LoadingScreenService) { }

  async canActivate() {
    if (this.authService.getAccount()) {
      this.authService.acquireToken();
      return true;
    }

    this.loadingScreenService.setLoading(true);
    this.authService.acquireToken();
    return false;
  }
}

/** TODO(lingc): refactor this file.
 * 1. Here are multiple classes named like IsXXXRole. The code logic is highly duplicated in those classes
 * 2. The logic to retrieve the userProfile is very tricky. We use to have a problem that our UI will initiate duplicated api calls to get the user profile. 
 *    The sharedDataService is to resolve the duplication, but the fix make the code hard to read 
 * */


class CanActivateWithUserProfile implements CanActivate {
  private subscription: Subscription;
  constructor(private apiService: ApiService, private router: Router, private sharedDataService: SharedDataService) { }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (!ApiService.userProfile) {
        this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
          const userProfile = data;
          if (userProfile && this.canUserActivate(userProfile)) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      } else {
        this.apiService.getUserProfile().then((response) => {
          const userProfile = response;
          if (userProfile && this.canUserActivate(userProfile)) {
            resolve(true);
          } else {
            // Just return to new request page.
            this.router.navigate(["create-request"]);
            resolve(false);
          }
        });
      }
    });
  }

  canUserActivate(userProfile: IUserProfile): boolean {
    return !!userProfile;
  }
}

@Injectable({
  providedIn: "root",
})
export class IsAdmin extends CanActivateWithUserProfile {
  constructor(apiService: ApiService, router: Router, sharedDataService: SharedDataService) {
    super(apiService, router, sharedDataService)
  }
  override canUserActivate(userProfile: IUserProfile) {
    return userProfile?.IsAdmin;
  }
}


@Injectable({
  providedIn: "root",
})
export class IsOperator extends CanActivateWithUserProfile {
  constructor(apiService: ApiService, router: Router, sharedDataService: SharedDataService) {
    super(apiService, router, sharedDataService)
  }
  override canUserActivate(userProfile: IUserProfile) {
    return userProfile?.IsOperator;
  }
}

@Injectable({
  providedIn: "root",
})
export class IsApproverAdmin extends CanActivateWithUserProfile {
  constructor(apiService: ApiService, router: Router, sharedDataService: SharedDataService) {
    super(apiService, router, sharedDataService)
  }
  override canUserActivate(userProfile: IUserProfile) {
    return userProfile?.IsApproverAdmin;
  }
}

@Injectable({
  providedIn: "root",
})
export class IsApprover extends CanActivateWithUserProfile {
  constructor(apiService: ApiService, router: Router, sharedDataService: SharedDataService) {
    super(apiService, router, sharedDataService)
  }

  override canUserActivate(userProfile: IUserProfile) {
    return userProfile?.IsApprover;
  }
}

@Injectable({
  providedIn: "root",
})
export class IsGETApprover extends CanActivateWithUserProfile {
  constructor(apiService: ApiService, router: Router, sharedDataService: SharedDataService) {
    super(apiService, router, sharedDataService)
  }
  override canUserActivate(userProfile: IUserProfile) {
    return userProfile?.IsGETApprover;
  }
}

@Injectable({
  providedIn: "root",
})
export class IsPfAdmin extends CanActivateWithUserProfile {
  constructor(apiService: ApiService, router: Router, sharedDataService: SharedDataService) {
    super(apiService, router, sharedDataService)
  }
  override canUserActivate(userProfile: IUserProfile) {
    return userProfile?.IsPfAdmin;
  }
}

@Injectable({
  providedIn: "root",
})
export class IsTestOrDevEnvironment implements CanActivate {
  constructor() { }

  async canActivate() {
    if (environment.production) {
      return false;
    }
    return true;
  }
}
