import { Component } from "@angular/core";
import { ApiService } from "../shared/api.service";
import { AuthService } from "../../auth/auth.service";
import { Product } from "./model";
import { Cloud } from "../shared/model";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../shared/modal.service";
import { ModalService as LionrockModalService } from "../../shared/modal.service";
import * as _ from "lodash";
import { DataService } from "../shared/data.service";
import { ActivatedRoute } from "@angular/router";
import { ModuleService } from "../../shared/module.service";
import { BaseComponent } from "../shared/base.component";
import { extendDefaultOptions } from "src/app/utility/gridHelper";

@Component({
  selector: "app-home",
  templateUrl: "./ondemand.component.html",
  styleUrls: ["./ondemand.component.scss", "../styles.scss"],
})
export class OnDemandComponent extends BaseComponent {
  products: Product[];
  selectedProducts: Product[] = [];
  isGridLoading: boolean;
  productMessage: string;
  product = "";
  errorMessage = "";

  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: "Region", field: "RegionName", sort: "asc" },
    { headerName: "Product ID", field: "ProductOid", width: 300, sort: "asc" },
    { headerName: "Product Ring", field: "ProductRing" },
    { headerName: "Product Name", field: "ProductName" },
    { headerName: "BigID", field: "CaymanProductId" },
    { headerName: "MIX First Party Product Name", field: "CaymanProductName" },
    { headerName: "Location Type", field: "LocationType", hide: true },
  ];
  gridOptions = extendDefaultOptions({
    columnDefs: this.columnDefs,
    rowData: this.selectedProducts,
  });

  constructor(
    apiService: ApiService,
    private modalService: ModalService,
    private lionrockModalService: LionrockModalService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    route: ActivatedRoute,
    private moduleService: ModuleService
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
  }

  async onInit() {
    this.dataService.selectedRegions = this.dataService.selectedRegions.filter((r) => r.EnabledOnDemand);
  }

  onCloudChange(cloud: Cloud) {
    this.dataService.changeCloud(cloud);
  }

  onRegionChange() {
    this.dataService.changeRegions();
  }

  onEdgeZoneChanges() {
    this.dataService.changeEdgeZones();
  }

  onInScopeServicesGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.gridColumnApi;
    if (!this.isGridLoading) this.selectedProducts = [];
    console.log("grid ready");
  }

  async AddProductPreview() {
    this.errorMessage = "";
    await this.loading((finish) => {
      this.apiService.GetProductList(this.dataService.getOnDemandLocationNames(), this.product).subscribe(
        (products) => {
          if (_.isEmpty(products)) {
            this.productMessage = "Product Not Found";
            finish();
            return;
          }
          this.productMessage = null;
          this.products = <Product[]>products;
          const confirmation = this.modalService.onDemandPrebuildoutConfirmModal(
            this.dataService.getOnDemandLocationNames(),
            this.products
          );
          finish();
          confirmation.then((confirmed) => {
            console.log("User confirmed:", confirmed);
            if (confirmed?.length) {
              this.selectedProducts = _.uniqWith(_.concat(this.selectedProducts, confirmed), _.isEqual);
            }
          });
        },
        (err) => {
          this.errorMessage = err;
          finish();
        }
      );
    });
  }

  onClearProductList() {
    this.selectedProducts = [];
  }

  async onDemandBuildoutTriggered() {
    await this.loading((finish) => {
      this.apiService.PostOnDemandPreBuildout(this.selectedProducts, this.user).subscribe(
        (res) => {
          this.selectedProducts = [];
          const r = <[{ jobId: string }]>res;
          finish();
          if (r instanceof Array) {
            this.lionrockModalService.informationModal(
              `On Demand Pre-buildout request has been submitted successfully! JobId: ${r.map((i) => i.jobId).join(",")}`
            );
          } else {
            this.lionrockModalService.informationModal(`On Demand Pre-buildout request is failed: ${r}`);
          }
        },
        () => {
          finish();
          this.lionrockModalService.informationModal(
            "Your request cannot be submitted at this time since the selected products are missing MIX First Party Mappings.<br />" +
              'Please reach out to <a href="mailto:azure1ppinfosys@microsoft.com">azure1ppinfosys@microsoft.com</a> for help adding the mapping for your SKU’s MIX Product and BIGID before proceeding forward.'
          );
        }
      );
    });
  }

  isOnDemandBuildoutButtonDisabled() {
    return this.selectedProducts == null || this.selectedProducts.length === 0;
  }

  onExportExcel() {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "Request Status",
      sheetName: "Request Status",
    };
    this.gridApi.exportDataAsExcel(params);
  }
}
