import { ChangeDetectorRef, Component, OnInit, ViewRef } from "@angular/core";
import { Router } from "@angular/router";
import {
  IUserProfile,
  IUIRequest,
  DeploymentScope,
  IRequestVmSku,
  IRequestDetails,
  ISqlQuota,
  IStorageAccountQuota,
  SqlMiType,
  IRequestDetailsInfo,
  IDnsEntryQuota,
  SqlPurchaseModelType,
  BatchRequestType,
  IBatchQuota,
  IPerAccountQuota,
  IBatchSku,
  IBasicInfo,
  IDetailsInfo,
  ISummaryInfo,
  IRequestRegion,
  ICosmosDbQuota,
  IJustification,
  EnableType,
  IRPFrontloadInfo,
  IDnsZoneQuota,
} from "../../npr-request.model";
import { SharedDataService } from "../../services/sharedDataService";
import { AuthService } from "../../auth/auth.service";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../../shared/modal.service";
import { BaseComponent } from "../../shared/base.component";
import { CreateRequestService } from "./create-request.service";
import { DateColumnFormatter, PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { Subscription } from "rxjs";
import { ApiService } from "../../services";

@Component({
  templateUrl: "./create-request.component.html",
  styleUrls: ["../../styles.scss", "./create-request.scss"],
})
export class CreateRequestComponent extends BaseComponent implements OnInit {
  step = 0;
  region: IRequestRegion;
  isAdmin = false;
  userProfile: IUserProfile;
  basicInfo: IBasicInfo;
  detailsInfo: IDetailsInfo;
  summaryInfo: ISummaryInfo;
  currentSteps: string[] = [];
  subscription: Subscription;

  constructor(
    private readonly router: Router,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private modalService: ModalService,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private sharedDataService: SharedDataService,
    public service: CreateRequestService
  ) {
    super();
    this.service.initBasicInfo();
    this.basicInfo = this.service.basicInfo;
    this.detailsInfo = this.service.detailsInfo;
    this.summaryInfo = this.service.summaryInfo;
    if (ApiService.userProfile) {
      this.isAdmin = ApiService.userProfile.IsAdmin;
    } else {
      this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
        if (data) {
          this.isAdmin = data.IsAdmin;
        } else {
          this.isAdmin = false;
        }
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.setLoading(true);
    this.basicInfo.email = (await this.auth.getUser())?.username;
    this.service.getTeamList();
    this.service.getRegionList();
    this.service.getSKUList();
    this.service.GetCosmosDBAzRestrictedRegions();
    this.service.getNameSpaceList();
    this.loadingService.setLoading(false);
  }

  validate(): void {
    const newInput: IUIRequest = {} as IUIRequest;
    newInput.Details = {} as IRequestDetails;
    newInput.Details.Enable = [];
    newInput.Details.VmSkus = [];
    newInput.Details.SqlQuota = {} as ISqlQuota;
    newInput.Details.SqlDtuQuota = {};
    newInput.Details.StorageAccountQuota = {} as IStorageAccountQuota;
    newInput.Details.VMQuota = {};
    newInput.Details.VmZones = {};
    newInput.Details.Info = {} as IRequestDetailsInfo;
    newInput.Details.BatchQuota = {} as IBatchQuota;
    newInput.Details.CosmosDbQuota = {} as ICosmosDbQuota;
    newInput.Details.RPFrontload = [] as IRPFrontloadInfo[];
    newInput.Details.ArmResourceTypes = [];
    newInput.Details.VmDisksQuota = {};

    newInput.Email = this.basicInfo.email;
    newInput.IsExternal = this.basicInfo.isExternal;
    if (this.basicInfo.isExternal) {
      newInput.Requestor = this.basicInfo.customer;
    } else {
      newInput.Requestor = this.basicInfo.team.label;
      newInput.TeamOid = this.basicInfo.team.oid;
      if (newInput.Requestor === "Other") {
        newInput.Requestor += "-" + this.basicInfo.otherTeam;
        newInput.TeamOid = this.basicInfo.otherTeamOid;
      }
    }
    newInput.SubscriptionId = this.basicInfo.subId.trim();
    newInput.Region = this.basicInfo.region.label;
    newInput.IsFlighting = this.service.isFlighting;

    // assign enable type AZ
    if (this.detailsInfo.azEnablement) {
      newInput.Details.Enable.push(EnableType.AZ);
      newInput.Details.Info.Zones = this.detailsInfo.zones.join(",");
    }

    // ARM Access with 0 quota
    if (this.detailsInfo.enableArmWithNoQuota) {
      newInput.Details.EnableArmWithNoQuota = true;
    } else {
      newInput.Details.EnableArmWithNoQuota = false;
      // assign enable type RDFE/ARM
      if (this.detailsInfo.regionEnablement) {
        if (this.detailsInfo.regionEnablementRDFE) {
          newInput.Details.Enable.push(EnableType.RDFE);
        }
        if (this.detailsInfo.regionEnablementARM) {
          newInput.Details.Enable.push(EnableType.ARM);
        }
      }

      // SQL
      if (this.detailsInfo.sql) {
        newInput.Details.Enable.push(EnableType.SQL);
      }

      // SQL DTU quota
      if (this.detailsInfo.sqlDtu) {
        newInput.Details.SqlDtuQuota[this.detailsInfo.sqlServerName] = this.detailsInfo.sqlDtuQuota;
      }

      // SQL Server quota
      if (this.detailsInfo.sqlServer) {
        newInput.Details.SqlQuota.SQLServerCount = this.detailsInfo.sqlServerQuota;
      }

      // SQLMI
      if (this.detailsInfo.sqlMI) {
        let totalSqlMiQuota = 0;
        let totalSqlMiSubnet = 1;
        switch (this.detailsInfo.sqlMiType) {
          case SqlMiType.Default:
            totalSqlMiQuota = 24;
            break;
          case SqlMiType.TotalvCores:
            totalSqlMiQuota = this.detailsInfo.sqlMiTotalvCores;
            totalSqlMiSubnet = this.detailsInfo.sqlMiSubNet;
            break;
          case SqlMiType.DetailInstances:
            totalSqlMiQuota =
              this.detailsInfo.sqlMiGPvCores * this.detailsInfo.sqlMiGPCount +
              this.detailsInfo.sqlMiBCvCores * this.detailsInfo.sqlMiBCCount;
            totalSqlMiSubnet = this.detailsInfo.sqlMiSubNet;
            break;
        }
        newInput.Details.SqlQuota.SqlMiSubNet = totalSqlMiSubnet;
        newInput.Details.SqlQuota.SQLMI = totalSqlMiQuota;
      }

      // HDI quota
      if (this.detailsInfo.hdi) {
        newInput.Details.HdiQuota = this.detailsInfo.hdiQuota;
        newInput.Details.Info.HdiSku = this.detailsInfo.hdiSku.value;
      }

      // DNS
      if (this.detailsInfo.dns.IsSubsriptionQuota || this.detailsInfo.dns.IsZoneQuota) {
        const perZoneQuotas: { [key: string]: IDnsZoneQuota } = {};
        if (this.detailsInfo.dns?.zoneName && this.detailsInfo.dns?.resourceGroup) {
          perZoneQuotas[this.detailsInfo.dns.zoneName] = {
            resourceGroup: this.detailsInfo.dns.resourceGroup,
            zoneRecordSetQuota: this.detailsInfo.dns?.zoneRecordSetQuota ?? 0,
          };
        }

        newInput.Details.DnsEntryQuota = {
          quota: this.detailsInfo.dns?.maxZoneQuota ?? 0,
          recordSetQuota: this.detailsInfo.dns?.maxRecordSetQuota ?? 0,
          perZoneQuotas: perZoneQuotas
        } as IDnsEntryQuota;
      }

      // Batch total accounts
      if (this.detailsInfo.requireBatchAccounts) {
        newInput.Details.BatchQuota.TotalAccounts = this.detailsInfo.batchTotalAccounts > 0 ? this.detailsInfo.batchTotalAccounts : 0;
      }

      // Batch
      if (this.detailsInfo.requireBatchComputeQuota) {
        let lowPriorityQuota = 0;
        let poolQuota = 0;
        let jobQuota = 0;
        const accountName = this.detailsInfo.batchAccountName;
        const vmQuota = {} as { [key: string]: number };
        this.detailsInfo.batchComputeQuota.map((q: IBatchSku) => {
          switch (q.requestType) {
            case BatchRequestType.Dedicated:
              vmQuota[q.sku] = q.quota;
              break;
            case BatchRequestType.LowPriority:
              lowPriorityQuota = q.quota;
              break;
            case BatchRequestType.PoolQuota:
              poolQuota = q.quota;
              break;
            case BatchRequestType.JobQuota:
              jobQuota = q.quota;
              break;
          }
        });

        newInput.Details.BatchQuota.PerAccountQuotas = {} as { [key: string]: IPerAccountQuota };
        newInput.Details.BatchQuota.PerAccountQuotas[accountName] = {
          LowPriorityCoreQuota: lowPriorityQuota,
          PoolQuota: poolQuota,
          VmQuota: vmQuota,
          JobQuota: jobQuota,
        } as IPerAccountQuota;
      }

      // storage quota
      if (this.detailsInfo.additionalStorage) {
        if (this.detailsInfo.storageQuotaARM) {
          newInput.Details.StorageAccountQuota.ARM = this.detailsInfo.storageQuotaARM;
        }
        if (this.detailsInfo.storageQuotaRDFE) {
          newInput.Details.StorageAccountQuota.RDFE = this.detailsInfo.storageQuotaRDFE;
        }
      }

      // SQL
      if (this.detailsInfo.sql && this.basicInfo.region.isGA) {
        if (this.detailsInfo.sqlPurchaseModel === SqlPurchaseModelType.VCores) {
          newInput.Details.SqlQuota.SQL = this.detailsInfo.sqlQuota;
        } else if (this.detailsInfo.sqlPurchaseModel === SqlPurchaseModelType.DTUs) {
          newInput.Details.SqlQuota.SQL_ByDtu = this.detailsInfo.sqlQuota;
        }
      }

      // Deployment Scope
      if (this.detailsInfo?.requireComputeArmVmQuota) {
        newInput.Details.DeploymentScope = this.detailsInfo.deploymentScope;
      }

      // Compute arm vm quota
      if (this.detailsInfo?.requireComputeArmVmQuota) {
        const VMQuota = this.detailsInfo.vmQuota;
        VMQuota.map((vm: IRequestVmSku) => {
          if (vm.checked && vm.quota) {
            newInput.Details.VMQuota[vm.value] = vm.quota;
          } else {
            newInput.Details.VmSkus.push(vm.value);
          }
          if (this.detailsInfo.deploymentScope === DeploymentScope.Zonal) {
            if (vm.zones?.length > 0) {
              newInput.Details.VmZones[vm.value] = vm.zones;
            }
            else {
              // request access to all zones if leave empty
              newInput.Details.VmZones[vm.value] = this.detailsInfo.azEntities;
            }
          }
        });
      }

      // Compute arm vm shared quota
      if (this.detailsInfo.requireComputeArmSharedQuota) {
        newInput.Details.VMQuota["*"] = this.detailsInfo.computeArmSharedQuota;
      }

      // Compute arm low priority quota
      if (this.detailsInfo.requireComputeArmLowPriorityQuota) {
        newInput.Details.VMQuota["Low Priority"] = this.detailsInfo.computeArmLowPriorityQuota;
      }

      // Compute rdfe vm quota
      if (this.detailsInfo.requireComputeRdfeVmQuota) {
        newInput.Details.RdfeVmQuota = this.detailsInfo.computeRdfeVmQuota;
        newInput.Details.Info.RdfeVmSku = this.detailsInfo.rdfeVmSku.value;
      }

      // Kusto Access
      if (this.detailsInfo.kusto) {
        newInput.Details.Enable.push(EnableType.Kusto);
      }

      // CosmosDB Access
      if (this.detailsInfo.cosmosDB.IsAccess) {
        newInput.Details.Enable.push(EnableType.CosmosDB);
      }

      // CosmosDB AZ Enablement
      if (this.detailsInfo.cosmosDB.IsAzEnablement) {
        newInput.Details.Enable.push(EnableType.CosmosDBAz);
      }

      // CosmosDB Per Subscription
      if (this.detailsInfo.cosmosDB.IsSubscriptionAccounts) {
        newInput.Details.CosmosDbQuota.SubscriptionAccounts = this.detailsInfo.cosmosDB.SubscriptionAccounts;
      }

      // CosmosDB Account Quota
      if (this.detailsInfo.cosmosDB.IsAccountQuota) {
        newInput.Details.CosmosDbQuota.PerAccountQuotas = {};
        newInput.Details.CosmosDbQuota.PerAccountQuotas[this.detailsInfo.cosmosDB.AccountName] = {
          ThroughputLimit: 0,
          StorageQuota: 0,
        };

        const quotas = { ...this.detailsInfo.cosmosDB.AccountQuotas };
        Object.keys(quotas).map((key) => (quotas[key] = quotas[key] || 0));
        this.detailsInfo.cosmosDB.AccountQuotas.map((q) => {
          newInput.Details.CosmosDbQuota.PerAccountQuotas[this.detailsInfo.cosmosDB.AccountName][q.sku] = q.quota;
        });
      }

      // App service quota
      if (this.detailsInfo.requireAppServiceQuota) {
        newInput.Details.AppServiceQuotas = {};
        this.detailsInfo.appServiceQuota.forEach((item) => {
          newInput.Details.AppServiceQuotas[item.offering] = {
            WindowsVmQuota: item.windowsVmQuota > 0 ? item.windowsVmQuota : 0,
            LinuxVmQuota: item.linuxVmQuota > 0 ? item.linuxVmQuota : 0,
          };
        });
      }

      // RP Access
      if (this.detailsInfo.rpFrontload) {
        newInput.Details.Enable.push(EnableType.RPFrontload);
        newInput.Details.RPFrontload.push({ Namespace: this.detailsInfo.namespaces, ServiceTreeId: this.detailsInfo.serviceTreeId });
      }

      if (this.detailsInfo.armResourceTypes) {
        newInput.Details.ArmResourceTypes = this.detailsInfo.armResourceTypes;
      }

      // VM Disks quota
      if (this.detailsInfo.requireVmDisks) {
        newInput.Details.VmDisksQuota[this.detailsInfo.vmDisksSku?.value] = this.detailsInfo.vmDisksQuota;
      }
    }

    this.modalService.validateRequestModal(newInput, 2).then(
      (r) => {
        this.service.uiRequest = newInput;
        this.step += 1;
        this.summaryInfo.pamApprovalState = r;

        // fix ViewDestroyedError: Attempt to use a destroyed view: detectChanges
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
        }
      },
      () => {
        // log error in modal.service.ts
      }
    );
  }

  submitRequest(): void {
    // quota request
    this.service.uiRequest.Details.Info.Justification = this.mergeJustification(this.summaryInfo.justification);
    this.service.uiRequest.UtilizeDate = this.summaryInfo.utilizeDate;
    if (!this.service.uiRequest.Details.DeploymentScope) {
      this.service.uiRequest.Details.DeploymentScope = DeploymentScope.Empty;
    }
    const allUIRequests = [this.service.uiRequest];

    if (this.summaryInfo.subIds) {
      let dedupedSubIds = [];
      const allSubIds: string[] = this.summaryInfo.subIds?.split(",").map((item: string) => item.trim());
      dedupedSubIds = [...new Set(allSubIds)];

      for (const [, sub] of dedupedSubIds.entries()) {
        allUIRequests.push({ ...this.service.uiRequest, SubscriptionId: sub });
      }
    }

    this.loadingService.setLoading(false);
    this.modalService
      .createRequestResponseModal(allUIRequests)
      .then(() => {
        this.router.navigate(["/request-status"]);
      })
      .catch(() => {
        // doing nothing here;
      });
  }

  prev(): void {
    this.step--;
  }

  validateBasicInfo(): void {
    const newInput: IUIRequest = {} as IUIRequest;
    newInput.Details = {} as IRequestDetails;

    newInput.Email = this.basicInfo.email;
    newInput.IsExternal = this.basicInfo.isExternal;
    if (this.basicInfo.isExternal) {
      newInput.Requestor = this.basicInfo.customer;
    } else {
      newInput.Requestor = this.basicInfo.team.label;
      newInput.TeamOid = this.basicInfo.team.oid;
      if (newInput.Requestor === "Other") {
        newInput.Requestor += "-" + this.basicInfo.otherTeam;
        newInput.TeamOid = this.basicInfo.otherTeamOid;
      }
    }
    newInput.SubscriptionId = this.basicInfo.subId.trim();
    newInput.Region = this.basicInfo.region.label;
    newInput.IsFlighting = this.service.isFlighting;
    this.modalService.validateRequestModal(newInput, 1).then(
      () => {
        if (this.basicInfo.region.label !== this.region?.label) {
          this.service.initDetailsInfo();
          this.detailsInfo = this.service.detailsInfo;
        }
        this.region = this.basicInfo.region;
        this.step += 1;
      },
      () => {
        // Doing nothing here
      }
    );
  }

  get steps(): string[] {
    if (this.basicInfo && this.basicInfo.customerRequest && this.basicInfo.isExternal) {
      return ["basicInfo", "summaryInfo"];
    }
    return ["basicInfo", "detailsInfo", "summaryInfo"];
  }

  mergeJustification(justification: IJustification[]): string {
    let justificationStr = "";

    // just return justification from textarea instead of questions list
    if (justification.length === 1) {
      return justification[0].Answer;
    }
    justification.forEach((s) => {
      if (s.Answer) {
        if (s.Type === "date") {
          s.Answer = PreprocessDateTime(s.Answer, DateColumnFormatter);
        }
        justificationStr += `${s.Question} ${s.Answer}\n`;
      } else {
        justificationStr += `${s.Question} Not answered\n`;
      }
    });

    return justificationStr;
  }

  getCustomerSubscriptions(): string {
    if (this.basicInfo.subscriptionsByCSV) {
      return this.basicInfo.subscriptions;
    } else {
      return this.basicInfo.subId;
    }
  }
}
