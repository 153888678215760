import { agDateColumnFilter } from "src/app/utility/gridHelper";
import { AllRequestedItemsColumnDefinition } from "../operator-review/ag-grid-column-definition";

export const SubRequestWithTicketInfoColumnDefinition = [
  ...AllRequestedItemsColumnDefinition,
  {
    headerName: "Ticket ID",
    field: "TicketId",
    cellRenderer: (params) => {
      if (!params.value) {
        return "";
      }
      const id: string = params.value.toString();
      if (id === "-1") {
        return id;
      }
      return `<a href="${params.data.Url}" rel="noopener noreferrer" target="_blank">${id}</a>`;
    },
  },
  {
    headerName: "Ticket Status", field: "TicketState",
  },
  {
    headerName: "Ticket Created Time",
    field: "TicketCreatedTime",
    filter: "agDateColumnFilter",
    filterParams:{
      comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue)
    },
  },
  {
    headerName: "Ticket Finished Time",
    field: "TicketFinishedTime",
    filter: "agDateColumnFilter",
    filterParams:{
      comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue)
    },
  },
];
