export interface KeyValuePair<TKey, TValue> {
  key: TKey;
  value: TValue;
}

export const getKeyValuePairs = (enumType: any, displayMap?: { [index: number]: string }): KeyValuePair<string, number>[] => {
  const values: KeyValuePair<string, number>[] = [];
  // tslint:disable-next-line:forin
  for (const key in enumType) {
    const value = enumType[key];
    if (isNaN(<any>key) && !isNaN(value)) {
      values.push({
        key: displayMap ? displayMap[value] : key,
        value: value,
      });
    }
  }
  return values;
};
