<div class="title" #title>
  <div class="container container-max-width">
    <h4>Query from All Requests</h4>
    <p style="line-height: 20px">
      <br />
    </p>
  </div>
</div>

<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <form #inputForm="ngForm" autocomplete="off" novalidate>
      <div class="form-group">
        <h6>Regions:</h6>
        <label style="width:100%;">
          <ng-select [(ngModel)]="regions" [items]="regionList" [multiple]="true" name="region"> </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Requestors:</h6>
        <label style="width:100%;">
          <ng-select
            [(ngModel)]="requestors"
            [items]="requestorList"
            [multiple]="true"
            name="requestor"
            groupBy="ring"
            [virtualScroll]="true">
          </ng-select>
        </label>
        <div>
          <label>
            <input type="checkbox" [(ngModel)]="other" name="Other" />
            <span class="ml-1">Other Requestor</span>
          </label>
          <input *ngIf='other === true' class="form-control" [(ngModel)]="otherRequestor" name="otherRequestor" title="otherRequestor" placeholder="Type your requestor" />
        </div>
      </div>
      <div class="form-group">
        <h6>Submitter:</h6>
        <input class="form-control" [(ngModel)]="submitter" name="submitter" title="submitter" />
      </div>
      <div class="form-group">
        <h6>Subscription ID:</h6>
        <input class="form-control" [(ngModel)]="sub" name="sub" title="sub" />
      </div>
      <div class="form-group nofloat">
        <button type="submit" class="btn btn-primary mr-1" [disabled]="submitDisabled" (click)="submitRequest()">
          Submit
        </button>
      </div>
      <div class="info-box">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span class="ml-1">Please provide at least one.</span>
      </div>
      <hr />
    </form>
    <div>
      <button class="sbutton mb-3" title="Export current results" (click)="export()">Export</button>
      <ag-grid-angular
        style="height: 540px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="subRequestsWithTickets"
        (gridReady)="onGridReady($event)"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
    <hr />
  </div>
</div>
