<div class="communication-modal">
    <div>
        Add Comment to Selected Workitem(s):
        <input class="form-control" name="inputText" id="inputText" type="text" required [(ngModel)]="newComment"
               placeholder="Add a comment." line-height="2em" />
    </div>
</div>
<div></div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
  <button type="button" class="btn btn-warning" (click)="activeModal.dismiss('')">
    Cancel
  </button>
</div>
