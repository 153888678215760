import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { PlannedQuotaSubscriptionBinding } from "src/app/generated-models/PlannedQuotaSubscriptionBinding";
import { ITeamListRecord } from "src/app/npr-request.model";
import { SharedDataService } from "../../services/sharedDataService";
import { PlannedQuotaSubscriptionBindingColumnDefinition } from "../plans/ag-grid-column-definition";
import { AdminViewComponent } from "./admin-view.component";
import { DataTypes } from "./admin-view-data-types";

@Component({
  templateUrl: "./admin-view-bindings.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewSubscriptionBindingsComponent extends AdminViewComponent<PlannedQuotaSubscriptionBinding> implements OnInit {
  teamList: ITeamListRecord[] = [];
  team: ITeamListRecord = null;
  mainTitle = "Bindings";
  subTitle = "Subscription bindings of my services";
  type = "Subscription Bindings";
  tableName = "PlannedQuotaSubscriptionBinding";
  keyFilterStats = "filter:admin-view-sub-bindings";
  columnDef = PlannedQuotaSubscriptionBindingColumnDefinition;

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    dataTypes: DataTypes,
    sharedDataService: SharedDataService
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.addRowEnable = true;
    this.deleteRowEnable = true;
    this.updateRowFunc = (data: PlannedQuotaSubscriptionBinding) => {
      if (data.Region && data.ServiceTreeId && data.SubscriptionId && data.SubscriptionKey) {
        return apiService.addPlannedQuotaSubscriptionBindings(data).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.addRowFunc = (data: PlannedQuotaSubscriptionBinding) => {
      if (data.Region && data.ServiceTreeId && data.SubscriptionId && data.SubscriptionKey) {
        return apiService.addPlannedQuotaSubscriptionBindings(data).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.deleteRowFunc = (data: PlannedQuotaSubscriptionBinding) => {
      return apiService.deletePlannedQuotaSubscriptionBindings(data).toPromise();
    };

    this.getTeamList();
  }

  refreshData(): void {
    this.message = "";
    if (this.team) {
      this.apiService
        .getPlannedQuotaSubscriptionBindingsByServiceTreeId(this.team.oid)
        .subscribe((response: PlannedQuotaSubscriptionBinding[]) => {
          this.rowData = response;
          super.setupFilterByLocalData();
        });
    }
  }

  getTeamList(): void {
    this.apiService?.getMyServiceTeamUser().subscribe(
      (response) => {
        if (response) {
          const regionlessTeam = [];
          this.teamList = [];
          response.map((r) => {
            const label = `${r.ServiceName} (${r.ServiceTreeId})`;
            if (r.Ring) {
              if (this.teamList.findIndex((team) => team.label === label) === -1) {
                this.teamList.push({
                  label: `${r.ServiceName} (${r.ServiceTreeId})`,
                  value: 0,
                  ring: r.Ring,
                  oid: r.ServiceTreeId,
                } as ITeamListRecord);
              }
            } else {
              if (regionlessTeam.findIndex((team) => team.label === label) === -1) {
                regionlessTeam.push({
                  label: `${r.ServiceName} (${r.ServiceTreeId})`,
                  value: 0,
                  ring: "No Ring",
                  oid: r.ServiceTreeId,
                } as ITeamListRecord);
              }
            }
          });
          this.teamList = this.teamList
            .sort((a, b) => {
              return a.ring?.localeCompare(b.ring) || a.label?.localeCompare(b.label);
            })
            .concat(regionlessTeam.sort((a, b) => a.label?.localeCompare(b.label)));
        }
      },
      () => {
        this.teamList = [];
      }
    );
  }
}
