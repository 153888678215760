<div *ngIf="filteredRows.length > 0">
  <span>Filters: </span>
  <div class="filter-pill-container" *ngFor="let filteredRow of filteredRows">
    <div role="button" class="filter-pill">
      <span class="filter-pill-content-wrapper">
        <span class="filter-pill-content">{{ getFilterDescription(filteredRow) }} </span>
      </span>
      <div class="filter-pill-close" role="button" (click)="clearFilter($event, filteredRow)">
        <svg
          viewBox="0 0 12.7 12.7"
          class="filter-pill-close-icon"
          role="presentation"
          focusable="false"
          xmlns:svg="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="close"
          data-type="1"
        >
          <g>
            <title></title>
            <path d="M12.8.8L12 0 6.4 5.7.8 0 0 .8l5.7 5.6L0 12l.8.8 5.6-5.7 5.6 5.7.8-.8-5.7-5.6z"></path>
          </g>
        </svg>
      </div>
    </div>
  </div>
</div>
