import { Component } from "@angular/core";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { ColDef, ColumnApi, ExcelExportParams } from "ag-grid-community";
import { ApiService } from "../shared/api.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../shared/modal.service";
import { DataService } from "../shared/data.service";
import { BaseComponent } from "../shared/base.component";
import { ModuleService } from "../../shared/module.service";
import { RegionNamesRendererComponent } from "../shared/region-names-modal/region-names-renderer.component";
import { FieldMappings, ProductOnboardStatus } from "../shared/model";

@Component({
  selector: "app-home",
  templateUrl: "./product-onboard-status.component.html",
  styleUrls: ["./product-onboard-status.component.scss", "../styles.scss"],
})
export class ProductOnboardStatusComponent extends BaseComponent {
  status: ProductOnboardStatus[] = null;
  isGridLoading: boolean;
  rowSelection = "multiple";
  product = "";
  fromDate: Date;
  fromDateMax: NgbDateStruct;
  toDate: Date;
  toDateMin: NgbDateStruct;
  toDateMax: NgbDateStruct;

  public filterByLatest = false;

  protected gridApi: any;
  protected gridColumnApi: ColumnApi;
  columnDefs = [];
  gridOptions = extendDefaultOptions({
    columnDefs: this.columnDefs,
    rowData: this.status,
  });

  constructor(
    apiService: ApiService,
    private modalService: ModalService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    route: ActivatedRoute,
    auth: AuthService,
    moduleService: ModuleService
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
    const now = moment();
    this.fromDateMax = {
      year: now.year(),
      month: now.month() + 1,
      day: now.date(),
    };
    this.toDateMax = {
      year: now.year(),
      month: now.month() + 1,
      day: now.date(),
    };
  }

  onInit(): void {
    (this.gridOptions.frameworkComponents = {
      regionNamesRenderer: RegionNamesRendererComponent,
    }),
      (this.gridOptions.detailCellRendererParams = {
        detailGridOptions: {
          defaultColDef: {
            filter: "agSetColumnFilter",
            resizable: true,
            sortable: true,
          },
          context: this,
          rowClass: "ticket-row",
          onGridReady: (params) => {
            // make detailGrid height automatically
            params.api.setDomLayout("autoHeight");
          },
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
          },
        },
        getDetailRowData: (params) => {
          const bigId = params.data.Id;
          this.apiService.GetProductOnboardingBigIds(bigId).subscribe((response) => {
            if (params.node.detailGridInfo != null && response != null) {
              params.node.detailGridInfo.api.setColumnDefs(this.GenerateColumnDefs(response.columnDefs));
              params.data.requestData = response.data;
              params.successCallback(params.data.requestData);
            }
          });
        },
      });
    this.gridOptions.context = this;
    this.fetchProductOnboardStatus();
  }

  GenerateColumnDefs(columnMappings: FieldMappings[]): ColDef[] {
    const columnDefs = [];
    columnMappings.map((map) => {
      if (map.headerName === "Id") {
        columnDefs.push({
          headerName: map.headerName,
          field: map.field,
          checkboxSelection: false,
          cellRenderer: "agGroupCellRenderer",
        });
      } else if (map.headerName === "RegionNames") {
        columnDefs.push({
          headerName: map.headerName,
          field: map.field,
          cellRenderer: "regionNamesRenderer",
        });
      } else {
        columnDefs.push({
          headerName: map.headerName,
          field: map.field,
        });
      }
    });
    return columnDefs;
  }

  fetchProductOnboardStatus(): void {
    console.log("Fetch Product Onboard Status!");
    this.showLoadingOverlay();
    this.apiService.GetProductOnboardStatus().subscribe((response) => {
      const columnDefs = this.GenerateColumnDefs(response.columnDefs);
      this.columnDefs = columnDefs;

      this.status = response.data;
    });
    this.hideOverlay();
  }

  ShowRegionNamesModal(regions: string[]): void {
    this.modalService
      .RegionNamesModal(regions)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }

  private hideOverlay() {
    this.isGridLoading = false;
    if (this.gridApi) {
      this.gridApi.hideOverlay();
    }
  }

  private showLoadingOverlay() {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    } else {
      this.isGridLoading = true;
    }
  }

  onInScopeServicesGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.gridColumnApi;
    if (!this.isGridLoading) {
      this.status = null;
    }
    console.log("grid ready");
  }

  isUserAuthorized(): boolean {
    return this.isAuthorizedUser;
  }

  isUserPermitted(): boolean {
    return this.dataService.isPermittedUser;
  }
  onLatestFilterSwitched(): void {
    this.filterByLatest = !this.filterByLatest;
    const filterInstance = this.gridApi.getFilterInstance("Id");
    if (filterInstance) {
      let filterValues = filterInstance.valueModel.allValues;
      console.log(filterValues);
      if (!filterValues || filterValues.length === 0) {
        return;
      }
      if (this.filterByLatest) {
        filterValues = [`${Math.max(...filterValues)}`];
      }
      filterInstance.setModel({
        type: "set",
        values: filterValues,
      });
      this.gridApi.onFilterChanged();
    }
  }

  onExportExcel(): void {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "Request Status",
      sheetName: "Request Status",
    } as ExcelExportParams;
    this.gridApi.exportDataAsExcel(params);
  }
}
