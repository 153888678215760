<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Quota Allocation Summary</h4>
      <p>Aggregated approved and pending quota allocation per region</p>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main mt-3">
    <div class="top-panel">
      <label class="float-left">
        <ng-select
          class="select-filter"
          name="Region"
          bindLabel="RegionName"
          [(ngModel)]="region"
          (ngModelChange)="onRegionChanged()"
          placeholder="Select a Region"
          [items]="regionList"
          groupBy="Status"
          required>
        </ng-select>
      </label>
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel()"
        placement="top-right"
        *ngIf="region"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
    </div>
    <div>
      <ag-grid-angular
        #agGrid
        id="ViewGrid"
        style="width: 100%; height: 540px"
        class="ag-theme-balham"
        [masterDetail]="true"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [excelStyles]="headerStyle"
        pagination="true"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
