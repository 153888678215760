import { Component, Input, Output, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-ack-error-billing-meter-dialog',
  templateUrl: './ack-error-billing-meter-dialog.component.html',
})
export class AckErrorBillingMeterDialogComponent implements OnInit {

  @Input() errorBillingMeterRequestsCount: number;

  reasons = [
    "CMX Data Sync Error",
    "RingOnboarding Form Error",
    "Billing Meter System Error",
    "Other"
  ];

  selectedReason = ""

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  modalConfirm() {
    if (this.selectedReason == "") {
      alert("Please select a reason.");
      return;
    }

    this.activeModal.close(this.selectedReason);
  }

}
