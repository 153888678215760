<div class="title">
  <div class="container container-max-width">
    <div>
      <h4>Planned Quota Fulfillment Status</h4>
      <p>See all service teams' progress in the region</p>
    </div>
    <div class="action-container">
      <button type="submit" class="btn btn-primary" (click)="refresh()">Refresh</button>
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel()"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
    </div>
  </div>
</div>
<div class="container container-content container-max-width">
  <div class="landing-main mt-3">
    <p *ngIf="errorMsg" class="message"> {{ errorMsg }}</p>
    <div class="top-panel">
      <label class="float-left">
        <ng-select
          class="select-filter"
          name="Region"
          bindLabel="RegionName"
          [(ngModel)]="currentRegion"
          (ngModelChange)="onRegionChanged()"
          placeholder="Select a Region"
          [items]="regionList"
          groupBy="Status"
          required>
        </ng-select>
      </label>
    </div>
    <ag-grid-angular
      #agGrid
      id="ViewGrid"
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [excelStyles]="headerStyle"
      pagination="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
