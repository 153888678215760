import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { PlanValidityEnum } from "src/app/shared/enums/plan-enums";
import { getKeyValuePairs } from "src/app/utility/enums";

export interface PlanApprovalModalOptions {
  model: PlanRegion;
  save: () => Promise<any>;
}

@Component({
  templateUrl: "./plan-approval-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanApprovalModalComponent implements OnInit {
  @Input() options: PlanApprovalModalOptions;
  dto: PlanRegion;

  readonly planValidityEnumMap = getKeyValuePairs(PlanValidityEnum);

  constructor(public activeModal: NgbActiveModal, private notificationService: ToastrService) {}

  async ngOnInit() {
    this.dto = this.options.model;
  }

  async submit() {
    if (!this.dto.ValidTo) {
      this.notificationService.warning(`Please select a plan validity.`);
      return;
    }
    if (this.dto.ValidTo === "Custom" && !this.dto.ValidToDate) {
      this.notificationService.warning(`Please select a date.`);
      return;
    } else {
      try {
        const result = await this.options.save();
        this.activeModal.close(result);
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }
}
