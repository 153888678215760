import { formatDate } from "@angular/common";
export const DateTimeColumnFormatter = "yyyy/MM/dd HH:mm";
export const DateColumnFormatter = "yyyy/MM/dd";
export const InvalidDateTime = "0001-01-01T00:00:00+00:00";

export function PreprocessDateTime(dateTime: string | number | Date, formatter?: string, locale?: string): string {
  if (dateTime) {
    if (typeof dateTime === "string" && dateTime === InvalidDateTime) {
      return "N/A";
    }

    try {
      return formatDate(dateTime, formatter || DateTimeColumnFormatter, locale || "en-US");
    } catch {
      return dateTime.toString();
    }
  }
  return "";
}
