<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Planned Quota Fulfillment Status</h4>
      <p>See status of planned quota</p>
    </div>
    <div class="action-container">
      <button type="submit" class="btn btn-primary" (click)="refresh()">Refresh</button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="top-panel" style="height: fit-content">
    <ul class="list-container col-md-10">
      <li class="flex-box mb-3">
        <div class="col-md-2 label">Region</div>
        <div class="col-md-10 value">{{ region }}</div>
      </li>
      <li class="flex-box mb-3">
        <div class="col-md-2 label">Service Team</div>
        <div class="col-md-10 value">{{ planRegion?.ServiceTeam }}&nbsp;({{ serviceTreeId }})</div>
      </li>
      <li class="flex-box mb-3">
        <div class="col-md-2 label">Plan Version</div>
        <div class="col-md-10 value">{{ planRegion?.Version }}</div>
      </li>
      <li class="flex-box mb-3">
        <div class="col-md-2 label">Status</div>
        <div class="col-md-10 value" data-test="PlanRegionStatus">{{ planRegion?.Status }}</div>
      </li>
      <li class="flex-box mb-3">
        <div class="col-md-2 label">Needed By</div>
        <div class="col-md-10 value">{{ startDate }}</div>
      </li>
      <li class="flex-box mb-3">
        <div class="col-md-2 label">Subscription Usage</div>
        <div class="col-md-10 value">{{ subscriptionUsed }} / {{ subscriptionAll }}</div>
      </li>
      <li class="flex-box mb-3">
        <div class="col-md-2 label">Property Usage</div>
        <div class="col-md-10 value">{{ propertyUsed }} / {{ propertyAll }}</div>
      </li>
    </ul>
  </div>
  <div class="landing-main mt-3">
    <b>Regional Plans:</b>
    <ag-grid-angular
      style="width: 100%; height: 350px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="planRegionGridOptions"
      [rowData]="planRegions"
      pagination="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
  <div class="landing-main mt-3">
    <b>Requests:</b>
    <ag-grid-angular
      style="width: 100%; height: 350px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="planQuotaRequestGridOptions"
      [rowData]="requests"
      pagination="true">
    </ag-grid-angular>
  </div>
  <div class="landing-main mt-3">
    <div class="display-flex">
      <div class="m-3">
        <b>Subscription Bindings:</b>
        <ag-grid-angular
          style="width: 100%; height: 350px"
          class="ag-theme-balham"
          [masterDetail]="true"
          [gridOptions]="subscriptionBindingGridOptions"
          [rowData]="subscriptionBindings"
          pagination="true">
        </ag-grid-angular>
      </div>
      <div class="m-3">
        <b>Resource Bindings:</b>
        <ag-grid-angular
          style="width: 100%; height: 350px"
          class="ag-theme-balham"
          [masterDetail]="true"
          [gridOptions]="propertyBindingGridOptions"
          [rowData]="propertyBindings"
          pagination="true">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
