import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlannedQuotaRequestDetail } from "src/app/generated-models";
import { ApiService } from "src/app/services";
import { RequestOperation } from "../../npr-request.model";

@Component({
  templateUrl: "./planned-quota-request-confirmation-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class PlannedQuotaRequestConfirmationModal implements OnInit {
  @Input() requestOperation: RequestOperation; // Maintain extension for 'Cancel' or other operations
  @Input() plannedQuotaRequest: PlannedQuotaRequestDetail;

  public isCompleted = false;
  public inProgress = false;
  public confirmation = true;
  public bodyMessage = "";
  public completeMessage = "";
  public title = "";

  // Params for canceling request
  public comment: string;
  public sendEmail = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  ngOnInit() {
    this.confirm();
  }

  confirm() {
    this.inProgress = false;
    this.confirmation = true;
    this.title = "Please Confirm";
    this.bodyMessage = `Are you sure want to ${this.requestOperation} planned quota request?`;
  }

  async start() {
    this.inProgress = true;
    this.confirmation = false;
    this.title = `${this.requestOperation} planned quota request`;

    switch (this.requestOperation) {
      case RequestOperation.Retry:
        await this.retryPlannedQuotaRequest();
        break;
    }
  }

  close() {
    if (this.confirmation) {
      this.activeModal.close(false);
    } else {
      this.activeModal.close(true);
    }
  }

  async retryPlannedQuotaRequest() {
    try {
        await this.apiService.retryPlannedQuotaRequest(this.plannedQuotaRequest.RequestId).toPromise();
        this.completeMessage = `Retry planned quota request <b>${this.plannedQuotaRequest.RequestId}</b> successfully`;
    } catch (ex) {
        this.completeMessage = `Retry planned quota request <b>${this.plannedQuotaRequest.RequestId}</b> failed with error: ${ex}`;
    }

    this.isCompleted = true;
  }
}
