import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";

@Component({
  templateUrl: "./plan-commit-response-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanCommitResponseModalComponent implements OnInit {
  @Input() warningMessage: string;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) { }

  ngOnInit() {
    return;
  }

  close() {
    this.activeModal.close(true);
  }
}
