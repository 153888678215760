<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">FFP Plans Assignment</h2>
  </div>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
      <div class="modal-body">
          <div class="col-md-9"><b>Products and Services Lists ID</b></div>
          <div class="col-md-9">
              <input class="form-control" [(ngModel)]="psl" name="psl" title="psl" required />
              <div class="info-box">
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  <span>Please input a valid PSL.</span>
              </div>
          </div>
          <br />
      </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid || !isValidPsl()" data-test="Submit">OK</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  </form>
</div>
