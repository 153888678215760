import { Component, Input, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NprConfiguration } from "src/app/app.configuration";
import { NewUser } from "src/app/generated-models/NewUser";
import { ITeamListRecord } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { isNonEmptyString } from "src/app/utility/common-helper";

export interface AddUserModalOptions {
  save: (users: NewUser[]) => Promise<any>;
  adminTeamList: string[];
}

@Component({
  templateUrl: "./add-user.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AddUserComponent implements OnInit {
  @Input() options: AddUserModalOptions;
  emailRegexp = new RegExp(NprConfiguration.emailRegExpression);
  team: ITeamListRecord;
  teamList: ITeamListRecord[];
  userUpn: string;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    this.teamList = [];
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);

    // Team List
    this.apiService?.getTeamList().subscribe(
      (response) => {
        const regionlessTeam = [];
        response
          .filter((team) => this.options.adminTeamList.includes(team.Oid))
          .map((r) => {
            if (r.Ring) {
              this.teamList.push({
                label: `${r.Name} (${r.Oid})`,
                value: 0,
                ring: r.Ring,
                oid: r.Oid,
              } as ITeamListRecord);
            } else {
              regionlessTeam.push({
                label: `${r.Name} (${r.Oid})`,
                value: 0,
                ring: "No Ring",
                oid: r.Oid,
              } as ITeamListRecord);
            }
          });
        this.teamList = this.teamList
          .sort((a, b) => {
            if (a.label === null) {
              console.log(a);
            }
            return a.ring.localeCompare(b.ring) || a.label.localeCompare(b.label);
          })
          .concat(regionlessTeam.sort((a, b) => a.label.localeCompare(b.label)));
        this.loadingService.setLoading(false);
      },
      (e: any) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  async submit() {
    if (!this.team) {
      this.notificationService.warning(`Please select a service name.`);
      return;
    } else {
      try {
        const users = this.userUpn.split(",").map((user) => {
          const quotaUser: NewUser = {
            ServiceTreeId: this.team.oid,
            Upn: user
          };
          return quotaUser;
        });
        const result = await this.options.save(users);
        this.activeModal.close(result);
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }

  isTouchedOrDirty(control: NgModel) {
    return control && (control.touched || control.dirty);
  }

  get isEmailValid() {
    return isNonEmptyString(this.userUpn) && this.userUpn.split(/[,;]/).every((emailItem) => this.emailRegexp.test(emailItem));
  }
}
