import * as CDs from "src/app/utility/commonColDef";

export const VmSizeColumnDefinition = [
    { ...CDs.RegionColDef("RegionName"), valueType: "region" },
    { headerName: "Name", field: "Name", valueType: "name" },
    { headerName: "Family", field: "Family", valueType: "family" },
    { headerName: "CRP VM SKU", field: "CrpVmSku" },
    { headerName: "CAS VM Sku", field: "CasVmSku" },
    { headerName: "Portal Vm Sku", field: "PortalVmSku" },
    { headerName: "Required Features", field: "RequiredFeatures", valueType: "requiredFeatures" },
    { headerName: "Cores", field: "Cores", valueType: "cores" },
];