import { Component } from "@angular/core";
import { ColumnApi, GridApi, GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { BaseComponent } from "../../shared/base.component";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { PlannedQuotaRequest } from "src/app/generated-models/PlannedQuotaRequest";
import { PlannedQuotaRequestStatus, RingLevel } from "src/app/shared/enums/plan-enums";
import * as CDs from "src/app/utility/commonColDef";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { viewRegionalPlanDetailFromQuotaRequestCellComponentParams } from "../plans/ag-grid-column-definition";
import { getPlannedQuotaRequestStatusDescription } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./planned-quota-requests.component.html",
  styleUrls: ["../../styles.scss"],
})
export class PlannedQuotaRequestsComponent extends BaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  rowData: PlannedQuotaRequest[] = [];
  rowModelType;
  serverSideStoreType;
  paginationPageSize;
  cacheBlockSize;

  constructor(private apiService: ApiService, private loadingService: LoadingScreenService, private notificationService: ToastrService) {
    super();
    this.rowModelType = "serverSide";
    this.serverSideStoreType = "partial";
    this.paginationPageSize = 15;
    this.cacheBlockSize = 15;
  }

  ngOnInit() {
    this.gridOptions = extendDefaultOptions({
      columnDefs: [
        {
          ...CDs.RequestId,
          filter: "agNumberColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
          cellRenderer: (params) => ApiService.generatePlannedQuotaRequestHyperLink(params.value),
        },
        {
          ...CDs.SubscriptionId("SubscriptionId"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.ServiceTreeId,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          headerName: "Service Team Name",
          field: "ServiceTeamName",
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.Ring,
          filter: "agSetColumnFilter",
          filterParams: {
            values: Object.values(RingLevel),
          },
        },
        {
          ...CDs.RegionColDef("Region"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          headerName: "Contact Email",
          field: "ContactEmail",
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.PlanVersion,
          cellRendererFramework: LinkCellComponent,
          cellRendererParams: viewRegionalPlanDetailFromQuotaRequestCellComponentParams,
        },
        {
          ...CDs.SubscriptionKey,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.Stage,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        {
          ...CDs.Status,
          filter: "agSetColumnFilter",
          filterParams: {
            values: Object.values(PlannedQuotaRequestStatus),
          },
          tooltipValueGetter: (params) => {
            return getPlannedQuotaRequestStatusDescription(params.data.Status)?.description || params.data.Status;
          },
        },
        {
          ...CDs.RequestSource,
          filter: "agSetColumnFilter",
          filterParams: {
            values: (params) => this.getColumnSelections(params),
          },
        },
        CDs.CreatedTime,
        CDs.CompletedTime,
      ],
      paginationPageSize: this.initPageSize,

      getRowHeight: (params) => {
        const defaultRowHeight = params.node.rowHeight;
        if (params.data.detailRows) {
          const numOfRows = Number(params.data.detailRows.length);
          const defaultColumnHeight = defaultRowHeight * 1.2;
          const spareSpace = defaultRowHeight * 0.5;
          const detailRowsHeight = Math.max(2, numOfRows) * defaultRowHeight;
          return defaultRowHeight + detailRowsHeight + defaultColumnHeight + spareSpace;
        } else {
          return defaultRowHeight;
        }
      },
    });
    this.loadingService.setLoading(true);
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    window.setTimeout(() => {
      const colIds = this.gridColumnApi.getAllColumns().map((c) => c.getColId());
      this.gridColumnApi.autoSizeColumns(colIds);
    }, 50);
    params.api.setServerSideDatasource(this.dataSource);
    this.loadingService.setLoading(false);
  }

  getColumnSelections(params) {
    this.apiService?.getPlannedQuotaRequestsColumnSelection(params.colDef.field).subscribe(
      (response: string[]) => {
        setTimeout(function () {
          params.success(response);
        }, 500);
      },
      () => {
        this.loadingService.setLoading(false);
      }
    );
  }

  dataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.apiService
        .getPlannedQuotaRequestsPagination(
          params.request.startRow,
          params.request.endRow,
          params.request.sortModel,
          params.request.filterModel
        )
        .subscribe((response) => {
          setTimeout(function () {
            if (response.Success) {
              params.success({
                rowData: response.Requests,
                rowCount: response.TotalCount,
              });
            } else {
              params.fail();
            }
          }, 200);
        });
    },
  };
}
