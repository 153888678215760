import { ActivatedRoute } from "@angular/router";
import { Component, Input } from '@angular/core';
import { BaseComponent } from "../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { DataService } from "../shared/data.service";
import { ModuleService } from "../../shared/module.service";
import { AuthService } from "../../auth/auth.service";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { ApiService } from "../shared/api.service";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { Cloud, Region, ProductStatusTrackPayload, ProductTrackInfo } from "../shared/model";


@Component({
  selector: 'app-product-status-track',
  templateUrl: './product-status-track.component.html',
  styleUrls: ['./product-status-track.component.scss', "../styles.scss"]
})
export class ProductStatusTrackComponent extends BaseComponent {

  cloudRegions: Region[];
  key: string;
  productStatusTrackInfo: ProductStatusTrackPayload;
  showBillingMeterRequestsOnly: boolean;

  gridOptions = extendDefaultOptions({
    getRowHeight: (params) => {
      return this.onGetRowHeight(params);
    },
  });

  constructor(
    apiService: ApiService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    route: ActivatedRoute,
    private moduleService: ModuleService,
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
    this.key = "";
    this.productStatusTrackInfo = null;
    this.showBillingMeterRequestsOnly = false;
  }

  onInit(): void {
    this.initCloudRegions();
    this.route.queryParams
      .subscribe(params => {
        if (params.product_oid) {
          this.key = params.product_oid;
        }

        if (params.cayman_product_id) {
          this.key = params.cayman_product_id;
        }

        if (params.show_billing_meter_requests_only != null) {
          this.showBillingMeterRequestsOnly = params.show_billing_meter_requests_only.toLowerCase() === 'true';
          console.log(this.showBillingMeterRequestsOnly);
        }

        if (params.region) {
          var regions = this.dataService.regions.filter( (region) => {
            region.Name == params.region;
          });

          if (regions.length > 0) {
            this.dataService.selectedRegion = regions[0];
          }
        }

        if (this.dataService.selectedRegion != null && this.key != "") {
          this.onFetchProductStatusTrack();
        }
      });
  }

  isUserAuthorized(): boolean {
    return this.isAuthorizedUser;
  }

  onCloudChange(cloud: Cloud): void {
    this.dataService.changeCloud(cloud);
    this.initCloudRegions();
  }

  onRegionChange(): void {
    this.dataService.changeRegion();
  }

  initCloudRegions(): void {
    this.cloudRegions =
      this.dataService.selectedCloud &&
      this.dataService.regions.filter(
        (region) =>
          region.CloudType === this.dataService.selectedCloud.CloudName &&
          ["Buildout", "Approved", "Production SLA", "Ring 0 Complete"].includes(region.State)
      )
  }

  distinctObjectArray(objArray: Map<string, string>[], key: string): Map<string, string>[]{
    return [...new Map(objArray.map(item => [item[key], item])).values()]
  }

  onFetchProductStatusTrack(): void {
    if (this.key == "" || this.dataService.selectedRegion == null) {
      return;
    }
    this.productStatusTrackInfo = null;
    this.loadingScreenService.setLoading(true);
    this.apiService
      .GetProductTrackInfo(
        this.key,
        this.dataService.selectedRegion.Name
      )
      .subscribe((resp) => {
        this.productStatusTrackInfo = <ProductStatusTrackPayload>resp;
        this.productStatusTrackInfo.productCayman.data = this.distinctObjectArray(this.productStatusTrackInfo.productCayman.data, "CaymanProductId")
        this.productStatusTrackInfo.availabilityCayman.data = this.distinctObjectArray(this.productStatusTrackInfo.availabilityCayman.data, "CaymanProductId")
        console.log(this.productStatusTrackInfo);
        this.loadingScreenService.setLoading(false);
      });
  }

  getProductInfo(): ProductTrackInfo {
    if (!this.productStatusTrackInfo?.product == null) {
      return null;
    }

    if (this.productStatusTrackInfo.product.data.length == 0) {
      return null;
    }

    return this.productStatusTrackInfo.product.data[0]
  }

  getProductCaymanColumnDef(): any[] {
    return [
      { headerName: "ProductOid", field: "ProductOid"},
      { headerName: "CaymanProductId", field: "CaymanProductId"},
      { headerName: "CaymanProductName", field: "CaymanProductName"},
      { headerName: "LocationType", field: "LocationType"},
      { headerName: "DeploymentScope", field: "DeploymentScope"},
    ];
  }

  getProductAvailabilityColumnDef(): any[] {
    return [
      { headerName: "ProductOid", field: "ProductOid"},
      { headerName: "CaymanProductId", field: "CaymanProductId"},
      { headerName: "CaymanProductName", field: "CaymanProductName"},
      { headerName: "CloudType", field: "CloudType"},
      { headerName: "LocationType", field: "LocationType"},
      { headerName: "LocationId", field: "LocationId"},
      { headerName: "RegionName", field: "RegionName"},
      { headerName: "InScope", field: "InScope"},
      { headerName: "CurrentState", field: "CurrentState"},
      { headerName: "DeploymentScope", field: "DeploymentScope"},
    ];
  }

  getProductOnboardingColumnDef(): any[] {

    const formIdCellComponentParams = {
      target: "_blank",
      url: (params: ColParams<Map<string, string>, string>) => `https://global.azure.com/product-catalog/ring-onboarding/${params.data["FormId"]}`,
    };

    return [
      {
        headerName: "FormId",
        field: "FormId",
        cellRendererFramework: LinkCellComponent,
        cellRendererParams: formIdCellComponentParams,
      },
      { headerName: "ProductOid", field: "ProductOid" },
      { headerName: "BigIds", field: "BigIds" },
      { headerName: "CreatedByAlias", field: "CreatedByAlias" },
      { headerName: "Created", field: "Created" },
      { headerName: "Status", field: "Status" },
      { headerName: "StatusMessage", field: "StatusMessage" },
    ];
  }

  getBillingMeterColumnDef(): any[] {
    return [
      { headerName: "ProductOid", field: "ProductOid" },
      { headerName: "CaymanProductId", field: "CaymanProductId" },
      { headerName: "CaymanProductName", field: "CaymanProductName" },
      { headerName: "LocationType", field: "LocationType" },
      { headerName: "LocationId", field: "LocationId" },
      { headerName: "RegionName", field: "RegionName" },
      { headerName: "BuildoutType", field: "BuildoutType" },
      { headerName: "JobId", field: "JobId" },
      { headerName: "StatusTrackingId", field: "StatusTrackingId" },
      { headerName: "Completed", field: "Completed" },
      { headerName: "Message", field: "Message" },
      { headerName: "Created", field: "Created" },
      { headerName: "CreatedBy", field: "CreatedBy" },
      { headerName: "LastUpdate", field: "LastUpdate" },
      { headerName: "IcmId", field: "IcmId"},
    ];
  }
}
