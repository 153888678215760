import { Component } from "@angular/core";
import { IToolPanel, IToolPanelParams } from "ag-grid-community";

@Component({
  selector: "custom-stats",
  template: `
    <div>
      <span>
        <h3>Custom Toolkit</h3>
        <dl style="padding: 10px 10px 10px 10px">
          <dt class="totalStyle">
            <input type="checkbox" (change)="onSettingBarChecked()" />
            Hide setting Bar
          </dt>
          <dt class="totalStyle">
            <input type="checkbox" (change)="onPageSizeChecked()" />
            Hide pagesize Bar
          </dt>
        </dl>
      </span>
    </div>
  `,
  styles: [
    `
      :host {
        padding-top: 10px;
        padding-left: 10px;
      }
      .totalStyle {
        padding-bottom: 5px;
      }
    `,
  ],
})
export class CustomToolingPanel implements IToolPanel {
  private gridApi: any;

  agInit(params: IToolPanelParams): void {
    this.gridApi = params.api;
  }

  updateTotals(): void {}

  refresh(): void {
    return;
  }

  private setStatusBarVisible(compKey: string) {
    var statusBarComponent = this.gridApi.getStatusPanel(compKey);
    var componentInstance = statusBarComponent;
    if (statusBarComponent.getFrameworkComponentInstance) {
      componentInstance = statusBarComponent.getFrameworkComponentInstance();
    }
    componentInstance.setVisible(!componentInstance.isVisible());
  }

  onSettingBarChecked(): void {
    this.setStatusBarVisible("statusBarViewConfigKey");
  }
  onPageSizeChecked(): void {
    this.setStatusBarVisible("statusBarViewPageSizeKey");
  }
}
