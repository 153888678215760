import { Component, OnInit } from "@angular/core";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { ColumnApi, GridApi, GridOptions, RowNode } from "ag-grid-community";
import { IApproverInput, IRegionGroupListRecord, Roles, IUserProfile, allowedCommonRegionStatuses } from "../../npr-request.model";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../../shared/modal.service";
import { ToastrService } from "ngx-toastr";
import { UserRoleColumnDefinition } from "./ag-grid-column-definition";
import { Region } from "../../generated-models";
import { UserRole } from "../../generated-models/UserRole";
import { NgForm } from "@angular/forms";
import { environment } from "src/environments/environment";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel } from "src/app/utility/common-helper";
import { map } from "rxjs/operators";

@Component({
  templateUrl: "./approver-admin.component.html",
  styleUrls: ["../../styles.scss"],
})
export class ApproverAdminComponent extends GridViewBaseComponent implements OnInit {
  gridOptions: GridOptions;
  initPageSize: number;
  selectedRowCount = 0;
  selectedRows: UserRole[] = [];
  rowData: UserRole[] = [];
  userProfile: IUserProfile = null;
  message = "";
  keyFilterStats = "filter:approvers-management";

  rolesList: string[] = [];
  regionsList: IRegionGroupListRecord[] = [];
  typesList: string[] = [];
  newApproverInput: IApproverInput = {} as IApproverInput;

  upnPlaceholder: string;
  upnValidationError: string;
  isApproverAdmin = false;

  gridApi: GridApi;
  gridColumnApi: ColumnApi;

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    protected notificationService: ToastrService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    super.InitAgGrid(UserRoleColumnDefinition);

    this.loadingService.setLoading(true);
    this.apiService
      .getUserProfile()
      .then((response) => {
        this.userProfile = response;
        this.getDropdownLists();
        this.resetNewApproverInput();
      })
      .catch((error) => {
        this.notificationService.error(error);
      });
    this.loadingService.setLoading(false);
  }

  refreshData() {
    this.apiService.getApprovers().subscribe((response: UserRole[]) => {
      if (response) {
        this.rowData = response;
      }
    });
    super.setupFilterByLocalData();
    this.loadingService.setLoading(false);
  }

  getDropdownLists() {
    // Roles List
    this.apiService.getApproverRoles().subscribe((response) => {
      if (response == null) {
        console.warn("No approver role is returned.");
      } else {
        this.rolesList = response;
      }
    });

    // Regions List
    this.apiService.getRegionList()
      .pipe(map(regions => regions.filter(region => allowedCommonRegionStatuses.includes(region.Status))))
      .subscribe((response: Region[]) => {
        if (response == null) {
          console.warn("No region is obtained from api server.");
        } else {
          this.regionsList = [];
          for (let i = 0; i < response.length; i++) {
            const rec: IRegionGroupListRecord = {} as IRegionGroupListRecord;
            rec.item_id = i + 1;
            rec.region = response[i].RegionName;
            rec.cloud = response[i].CloudName;
            this.regionsList.push(rec);
          }
        }
      });

    // Types List
    this.apiService.getApproverRoleTypes().subscribe((response) => {
      if (response == null) {
        console.warn("No role type is returned.");
      } else {
        this.typesList = response;
      }
    });
  }

  onGridReady(params: GridOptions) {
    super.onGridReady(params);
    this.refreshData();
  }

  resetNewApproverInput() {
    // Use this simple way to construct the placeholder, will move to more stable way based on the current environment.
    const domain = this.userProfile.Email.split("@")[1];
    this.upnPlaceholder = "user1@" + domain + "," + "user2@" + domain + ", ...";
    this.newApproverInput.UserAccounts = "";
    this.newApproverInput.IsAllRegionsSelected = false;
    this.newApproverInput.IsAllTypesSelected = false;
    this.newApproverInput.Role = "";
    this.newApproverInput.Regions = [];
    this.newApproverInput.Types = [];
    this.message = "";
  }

  submitRequest(form: NgForm) {
    let passValidation = true;

    this.upnValidationError = "";
    const regexp = new RegExp(environment.requiredUpnPattern);
    if (form.value.upn) {
      for (let upn of form.value.upn.split(",")) {
        if (!regexp.test(upn.trim())) {
          this.upnValidationError = `You should enter user acccount in the format: ${this.upnPlaceholder}`;
          passValidation = false;
          break;
        }
      }
    }

    const newApprover: UserRole = {} as UserRole;
    newApprover.Upn = form.value.upn;
    newApprover.Role = form.value.roles;
    newApprover.Regions = this.newApproverInput.IsAllRegionsSelected ? "*" : form.value.regions.toString();
    newApprover.Types = this.newApproverInput.IsAllTypesSelected ? "*" : form.value.types.toString();

    if (passValidation) {
      this.loadingService.setLoading(true);

      this.apiService.addApprovers(newApprover).subscribe(
        () => {
          this.message = `New approver(s) have been added successfully.`;
          this.notificationService.info(this.message);
          this.resetNewApproverInput();
        },
        () => {
          this.message = `Failed to add new approver(s). `;
          this.notificationService.error(this.message);
          this.loadingService.setLoading(false);
        },
        () => {
          this.refreshData();
          this.loadingService.setLoading(false);
        }
      );
    }
  }

  async editApprover() {
    const selectedRows: UserRole[] = this.gridApi.getSelectedRows();
    if (selectedRows.length === 0 || selectedRows.length > 1) {
      this.message = `Please select only 1 row each time for editing.`;
      this.notificationService.warning(this.message);
      return;
    }
    const p = this.modalService.approverEditModal(`Update Approver User Role data`, this.selectedRows[0], this.regionsList, this.typesList);
    p.then((ret) => {
      if (ret) {
        const updateApprover: UserRole = {} as UserRole;
        updateApprover.Upn = this.selectedRows[0].Upn;
        updateApprover.Role = this.selectedRows[0].Role;
        updateApprover.Regions = ret.Regions;
        updateApprover.Types = ret.Types;

        this.apiService.setApprovers(updateApprover).subscribe(
          () => {
            this.message = `Update approver user role data successfully.`;
            this.notificationService.info(this.message);
          },
          () => {
            this.message = `Failed to update the approver ${updateApprover.Upn}`;
            this.notificationService.error(this.message);
          },
          () => {
            this.refreshData();
          }
        );
      } else {
        this.notificationService.warning("You clicked No button, no change has been applied", `Hey ${this.userProfile.Name},`);
      }
    }).catch((error) => {
      if (error) {
        this.notificationService.error(error);
      }
    });
  }

  async deleteApprovers() {
    const selectedRows: UserRole[] = this.gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      this.message = "Please select at least one row before deleting.";
      this.notificationService.warning(this.message);
      return;
    }

    try {
      await this.modalService.confirmationModal("Are you sure delete the selected approver(s)?");
    } catch {
      // For the model dialog dimiss
      return;
    }

    const deleteUserRolesData = [];

    for (let i = 0; i < selectedRows.length; ++i) {
      const deleteApprover: UserRole = {} as UserRole;
      deleteApprover.Upn = this.selectedRows[i].Upn;
      deleteApprover.Role = this.selectedRows[i].Role;
      deleteApprover.Regions = this.selectedRows[i].Regions;
      deleteApprover.Types = this.selectedRows[i].Types;
      deleteUserRolesData.push(deleteApprover);
    }

    this.apiService.deleteApprovers(deleteUserRolesData).subscribe(
      () => {
        this.message = `Delete approvers user role data successfully.`;
        this.notificationService.info(this.message);
      },
      () => {
        this.message = `Failed to delete the approvers.`;
        this.notificationService.error(this.message);
      },
      () => {
        this.refreshData();
      }
    );
  }

  exportExcel() {
    var fileName = "approvers-export-" + PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm");
    var sheetName = "Approvers";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }

  onChecked(isChecked: boolean) {
    this.selectAllRowsOnCurrentPage(isChecked);
  }

  selectAllRowsOnCurrentPage(selected: boolean) {
    const lastGridIndex = this.gridApi.getDisplayedRowCount() - 1;
    const currentPage = this.gridApi.paginationGetCurrentPage();
    const pageSize = this.gridApi.paginationGetPageSize();
    const startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      if (rowNode.group === false) {
        rowNode.setSelected(selected, false);
      }
    }
  }

  onSelectionChanged(event: GridOptions) {
    this.selectedRows = [];
    this.selectedRowCount = event.api.getSelectedNodes().length;
    event.api.getSelectedNodes().forEach((row: RowNode) => {
      this.selectedRows.push(row.data);
    });
  }

  onRolesChanged() {
    if (this.newApproverInput.Role === Roles.ApproverAdmin) {
      this.isApproverAdmin = true;
      this.newApproverInput.IsAllRegionsSelected = true;
    } else {
      this.isApproverAdmin = false;
      this.newApproverInput.IsAllRegionsSelected = false;
    }
  }
}
