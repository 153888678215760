import { Component, OnInit, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { IGenericListRecord } from "../../npr-request.model";

@Component({
  templateUrl: "./select-option-and-input-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class SelectOptionAndInputModalComponent implements OnInit {
  @Input() message: string;
  @Input() options: string[];

  @Output() returnText = "";
  @Output() selectedOption = "";
  optionList: IGenericListRecord[] = [];
 
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    for (let i = 0; i < this.options.length; i++) {
      this.optionList.push({item_id: i + 1, item_text:this.options[i]} as IGenericListRecord);
    }
  }
}
