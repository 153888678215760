import { Component, OnInit } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../loading-screen.service";
import { ArmResourceTypeColumnDefinition } from "./ag-grid-column-definition";
import { IUserProfile } from "src/app/npr-request.model";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { ArmResourceType } from "../../generated-models";

@Component({
  templateUrl: "./arm-resource-type-details.component.html",
  styleUrls: ["../../styles.scss", "./arm-resource-type-details.component.scss"],
})
export class ArmResourceTypeEnablement extends GridViewBaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  userProfile: IUserProfile;
  keyFilterStats = "filter:arm-resource-type";
  rowData: ArmResourceType[] = [];

  constructor(
    protected apiService: ApiService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit(): void {
    super.InitAgGrid(ArmResourceTypeColumnDefinition);
    this.gridOptions.rowSelection = "single";

    this.apiService
      .getUserProfile()
      .then((response) => {
        if (response) {
          this.userProfile = response;
        } else {
          this.notificationService.error("user profile returns null, no data was loaded.");
        }
      })
      .catch((error) => {
        this.notificationService.error(`unable to get user profile, error: ${error.message}`);
      });
  }

  refreshData(): void {
    this.loadingService.setLoading(true);

    this.apiService.getArmResourceTypes().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: unknown) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      }
    );
  }
}
