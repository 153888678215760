<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Add a new user to your team</h2>
  </div>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body" style="height: 100px">
      <div>
        <span>Select the service that you own</span>
        <label style="width: 100%">
          <ng-select
            name="TeamName"
            [(ngModel)]="team"
            [items]="teamList"
            groupBy="ring"
            placeholder="Start typing"
            [virtualScroll]="true"
            required
            data-test="TeamName"
            #teamName="ngModel">
            <ng-template ng-option-tmp let-item="item">
              <ng-container>
                <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
                  {{ item.label }}
                </label>
              </ng-container>
            </ng-template>
          </ng-select>
        </label>
      </div>
    </div>
    <div class="modal-body" style="height: 100px">
      <div>
        <span>Enter the UPNs separated by commas</span>
        <input class="form-control" [(ngModel)]="userUpn" name="userUpn" title="userUpn" required #email="ngModel" />
        <ng-container *ngIf="isTouchedOrDirty(email)">
          <em *ngIf="email.errors?.required">Required</em>
          <em *ngIf="!isEmailValid">Enter the UPNs in this format: alias1@microsoft.com,alias2@microsoft.com</em>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid">OK</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close()">Cancel</button>
    </div>
  </form>
</div>
