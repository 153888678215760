import { Component, HostListener } from "@angular/core";
import { ColDef, ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { PlansColumnDefinition } from "./ag-grid-column-definition";
import { ModalService } from "src/app/shared/modal.service";
import { PlanOnboardingModalOptions } from "./modals/plan-onboarding-modal/plan-onboarding-modal.component";
import { Plan } from "src/app/generated-models/Plan";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix, saveFile } from "src/app/utility/common-helper";
import { ColParams } from "src/app/shared/grid/ag-grid";

@Component({
  templateUrl: "./plans.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansComponent extends GridViewBaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  showMyServiceTeamPlansOnly: boolean = true;
  myPlanServiceTreeIds: string[] = [];
  rowData: Plan[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  keyFilterStats = "filter:region-agnostic-plans";

  message: string;

  constructor(
    protected apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(apiService, sharedDataService);
  }

  async ngOnInit() {
    this.routeToPlanEditPage();
    super.InitAgGrid(PlansColumnDefinition);
    
    var plans = await this.apiService.getMyPlans().toPromise();
    if (plans != null) {
      this.myPlanServiceTreeIds = plans.map(plan => plan.ServiceTreeId);
    }
    
    if (this.userProfile?.IsApprover) {
      this.showMyServiceTeamPlansOnly = false;
    }
    PlansColumnDefinition.forEach( (colDef: ColDef) => {
      if (colDef.headerName === 'Custom Filter') {
        colDef.valueGetter = (params: ColParams<Plan, void>) => {
          if (!this.showMyServiceTeamPlansOnly) {
            return true;
          }
          const row = params.data as Plan;
          return this.myPlanServiceTreeIds.some(service => row.ServiceTreeId === service);
        }
      }
    });

    this.gridOptions.api?.setColumnDefs(PlansColumnDefinition);
  }

  onGridReady(params: GridOptions) {
    super.onGridReady(params);
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  refreshData() {
    this.loadingService.setLoading(true);
    this.apiService.getPlans().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    setTimeout(() => {
      //this.gridApi.sizeColumnsToFit();
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onShowMyServiceTeamPlansOnlyChanged() {
    this.gridApi.onFilterChanged();
  }

  async showOnboardingDialog() {
    const options: PlanOnboardingModalOptions = {
      save: this.onBoardNewPlan.bind(this),
    };
    await this.modalService.planOnboardingModal(options);
  }

  onBoardNewPlan(serviceTreeId: string) {
    this.loadingService.setLoading(true);

    this.apiService.onboardNewPlan(serviceTreeId).subscribe(
      () => {
        this.message = `New Plan has been onboarded successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err: unknown) => {
        this.message = `Failed to onboard new plan. ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      () => {
        this.refreshData();
      }
    );
  }

  exportExcel() {
    const fileName = "RegionAgnosticPlans-" + getExportedExcelFileNameSuffix();
    const sheetName = "Region Agnostic Plans";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }

  routeToPlanEditPage() {
    // deep link to edit plan page if service query param is provided
    this.route.queryParams.subscribe((params) => {
      if (params.service && params.service.trim() != "") {
        this.router.navigate([`/plans/services/${params.service}/editor`]);
      }
    });
  }
}
