import { ApiService } from "src/app/services";
import * as CDs from "src/app/utility/commonColDef";

export const RequestColumnDefinitionForApprover = [
  {
    headerName: "ID",
    field: "RequestId",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      innerRenderer: (params) => {
        // provide an inner renderer
        return params.data.RequestLink;
      },
    },
  },
  CDs.Requestor,
  CDs.ApprovedBy,
  CDs.RingLevel,
  CDs.Submitter,
  CDs.RegionColDef("Region"),
  CDs.Cloud,
  CDs.SubscriptionId("SubscriptionId", { cellRenderer: "subscriptionRenderer" }),
  CDs.IsExternalDisplay,
  CDs.IsHoboDisplay,
  CDs.RequestDisplayServiceType,
  CDs.SKU,
  CDs.Quota,
  CDs.DeploymentScope,
  CDs.AdditionalParams,
  CDs.PriorityString,
  { headerName: "Subscription Env", field: "OfferDetails.SubscriptionEnv" },
  { headerName: "Cost Category", field: "OfferDetails.CostCategory" },
  { headerName: "Offer Id", field: "OfferDetails.OfferId" },
  { headerName: "Offer Type", field: "OfferDetails.OfferType" },
  { headerName: "Offer Name", field: "OfferDetails.OfferName" },
  CDs.CreatedTime,
  CDs.Email,
  CDs.Dependencies,
  {
    ...CDs.Justification,
    cellRenderer: (param) => {
      return ApiService.generateParentRequestHyperLink(param.data.ParentRequestId, "View in new tab");
    }
  },
  CDs.RequestSource,
  CDs.Notes,
  CDs.UtilizeDate,
];
