import { Component, OnInit } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { Region } from "src/app/generated-models";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApiService } from "../../services";
import { PlannedQuotaStatus } from "./ag-grid-column-definition";
import { PlanRegionStatus } from "src/app/generated-models/PlanRegionStatus";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./plan-region-status.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlannedQuotaRegionStatusComponent extends BaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  rowData = [];
  region: string;
  errorMsg = "";
  currentRegion: Region;
  regionList: Region[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: 'header',
      font: {
        bold: true,
      },
    },
  ];

  constructor(
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) {
    super();
  }

  ngOnInit(): void {
    this.gridOptions = extendDefaultOptions({
      columnDefs: PlannedQuotaStatus,
      paginationPageSize: this.initPageSize,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
    });
    this.activatedRoute.params.subscribe((queryParams) => {
      this.region = queryParams.region;
      if (this.region != null) {
        this.title.setTitle(`Planned Quota Region Status - ${this.region}`);
      }
    });

    this.loadingService.setLoading(true);
    this.getPlannedQuotaFulfillmentStatus();

    // Region List
    // includeDisabled = true, includeAirGapped = false
    this.apiService.getRegionList(true, false).subscribe((response) => {
      if (response) {
        this.regionList = response;
        this.currentRegion = this.regionList.find((r) => r.RegionName === this.region);
      }
    });
  }

  getPlannedQuotaFulfillmentStatus(): void {
    this.apiService.getPlannedQuotaStatusByRegion(this.region).subscribe(
      (planRegions: PlanRegionStatus[]) => {
        this.rowData = [];
        planRegions?.forEach((r: PlanRegionStatus) => {
          const [subCompleted, subAll] = [r.SubscriptionCompleted, r.SubscriptionCount];
          const [propCompleted, propAll] = [r.PropertyCompleted, r.PropertyCount];
          this.rowData.push({
            Region: r.Region,
            ServiceTeam: r.ServiceTeam,
            ServiceTreeId: r.ServiceTreeId,
            Ring: r.Ring,
            Stage: r.Stage,
            SubscriptionBindings: `${subCompleted} / ${subAll}`,
            PropertyBindings: `${propCompleted} / ${propAll}`,
            Status: this.getStatus(subCompleted, subAll),
          });
        });
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        this.loadingService.setLoading(false);
      },
      (err: unknown) => {
        this.errorMsg = err as string;
        this.loadingService.setLoading(false);
      }
    );
  }

  refresh(): void {
    this.loadingService.setLoading(true);
    this.getPlannedQuotaFulfillmentStatus();
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onRegionChanged(): void {
    if (this.currentRegion) {
      window.location.href = `/quota/plans/regions/${this.currentRegion.RegionName}/status`;
    }
  }

  private getStatus(numerator: number, denominator: number): string {
    if (numerator === 0) {
      return "Not Started";
    }
    if (denominator === numerator) {
      return "Done";
    } else {
      return "In Progress";
    }
  }

  exportExcel(): void {
    const fileName = `PlannedQuotaFulfillmentStatus-${this.region}-` + getExportedExcelFileNameSuffix();
    const sheetName = this.region;

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
