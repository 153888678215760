import { Component, OnInit } from "@angular/core";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { RegionalPlanStatus } from "./ag-grid-column-definition";
import { BaseComponent } from "../../shared/base.component";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { PlannedQuotaSubscriptionBinding } from "src/app/generated-models/PlannedQuotaSubscriptionBinding";
import { PlannedQuotaPropertyBinding } from "src/app/generated-models/PlannedQuotaPropertyBinding";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { PlannedQuotaRequest } from "src/app/generated-models/PlannedQuotaRequest";
import * as _ from "lodash";
import { DateColumnFormatter, PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { PlanRegionStatus } from "src/app/generated-models/PlanRegionStatus";

@Component({
  templateUrl: "./plan-status.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlannedQuotaFulfillmentStatusComponent extends BaseComponent implements OnInit {
  planRegionGridOptions: GridOptions;
  planQuotaRequestGridOptions: GridOptions;
  subscriptionBindingGridOptions: GridOptions;
  propertyBindingGridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  rowData = [];
  region: string;
  serviceTreeId: string;
  stage: string;
  planRegions: PlanRegion[] = [];
  requests: PlannedQuotaRequest[] = [];
  subscriptionBindings: PlannedQuotaSubscriptionBinding[] = [];
  propertyBindings: PlannedQuotaPropertyBinding[] = [];
  planRegion: PlanRegion = null;
  subscriptionAll = 0;
  subscriptionUsed = 0;
  propertyAll = 0;
  propertyUsed = 0;
  startDate: string;

  constructor(
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private activatedRoute: ActivatedRoute,
    private title: Title) {
    super();
  }

  ngOnInit(): void {
    this.planRegionGridOptions = extendDefaultOptions({
      columnDefs: RegionalPlanStatus.PlanRegionColumnDefinition,
      paginationPageSize: this.initPageSize,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
    });
    this.planQuotaRequestGridOptions = extendDefaultOptions({
      columnDefs: RegionalPlanStatus.RequestColumnDefinition,
      paginationPageSize: this.initPageSize,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
    });
    this.subscriptionBindingGridOptions = extendDefaultOptions({
      columnDefs: RegionalPlanStatus.SubscriptionBindingColumnDefinition,
      paginationPageSize: this.initPageSize,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
    });
    this.propertyBindingGridOptions = extendDefaultOptions({
      columnDefs: RegionalPlanStatus.PropertyBindingColumnDefinition,
      paginationPageSize: this.initPageSize,
      enableRangeSelection: false,  
      rowSelection: "single",
      animateRows: true,
    });

    this.activatedRoute.params.subscribe((queryParams) => {
      this.serviceTreeId = queryParams.serviceTreeId;
      this.region = queryParams.region;
      this.stage = queryParams.stage;
      this.title.setTitle(`Planned Quota Fulfillment Status - Region Access and Quota`);
    });

    this.loadingService.setLoading(true);
    this.getPlannedQuotaFulfillmentStatus();
  }

  getPlannedQuotaFulfillmentStatus(): void {
    forkJoin([
      this.apiService.getPlanRegions(this.serviceTreeId, this.region, this.stage).pipe(catchError(() => of([]))),
      this.apiService.getPlannedQuotaRequests(this.region, "", this.serviceTreeId).pipe(catchError(() => of([]))),
      this.apiService.getPlannedQuotaSubscriptionBindingsByRegionAndTeam(this.region, this.serviceTreeId).pipe(catchError(() => of([]))),
      this.apiService.getPlannedQuotaPropertyBindingsByRegionAndTeam(this.region, this.serviceTreeId).pipe(catchError(() => of([]))),
      this.apiService.getPlanRegionStatus(this.region, this.serviceTreeId, this.stage).pipe(catchError(() => of([]))),
    ]).subscribe(([planRegions, requests, subscriptionBindings, propertyBindings, status]: [PlanRegion[], PlannedQuotaRequest[], PlannedQuotaSubscriptionBinding[], PlannedQuotaPropertyBinding[], PlanRegionStatus]) => {
      this.planRegions = planRegions;
      this.requests = requests;
      this.subscriptionBindings = subscriptionBindings;
      this.propertyBindings = propertyBindings;
      this.planRegion = planRegions[0];
      if (this.planRegion && this.planRegion.StartDate) {
        this.startDate = PreprocessDateTime(this.planRegion.StartDate, DateColumnFormatter);
      }

      if (status?.SubscriptionCount) {
        this.subscriptionAll = status.SubscriptionCount;
        this.subscriptionUsed = status.SubscriptionCompleted;
      }
      if (status?.PropertyCount) {
        this.propertyAll = status.PropertyCount;
        this.propertyUsed = status.PropertyCompleted;
      }
      this.loadingService.setLoading(false);
    });
  }

  refresh(): void {
    this.loadingService.setLoading(true);
    this.getPlannedQuotaFulfillmentStatus();
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
