import { AgsSideNavItem } from "@ags/common-ui";
import { AppRoutingModule } from "./app-routing.module";

/*@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})*/
export class AppComponent {
  static prepNavItems: AgsSideNavItem[] = [
    {
      name: "Billing Meter",
      children: AppRoutingModule.billingMeterRouters.filter((r) => !r.hidden),
    },
  ];
}
