import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { IWorkItemResponse, IDiscussionHistory, CommentUpdate, TicketStatus, AssignTo } from "src/app/npr-request.model";
import { LoadingScreenService } from "../loading-screen.service";

@Component({
  templateUrl: "./ticket-comments-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class TicketCommentsModalComponent implements OnInit {
  @Input() workItem: IWorkItemResponse;
  @Input() id: string;
  @Input() region: string;
  @Input() isRequestArchived: boolean;
  newComment: string;
  comments: IDiscussionHistory[];

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService, protected loadingService: LoadingScreenService) {}

  ngOnInit() {
    this.comments = this.workItem.DiscussionHistory;
  }

  isValidComment() {
    if (this.newComment) {
      return this.newComment.trim().length != 0;
    }
    // undefined return false
    return false;
  }

  async onSave() {
    this.loadingService.setLoading(true);
    const commentString = this.newComment?.replace("\n", "<br />");
    console.log(commentString);

    const commentUpdate = {} as CommentUpdate;
    commentUpdate.Comment = commentString;

    await this.apiService.updateWorkItemById(this.id, this.region, commentUpdate).subscribe(
      () => {
        this.activeModal.close("");
      },
      (err) => {
        this.activeModal.dismiss(err);
      }
    );
  }
}
