import { Component, OnInit } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { BaseComponent } from "../../shared/base.component";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { PlannedQuotaRequest } from "src/app/generated-models/PlannedQuotaRequest";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import * as CDs from "src/app/utility/commonColDef";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { viewRegionalPlanDetailFromQuotaRequestCellComponentParams } from "../plans/ag-grid-column-definition";

enum PlannedQuotaQueryRequestType {
  Alias = 0,
  Service = 1,
}

class PlannedQuotaQueryRequest {
  constructor(public text: string, public type: PlannedQuotaQueryRequestType) {}
}

@Component({
  templateUrl: "./planned-quota-my-requests.component.html",
  styleUrls: ["../../styles.scss"],
})
export class PlannedQuotaMyRequestsComponent extends BaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  rowData: PlannedQuotaRequest[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  // storage a reference to the enum
  plannedQuotaQueryRequestType = PlannedQuotaQueryRequestType;
  public plannedQuotaQueryRequest: PlannedQuotaQueryRequest;

  constructor(private apiService: ApiService, private loadingService: LoadingScreenService, private notificationService: ToastrService) {
    super();
    this.plannedQuotaQueryRequest = new PlannedQuotaQueryRequest("", PlannedQuotaQueryRequestType.Alias);
  }

  ngOnInit(): void {
    this.gridOptions = extendDefaultOptions({
      columnDefs: [
        {
          ...CDs.RequestId,
          filter: "agNumberColumnFilter",
          cellRenderer: (params) => ApiService.generatePlannedQuotaRequestHyperLink(params.value),
        },
        {
          ...CDs.SubscriptionId("SubscriptionId"),
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.ServiceTreeId,
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Service Team Name",
          field: "ServiceTeamName",
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.Ring,
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.RegionColDef("Region"),
          filter: "agSetColumnFilter",
        },
        {
          headerName: "Contact Email",
          field: "ContactEmail",
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.PlanVersion,
          cellRendererFramework: LinkCellComponent,
          cellRendererParams: viewRegionalPlanDetailFromQuotaRequestCellComponentParams,
        },
        {
          ...CDs.SubscriptionKey,
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.Stage,
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.Status,
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.RequestSource,
          filter: "agSetColumnFilter",
        },
        {
          ...CDs.CreatedTime,
          cellRenderer: (params) => PreprocessDateTime(params.value),
          filter: "agTextColumnFilter",
        },
        {
          ...CDs.CompletedTime,
          cellRenderer: (params) => PreprocessDateTime(params.value),
          filter: "agTextColumnFilter",
        },
      ],
      paginationPageSize: this.initPageSize,

      getRowHeight: (params) => {
        const defaultRowHeight = params.node.rowHeight;
        if (params.data.detailRows) {
          const numOfRows = Number(params.data.detailRows.length);
          const defaultColumnHeight = defaultRowHeight * 1.2;
          const spareSpace = defaultRowHeight * 0.5;
          const detailRowsHeight = Math.max(2, numOfRows) * defaultRowHeight;
          return defaultRowHeight + detailRowsHeight + defaultColumnHeight + spareSpace;
        } else {
          return defaultRowHeight;
        }
      },
    });
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    window.setTimeout(() => {
      const colIds = this.gridColumnApi.getAllColumns().map((c) => c.getColId());
      this.gridColumnApi.autoSizeColumns(colIds);
    }, 50);

    this.onAssignModeChanged();
  }

  async onAssignModeChanged(): Promise<void> {
    this.loadingService.setLoading(true);
    try {
      switch (this.plannedQuotaQueryRequest.type) {
        case PlannedQuotaQueryRequestType.Alias:
          this.rowData = await this.apiService?.getMyPlannedQuotaRequests().toPromise();
          break;
        case PlannedQuotaQueryRequestType.Service:
          this.rowData = await this.apiService?.getMyServicesPlannedQuotaRequests().toPromise();
          break;
        default:
          this.rowData = await this.apiService?.getMyPlannedQuotaRequests().toPromise();
          break;
      }
    } catch (err) {
      this.rowData = [];
      this.notificationService.error(err as string);
    }

    this.loadingService.setLoading(false);
  }

  exportExcel() {
    var requestOwner = this.plannedQuotaQueryRequest.type == this.plannedQuotaQueryRequestType.Alias ? "me" : "my service";
    var fileName = `PlannedQuotaRequests-${requestOwner}` + getExportedExcelFileNameSuffix();
    var sheetName = `Planned Quota Requests of ${requestOwner}`;

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
