import { Component, HostListener } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { RegionalQuotaStatistics } from "src/app/generated-models/RegionalQuotaStatistics";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { BaseComponent } from "../../shared/base.component";
import { PlansRegionalQuotaInfoColumnDefinition } from "./ag-grid-column-definition";
import { Region } from "src/app/generated-models";
import { RegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { allowedCommonRegionStatuses } from "../../npr-request.model";

@Component({
  templateUrl: "./plans-regional-quota-information.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansRegionalQuotaInformationComponent extends BaseComponent {
  regionList: Region[] = [];
  region: Region;
  regionEnum = RegionStatusEnum;

  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;

  rowData: RegionalQuotaStatistics[] = [];

  hideAlreadyAssigned = false;
  message: string;
  headerStyle: ExcelStyle[] = [
    {
      id: 'header',
      alignment: {
        horizontal: 'Center'
      },
      font: {
        bold: true,
      },
    },
  ];

  constructor(private apiService: ApiService, private loadingService: LoadingScreenService, private notificationService: ToastrService) {
    super();
  }

  async ngOnInit() {
    this.gridOptions = extendDefaultOptions({
      columnDefs: PlansRegionalQuotaInfoColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
      suppressPaginationPanel: true,
    });

    this.loadingService.setLoading(true);

    // Region List
    // includeDisabled = true, includeAirGapped = false
    const regionListResponse = await this.apiService.getRegionList(true, false).toPromise();
    if (!regionListResponse) {
      console.warn("No region is obtained from api server.");
    } else {
      this.regionList = regionListResponse
        .filter(region => allowedCommonRegionStatuses.includes(region.Status))
        .sort((region1, region2) => {
          return this.regionEnum[region1.Status.replace(/\s/g, "")] - this.regionEnum[region2.Status.replace(/\s/g, "")];
        });
    }

    this.loadingService.setLoading(false);
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.sizeColumnsToFit();
    this.gridColumnApi.autoSizeAllColumns();
  }

  async getRegionalQuotaStatistics(region: string) {
    this.loadingService.setLoading(true);
    this.apiService.getRegionalQuotaStatistics(region).subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  async onRegionChanged() {
    if (this.region) {
      await this.getRegionalQuotaStatistics(this.region.RegionName);
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 100);
    } else {
      this.rowData = [];
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    setTimeout(() => {
      //this.gridApi.sizeColumnsToFit();
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  exportExcel() {
    var fileName = `QuotaAllocationSummary-${this.region.RegionName}-` + getExportedExcelFileNameSuffix();
    var sheetName = "Regional Quota Statistics";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
