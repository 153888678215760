<div class="title" #title>
    <div class="container container-max-width">
      <h4>Available VM Sizes per Region</h4>
    </div>
  </div>
  <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main">
      <br />
      <div class="hint" *ngIf="this.rowData">
          <label>{{ this.rowData.length }} row(s)</label>
      </div>
  
      <grid-filter-pills [gridApi]="gridApi" *ngIf="gridApi"></grid-filter-pills>
      <form #operatorForm="ngForm" autocomplete="off" novalidate>
        <div class="nofloat">
          <ag-grid-angular
            style="height: 540px"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData"
            [masterDetail]="true"
            [detailRowHeight]="145"
            pagination="true"
            (gridReady)="onGridReady($event)"
            (filterChanged)="onFilterChanged($event)"
          >
          </ag-grid-angular>
        </div>
      </form>
    </div>
  </div>
