import { Injectable } from "@angular/core";
import { AccountInfoExtended, AgsAuthService } from "@ags/core";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private agsAuthService: AgsAuthService) {}

  public async isAuthenticated(resourceUrl?: string[]) {
    const accessToken$ = await this.agsAuthService.acquireToken(resourceUrl);
    return !!accessToken$;
  }

  public async getUser(): Promise<AccountInfoExtended> {
    return this.agsAuthService.getAccount();
  }
}
