<div class="title" #title>
  <div class="container container-max-width">
    <h4>Approvers Management</h4>
    <p style="line-height: 20px">
      Use this management page to add, edit or delete approvers
      <br />
    </p>
  </div>
</div>

<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <div>
      <p class="message">{{ this.message }}</p>
    </div>
    <div>
      <form #approverAdminForm="ngForm" autocomplete="off" novalidate>
        <!--Role-->
        <div class="form-group">
          <h6>Role Type:</h6>
          <label style="width: 100%">
            <ng-select
              name="roles"
              labelForId="roles"
              [items]="rolesList"
              bindLabel="role"
              bindValue="role"
              (change)="onRolesChanged()"
              [(ngModel)]="newApproverInput.Role"
              required
            >
            </ng-select>
          </label>
        </div>
        <!--User Account-->
        <div
          class="form-group"
          [ngClass]="{
            error: approverAdminForm.controls.upn?.invalid && approverAdminForm.controls.upn?.touched
          }"
        >
          <h6>User Account(s):</h6>
          <textarea
            class="form-control"
            [(ngModel)]="newApproverInput.UserAccounts"
            name="upn"
            title="upn"
            id="upn"
            type="text"
            placeholder="{{ upnPlaceholder }}"
            rows="2"
            required
          >
          </textarea>
          <em *ngIf="approverAdminForm.controls.upn?.invalid && approverAdminForm.controls.upn?.touched">Required</em>
          <em *ngIf="!approverAdminForm.controls.upn?.invalid && this.upnValidationError?.length != 0">{{ this.upnValidationError }}</em>
        </div>
        <!--Region-->
        <div
          class="form-group"
          [ngClass]="{
            error: approverAdminForm.controls.regions?.invalid && approverAdminForm.controls.regions?.touched
          }"
        >
          <h6>Region(s):</h6>
          <div>
            <label>
              <input type="checkbox" name="allRegions" [disabled]="isApproverAdmin" [(ngModel)]="newApproverInput.IsAllRegionsSelected"/>
              Select all regions
            </label>
          </div>
          <label *ngIf="!newApproverInput.IsAllRegionsSelected && !isApproverAdmin" style="width: 100%">
            <ng-select
              name="regions"
              labelForId="regions"
              [items]="regionsList"
              bindLabel="region"
              bindValue="region"
              groupBy="cloud"
              required
              [multiple]="true"
              [(ngModel)]="newApproverInput.Regions"
              placeholder="Enter region name"
            >
            </ng-select>
          </label>

          <em *ngIf="approverAdminForm.controls.regions?.invalid && approverAdminForm.controls.regions?.touched">Required</em>
        </div>

        <!--Types-->
        <div
          class="form-group"
          [ngClass]="{
            error: approverAdminForm.controls.types?.invalid && approverAdminForm.controls.types?.touched
          }"
        >
          <h6>Type(s):</h6>
          <div>
            <label>
              <input type="checkbox" name="allTypes" [(ngModel)]="newApproverInput.IsAllTypesSelected"/>
              Select all types
            </label>
          </div>
          <label *ngIf="!newApproverInput.IsAllTypesSelected" style="width: 100%">
            <ng-select
              name="types"
              labelForId="types"
              [items]="typesList"
              required
              [multiple]="true"
              [(ngModel)]="newApproverInput.Types"
              placeholder="Enter type name"
            >
            </ng-select>
          </label>

          <em *ngIf="approverAdminForm.controls.types?.invalid && approverAdminForm.controls.types?.touched">Required</em>
        </div>

        <!--Submit Button-->
        <div class="form-group nofloat">
          <button
            type="submit"
            class="btn btn-primary mr-1"
            [disabled]="approverAdminForm.invalid"
            (click)="submitRequest(approverAdminForm)"
          >
            Add
          </button>
          <div class="float-right mt-3 mr-1">
            <a
              href="javascript:void(0)"
              class="topbar-item"
              (click)="exportExcel()"
              placement="top-right"
              container="body"
              ngbTooltip="Export to Excel"
              aria-label="Export to Excel"
            >
              <span class="far fa-file-excel fa-lg"></span>
            </a>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <div class="hint leftalign">
      <input type="checkbox" (ngModelChange)="onChecked($event)" title="selectAll" />
      {{ selectedRowCount }} of {{ rowData.length }} selected &nbsp;&nbsp;|&nbsp;&nbsp;
      <a href="javascript:void(0)" class="topbar-item" (click)="editApprover()" container="body"> Edit Approver </a>
      &nbsp;&nbsp;
      <a href="javascript:void(0)" class="topbar-item" (click)="deleteApprovers()" container="body"> Delete </a>
    </div>
    <form #approverGridFrom="ngForm" autocomplete="off" novalidate>
      <div class="nofloat">
        <ag-grid-angular
          style="height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowData"
          [masterDetail]="true"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged($event)"
          (dblclick)="editApprover()"
          (selectionChanged)="onSelectionChanged($event)"
          pagination="true"
          rowSelection="multiple"
        >
        </ag-grid-angular>
      </div>
      <br />
    </form>
  </div>
</div>
