<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Assign plans to {{ region.RegionName }}</h2>
    <a
      *ngIf="isCompleted"
      href="javascript:void(0)"
      class="topbar-item"
      (click)="exportExcel()"
      placement="bottom"
      container="body"
      ngbTooltip="Export assign result to Excel"
      aria-label="Export assign result to Excel">
      <span class="far fa-file-excel fa-lg"></span>
    </a>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isInProgress"> </mat-progress-bar>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body">
      <ag-grid-angular
        #agGrid
        id="ViewGrid"
        style="width: 100%; height: 540px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
      <div class="mt-3">
        <span>Purpose</span>
        <ng-select
          class="mt-2"
          [items]="planPurposeEnumMap"
          [(ngModel)]="purpose"
          [clearable]="false"
          placeholder="Select a purpose"
          bindValue="key"
          bindLabel="key"
          name="purpose"
          required>
        </ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <div class="left">{{ assignResultLabel }}</div>
      <div class="fill-remaining-space"></div>
      <button type="submit" *ngIf="!isCompleted" class="btn btn-primary" [disabled]="ngForm.invalid">Assign</button>
      <button type="button" class="btn btn-primary" (click)="continue()" [hidden]="requireSkuAvailableInCrp || retried">Proceed</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
    </div>
  </form>
</div>
