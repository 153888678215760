<div class="title">
  <div class="container">
    <h4>Billing Meter Status</h4>
    <p>Use this form to view Billing Meter status</p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isUserAuthorized()">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && isUserAuthorized() && !userCanViewStatus">
  <h5>Permission is required!</h5>
  <h5>Please Contact <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add the permission </h5>
</div>
<div class="container container-content" *ngIf="isLoadingFinished && userCanViewStatus">
  <div class="main-content">
    <div>
      <div class="form-group">
        <button type="button" class="btn btn-primary mr-1" (click)="downloadFullReport()">
          Export Region Scope Meter Reconciliation Report
        </button>
      </div>
    </div>
    <hr />
    <div class="form-group">
      <h4>Select Cloud and Region to get started</h4>
    </div>
    <div class="region-select">
      <div class="form-group">
        <h6>Cloud</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.clouds"
            bindLabel="CloudName"
            placeholder="Select Cloud"
            (change)="onCloudChange($event)"
            [(ngModel)]="dataService.selectedCloud"
            name="CloudName"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Loading...</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Region</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.cloudRegions"
            bindLabel="Name"
            placeholder="Select Region"
            (change)="onRegionChange()"
            [(ngModel)]="dataService.selectedRegions"
            name="Name"
            [multiple]="true"
            [closeOnSelect]="false"
            [disabled]="dataService.selectedCloud == null"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" (change)="$event.stopPropagation();" />
              {{ item.Name }}
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Edge Zone</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.regionEdgeZones"
            bindLabel="EdgeZoneName"
            placeholder="Select Edge Zone"
            (change)="onEdgeZoneChanges()"
            [(ngModel)]="dataService.selectedEdgeZones"
            name="EdgeZoneName"
            [multiple]="true"
            [closeOnSelect]="false"
            [disabled]="dataService.selectedRegion == null"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" (change)="$event.stopPropagation();" />
              {{ item.EdgeZoneName }}
            </ng-template>
          </ng-select>
        </label>
      </div>

      <div class="form-group">
        <h6>Product Name or Product Id</h6>
        <input [(ngModel)]="product" (keyup.enter)="onFetchRequestStatus()" title="product" />
      </div>
    </div>

    <form class="form-inline">
      <div class="form-group">
        <h6>From Date</h6>
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            [maxDate]="fromDateMax"
            (dateSelect)="onFromDateSelect()"
            name="dp"
            title="dp"
            [(ngModel)]="fromDate"
            ngbDatepicker
            #f="ngbDatepicker"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="f.toggle()" type="button" title="calendar"></button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <h6>To Date</h6>
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            [minDate]="toDateMin"
            [maxDate]="toDateMax"
            (dateSelect)="onToDateSelect()"
            name="dp"
            title="dp"
            [(ngModel)]="toDate"
            ngbDatepicker
            #t="ngbDatepicker"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="t.toggle()" type="button" title="calendar"></button>
          </div>
        </div>
      </div>
    </form>

    <div class="form-group">
      <button type="button" class="btn btn-primary mr-1" [disabled]="dataService.selectedCloud == null" (click)="onFetchRequestStatus()">
        Fetch {{ isPrep() ? "Request" : "Buildout" }} Status
      </button>
    </div>

    <hr />
    <h4>Review Services</h4>
    <p style="margin-bottom: 0px">
      Filter by:&nbsp;
      <a *ngIf="filterByLatest" href="javascript:void(0)" (click)="onLatestFilterSwitched()"> All </a>
      {{ filterByLatest ? "" : "All" }}
      &nbsp;|&nbsp;
      <a *ngIf="!filterByLatest" href="javascript:void(0)" (click)="onLatestFilterSwitched()"> Latest </a>
      {{ filterByLatest ? "Latest" : "" }}
    </p>
    <div style="width: 100%; text-align: right">
      <button type="button" class="btn btn-primary mr-1" (click)="onExportExcel()">Export Request Details</button>
      <button type="button" class="btn btn-primary mr-1" (click)="onExportExcelWithDetails()">Export Request and Meter Details</button>
    </div>
    <!--form #reqStatusForm="ngForm" autocomplete="off" style="height: 650px" novalidate-->
    <div class="nofloat" style="width: 100%">
      <ag-grid-angular
        style="height: 540px; margin-bottom: 10px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowSelection]="rowSelection"
        [rowMultiSelectWithClick]="true"
        [rowData]="status"
        [columnDefs]="columnDefs"
        (gridReady)="onInScopeServicesGridReady($event)"
        [masterDetail]="true"
        [detailRowHeight]="145"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
    <div>
      <div class="form-group">
        <button
          type="button"
          class="btn btn-primary mr-1"
          [disabled]="isResubmitBuildoutButtonDisabled()"
          (click)="resubmitBuildoutTriggered()"
        >
          Resubmit Billing Meter Request
        </button>
      </div>
    </div>
  </div>
</div>
