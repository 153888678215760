/// <reference path="../../../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { FfpPlanFile } from "src/app/generated-models/FfpPlanFile";

export interface PlanEditorModalOptions {
  model: FfpPlanFile;
  save: (model: FfpPlanFile) => Promise<any>;
  isReadonly: boolean;
  schema: string;
}

export interface PlanJsonSchema {
  FullSchema: string;
  MergeSchema: string;
}

@Component({
  templateUrl: "./ffp-plan-editor-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../ffp.scss"],
})
export class FfpPlanEditorModalComponent implements OnInit {
  @Input() options: PlanEditorModalOptions;
  editor: monaco.editor.IStandaloneCodeEditor;
  ffpPlanFile: FfpPlanFile;

  constructor(public activeModal: NgbActiveModal, private notificationService: ToastrService) {}

  async ngOnInit() {
    this.ffpPlanFile = this.options.model;
  }

  onEditorReady(editor: monaco.editor.IStandaloneCodeEditor) {
    this.editor = editor;
    this.editor.setValue(this.ffpPlanFile.Content);
    monaco.editor.setModelLanguage(this.editor.getModel(), this.ffpPlanFile.Format);
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      allowComments: true,
      schemas: [
        {
          uri: "http://lionrock/ffp-plan-schema.json",
          fileMatch: ["*"],
          schema: JSON.parse(this.options.schema),
        },
      ],
    });

    // delay 1sec for formatting the content
    setTimeout(() => {
      this.editor
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          this.editor.updateOptions({ readOnly: this.options.isReadonly });
        });
    }, 1000);
  }

  async submit() {
    try {
      this.ffpPlanFile.Content = this.editor.getValue();
      await this.options.save(this.ffpPlanFile);
    } catch (e) {
      this.notificationService.error(e);
    }
  }
}
