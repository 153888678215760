import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { IUserProfile, IQuotaImplicationsData, ISubRequest, RequestServiceType, ResourceType } from "../../npr-request.model";
import { RequestColumnDefinitionForAllRequests, RequestColumnDefinitionForImplications } from "./ag-grid-column-definition";
import { ApiService } from "src/app/services";
import { GetDeploymentTypeByService, GetResourceTypeByService, deepCopy } from "src/app/utility/common-helper";
import { GridApi, GridOptions } from "ag-grid-community";

@Component({
  templateUrl: "./quota-implications-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class QuotaImplicationsModalComponent implements OnInit {
  @Input() options: ISubRequest[]; // Keep the original subRequests
  @Input() userProfile: IUserProfile = null;
  rowData: IQuotaImplicationsData[] = []; // Keep the pre-approved quota information
  gridOptionsForRequests = {
    columnDefs: RequestColumnDefinitionForAllRequests,
  };
  gridOptionsForImplications = {
    columnDefs: RequestColumnDefinitionForImplications,
  };
  rowDataWhole: IQuotaImplicationsData[] = [];
  enableFilter = false;
  region = "";
  teamName = "";
  gridApi: GridApi;
  quotaDetails = "";
  isRowSelected = false;
  isCompleted = false;
  lastSubscriptionId = "";

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  ngOnInit() {
    const filteredSubRequests = this.filterSubRequests(this.options);

    filteredSubRequests.map((option) => {
      if (option.RequestServiceType === RequestServiceType.ArmVmQuota || option.RequestServiceType === RequestServiceType.RdfeVmQuota) {
        if (Number(option.Quota) > 0) {
          const deploymentType = GetDeploymentTypeByService(option.RequestServiceType);
          const data = this.rowDataWhole.find(
            (d) => d.DeploymentSystem === deploymentType && d.Sku === option.SKU && d.ResourceType === ResourceType.Compute
          );

          if (data) {
            data.Quota += Number(option.Quota) || 0;
          } else {
            this.rowDataWhole.push({
              DeploymentSystem: deploymentType,
              Sku: option.SKU,
              ResourceType: ResourceType.Compute,
              PreApprovedQuota: 0,
              StampedQuota: 0,
              Quota: Number(option.Quota) || 0,
              hide: false,
            });
          }
        }
      } else if (
        option.RequestServiceType === RequestServiceType.ArmStorageQuota ||
        option.RequestServiceType === RequestServiceType.RdfeStorageQuota
      ) {
        const deploymentType = GetDeploymentTypeByService(option.RequestServiceType);
        const data = this.rowDataWhole.find((d) => d.DeploymentSystem === deploymentType && d.ResourceType === ResourceType.Storage);
        if (data) {
          data.Quota += Number(option.Quota) || 0;
        } else {
          this.rowDataWhole.push({
            DeploymentSystem: deploymentType,
            ResourceType: ResourceType.Storage,
            PreApprovedQuota: 0,
            StampedQuota: 0,
            Quota: Number(option.Quota) || 0,
            hide: false,
          });
        }
      }
    });
    this.region = this.options[0].Region;
    this.teamName = this.options[0].Requestor;

    this.apiService.getQuotaImplications(this.options[0].TeamOid, this.region).subscribe((response) => {
      response.map((res) => {
        const data = this.rowDataWhole.find((d) => {
          if (d.ResourceType === ResourceType.Compute) {
            return d.DeploymentSystem === res.DeploymentSystem && d.Sku === res.Sku && d.ResourceType === res.ResourceType;
          } else {
            return d.DeploymentSystem === res.DeploymentSystem && d.ResourceType === res.ResourceType;
          }
        });
        if (data) {
          data.PreApprovedQuota += res.PreApprovedQuota;
          data.StampedQuota += res.StampedQuota;
        }
      });
      this.rowData = this.rowDataWhole;
      this.enableFilter = true;
    });
  }

  onGridReady(params: GridOptions, selected: boolean): void {
    this.gridApi = params.api;
    if (selected) {
      params.api.selectAll();
    }
    params.api.sizeColumnsToFit();
  }

  onRowClicked(params: GridOptions): void {
    if (params.api.getSelectedRows().length === 1) {
      const rows: ISubRequest[] = params.api.getSelectedRows();
      if (rows[0].SubscriptionId !== this.lastSubscriptionId) {
        this.quotaDetails = "";
        this.isRowSelected = true;
        this.isCompleted = false;
        this.apiService.getQuotaDetails(rows[0].SubscriptionId, this.region).subscribe(
          (response) => {
            if (response) {
              this.quotaDetails = rows[0].SubscriptionId + ": " + JSON.stringify(response);
            } else {
              this.quotaDetails = "No quota details displayed.";
            }
          },
          () => {
            this.quotaDetails = "Failed to get quota details.";
            this.isCompleted = true;
          },
          () => {
            this.isCompleted = true;
          }
        );
        this.lastSubscriptionId = rows[0].SubscriptionId;
      } else {
        this.isCompleted = true;
      }
    }
  }

  onSelectionChanged(params: GridOptions): void {
    if (this.enableFilter) {
      this.rowDataWhole = this.rowDataWhole.map((data) => {
        return { ...data, Quota: 0, hide: true };
      });

      const selectedSubRequests = params.api.getSelectedRows() as ISubRequest[];
      const filteredSubRequests = this.filterSubRequests(selectedSubRequests);

      filteredSubRequests.map((row: ISubRequest) => {
        const resourceType = GetResourceTypeByService(row.RequestServiceType);
        const deploymentType = GetDeploymentTypeByService(row.RequestServiceType);
        if (resourceType === ResourceType.Storage && row.Quota !== undefined) {
          const data = this.rowDataWhole.find((d) => d.DeploymentSystem === deploymentType && d.ResourceType === resourceType);
          data.Quota += Number(row.Quota) || 0;
          data.hide = false;
        }

        if (resourceType === ResourceType.Compute && row.Quota !== undefined) {
          const data = this.rowDataWhole.find(
            (d) => d.DeploymentSystem === deploymentType && d.Sku === row.SKU && d.ResourceType === resourceType
          );
          data.Quota += Number(row.Quota) || 0;
          data.hide = false;
        }
      });

      this.rowData = this.rowDataWhole.filter((data) => data.hide !== true);
    }
  }

  onSaveOrReject(type: string) {
    this.activeModal.close({
      type,
      data: this.gridApi.getSelectedRows(),
    });
  }

  // Filter max quota for the same service, sub, sku
  filterSubRequests(subRequests: ISubRequest[]): ISubRequest[] {
    const resultList: ISubRequest[] = [];
    subRequests.forEach((req) => {
      const existingRequest = resultList.find((item) => {
        return item.RequestServiceType === req.RequestServiceType && item.SubscriptionId === req.SubscriptionId && item.SKU === req.SKU;
      });

      if (!existingRequest) {
        resultList.push(deepCopy(req));
      } else if (req.Quota > existingRequest.Quota) {
        existingRequest.Quota = req.Quota;
      }
    });

    return resultList;
  }
}
