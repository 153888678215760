import { Component } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { PlansMyRegionalPlanColumnDefinition } from "./ag-grid-column-definition";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";

@Component({
  templateUrl: "./plan-my-regional-plans.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansMyRegionalPlansComponent extends GridViewBaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize: number = 15;
  keyFilterStats = "filter:my-regional-plans";
  rowData: PlanRegion[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  constructor(
    protected apiService: ApiService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    super.InitAgGrid(PlansMyRegionalPlanColumnDefinition);
    this.refreshData();
  }

  refreshData() {
    this.loadingService.setLoading(true);
    this.apiService.getMyRegionalPlans().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  exportExcel() {
    var fileName = "MyRegionalPlans-" + getExportedExcelFileNameSuffix();
    var sheetName = "My Regional Plans";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
