import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { forkJoin, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AccountInfoExtended, AgsAuthService } from "@ags/core";
import { Router } from "@angular/router";
import {
  AvailabilityZone,
  BillingMeterDetailsPayload,
  ProductOnboardBigIdPayload,
  ProductOnboardStatusPayload,
  Region,
  RequestByProductPayload,
  Service,
} from "./model";
import { Product } from "../ondemand/model";
import * as _ from "lodash";
import { Status } from "../status/model";
import { AckErrorBillingMeterRequest } from "../error-billing-meter-list/model";
import { SkipList } from "../skip-list/model";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private backendApiRoot = environment.betApiEndpoint;

  constructor(private http: HttpClient, private ags: AgsAuthService, private router: Router) {}

  private send<T>(block: (HttpClient) => Observable<T>) {
    const handleError = this.handleError;
    return block(this.http).pipe(catchError(handleError));
  }

  private get<T>(url: string, options = {}): Observable<T> {
    return this.send((http) => http.get(`${this.backendApiRoot}${url}`, options));
  }

  private post<T>(url: string, body = {}, options = {}): Observable<T> {
    return this.send((http) => http.post(`${this.backendApiRoot}${url}`, body, options));
  }

  CheckPermission() {
    return this.get("/api/checkPermission");
  }

  GetClouds() {
    return this.get("/api/Clouds");
  }

  GetRegions() {
    return this.get("/api/Regions");
  }

  GetEdgeZones() {
    return this.get("/api/EdgeZones");
  }

  GetAvailabilityZones() {
    return this.get("/api/AvailabilityZones");
  }

  GetPsl(regionName: string) {
    return this.get(`/api/PSL/${regionName}`);
  }

  getSkipList() {
    return this.get(`/api/SkipList`);
  }

  modifySkipList(modifiedSkipList: SkipList[]) {
    console.log(modifiedSkipList);
    return this.post(`/api/SkipList`, modifiedSkipList);
  }
  downloadFullReport(): any {
    return this.get(`/api/productRequestReport`, { responseType: "blob" });
  }

  exportExcelWithDetails(requests: any[]): any {
    return this.post(`/api/ExportBillingMeterDetails`, { data: requests }, { responseType: "blob" });
  }

  exportExcelRequestWithDetails(requests: any[], buildoutType: string): any {
    return this.post(`/api/ExportRequestBillingMeterDetails`, { data: requests, buildoutType: buildoutType }, { responseType: "blob" });
  }

  GetJobs(regionNames: string[]) {
    return this.get(`/api/Jobs/${regionNames.join(",")}`);
  }

  SearchProduct(region: string, q: string) {
    return this.get(`/api/searchProducts?region=${region}&q=${q}&from=0&size=1`);
  }

  GetInScopeServices(regionName: string) {
    const services = this.get(`/api/InScopeServices/${regionName}`);
    const jobs = this.get(`/api/Jobs/${regionName}`);
    return forkJoin([services, jobs]);
  }

  GetPslProduct(pslId: number) {
    return this.get(`/api/PSL/product/${pslId}`);
  }

  SubmitPslProduct(pslId: number, caymanProductIds: string[]) {
    return this.post(`/api/PSL/product/${pslId}`, caymanProductIds);
  }

  GetProductOnboardStatus(): Observable<ProductOnboardStatusPayload> {
    return this.get<ProductOnboardStatusPayload>(`/api/GetOnboarding`);
  }

  GetProductOnboardingBigIds(bigId: string): Observable<ProductOnboardBigIdPayload> {
    return this.get<ProductOnboardBigIdPayload>(`/api/GetOnboarding/BigIds/${bigId}`);
  }

  GetRequestsByProduct(regionName: string, productOid: string): Observable<RequestByProductPayload> {
    return this.get<RequestByProductPayload>(`/api/RequestsByProduct/${regionName}/${productOid}`);
  }

  GetRequestStatus(regionNames: string[], key: string, fromDate: string, toDate: string) {
    return this.get(`/api/RequestStatus/${regionNames.join(",")}/'${key}'/'${fromDate}'/'${toDate}'`);
  }

  GetRequestStatusByJobId(jobId: string, fromDate: string, toDate: string) {
    return this.get(`/api/RequestStatus/''/''/'${fromDate}'/'${toDate}'?job_id=${jobId}`);
  }

  GetBillingMeterDetails(jobId: string, bigId: string): Observable<BillingMeterDetailsPayload> {
    return this.get<BillingMeterDetailsPayload>(`/api/BillingMeterDetails/${jobId}/${bigId}`);
  }

  GetBuildoutStatus(regionNames: string[], fromDate: string, toDate: string) {
    return this.get(`/api/BuildoutStatus/${regionNames.join(",")}/'${fromDate}'/'${toDate}'`);
  }

  GetProductList(RegionNames: string[], product: string) {
    const newProduct = product.replace(/[,|\n|\n\r]+/g, ",");
    return this.post("/api/OndemandProduct/", {
      regions: RegionNames,
      key: newProduct,
    });
  }

  GetIncrementalServices(regionNames: string[]) {
    return this.get(`/api/GetIncrementalServices/${regionNames.join(",")}`);
  }

  GetOnboardedProductList() {
    return this.get(`/api/GetOnboardedProductList`);
  }

  GetOnboardProductList(products: string) {
    return this.post(`/api/GetOnboardProductList`, { products: products });
  }

  GetProductTrackInfo(key: string, region: string) {
    return this.get(`/api/ProductStatusTrack?key=${key}&region=${region}`);
  }

  GetErrorBillingMeterRequests() {
    return this.get(`/api/ErrorBillingMeterRequests`);
  }

  AckErrorBillingMeterRequests(data: Map<string, AckErrorBillingMeterRequest[]>) {
    return this.post(`/api/AckErrorBillingMeterRequests`, data);
  }

  OnboardProducts(productOids: string[], comments: string) {
    return this.post(`/api/OnboardProducts`, { productOids: productOids, comments: comments });
  }

  NewRegionBillingMeter(regionName: string, services: Service[], user: AccountInfoExtended) {
    return this.post(`/api/NewRegionBillingMeter`, {
      regionName: regionName,
      caymanProductIds: [...new Set(_.map(services, (s) => s.CaymanProductId))],
      createdBy: user.username,
    });
  }

  IncrementalBillingMeter(services: Service[], user: AccountInfoExtended) {
    services.forEach((s) => {
      console.log(`S: ${JSON.stringify(s)}`);
    });
    const result = this.post(
      `/api/IncrementalBillingMeter`,
      _.map(
        _.groupBy(services, (s) => s.EdgeZoneName || s.RegionName),
        (v, k) => {
          return {
            regionName: k,
            productOids: _.map(v, (s) => s.ProductOid),
            createdBy: user.username,
          };
        }
      )
    );
    console.log(`Incremental Pre-Buildout triggered! Total Count: ${services.length}`);
    return result;
  }

  PostOnDemandPreBuildout(products: Product[], user: AccountInfoExtended) {
    const caymanProducts = _.map(
      _.groupBy(products, (p) => p.RegionName),
      (v, k) => {
        return {
          regionName: k,
          caymanProducts: v,
          createdBy: user.username,
        };
      }
    );
    return this.post(`/api/OnDemandPrebuildout`, caymanProducts);
  }

  PostInitialBulkSubmit(products: Product[], user: AccountInfoExtended) {
    const caymanProducts = _.map(
      _.groupBy(products, (p) => p.RegionName),
      (v, k) => {
        return {
          regionName: k,
          caymanProducts: _.map(v, (p) => {
            return {
              locationId: p.LocationId,
              locationType: p.LocationType,
              caymanProductId: p.CaymanProductId,
              regionName: p.RegionName,
              productName: p.ProductName,
              productOid: p.ProductOid,
              caymanProductName: p.CaymanProductName,
              productRing: p.ProductRing,
            };
          }),
          createdBy: user.username,
        };
      }
    );
    return this.post(`/api/InitialBulkSubmit`, caymanProducts);
  }

  PostResubmitPreBuildout(status: Status[], user: AccountInfoExtended) {
    const caymanProducts = _.map(
      _.groupBy(status, (p) => p.EdgeZoneName || p.RegionName),
      (v, k) => {
        return {
          regionName: k,
          caymanProducts: _.map(v, (p) => {
            return {
              locationId: p.LocationId,
              locationType: p.LocationType,
              caymanProductId: p.CaymanProductId,
              regionName: p.EdgeZoneName || p.RegionName,
              productName: p.ProductName,
              productOid: p.ProductOid,
              caymanProductName: p.CaymanProductName,
              productRing: p.ProductRing,
            };
          }),
          createdBy: user.username,
        };
      }
    );
    return this.post(`/api/ResubmitPrebuildout`, caymanProducts);
  }

  PostInitBuildout(region: Region, az: AvailabilityZone, user: AccountInfoExtended) {
    let result: any = null;
    if (az) {
      result = this.post(`/api/InitBuildoutRegion`, {
        regionName: region.Name,
        index: az.Index,
        azGuid: az.DCMT_AvailabilityZoneId,
        createdBy: user.username,
      });
    } else {
      result = this.post(`/api/InitBuildoutRegion`, {
        regionName: region.Name,
        createdBy: user.username,
      });
    }
    console.log("Buildout triggered!");
    return result;
  }

  private handleError = (error: HttpErrorResponse) => {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `A client-side or network error occurred: ${error.error.message}`;
    } else {
      if (error.status === 0) {
        errorMessage = "AJAX request was cancelled. ";
      } else if (error.status === 404) {
        this.toError(error);
        return;
      } else if (error.status === 403) {
        this.toError(error);
        return;
      }

      if (error.error?.message) {
        errorMessage += `Server response: ${error.error.message}.`;
      } else if (typeof error.error === "string") {
        errorMessage += `Server response: ${error.error}.`;
      } else {
        // format error message (exclude http statusText since it's removed in http2)
        // refer to source code https://github.com/angular/angular/blob/main/packages/common/http/src/response.ts#L319-L350
        if (error.status >= 200 && error.status < 300) {
          errorMessage += `Server response: Http failure during parsing for ${error.url || "(unknown url)"}.`;
        } else {
          errorMessage += `Server response: Http failure response for ${error.url || "(unknown url)"}: ${error.status}.`;
        }
      }
    }

    console.error(errorMessage);
    return throwError({ message: errorMessage });
  };

  toError(error: HttpErrorResponse) {
    let errorMessage: string = error.message;
    if (error.status === 404) {
      errorMessage = "Resource not found. ";
    } else if (error.status === 403) {
      errorMessage = "Access denied.";
    }
    this.router.navigate(["/prep/error"], {
      queryParams: {
        status: error.status,
        message: errorMessage,
      },
    });
  }
}
