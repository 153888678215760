import { Component, HostListener, OnInit } from "@angular/core";
import { ColDef, ColumnApi, FilterChangedEvent, GridApi, GridOptions, RowNode } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { ModalService } from "src/app/shared/modal.service";
import * as CDs from "src/app/utility/commonColDef";
import { extendDefaultOptions, setupFilterByLocalData, cacheFilterStatsLocal } from "../../utility/gridHelper";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApiService } from "../../services";
import { IUserProfile, FfpPlanListRecord } from "src/app/npr-request.model";
import { FfpPlanSubmission } from "../../generated-models/FfpPlanSubmission";
import { ProjectBuildout } from "../../generated-models/ProjectBuildout";
import { FfpPlanInstance } from "../../generated-models/FfpPlanInstance";

interface RowDataForProject {
  ProjectOid: string;
  ProjectStatus: string;
  AssignedVersion?: number;
  AlreadyAssigned?: string;
  AssignedBy?: string;
  AssignedTime?: Date | null;
  RegionalPlanStatus?: string;
}

@Component({
  templateUrl: "./ffp-plans-assignment-service-owner.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpPlansAssignmentServiceOwnerComponent extends BaseComponent implements OnInit {
  projects: ProjectBuildout[] = [];
  selectedProject: ProjectBuildout;
  userProfile: IUserProfile = null;

  rowDataForProject: RowDataForProject[] = [];

  gridOptionsForProject: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;

  ffpPlans: FfpPlanListRecord[] = [];
  selectedPlan: FfpPlanListRecord;

  ffpPlanInstances: FfpPlanInstance[] = [];

  hideAlreadyAssigned = false;
  hasRowSelected = false;

  keyFilterStatusMultiRegions = "filter:one-to-multiple";
  canAssign = false;

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.hasRowSelected = false;
    this.canAssign = false;

    const ProjectsColumnDefinition: ColDef[] = [
      {
        colId: "customFilter",
        valueGetter: (params: ColParams<RowDataForProject, void>) => {
          if (this.hideAlreadyAssigned) {
            return this.isAssignable(params.data);
          }

          return true;
        },
        filterParams: {
          values: ["true", "false"],
          newRowsAction: "keep",
        },
        hide: true,
      },
      {
        ...CDs.ProjectOidColDef("ProjectOid"),
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      CDs.AlreadyAssigned,
      CDs.AssignedVersion,
      CDs.AssignedBy,
      CDs.AssignedTime,
      CDs.RegionalPlanStatus,
    ];

    this.gridOptionsForProject = extendDefaultOptions({
      columnDefs: ProjectsColumnDefinition,
      suppressPaginationPanel: true,
      isRowSelectable: (node: RowNode) => this.isAssignable(node.data),
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
        ]
      }
    });

    this.apiService.getUserProfile().then(async (response) => {
      this.userProfile = response;
      if (response) {
        this.gridApi?.showLoadingOverlay();

        // Projects List
        const projectListResponse = await this.apiService.getFfpProjectBuildouts().toPromise();
        if (!projectListResponse) {
          console.warn("No region obtained from server.");
        } else {
          this.projects = projectListResponse
        }
      }
    });


    this.loadingService.setLoading(true);

    // FfpPlan List
    const ffpPlanListResponse = await this.apiService.getMyFfpPlans().toPromise();
    if (!ffpPlanListResponse) {
      console.warn("No plan obtained from server.");
    } else {
      this.ffpPlans = ffpPlanListResponse
        .filter((p) => p.Version > 0)
        .map((p) => ({ ...p, Label: `${p.ServiceTeam} (${p.ServiceTreeId}) [${p.Type}]` } as FfpPlanListRecord));
    }

    await this.getLatestFfpInstances();

    this.loadingService.setLoading(false);
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  async getLatestFfpInstances(): Promise<void> {
    return this.apiService
      .getLatestFfpInstances()
      .toPromise()
      .then(
        (response) => {
          this.ffpPlanInstances = response;
          setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
        },
        (e: any) => {
          this.ffpPlanInstances = [];
          this.notificationService.error(e);
        }
      );
  }

  onPlanChanged(): void {
    this.rowDataForProject = [];

    if (this.selectedPlan) {
      const rowData: RowDataForProject[] = [];
      this.projects.forEach((project) => {
        const row: RowDataForProject = {
          ProjectOid: project.ProjectOid,
          ProjectStatus: project.Status,
        };
        rowData.push(row);
      });

      rowData.forEach((row) => {
        row.AlreadyAssigned = "No";

        const ffpPlanInstance = this.ffpPlanInstances.find(
          (p) => p.ProjectOid === row.ProjectOid && p.ServiceTreeId === this.selectedPlan.ServiceTreeId
        );
        if (ffpPlanInstance) {
          if (ffpPlanInstance.Version === this.selectedPlan.Version) {
            row.AlreadyAssigned = "Yes";
          }

          row.AssignedVersion = ffpPlanInstance.Version;
          row.AssignedBy = ffpPlanInstance.Submitter;
          row.AssignedTime = ffpPlanInstance.SubmitTime;
          row.RegionalPlanStatus = ffpPlanInstance.Status;
        }
      });
      this.rowDataForProject = rowData;
      this.hasRowSelected = false;
      this.canAssign = false;
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 100);
    }
    setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
  }

  // Plan is not able to be assigned if the latest version is already assigned to the region
  isAssignable(row: RowDataForProject): boolean {
    return row.AlreadyAssigned === "No";
  }

  onHideAlreadyAssignedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  async showFfpPlanAssignmentDialog(): Promise<void> {
    try {
      await this.assignFfpPlans();
    } catch {
      // For the model dialog dimiss
      return;
    }
  }

  async assignFfpPlans(): Promise<void> {
    const selectedRows = this.gridApi.getSelectedRows() as RowDataForProject[];
    const ffpPlanSubmissions = selectedRows.map((r) => {
      return {
        ServiceTreeId: this.selectedPlan.ServiceTreeId,
        ServiceTeam: this.selectedPlan.ServiceTeam,
        Version: this.selectedPlan.Version,
        ProjectOid: r.ProjectOid,
      } as FfpPlanSubmission;
    });

    await this.modalService.ffpPlanAssignmentResponseModal(ffpPlanSubmissions);
    await this.getLatestFfpInstances();

    // reload grid
    this.onPlanChanged();
  }

  onSelectionChanged(params: GridOptions): void {
    var rows = params.api.getSelectedRows().length;
    this.hasRowSelected = rows > 0;
    if (!this.hasRowSelected) {
      this.canAssign = false;
    }
    else {
      this.canAssign = true;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onFilterChangedPlan(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusMultiRegions);
  }
}
