import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services";
import { BaseComponent } from "../../shared/base.component";
import { NgModel } from "@angular/forms";
import { ITeamListRecord } from "../../npr-request.model";
import { Router } from "@angular/router";

@Component({
  templateUrl: "./plan-bindings-search.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansBindingsSearchComponent extends BaseComponent implements OnInit {
  teamList: ITeamListRecord[] = [];
  team = null;

  constructor(private apiService: ApiService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.getTeamList();
  }

  search() {
    this.router.navigate([`/plans/services/${this.team.oid}/bindings`]);
  }

  isTouchedOrDirty(control: NgModel): boolean {
    return control && (control.touched || control.dirty);
  }

  getTeamList(): void {
    this.apiService?.getMyServiceTeamUser().subscribe((response) => {
      if (response) {
        const regionlessTeam = [];
        this.teamList = [];
        response.map((r) => {
          const label = `${r.ServiceName} (${r.ServiceTreeId})`;
          if (r.Ring) {
            if (this.teamList.findIndex(team => team.label === label) === -1) {
              this.teamList.push({
                label: `${r.ServiceName} (${r.ServiceTreeId})`,
                value: 0,
                ring: r.Ring,
                oid: r.ServiceTreeId,
              } as ITeamListRecord);
            }
          } else {
            if (regionlessTeam.findIndex(team => team.label === label) === -1) {
              regionlessTeam.push({
                label: `${r.ServiceName} (${r.ServiceTreeId})`,
                value: 0,
                ring: "No Ring",
                oid: r.ServiceTreeId,
              } as ITeamListRecord);
            }
          }
        });
        this.teamList = this.teamList
          .sort((a, b) => {
            return a.ring?.localeCompare(b.ring) || a.label?.localeCompare(b.label);
          })
          .concat(regionlessTeam.sort((a, b) => a.label?.localeCompare(b.label)));
      }
    }, () => {
      this.teamList = [];
    });
  }

}
