import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IUIRequest } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";

@Component({
  templateUrl: "./validate-request-modal.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class ValidateRequestModalComponent implements OnInit {
  @Input() request: IUIRequest;
  @Input() screenNumber: number;

  public aggregatedResult: string[] = [];
  public aggregatedResultTemp: string[] = [];
  public isCompleted = false;
  public closeBtnVisible = true;
  private validationFailed = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  async ngOnInit() {
    this.validateRequest(this.request, this.screenNumber).then(() => {
      if (!this.validationFailed) {
        if (this.request.TeamOid && this.request.Region) {
          this.activeModal.close();
          this.aggregatedResult = [...this.aggregatedResultTemp];
          this.isCompleted = true;
        } else {
          this.activeModal.close();
        }
      } else {
        this.aggregatedResult = [...this.aggregatedResultTemp];
        this.isCompleted = true;
      }
    });
  }

  validateRequest(request: IUIRequest, screenNumber: number) {
    const commonMessage = `<b>${request.Region}</b> for <b>${request.SubscriptionId}</b>`;
    return this.apiService
      .validateRequest(request, screenNumber)
      .toPromise()
      .then(() => {
        this.validationFailed = false;
        this.aggregatedResultTemp.push(`Validation pass in ${commonMessage}`);
      })
      .catch((err) => {
        this.validationFailed = true;
        this.aggregatedResultTemp.push(`Validation failed in ${commonMessage}<br /><span class='red-text'>${err}</span>`);
      });
  }
}
