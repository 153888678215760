<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>All Regional Plans</h4>
      <p>Regional Plans from all service teams</p>
    </div>
    <div class="action-container">
      <button *ngIf="isAdmin" type="submit" class="btn btn-delete mr-1" [disabled]="!hasRowSelected" (click)="batchRemovePlans()">Remove</button>
      <button type="submit" class="btn btn-primary mr-1" (click)="refreshData()">Refresh</button>
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="exportExcel()"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main mt-3">
    <ag-grid-angular
      #agGrid
      id="ViewGrid"
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [excelStyles]="headerStyle"
      (selectionChanged)="onSelectionChanged($event)"
      pagination="true"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
    >
    </ag-grid-angular>
  </div>
</div>
