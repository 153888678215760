import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ColDef, ColGroupDef, ColumnApi, ExcelExportParams, FilterChangedEvent, GridApi, GridOptions } from "ag-grid-community";
import * as CDs from "src/app/utility/commonColDef";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { Plan } from "../../generated-models";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { SharedDataService } from "../../services/sharedDataService";
import { planEditorCellComponentParams, viewRegionAgnosticPlanHistoryCellComponentParams } from "../plans/ag-grid-column-definition";
import { PlanFileStatus } from "src/app/generated-models/PlanFileStatus";
import { cacheFilterStatsLocal, setupFilterByLocalData } from "src/app/utility/gridHelper";
import { IPlansUnsupportedSkusRowData, PlansRisksViewDataInfo } from "src/app/npr-request.model";
import { PlansRisks } from "src/app/generated-models/PlansRisks";
import { GridViewBaseComponent } from "src/app/utility/grid-view-base.component";
import { PlanDetail } from "src/app/generated-models/PlanDetail";


@Component({
  templateUrl: "./plans-risks.component.html",
  styleUrls: ["../../styles.scss"],
})
export class PlansRisksComponent extends GridViewBaseComponent implements OnInit {
  regionSkus: Map<string, PlanDetail[]> = new Map<string, PlanDetail[]>();
  gridOptions: GridOptions;
  initPageSize: 15;
  keyFilterStats: string = "filter:plans-risks";
  tableList: PlansRisksViewDataInfo[];
  tableItem: PlansRisksViewDataInfo;
  tableName: string;
  columnDefs: (ColDef | ColGroupDef)[];
  rowData: (PlanFileStatus | Plan | IPlansUnsupportedSkusRowData)[] = [];
  tableRadio: number = 0;

  
  private PlansUncommittedColumnDef = [
    {
        ...CDs.ServiceTreeId,
        cellRendererFramework: LinkCellComponent,
        cellRendererParams: planEditorCellComponentParams,
    },
    CDs.ServiceTeam,
    CDs.Ring,
    CDs.LatestVersion,
    {
      headerName: "Last Modified Time", 
      field: "LastModified",
      cellRenderer: (params) => PreprocessDateTime(params.value),
    },
    { headerName: "Last Modified By", field: "LastModifiedBy" },
  ];

  private TeamsNotOnboardedColumnDef = [
    {
        ...CDs.ServiceTreeId,
        cellRendererFramework: LinkCellComponent,
        cellRendererParams: planEditorCellComponentParams,
    },
    CDs.ServiceTeam,
    CDs.Ring,
    CDs.CreatedTime,
    CDs.CreatedBy,
  ];

  private PlansUnsupportedSkusColumnDef = [
    CDs.ServiceTreeId,
    {
      ...CDs.LatestVersion,
      cellRendererFramework: LinkCellComponent,
      cellRendererParams: viewRegionAgnosticPlanHistoryCellComponentParams,
    },
    { headerName: "Subscription Name", field: "SubscriptionName"},
    CDs.Stage,
    { ...CDs.RegionColDef("RegionName"), valueType: "region" },
    { headerName: "Unsupported SKU Name", field: "SkuName"},
    { headerName: "Service Type", field: "ServiceType"},
  ];

  public gridApi: GridApi;
  public columnApi: ColumnApi;

  constructor(protected apiService: ApiService, protected notificationService: ToastrService, protected loadingService: LoadingScreenService, protected sharedDataService: SharedDataService) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    super.InitAgGrid(this.PlansUncommittedColumnDef);
    
  }
  
  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
  }

  async refreshData(): Promise<void> {
    this.getTableList();
  }

  onFilterChanged(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStats)
  }

  async onRadioChanged() {
    this.tableItem = this.tableList[this.tableRadio];
    if (this.tableItem.RowData == null && this.tableItem.TableName === 'Plans With Unavailable Skus') {
      await this.getPlansWithUnavailableSkus();
    }
    this.tableName = this.tableItem.TableName;
    this.columnDefs = this.tableItem.ColumnDefs;
    this.rowData = this.tableItem.RowData;
  }

  async getPlansWithUnavailableSkus() {
    this.loadingService.setLoading(true);
      var planDetails = await this.apiService.getRegionalPlansAllVmSkus().toPromise();
      var vmSkus = await this.apiService.getVmSkusByRegion().toPromise();
        let rowData: IPlansUnsupportedSkusRowData[] = [];
        for (const planDetail of planDetails) {
          if (this.regionSkus.has(planDetail.Region)) {
            this.regionSkus.get(planDetail.Region)?.push(planDetail);
          } else {
            this.regionSkus.set(planDetail.Region, [planDetail]);
          }
        }
    
        for (const [region, planDetails] of this.regionSkus) {
          const regionSupportedVmSku = vmSkus.filter((sku) => sku.RegionName === region);
            for (const planDetail of planDetails) {
              if (!regionSupportedVmSku.some((r) => r.CrpVmSku === planDetail.Sku)) {
                rowData.push({
                  ServiceTreeId: planDetail.ServiceTreeId,
                  Version: planDetail.Version,
                  SubscriptionName: planDetail.SubscriptionName,
                  Stage: planDetail.StageName,
                  RegionName: region,
                  SkuName: planDetail.Sku,
                  ServiceType: planDetail.Service
                } as IPlansUnsupportedSkusRowData);
              }
            }
        } 
     
          this.tableItem.RowData = rowData;
          this.loadingService.setLoading(false);
          setTimeout(() => {
            this.gridColumnApi.autoSizeAllColumns();
          }, 100);
          super.setupFilterByLocalData();
  }

  async exportCurrentView(tableName: string): Promise<void> {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: tableName.replace(/\s/g, '') + '-'+ PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm", "en-US"),
      sheetName: tableName,
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }

  getTableList() {
    this.loadingService.setLoading(true);
      this.apiService.getPlansRisks().subscribe((plansRisks: PlansRisks) => {     
        this.tableList = [
          {
            TableName: "Uncommitted Plans",
            ColumnDefs: this.PlansUncommittedColumnDef,
            RowData: plansRisks.UncommittedPlans,
          },
          {
            TableName: "Teams Not Onboarded",
            ColumnDefs: this.TeamsNotOnboardedColumnDef,
            RowData: plansRisks.TeamsNotOnboarded,
          },
          {
            TableName: "Plans With Unavailable Skus",
            ColumnDefs: this.PlansUnsupportedSkusColumnDef,
            RowData: null,
          }
        ];

        this.onRadioChanged();
        this.loadingService.setLoading(false);
        setupFilterByLocalData(this.gridApi, this.columnApi, this.keyFilterStats);
      },
      (e: any) => {
          this.tableList = [
            {
              TableName: "Uncommitted Plans",
              ColumnDefs: this.PlansUncommittedColumnDef,
              RowData: [],
            },
            {
              TableName: "Teams Not Onboarded",
              ColumnDefs: this.TeamsNotOnboardedColumnDef,
              RowData: [],
            },
            {
              TableName: "Plans With Unavailable Skus",
              ColumnDefs: this.PlansUnsupportedSkusColumnDef,
              RowData: null,
            }
          ];
          this.onRadioChanged();
          this.loadingService.setLoading(false);
          this.notificationService.error(e);
      });
  }
}
