import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { InformationModalComponent } from "./information-modal/information-modal.component";
import { SelectOptionModalComponent } from "./select-option-modal/select-option-modal.component";
import { SelectOptionAndInputModalComponent } from "./select-option-and-input-modal/select-option-and-input-modal.component";
import { RequestInputModalComponent } from "./request-input-modal/request-input-modal.component";
import {
  IUserProfile,
  IWorkItemResponse,
  IUIRequest,
  ISubRequest,
  RequestOperation,
  IEditModalOption,
  IRegionGroupListRecord,
  IParentRequest,
  PlanAssignmentCSVRecord,
} from "../npr-request.model";
import { QuotaImplicationsModalComponent } from "./quota-implications-modal/quota-implications-modal.component";
import { EditModalComponent } from "./edit-modal/edit-modal.component";
import { ApproverEditModalComponent } from "./approver-edit-modal/approver-edit-modal.component";
import { TicketCommentsModalComponent } from "./ticket-comments-modal/ticket-comments-modal.component";
import { WorkitemBulkModalComponent } from "./workitem-bulk-modal/workitem-bulk-modal.component";
import { SubscriptionDetailsModalComponent } from "./subscription-details-modal/subscription-details-modal.component";
import { AZMappingsModalComponet } from "./az-mapping-modal/az-mappings-modal.component";
import { FilterModalComponent } from "./filter-modal";
import { DatepickerModalComponent } from "./datepicker-modal/datepicker-modal.component";
import { SubrequestResponseModalComponent } from "../quota/incidents-view/active-subrequests/subrequest-response-modal.component";
import { PromptModalComponent } from "./prompt-modal/prompt-modal.component";
import { PreviousApprovalsModalComponent } from "./previous-approvals-modal/previous-approvals-modal.component";
import { PlannedQuotaRequestDetail, Region, UserRole } from "../generated-models";
import { CreateRequestResponseModalComponent } from "../quota/create-request/modals/create-request-response-modal.component";
import { CreateRequestModalComponent } from "../quota/create-request/modals/create-request-modal.component";
import { ValidateRequestModalComponent } from "../quota/create-request/modals/validate-request-modal.component";
import { ParentRequestsResponseModalComponent } from "../quota/incidents-view/error-requests/parentrequests-response-modal.component";
import {
  PlanApprovalModalComponent,
  PlanApprovalModalOptions,
} from "../quota/plans/modals/plan-approval-modal/plan-approval-modal.component";
import {
  PlanNewFileModalComponent,
  PlanNewFileModalOptions,
} from "../quota/plans/modals/plan-new-file-modal/plan-new-file-modal.component";
import { PlanEditorModalComponent, PlanEditorModalOptions } from "../quota/plans/modals/plan-editor-modal/plan-editor-modal.component";
import {
  PlanOnboardingModalComponent,
  PlanOnboardingModalOptions,
} from "../quota/plans/modals/plan-onboarding-modal/plan-onboarding-modal.component";
import { PlanAssignmentModalComponent } from "../quota/plans/modals/plan-assignment-modal/plan-assignment-modal.component";
import { PlanAssignmentCSVModalComponent } from "../quota/plans/modals/plan-assignment-csv-modal/plan-assignment-csv-modal.component";
import { AddUserComponent, AddUserModalOptions } from "../shared/add-user-modal/add-user.component";
import { PlanCommitModalComponent } from "../quota/plans/modals/plan-commit-modal/plan-commit-modal.component";
import { PlanHistoryModalComponent } from "../quota/plans/modals/plan-history-modal/plan-history-modal.component";
import { PlanRejectModalComponent } from "../quota/plans/modals/plan-reject-modal/plan-reject-modal.component";
import { PlanBatchApprovalComponent } from "../quota/plans/modals/plan-batch-approval-modal/plan-batch-approval-component";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { PlanAssignmentResponseModalComponent } from "../quota/plans/modals/plan-assignment-response-modal/plan-assignment-response-modal.component";
import { PlanAssignmentReconfirmModalComponent } from "../quota/plans/modals/plan-assignment-response-modal/plan-assignment-reconfirm-modal.component";
import { PlanSubmission } from "../generated-models/PlanSubmission";
import { PlanDiffModalComponent, PlanDiffModalOptions } from "../quota/plans/modals/plan-diff-modal/plan-diff-modal.component";
import { PlanCommitResponseModalComponent } from "../quota/plans/modals/plan-commit-response-modal/plan-commit-response-modal.component";
import { PlanRemoveModalComponent } from "../quota/plans/modals/plan-remove-modal/plan-remove-modal.component";
import { PlannedQuotaRequestConfirmationModal } from "../quota/planned-quota-request-detail/planned-quota-request-confirmation-modal.component";
import { EmailDetailModalComponent, EmailDetailModalOptions } from "./email-detail-modal/email-detail-modal.component";
import { PlanReprocessModalComponent } from "../quota/plans/modals/plan-reprocess-modal/plan-reprocess-modal.component";
import { FfpPlanHistoryModalComponent } from "../quota/ffp/modals/ffp-plan-history-modal/ffp-plan-history-modal.component";
import { FfpPlanEditorModalComponent } from "../quota/ffp/modals/ffp-plan-editor-modal/ffp-plan-editor-modal.component";
import { PfPlanEditorModalComponent } from "../quota/pf/modals/pf-plan-editor-modal/pf-plan-editor-modal.component";
import { PfPlanHistoryModalComponent } from "../quota/pf/modals/pf-plan-history-modal/pf-plan-history-modal.component";
import { PfPlan } from "../generated-models/PfPlan";
import { PfPlanBatchApprovalModalComponent } from "../quota/pf/modals/pf-plan-batch-approval-modal/pf-plan-batch-approval-modal.component";
import { FfpPlanSubmission } from "../generated-models/FfpPlanSubmission";
import { FfpPlanAssignmentModalComponent } from "../quota/ffp/modals/ffp-plan-assignment-modal/ffp-plan-assignment-modal.component";
import { FfpPlanAssignmentResponseModalComponent } from "../quota/ffp/modals/ffp-plan-assignment-response-modal/ffp-plan-assignment-response-modal.component";
import { FfpPlanOnboardingModalComponent, FfpPlanOnboardingModalOptions } from "../quota/ffp/modals/ffp-plan-onboarding-modal/ffp-plan-onboarding-modal.component";
import { FfpProjectBuildoutModalComponent } from "../quota/ffp/modals/ffp-region-buildout-modal/ffp-project-buildout-modal.component";
import { FfpPlanBatchApprovalComponent } from "../quota/ffp/modals/ffp-plan-batch-approval-modal/ffp-plan-batch-approval.component";
import { FfpPlanInstance } from "../generated-models/FfpPlanInstance";
import { FfpSyncProjectBuildoutResponseModalComponent } from "../quota/ffp/modals/ffp-sync-project-buildout-response-modal/ffp-sync-buildout-project-response-modal.component";
import { FfpPlanReprocessAssignResponseModalComponent } from "../quota/ffp/modals/ffp-plan-reprocess-assign-response-modal/ffp-plan-reprocess-assign-response-modal.component";

@Injectable()
export class ModalService {
  constructor(private ngbModal: NgbModal) { }

  createRequestResponseModal(requests: IUIRequest[]) {
    const ngbModalRef = this.ngbModal.open(CreateRequestResponseModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as CreateRequestResponseModalComponent;
    component.uiRequests = requests;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  createRequestModal(message: string) {
    const ngbModalRef = this.ngbModal.open(CreateRequestModalComponent, {
      backdrop: "static",
    });
    const createRequestModalComponent = ngbModalRef.componentInstance as CreateRequestModalComponent;
    createRequestModalComponent.message = message;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  validateRequestModal(request: IUIRequest, screenNumber: number) {
    const ngbModalRef = this.ngbModal.open(ValidateRequestModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as ValidateRequestModalComponent;
    component.request = request;
    component.screenNumber = screenNumber;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  activeSubRequestModal(title: string, requestOperation: RequestOperation, subRequests: ISubRequest[]) {
    const ngbModalRef = this.ngbModal.open(SubrequestResponseModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const subrequestResponseModalComponent = ngbModalRef.componentInstance as SubrequestResponseModalComponent;
    subrequestResponseModalComponent.title = title;
    subrequestResponseModalComponent.requestOperation = requestOperation;
    subrequestResponseModalComponent.subRequests = subRequests;

    return ngbModalRef.result;
  }

  plannedQuotaRequestConfirmationModal(requestOperation: RequestOperation, request: PlannedQuotaRequestDetail) {
    const ngbModalRef = this.ngbModal.open(PlannedQuotaRequestConfirmationModal, {
      backdrop: "static",
      size: "lg",
    });
    const plannedQuotaResponseModalComponent = ngbModalRef.componentInstance as PlannedQuotaRequestConfirmationModal;
    plannedQuotaResponseModalComponent.requestOperation = requestOperation;
    plannedQuotaResponseModalComponent.plannedQuotaRequest = request;

    return ngbModalRef.result;
  }

  parentRequestResponseModal(title: string, requestOperation: RequestOperation, parentRequests: IParentRequest[]) {
    const ngbModalRef = this.ngbModal.open(ParentRequestsResponseModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const parentRequestsResponseModalComponent = ngbModalRef.componentInstance as ParentRequestsResponseModalComponent;
    parentRequestsResponseModalComponent.title = title;
    parentRequestsResponseModalComponent.requestOperation = requestOperation;
    parentRequestsResponseModalComponent.parentRequests = parentRequests;
    return ngbModalRef.result;
  }

  // The size of the modal window. valid value: "sm", "md", "lg"
  confirmationModal(message: string, size = "md") {
    const ngbModalRef = this.ngbModal.open(ConfirmationModalComponent, {
      backdrop: "static",
      size: size,
    });
    const confirmationModalComponent = ngbModalRef.componentInstance as ConfirmationModalComponent;
    confirmationModalComponent.message = message;
    return ngbModalRef.result;
  }

  informationModal(message: string) {
    const ngbModalRef = this.ngbModal.open(InformationModalComponent, {
      backdrop: "static",
    });
    const informationModalComponent = ngbModalRef.componentInstance as InformationModalComponent;
    informationModalComponent.message = message;
    return ngbModalRef.result;
  }

  promptModal(title: string, message: string, size = "lg") {
    const ngbModalRef = this.ngbModal.open(PromptModalComponent, { size: size });
    const promptModalComponent = ngbModalRef.componentInstance as PromptModalComponent;
    promptModalComponent.title = title;
    promptModalComponent.message = message;
    return ngbModalRef.result;
  }

  selectOptionModal(message: string, options: string[]) {
    const ngbModalRef = this.ngbModal.open(SelectOptionModalComponent, {
      backdrop: "static",
    });
    const selectOptionModalComponent = ngbModalRef.componentInstance as SelectOptionModalComponent;
    selectOptionModalComponent.message = message;
    selectOptionModalComponent.options = options;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });
    return ngbModalRef.result;
  }

  datepickerModal(message: string) {
    const ngbModalRef = this.ngbModal.open(DatepickerModalComponent, {
      backdrop: "static",
    });
    const datepickerModalComponent = ngbModalRef.componentInstance as DatepickerModalComponent;
    datepickerModalComponent.message = message;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });
    return ngbModalRef.result;
  }

  selectOptionAndInputModal(message: string, options: string[]) {
    const ngbModalRef = this.ngbModal.open(SelectOptionAndInputModalComponent, {
      backdrop: "static",
    });
    const selectOptionAndInputModalComponent = ngbModalRef.componentInstance as SelectOptionAndInputModalComponent;
    selectOptionAndInputModalComponent.message = message;
    selectOptionAndInputModalComponent.options = options;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
        return null;
      });
    return ngbModalRef.result;
  }

  requestInputModal(message: string, required = true, title = "") {
    const ngbModalRef = this.ngbModal.open(RequestInputModalComponent, {
      backdrop: "static",
    });
    const requestInputModalComponent = ngbModalRef.componentInstance as RequestInputModalComponent;
    requestInputModalComponent.message = message;
    requestInputModalComponent.required = required;
    requestInputModalComponent.title = title;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });
    return ngbModalRef.result;
  }

  quotaImplicationsModal(options: ISubRequest[], userProfile: IUserProfile) {
    const ngbModalRef = this.ngbModal.open(QuotaImplicationsModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as QuotaImplicationsModalComponent;
    component.options = options;
    component.userProfile = userProfile;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  subscriptionDetailsModal(message: string, subscriptionId: string) {
    const ngbModalRef = this.ngbModal.open(SubscriptionDetailsModalComponent, {
      backdrop: "static",
    });

    const subscriptionDetailsModalComponent = ngbModalRef.componentInstance as SubscriptionDetailsModalComponent;
    subscriptionDetailsModalComponent.message = message;
    subscriptionDetailsModalComponent.subscriptionId = subscriptionId;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  azMappingsModal(region: string, subscriptionId: string, cloudType: string) {
    const ngbModalRef = this.ngbModal.open(AZMappingsModalComponet, {
      backdrop: "static",
    });

    const azMappingsModalComponent = ngbModalRef.componentInstance as AZMappingsModalComponet;
    azMappingsModalComponent.region = region;
    azMappingsModalComponent.subscriptionId = subscriptionId;
    azMappingsModalComponent.cloudType = cloudType;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  approverEditModal(message: string, data: UserRole, regionsList: IRegionGroupListRecord[], typesList: string[]) {
    const ngbModalRef = this.ngbModal.open(ApproverEditModalComponent, {
      backdrop: "static",
    });
    const approverEditModalComponent = ngbModalRef.componentInstance as ApproverEditModalComponent;
    approverEditModalComponent.message = message;
    approverEditModalComponent.data = data;
    approverEditModalComponent.regionsList = regionsList;
    approverEditModalComponent.typesList = typesList;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  editModal(type: string, formData: IEditModalOption[], tableName: string, editableList: string[], updateRowFunc: any) {
    const ngbModalRef = this.ngbModal.open(EditModalComponent, {
      backdrop: "static",
      size: "lg",
    });

    console.log("modal init for Data:", formData);

    const component = ngbModalRef.componentInstance as EditModalComponent;
    component.title = `Edit ${type}`;
    component.formData = formData;
    component.tableName = tableName;
    component.editableList = editableList;
    component.updateRowFunc = updateRowFunc;
    component.action = "Edit";

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  newRowModal(type: string, formData: IEditModalOption[], tableName: string, updateRowFunc: any) {
    const ngbModalRef = this.ngbModal.open(EditModalComponent, {
      backdrop: "static",
      size: "lg",
    });

    console.log("modal init for option:", formData);

    const component = ngbModalRef.componentInstance as EditModalComponent;
    component.title = `Insert ${type}`;
    component.formData = formData;
    component.tableName = tableName;
    component.updateRowFunc = updateRowFunc;
    component.action = "Insert";

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error.message);
        }
      });

    return ngbModalRef.result;
  }

  ticketCommentsModal(workItem: IWorkItemResponse, id: string, region: string, isRequestArchived: boolean) {
    const ngbModalRef = this.ngbModal.open(TicketCommentsModalComponent, {
      backdrop: "static",
    });
    const component = ngbModalRef.componentInstance as TicketCommentsModalComponent;
    component.workItem = workItem;
    component.id = id;
    component.region = region;
    component.isRequestArchived = isRequestArchived;

    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.error(error);
        }
      });
    return ngbModalRef.result;
  }

  workitemBulkModal(workitemList: IWorkItemResponse[]) {
    const ngbModalRef = this.ngbModal.open(WorkitemBulkModalComponent);
    const component = ngbModalRef.componentInstance as WorkitemBulkModalComponent;
    component.workitemList = workitemList;
    ngbModalRef.result
      .then((result) => {
        if (result) {
          return result;
        }
      })
      .catch((error) => {
        if (error) {
          console.error(error);
        }
      });
    return ngbModalRef.result;
  }

  filterModal(message: string) {
    const ngbModalRef = this.ngbModal.open(FilterModalComponent, {
      backdrop: "static",
    });
    const informationModalComponent = ngbModalRef.componentInstance as FilterModalComponent;
    informationModalComponent.message = message;
    return ngbModalRef.result;
  }

  planApprovalModal(options: PlanApprovalModalOptions) {
    const ngbModalRef = this.ngbModal.open(PlanApprovalModalComponent, {
      backdrop: "static",
      size: "md",
    });
    const component = ngbModalRef.componentInstance as PlanApprovalModalComponent;
    component.options = options;
    return ngbModalRef.result;
  }

  planRejectModal() {
    const ngbModalRef = this.ngbModal.open(PlanRejectModalComponent, {
      backdrop: "static",
      size: "md",
    });
    return ngbModalRef.result;
  }

  planNewFileModal(options: PlanNewFileModalOptions) {
    const ngbModalRef = this.ngbModal.open(PlanNewFileModalComponent, {
      backdrop: "static",
      size: "md",
    });
    const component = ngbModalRef.componentInstance as PlanNewFileModalComponent;
    component.options = options;
    return ngbModalRef.result;
  }

  planAssignmentModal(purpose: string) {
    const ngbModalRef = this.ngbModal.open(PlanAssignmentModalComponent, {
      backdrop: "static",
      size: "md",
    });
    const component = ngbModalRef.componentInstance as PlanAssignmentModalComponent;
    component.purpose = purpose;
    return ngbModalRef.result;
  }

  planAssignmentCSVModal(records: PlanAssignmentCSVRecord[], region: Region) {
    const ngbModalRef = this.ngbModal.open(PlanAssignmentCSVModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as PlanAssignmentCSVModalComponent;
    component.rowData = records;
    component.region = region;

    return ngbModalRef.result;
  }

  planAssignmentResponseModal(planSubmissions: PlanSubmission[]) {
    const ngbModalRef = this.ngbModal.open(PlanAssignmentResponseModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const planAssignmentResponseModalComponent = ngbModalRef.componentInstance as PlanAssignmentResponseModalComponent;
    planAssignmentResponseModalComponent.planSubmissions = planSubmissions;

    return ngbModalRef.result;
  }

  planAssignmentReconfirmModal(planSubmission: PlanSubmission) {
    const ngbModalRef = this.ngbModal.open(PlanAssignmentReconfirmModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const planAssignmentReconfirmModalComponent = ngbModalRef.componentInstance as PlanAssignmentReconfirmModalComponent;
    planAssignmentReconfirmModalComponent.planSubmission = planSubmission;

    return ngbModalRef.result;
  }

  planEditorModal(options: PlanEditorModalOptions) {
    const ngbModalRef = this.ngbModal.open(PlanEditorModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as PlanEditorModalComponent;
    component.options = options;
    return ngbModalRef;
  }

  planDiffModal(options: PlanDiffModalOptions) {
    const ngbModalRef = this.ngbModal.open(PlanDiffModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as PlanDiffModalComponent;
    component.options = options;
    return ngbModalRef;
  }

  planHistoryModal(serviceTreeId: string) {
    const ngbModalRef = this.ngbModal.open(PlanHistoryModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as PlanHistoryModalComponent;
    component.serviceTreeId = serviceTreeId;
    return ngbModalRef.result;
  }

  planCommitModal() {
    const ngbModalRef = this.ngbModal.open(PlanCommitModalComponent, {
      backdrop: "static",
      size: "md",
    });
    const component = ngbModalRef.componentInstance as PlanCommitModalComponent;
    return ngbModalRef.result;
  }

  planOnboardingModal(options: PlanOnboardingModalOptions) {
    const ngbModalRef = this.ngbModal.open(PlanOnboardingModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as PlanOnboardingModalComponent;
    component.options = options;
    return ngbModalRef.result;
  }

  planSetRemovedModal(planRegions: PlanRegion[]) {
    const ngbModalRef = this.ngbModal.open(PlanRemoveModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const planRemoveModalComponent = ngbModalRef.componentInstance as PlanRemoveModalComponent;
    planRemoveModalComponent.planRegions = planRegions;

    return ngbModalRef.result;
  }

  planAddUserModal(options: AddUserModalOptions) {
    const ngbModalRef = this.ngbModal.open(AddUserComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as AddUserComponent;
    component.options = options;
    return ngbModalRef.result;
  }

  planReprocessModal(payload: PlanSubmission, latestVersion: number) {
    const ngbModalRef = this.ngbModal.open(PlanReprocessModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as PlanReprocessModalComponent;
    component.payload = payload;
    component.latestVersion = latestVersion;
    return ngbModalRef.result;
  }

  ffpPlanOnboardingModal(options: FfpPlanOnboardingModalOptions) {
    const ngbModalRef = this.ngbModal.open(FfpPlanOnboardingModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as FfpPlanOnboardingModalComponent;
    component.options = options;
    return ngbModalRef.result;
  }

  ffpPlanEditorModal(options: PlanEditorModalOptions) {
    const ngbModalRef = this.ngbModal.open(FfpPlanEditorModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as FfpPlanEditorModalComponent;
    component.options = options;
    return ngbModalRef;
  }

  ffpPlanHistoryModal(serviceTreeId: string, type: string) {
    const ngbModalRef = this.ngbModal.open(FfpPlanHistoryModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as FfpPlanHistoryModalComponent;
    component.serviceTreeId = serviceTreeId;
    component.type = type;
    return ngbModalRef.result;
  }

  ffpPlanAssignmentResponseModal(ffpPlanSubmissions: FfpPlanSubmission[]) {
    const ngbModalRef = this.ngbModal.open(FfpPlanAssignmentResponseModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const ffpPlanAssignmentResponseModalComponent = ngbModalRef.componentInstance as FfpPlanAssignmentResponseModalComponent;
    ffpPlanAssignmentResponseModalComponent.ffpPlanSubmissions = ffpPlanSubmissions;
    return ngbModalRef.result;
  }

  ffpPlanAssignmentModal() {
    const ngbModalRef = this.ngbModal.open(FfpPlanAssignmentModalComponent, {
      backdrop: "static",
      size: "md",
    });
    return ngbModalRef.result;
  }

  ffpProjectBuildoutModal() {
    const ngbModalRef = this.ngbModal.open(FfpProjectBuildoutModalComponent, {
      backdrop: "static",
      size: "md",
    });
    return ngbModalRef.result;
  }

  ffpSyncProjectBuildoutResponseModalComponent(projectOid: string) {
    const ngbModalRef = this.ngbModal.open(FfpSyncProjectBuildoutResponseModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as FfpSyncProjectBuildoutResponseModalComponent;
    component.projectOid = projectOid;
    return ngbModalRef.result;
  }

  ffpPlanReprocessAssignResponseModalComponent(ffpPlanInstances: FfpPlanInstance[]) {
    const ngbModalRef = this.ngbModal.open(FfpPlanReprocessAssignResponseModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const ffpPlanAssignmentResponseModalComponent = ngbModalRef.componentInstance as FfpPlanReprocessAssignResponseModalComponent;
    ffpPlanAssignmentResponseModalComponent.ffpPlanInstances = ffpPlanInstances;
    return ngbModalRef.result;
  }

  pfPlanEditorModal(options: PlanEditorModalOptions) {
    const ngbModalRef = this.ngbModal.open(PfPlanEditorModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as PfPlanEditorModalComponent;
    component.options = options;
    return ngbModalRef;
  }

  pfPlanHistoryModal(serviceTreeId: string) {
    const ngbModalRef = this.ngbModal.open(PfPlanHistoryModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as PfPlanHistoryModalComponent;
    component.serviceTreeId = serviceTreeId;
    return ngbModalRef.result;
  }

  pfPlanBatchApprovalModal(title: string, pfPlans: PfPlan[]) {
    const ngbModalRef = this.ngbModal.open(PfPlanBatchApprovalModalComponent, {
      backdrop: "static",
      size: "md",
    });
    const component = ngbModalRef.componentInstance as PfPlanBatchApprovalModalComponent;
    component.title = title;
    component.pfPlans = pfPlans;
    return ngbModalRef.result;
  }

  previousApprovalsModal(requestId: string, subrequestId: number) {
    const ngbModalRef = this.ngbModal.open(PreviousApprovalsModalComponent, {
      backdrop: "static",
      size: "lg",
    });
    const promptModalComponent = ngbModalRef.componentInstance as PreviousApprovalsModalComponent;
    promptModalComponent.requestId = requestId;
    promptModalComponent.subrequestId = subrequestId;
    return ngbModalRef.result;
  }

  planBatchApprovalModal(title: string, planRegions: PlanRegion[]) {
    const ngbModalRef = this.ngbModal.open(PlanBatchApprovalComponent, {
      backdrop: "static",
      size: "md",
    });
    const component = ngbModalRef.componentInstance as PlanBatchApprovalComponent;
    component.title = title;
    component.planRegions = planRegions;
    return ngbModalRef.result;
  }

  ffpPlanBatchApprovalModal(title: string, ffpPlanInstances: FfpPlanInstance[]) {
    const ngbModalRef = this.ngbModal.open(FfpPlanBatchApprovalComponent, {
      backdrop: "static",
      size: "md",
    });
    const component = ngbModalRef.componentInstance as FfpPlanBatchApprovalComponent;
    component.title = title;
    component.ffpPlanInstances = ffpPlanInstances;
    return ngbModalRef.result;
  }

  planCommitResponseModal(warningMessage: string) {
    const ngbModalRef = this.ngbModal.open(PlanCommitResponseModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const planCommitResponseModalComponent = ngbModalRef.componentInstance as PlanCommitResponseModalComponent;
    planCommitResponseModalComponent.warningMessage = warningMessage;

    return ngbModalRef.result;
  }

  emailDetailModal(options: EmailDetailModalOptions) {
    const ngbModalRef = this.ngbModal.open(EmailDetailModalComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as EmailDetailModalComponent;
    component.options = options;
    return ngbModalRef;
  }
}
