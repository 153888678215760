import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ISubRequest, RequestOperation } from "../../../npr-request.model";
import { ApiService } from "../../../services";

@Component({
  templateUrl: "./subrequest-response-modal.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class SubrequestResponseModalComponent implements OnInit {
  @Input() title: string;
  @Input() requestOperation: RequestOperation;
  @Input() subRequests: ISubRequest[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;
  public inProgress = false;
  public confirmation = true;
  public message = "";

  // Params for cancel sub requests
  public comment: string;
  public sendEmail = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) {}

  ngOnInit() {
    this.confirm();
  }

  confirm() {
    this.inProgress = false;
    this.confirmation = true;
    this.title = "Please Confirm";
    this.message = `Are you sure want to ${this.requestOperation} sub request(s)?`;
  }

  async start() {
    this.inProgress = true;
    this.confirmation = false;
    this.title = `${this.requestOperation} sub request(s)`;

    switch (this.requestOperation) {
      case RequestOperation.Cancel:
        await this.cancelSubRequests();
        break;
      case RequestOperation.Retry:
        await this.retrySubRequests();
        break;
      case RequestOperation.SkipDependencies:
        await this.skipDependenciesOfSubRequests();
        break;
    }
  }

  close() {
    if (this.confirmation) {
      this.activeModal.close(false);
    } else {
      this.activeModal.close(true);
    }
  }

  async cancelSubRequests() {
    if (!this.comment) {
      this.comment = "Request was cancelled by Admin or Submitter";
    }

    await Promise.all(
      this.subRequests.map(async (req) => {
        try {
          await this.apiService.cancelSubRequests(req.RequestId, this.comment, this.sendEmail).toPromise();
          this.aggregatedResult.push(`Cancel request <b>${req.RequestId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Cancel request <b>${req.RequestId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }

  async retrySubRequests() {
    await Promise.all(
      this.subRequests.map(async (req) => {
        try {
          await this.apiService.retrySubRequests(req.RequestId).toPromise();
          this.aggregatedResult.push(`Retry request <b>${req.RequestId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Retry request <b>${req.RequestId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }

  async skipDependenciesOfSubRequests() {
    await Promise.all(
      this.subRequests.map(async (req) => {
        try {
          await this.apiService.skipDependenciesOfSubRequests(req.RequestId).toPromise();
          this.aggregatedResult.push(`Skip Dependencies of request <b>${req.RequestId}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Skip Dependencies of request <b>${req.RequestId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }
}
