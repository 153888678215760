import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./information-modal.component.html",
  styles: [".modal-body { max-height: 650px; overflow: scroll; }"],
})
export class InformationModalComponent {
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) {}
}
