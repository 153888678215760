import { ColDef } from "ag-grid-community";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import * as CDs from "src/app/utility/commonColDef";

export const pfPlanEditorCellComponentParams = {
    target: "_blank",
    url: (params: ColParams<PlanRegion, string>) => `/quota/pf/services/${params.data.ServiceTreeId}/editor`,
  };

export const reviewApprovablePfPlanCellComponentParams = {
    value: "Review",
    target: "_blank",
    url: (params: ColParams<PlanRegion, string>) =>
      `/quota/pf/services/${params.data.ServiceTreeId}/versions/${params.data.Version}/review`,
  };
export const PfPlansColumnDefinition: ColDef[] = [
    {
      ...CDs.ServiceTreeId,
      cellRendererFramework: LinkCellComponent,
      cellRendererParams: pfPlanEditorCellComponentParams,
    },
    CDs.ServiceTeam,
    CDs.Ring,
    CDs.LatestCommitVersion,
    CDs.SubmitTime("Submit Time", "CreatedTime"),
    CDs.CreatedBy,
    CDs.Status,
    CDs.CustomFilter
  ];

  export const ApprovablePfPlansColumnDefinition: ColDef[] = [
    {
      headerName: "Action",
      colId: "action",
      sortable: false,
      suppressMenu: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      cellRendererFramework: LinkCellComponent,
      cellRendererParams: reviewApprovablePfPlanCellComponentParams,
    },
    CDs.Ring,
    CDs.ServiceTeam,
    CDs.ServiceTreeId,
    {
      ...CDs.Version,
      //cellRendererFramework: LinkCellComponent,
      // cellRendererParams: viewRegionalPlanDetailCellComponentParams,
    },
    {
      "headerName": "Submitter",
      "field": "CreatedBy"
    },
    CDs.SubmitTime("Submit Time", "CreatedTime"),
    CDs.Status,
  ];