<div class="modal-header">
  <h4 class="modal-title" style="text-transform: capitalize;">{{ title }}</h4>
</div>

<div *ngIf="inProgress">
  <mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
  <div class="modal-body">
    <p>
      In progress {{ this.aggregatedResult.length }}/{{ this.parentRequests.length }} :
    </p>
    <ul *ngFor="let item of aggregatedResult">
      <li [innerHtml]="item"></li>
    </ul>
  </div>
</div>

<div *ngIf="confirmation">
  <div class="modal-body" style="text-align: center;">
    <p>
      {{ message }}
    </p>
    <div *ngIf="isRetry">
    <table style="text-align: right; width: 64%;">
      <tr>
        <td>Force retry</td>
        <td style="text-align: left; padding-left: 3%;">
          <input type="checkbox" [(ngModel)]="forceRetry" />
        </td>
      </tr>
    </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" *ngIf="confirmation" (click)="start()">
    Yes
  </button>
  <button type="button" class="btn btn-warning" (click)="close()">
    Close
  </button>
</div>
