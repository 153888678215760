import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NprConfiguration } from "src/app/app.configuration";
import { Team } from "src/app/generated-models";
import { FfpPlanTypeEnum, ITeamListRecord, IUserProfile } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";

export interface FfpPlanOnboardingModalOptions {
  save: (serviceTreeId: string, type: string) => Promise<any>;
}

@Component({
  templateUrl: "./ffp-plan-onboarding-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class FfpPlanOnboardingModalComponent implements OnInit {
  @Input() options: FfpPlanOnboardingModalOptions;
  team: ITeamListRecord;
  teamList: ITeamListRecord[];
  planTypeList: string[];
  userProfile: IUserProfile;
  isOnboardEnabled: boolean;
  teamOid: string;
  planType: string;
  guidRegExpression = NprConfiguration.guidRegExpression;
  customTeam = false;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    this.teamList = [];
    this.planTypeList = Object.values(FfpPlanTypeEnum);
    
  }

  async ngOnInit() {
    this.apiService
      .getUserProfile()
      .then((response) => {
        if (response) {
          this.userProfile = response;
          this.isOnboardEnabled = this.userProfile.IsAdmin || this.userProfile.UserRoles.some((_) => _.Services.length > 0);
          this.getTeamsList();
        } else {
          this.notificationService.error("user profile returns null, no data was loaded.");
        }
      })
      .catch((error) => {
        this.notificationService.error(`unable to get user profile, error: ${error.message}`);
      });
  }

  getTeamsList(): void {
    this.loadingService.setLoading(true);
    // Team List
    this.apiService?.getTeamList().subscribe(
      (response) => {
        let teams: Team[] = [];
        if (response) {
          if (this.userProfile.IsAdmin) {
            teams = response;
          } else {
            response.map((r) => {
              if (this.userProfile.UserRoles.some((_) => _.Services.includes(r.Oid))) {
                teams.push(r);
              }
            });
          }
        }

        const regionlessTeam = [];
        teams.map((r) => {
          if (r.Ring) {
            this.teamList.push({
              label: `${r.Name} (${r.Oid})`,
              value: 0,
              ring: r.Ring,
              oid: r.Oid,
            } as ITeamListRecord);
          } else {
            regionlessTeam.push({
              label: `${r.Name} (${r.Oid})`,
              value: 0,
              ring: "No Ring",
              oid: r.Oid,
            } as ITeamListRecord);
          }
        });
        this.teamList = this.teamList
          .sort((a, b) => {
            if (a.label === null) {
              console.log(a);
            }
            return a.ring.localeCompare(b.ring) || a.label.localeCompare(b.label);
          })
          .concat(regionlessTeam.sort((a, b) => a.label.localeCompare(b.label)));
        this.loadingService.setLoading(false);
      },
      (e: unknown) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      }
    );
  }

  disableSubmit(): boolean {
    if (this.customTeam) {
      return !this.teamOid || !RegExp(this.guidRegExpression).test(this.teamOid);
    }
    else{
      return !this.team;
    }
  }

  async submit() {
    if (this.disableSubmit()) {
      this.notificationService.warning(`Please select a service name or enter a valid service tree ID.`);
      return;
    } else {
      try {
        const result = await this.options.save(this.team?.oid || this.teamOid, this.planType);
        this.activeModal.close(result);
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }
}
