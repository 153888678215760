import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { GridApi } from "ag-grid-community";
import { Cloud, Job, Product, PslRegion, Service } from "../shared/model";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ModalService } from "../shared/modal.service";
import { ModalService as LionrockModalService } from "../../shared/modal.service";
import { DataService } from "../shared/data.service";
import { ModuleService } from "../../shared/module.service";
import { BaseComponent } from "../shared/base.component";
import { AuthService } from "../../auth/auth.service";
import { ApiService } from "../shared/api.service";

@Component({
  selector: "app-home",
  templateUrl: "./psl.component.html",
  styleUrls: ["./psl.component.scss", "../styles.scss"],
})
export class PslComponent extends BaseComponent {
  loadingData: boolean;
  pslProducts: Service[] = null;
  jobs: Job[];
  firstLoad = true;
  private gridApi: GridApi;
  rowSelection = "multiple";

  product = "";
  products: Product[] = [];
  errorMessage = "";

  columnDefs = [];
  gridOptions = extendDefaultOptions({
    defaultColDef: {
      headerCheckboxSelectionFilteredOnly: true,
      filter: "agSetColumnFilter",
      resizable: true,
      sortable: true,
      flex: 1,
    },
    columnDefs: this.columnDefs,
    autoGroupColumnDef: {
      cellRendererSelector: (params) => {
        const allLeafChildren = params.node.allLeafChildren;
        if (allLeafChildren?.length == 1 && allLeafChildren?.[0].data?.CaymanProductId.length == 0) {
          return;
        }
        return { component: "agGroupCellRenderer" };
      },
      sort: "asc",
      headerName: "Cayman Product",
      field: "CaymanProductId",
      minWidth: 400,
      cellRendererParams: {
        checkbox: true,
      },
    },
    rowSelection: "multiple",
    groupSelectsChildren: true,
    groupSelectsFiltered: true,
    suppressAggFuncInHeader: true,
    suppressRowClickSelection: true,
    rowData: this.pslProducts,
    isRowSelectable: (rowNode) => rowNode.data && rowNode.data.CaymanProductId.length > 0,
    onModelUpdated: (params) => {
      console.log("grid ready");
      if (this.firstLoad) {
        params.api.forEachNode(function (node) {
          if (!node.allChildrenCount) {
            node.setSelected(!node.data.Skipped);
          }
        });
      }
      this.firstLoad = false;
    },
  });

  constructor(
    apiService: ApiService,
    private modalService: ModalService,
    private lionrockModalService: LionrockModalService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    private router: Router,
    route: ActivatedRoute,
    private moduleService: ModuleService
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
  }

  async onInit(): Promise<void> {
    if (!this.userCanInitialBuildout) {
      if (this.userCanOnDemandBuildout) {
        await this.router.navigate(["/prep/ondemand"]);
        return;
      } else if (this.userCanViewStatus) {
        await this.router.navigate(["/prep/status"]);
        return;
      }
    }
    const initializeRegion = this.dataService.selectedRegions.find((region) => this.dataService.initialRegions.includes(region));
    this.dataService.setSelectedRegions(initializeRegion ? [initializeRegion] : []);

    this.columnDefs = [
      {
        field: "ProductOid",
        rowGroup: true,
        hide: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (nodeA.selectable && !nodeB.selectable) {
            return isInverted ? 1 : -1;
          } else if (!nodeA.selectable && nodeB.selectable) {
            return isInverted ? -1 : 1;
          }
          return valueA?.localeCompare(valueB) * (isInverted ? -1 : 1);
        },
      },
      {
        field: "CaymanProductId",
        hide: true,
      },
      { field: "ProductName", aggFunc: "first" },
      { field: "ProductType", aggFunc: "first" },
      { field: "Ring", aggFunc: "first" },
      { field: "RegionName", aggFunc: "first" },
      { field: "Skipped" },
      { field: "CaymanProductName" },
      { field: "LocationType" },
      { field: "LocationId" },
    ];
  }

  resetData(): void {
    this.jobs = null;
    this.pslProducts = null;
  }

  onCloudChange(cloud: Cloud): void {
    this.dataService.changeCloud(cloud);
    // reset the service list.
    this.resetData();
  }

  async onRegionChange(): Promise<void> {
    this.dataService.changeRegion();
    // reset the service list.
    this.resetData();
    this.dataService.psl = null;
    this.dataService.pslList = (await this.apiService.GetPsl(this.dataService.selectedRegion.Name).toPromise()) as PslRegion[];
  }

  onPslChange() {
    this.resetData();
  }

  async onPslProductsFetched(): Promise<void> {
    console.log("InScopeServices fetched!");
    this.loadingData = true;
    if (this.gridApi) {
      this.gridApi.setRowData([]);
      this.gridApi.showLoadingOverlay();
    }

    this.apiService.GetPslProduct(this.dataService.psl.PslId).subscribe((products) => {
      this.firstLoad = true;
      this.pslProducts = products as Service[];
      this.loadingData = false;
      this.gridApi?.hideOverlay();
    });
  }

  onPslProductsGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setRowData([]);
    if (this.loadingData && this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
    console.log("grid ready");
  }

  async onPslRegionBillingMeter(): Promise<void> {
    await this.lionrockModalService.confirmationModal(
      `For ${this.dataService.getInitialLocationName()}, the following billing meters creation will be triggered. Please confirm.`
    );
    await this.loading((finish) => {
      this.apiService
        .SubmitPslProduct(this.dataService.psl.PslId, [...new Set(this.gridApi.getSelectedRows().map((r) => r.CaymanProductId))])
        .subscribe(
          (res) => {
            const jobId = res as string;
            if (this.dataService.selectedEdgeZone) this.dataService.selectedEdgeZone.Initialized = true;
            else this.dataService.selectedRegion.Initialized = true;
            finish();
            this.lionrockModalService
              .informationModal(`<h6>Billing Meters request has been submitted successfully! </h6><br/>JobId: ${jobId}`)
              .then(() => {
                this.onPslProductsFetched();
              });
          },
          (err) => {
            finish();
            this.lionrockModalService.informationModal(`${err.message.replace(/\n/g, "<br/>")}`);
          }
        );
    });
  }

  onExportExcel(): void {
    this.gridApi.exportDataAsExcel({
      fileName: "PslProduct.xlsx",
      allColumns: true,
    });
  }

  disableNewRegionBillingMeter(): boolean {
    return this.pslProducts == null || this.dataService.availabilityRegionOrEdgeZone();
  }
}
