import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { SubRequestColumnDefinition } from "../error-requests/error-requests-columns";
import { ErrorRequestsComponent } from "../error-requests/error-requests.component";
import { ErrorLevel, ISubRequest, RequestOperation, RequestStatus, TicketPriorityEnum } from "../../../npr-request.model";
import { ApiService } from "../../../services";
import { LoadingScreenService } from "../../../shared/loading-screen.service";
import { ModalService } from "../../../shared/modal.service";
import { PreprocessDateTime } from "../../../utility/view-field-mapping";
import { SharedDataService } from "../../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { ExcelStyle } from "ag-grid-community";

@Component({
  templateUrl: "./active-subrequests.component.html",
  styleUrls: ["../../../styles.scss"],
})
export class ActiveSubrequestsComponent extends ErrorRequestsComponent implements OnInit {
  errorLevel: string = ErrorLevel.SubRequest;
  columnDef = SubRequestColumnDefinition;
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];
  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
    this.refreshRowFunc = async () => await this.getAndRenderActiveSubRequests();
    this.retryFunc = this.retrySubRequests;
  }

  async getAndRenderActiveSubRequests() {
    const newData: ISubRequest[] = [];
    const requestData: ISubRequest[] = await this.apiService.getAllSubRequests().toPromise();
    requestData.sort((a, b) => new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
    requestData.forEach((element: ISubRequest) => {
      element.RequestDisplayServiceType = ApiService.fetchServiceTypeName(element.RequestServiceType, element.SKU);
      element.Placeholder = "";
      element.RequestId = `${element.ParentRequestId}-${element.SubRequestId}`;
      element.IsExternalDisplay = element.IsExternal ? "Yes" : "No";
      element.RingLevel = element.IsExternal ? "External" : element.RingLevel;
      let priorityNumber = element.Priority;
      priorityNumber = priorityNumber === 0 ? 3 : priorityNumber;
      element.PriorityString = `${priorityNumber} - ${TicketPriorityEnum[priorityNumber]}`;
      element.RequestLink = ApiService.generateSubRequestHyperLink(element.ParentRequestId, element.SubRequestId.toString());
      element.CreatedTime = PreprocessDateTime(element.CreatedTime);
      if (element.CompletedTime) {
        element.CompletedTime = PreprocessDateTime(element.CompletedTime);
      }

      if (element.Status !== RequestStatus.Cancelled && element.Status !== RequestStatus.Completed) {
        newData.push(element);
      }
    });
    super.setDefaultFilter("filter:active-subrequest", false);
    return newData;
  }

  async retrySubRequests() {
    const selectedRows: ISubRequest[] = this.gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      this.message = `Please select ${this.errorLevel}(s)`;
      return;
    }

    const needRefresh: boolean = await this.modalService.activeSubRequestModal("Retry Sub Ruquests", RequestOperation.Retry, selectedRows);
    if (needRefresh) {
      this.refreshData();
    }
  }

  async cancelSubRequests() {
    const selectedRows: ISubRequest[] = this.gridApi.getSelectedRows();
    if (selectedRows.length === 0) {
      this.message = `Please select ${this.errorLevel}(s)`;
      return;
    }

    const needRefresh: boolean = await this.modalService.activeSubRequestModal(
      "Cancel Sub Ruquests",
      RequestOperation.Cancel,
      selectedRows
    );

    if (needRefresh) {
      this.refreshData();
    }
  }

  refreshData() {
    super.refreshData();
    super.setDefaultFilter("filter:active-subrequest", false);
  }

  exportExcel() {
    var fileName = `ActiveSubRequests-` + getExportedExcelFileNameSuffix();
    var sheetName = `Active Sub Requests`;

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
