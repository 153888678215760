import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { ITeamListRecord } from "src/app/npr-request.model";
import { PlannedQuotaPropertyBinding } from "src/app/generated-models/PlannedQuotaPropertyBinding";
import { SharedDataService } from "../../services/sharedDataService";
import { PlannedQuotaPropertyBindingColumnDefinition } from "../plans/ag-grid-column-definition";
import { AdminViewComponent } from "./admin-view.component";
import { DataTypes } from "./admin-view-data-types";

@Component({
  templateUrl: "./admin-view-bindings.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewPropertyBindingsComponent extends AdminViewComponent<PlannedQuotaPropertyBinding> implements OnInit {
  teamList: ITeamListRecord[] = [];
  team: ITeamListRecord = null;
  mainTitle = "Bindings";
  subTitle = "Resource bindings of my services";
  type = "Property Bindings";
  tableName = "PlannedQuotaPropertyBinding";
  keyFilterStats = "filter:admin-view-prop-bindings";
  columnDef = PlannedQuotaPropertyBindingColumnDefinition;

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    dataTypes: DataTypes,
    sharedDataService: SharedDataService
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.addRowEnable = true;
    this.deleteRowEnable = true;
    this.updateRowFunc = (data: PlannedQuotaPropertyBinding) => {
      if (data.Region && data.ServiceTreeId && data.SubscriptionKey && data.BindingKey && data.BindingType && data.BindingValue) {
        return apiService.addPlannedQuotaPropertyBindings(data).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.addRowFunc = (data: PlannedQuotaPropertyBinding) => {
      if (data.Region && data.ServiceTreeId && data.SubscriptionKey && data.BindingKey && data.BindingType && data.BindingValue) {
        return apiService.addPlannedQuotaPropertyBindings(data).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.deleteRowFunc = (data: PlannedQuotaPropertyBinding) => {
      return apiService.deletePlannedQuotaPropertyBindings(data).toPromise();
    };

    this.getTeamList();
  }

  refreshData(): void {
    this.message = "";
    if (this.team) {
      this.apiService.getPlannedQuotaPropertyBindingsByServiceTreeId(this.team.oid).subscribe((response: PlannedQuotaPropertyBinding[]) => {
        this.rowData = response;
        super.setupFilterByLocalData();
      });
    }
  }

  getTeamList(): void {
    this.apiService?.getMyServiceTeamUser().subscribe(
      (response) => {
        if (response) {
          const regionlessTeam = [];
          this.teamList = [];
          response.map((r) => {
            const label = `${r.ServiceName} (${r.ServiceTreeId})`;
            if (r.Ring) {
              if (this.teamList.findIndex((team) => team.label === label) === -1) {
                this.teamList.push({
                  label: `${r.ServiceName} (${r.ServiceTreeId})`,
                  value: 0,
                  ring: r.Ring,
                  oid: r.ServiceTreeId,
                } as ITeamListRecord);
              }
            } else {
              if (regionlessTeam.findIndex((team) => team.label === label) === -1) {
                regionlessTeam.push({
                  label: `${r.ServiceName} (${r.ServiceTreeId})`,
                  value: 0,
                  ring: "No Ring",
                  oid: r.ServiceTreeId,
                } as ITeamListRecord);
              }
            }
          });
          this.teamList = this.teamList
            .sort((a, b) => {
              return a.ring?.localeCompare(b.ring) || a.label?.localeCompare(b.label);
            })
            .concat(regionlessTeam.sort((a, b) => a.label?.localeCompare(b.label)));
        }
      },
      () => {
        this.teamList = [];
      }
    );
  }
}
