/// <reference path="../../../../node_modules/monaco-editor/monaco.d.ts" />
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from "@angular/core";

let loadedMonaco = false;
let loadPromise: Promise<void>;

/*
  Monaco Editor
  https://microsoft.github.io/monaco-editor/index.html
*/

@Component({
  selector: "monaco-editor",
  templateUrl: "./monaco-editor.component.html",
  styleUrls: ["./monaco-editor.component.scss"],
})
export class MonacoEditorComponent implements AfterViewInit, OnChanges {
  @ViewChild("editorContainer") editorContainer: ElementRef;

  @Input() code: string;
  @Input() language: string;
  @Input() theme: string;
  @Input() isReadonly: boolean;

  @Output() ready = new EventEmitter<monaco.editor.IStandaloneCodeEditor>();
  @Output() codeChange = new EventEmitter<String>();

  // Holds instance of the current code editor
  codeEditorInstance: monaco.editor.IStandaloneCodeEditor;

  constructor() {}

  // supports two-way binding
  ngOnChanges() {
    if (this.codeEditorInstance) {
      this.codeEditorInstance.setValue(this.code);
    }
  }

  ngAfterViewInit() {
    if (loadedMonaco) {
      // Wait until monaco editor is available
      loadPromise.then(() => {
        this.initMonaco();
      });
    } else {
      loadedMonaco = true;
      loadPromise = new Promise<void>((resolve: any) => {
        if (typeof (<any>window).monaco === "object") {
          resolve();
          return;
        }
        const onAmdLoader: any = () => {
          // Load monaco
          (<any>window).require.config({ paths: { vs: "/quota/assets/monaco/vs" } });

          (<any>window).require(["vs/editor/editor.main"], () => {
            this.initMonaco();
            resolve();
          });
        };

        // Load AMD loader if necessary
        if (!(<any>window).require) {
          const loaderScript: HTMLScriptElement = document.createElement("script");
          loaderScript.type = "text/javascript";
          loaderScript.src = "/quota/assets/monaco/vs/loader.js";
          loaderScript.addEventListener("load", onAmdLoader);
          document.body.appendChild(loaderScript);
        } else {
          onAmdLoader();
        }
      });
    }
  }

  initMonaco(): void {
    this.codeEditorInstance = monaco.editor.create(this.editorContainer.nativeElement, {
      value: this.code,
      language: this.language,
      theme: this.theme,
      readOnly: this.isReadonly,
      minimap: {
        enabled: false,
      },
      renderWhitespace: "boundary",
      scrollBeyondLastLine: false,
    });

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      allowComments: true,
    });

    // To support two-way binding of the code
    this.codeEditorInstance.getModel().onDidChangeContent(() => {
      this.codeChange.emit(this.codeEditorInstance.getValue());
    });

    this.ready.emit(this.codeEditorInstance);
  }
}
