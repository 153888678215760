import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import {
  IParentRequest,
  ISubRequest,
  IWorkItemResponse,
  MyRequestAndWorkItems,
  RequestServiceType,
  TicketPriorityEnum,
} from "../../npr-request.model";
import { ModalService } from "../../shared/modal.service";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { RequestViewBase } from "../../shared/request-view-base";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import {
  UserSubRequestItemsColumnDefinition,
  UserParentRequestItemsColumnDefinition,
  CustomerRequestSubsColumnDefinition,
} from "./ag-grid-column-definition";
import { CustomTooltipComponent } from "../../shared/auxillary-components/custom-tooltip.component";
import { ServiceTypeRendererComponent } from "../../shared/az-mapping-modal/service-type-renderer.component";
import { OnColumnMoved, saveFile, setColumnState } from "../../utility/common-helper";
import { VmSku } from "../../generated-models";
import { extendDefaultOptions } from "../../utility/gridHelper";
import * as CDs from "src/app/utility/commonColDef";
import { SharedDataService } from "../../services/sharedDataService";
@Component({
  templateUrl: "./request-status.component.html",
  styleUrls: ["../../styles.scss"],
})
export class RequestStatusComponent extends RequestViewBase implements OnInit {
  /*
   * inherited property from RequestViewBase for ag-grid Angular binding
   */
  gridOptions: GridOptions;
  workItemGridOptions: GridOptions;
  rowData: IParentRequest[] = [];
  workItemRowData: IWorkItemResponse[] = [];
  hasWorkItem = false;
  initPageSize = 15;
  skuMapping: Map<string, string> = new Map();
  selectedRowCount = 0;
  subrequestRowData: ISubRequest[] = [];
  gridApiForWorkItem: GridApi;
  gridColumnApiForWorkItem: ColumnApi;

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService
  ) {
    super(modalService, apiService, loadingService, notificationService, sharedDataService);
  }
  ngOnInit() {
    // base method for ag-grid angular binding
    super.InitAgGrid(UserParentRequestItemsColumnDefinition);
    this.gridOptions.detailCellRendererParams = {
      detailGridOptions: {
        defaultColDef: {
          filter: "agSetColumnFilter",
          resizable: true,
          sortable: true,
        },
        context: this,
        frameworkComponents: {
          // register angular component for customized column header
          // https://www.ag-grid.com/javascript-grid-header-rendering/#example-header-component
          customTooltip: CustomTooltipComponent,
          serviceTypeRenderer: ServiceTypeRendererComponent,
        },
        rowClass: "ticket-row",
        columnDefs: UserSubRequestItemsColumnDefinition,
        onGridReady: (params) => {
          // make detailGrid height automatically
          params.api.setDomLayout("autoHeight");
        },
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
          window.setTimeout(() => {
            const colIds = params.columnApi.getAllColumns().map((c) => c.getColId());
            params.columnApi.autoSizeColumns(colIds);
          }, 50);
        },
      },
      getDetailRowData: (params) => {
        params.data.requestData = this.subrequestRowData.filter((data) => data.ParentRequestId === params.data.RequestId);
        params.node.detailGridInfo.api.setColumnDefs(UserSubRequestItemsColumnDefinition);
        for (let subRequest of params.data.requestData) {
          if (subRequest.RequestServiceType == RequestServiceType.AppService) {
            subRequest.Quota = "Windows:" + subRequest.Quota;
            if (subRequest.ServiceParams != null && "LinuxVmQuota" in subRequest.ServiceParams) {
              subRequest.Quota += " Linux:" + subRequest.ServiceParams["LinuxVmQuota"];
            }
          }
        }
        // use setTimeout to avoid ag-grid error: cannot get grid to draw rows when it is in the middle of drawing rows.
        setTimeout(() => {
          params.node.setRowHeight(null); // set null to enable auto adjustment
          this.gridApi.onRowHeightChanged(); // this triggers getRowHeight silently
          params.successCallback(params.data.requestData);
        }, 0);
      },
    };
    this.workItemGridOptions = extendDefaultOptions({
      columnDefs: [
        CDs.TicketId,
        CDs.RequestId,
        CDs.QMSError,
        CDs.FirstComments,
        CDs.RequestStatus,
        CDs.CreatedDate,
        CDs.LastCommentDate,
        CDs.RegionColDef("ArmRegionName"),
        CDs.SubscriptionId("SubscriptionID"),
        CDs.SKU,
      ],
      context: this, // passed context for customized component callback
      isRowSelectable: () => {
        return true;
      },
      animateRows: true,
      paginationPageSize: this.initPageSize,
    });
    this.gridOptions.onColumnMoved = (params) => OnColumnMoved(params, "myRequestColumnState");
  }

  onChecked(isChecked: boolean) {
    this.selectAllRowsOnCurrentPage(isChecked);
  }

  onGridReady(params: GridOptions) {
    // base method for ag-grid api setup
    super.SetAgGridContext(params);
    // base method to load data with authentication
    // the implementation is defined in derived refreshData()
    super.LoadData();
    setColumnState(params, "myRequestColumnState");
  }

  onWorkItemGridReady(params: GridOptions) {
    this.gridApiForWorkItem = params.api;
    this.gridColumnApiForWorkItem = params.columnApi;
    this.gridApiForWorkItem.showLoadingOverlay();
  }

  refreshData() {
    this.apiService.getVmSkus().subscribe((response: VmSku[]) => {
      if (response && response.length > 0) {
        response.forEach((elem) => this.skuMapping.set(elem.CrpVmSku, elem.PortalVmSku));
        this.gridApi?.showLoadingOverlay();
        this.gridApiForWorkItem?.showLoadingOverlay();
        this.apiService.getParentRequestAndWorkItemByUser(true).subscribe(
          (resp: MyRequestAndWorkItems) => {
            if (this.workItemRowData.length === 0) {
              this.workItemRowData = [];
            }

            if (resp) {
              this.workItemRowData = resp.Tickets;
              this.hasWorkItem = this.workItemRowData.length > 0;
              if (this.hasWorkItem) {
                this.message = `Incomplete workitems refreshed.`;
              }
              this.subrequestRowData = [];
              this.apiService.getSubRequestByUser().subscribe(
                (response: ISubRequest[]) => {
                  if (response) {
                    response.forEach((element) => {
                      element.RequestDisplayServiceType = ApiService.fetchServiceTypeName(element.RequestServiceType, element.SKU);
                      element.Placeholder = "";
                      element.RequestId = `${element.ParentRequestId}-${element.SubRequestId}`;
                      let priorityNumber = element.Priority;
                      priorityNumber = priorityNumber == 0 ? 3 : priorityNumber;
                      element.PriorityString = `${priorityNumber} - ${TicketPriorityEnum[priorityNumber]}`;
                      element.RequestLink = ApiService.generateSubRequestHyperLink(
                        element.ParentRequestId,
                        element.SubRequestId.toString()
                      );
                      element.CreatedTime = PreprocessDateTime(element.CreatedTime);
                      if (element.CompletedTime) {
                        element.CompletedTime = PreprocessDateTime(element.CompletedTime);
                      }
                      if (element.Status === "Backlogged") {
                        const parentRequest = resp.Requests.find((request) => request.RequestId === element.ParentRequestId);
                        if (parentRequest) {
                          parentRequest.HasBacklogged = true;
                        }
                      } else if (element.Status === "Forelogged") {
                        const parentRequest = resp.Requests.find((request) => request.RequestId === element.ParentRequestId);
                        if (parentRequest) {
                          parentRequest.HasForelogged = true;
                        }
                      } else if (element.Status === "ActionRequired") {
                        const parentRequest = resp.Requests.find((request) => request.RequestId === element.ParentRequestId);
                        if (parentRequest) {
                          parentRequest.HasActionRequired = true;
                        }
                      }
                      this.subrequestRowData.push(element);
                    });
                  }
                },
                (err) => {
                  console.error(err);
                },
                () => {
                  this.prepareData(resp.Requests as IParentRequest[]);
                  this.gridApiForWorkItem?.hideOverlay();
                }
              );
            }
          },
          (error) => {
            this.gridApi?.hideOverlay();
            this.gridApiForWorkItem?.hideOverlay();
            this.message = "Failed to get requests submitted by you. Error: " + error;
          }
        );
      }
    });
  }
  async handleWorkItem() {
    const selectedRows: IWorkItemResponse[] = this.gridApiForWorkItem.getSelectedRows();
    if (selectedRows.length === 0) {
      this.message = `Please select workitems(s)`;
      return;
    }
    this.message = "";

    await this.modalService
      .workitemBulkModal(selectedRows)
      .then(() => this.refreshData())
      .catch((err) => {
        console.log("modal closed.", err);
      });

    this.message = `Your selected workitem(s) have been handled.`;
  }

  prepareData(requestData: IParentRequest[]) {
    // Must init for the first cache fillup
    if (this.rowData.length === 0) {
      this.rowData = [];
    }
    const newData: IParentRequest[] = [];
    requestData.sort((a, b) => new Date(b.CreatedTime).getTime() - new Date(a.CreatedTime).getTime());
    requestData.forEach((element: IParentRequest) => {
      element.Placeholder = "";
      element.IsExternalDisplay = element.IsExternal ? "Yes" : "No";
      element.IsHoboDisplay = element.IsHobo == null ? "" : element.IsHobo ? "Yes" : "No";
      if (element.Details && element.Details.VmSkus) {
        element.Details.VmSkus = element.Details.VmSkus.map((elem) => this.skuMapping.get(elem));
      }
      element.CreatedTime = PreprocessDateTime(element.CreatedTime);
      if (element.CompletedTime) {
        element.CompletedTime = PreprocessDateTime(element.CompletedTime);
      }
      element.RequestLink = ApiService.generateParentRequestHyperLink(element.RequestId);
      element.Status = element.Status.replace("Processed", "In Progress");

      newData.push(element);
    });
    this.rowData = newData;

    // delay the auto-size to make sure data has been retrieved
    setTimeout(() => {
      this.gridColumnApi?.autoSizeAllColumns();
      this.gridColumnApiForWorkItem?.autoSizeAllColumns();
    }, 1000);
  }

  selectAllRowsOnCurrentPage(selected: boolean) {
    this.selectedRowCount = 0;
    const lastGridIndex = this.gridApiForWorkItem.getDisplayedRowCount() - 1;
    const currentPage = this.gridApiForWorkItem.paginationGetCurrentPage();
    const pageSize = this.gridApiForWorkItem.paginationGetPageSize();
    const startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }

    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const rowNode = this.gridApiForWorkItem.getDisplayedRowAtIndex(i);
      if (rowNode.group === false) {
        rowNode.setSelected(selected, false);
        this.selectedRowCount += 1;
      }
    }
  }

  onSelectionChanged(event) {
    this.selectedRowCount = event.api.getSelectedNodes().length;
  }
}
