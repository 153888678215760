import { ModalService } from "../../shared/modal.service";
import { getRequestStatusDescription,
         requestStatusMapping 
} from "../../utility/common-helper";
import * as CDs from "src/app/utility/commonColDef";

function statusModel(params) {
  const modalService = params.context.modalService as ModalService;
  const name = getRequestStatusDescription(params.value)?.name;
  // We have different Modal for pending approval
  if (name === "Pending Approval") {
    modalService.previousApprovalsModal(params.data.ParentRequestId, params.data.SubRequestId);
  } else {
    const descriptions = requestStatusMapping
      .filter((m) => m.description)
      .filter((value, index, self) => self.findIndex((d) => d.name === value.name) === index)
      .map((m) => {
        const current = m.name === name;
        return `${(current && "<mark>") || ""}<b>${m.name}:</b>${m.description}${(current && "</mark>") || ""}`;
      })
      .join("<br/><br />\n");
    modalService.promptModal("Status Description", descriptions, "xl");
  }
}

export const SubrequestColumnDefinitionForRequest = [
  CDs.RequestId,
  {
    ...CDs.Status,
    onCellClicked: statusModel,
    cellRenderer: (params) => {
      let status = "";
      if (params.value === "InProgress" && params.data.FulfillChannel === "RDQuota") {
        status = "Awaiting RDQuota";
      } else {
        status = getRequestStatusDescription(params.value)?.name || params.value;
      }
      return `<a href="javascript:void(0)">${status}</a>`;
    },
  },
  CDs.TicketIds,
  CDs.RequestDisplayServiceType,
  CDs.SKU,
  CDs.Quota,
  CDs.DeploymentScope,
  CDs.AdditionalParams,
  CDs.FulfillChannel,
  CDs.Dependencies,
  CDs.ApprovedBy
];
