<div class="title" #title>
    <div class="container container-max-width">
      <div>
        <h4>Review PF Plan</h4>
        <p>Review the diff between approved and new PF plan versions with all plan files</p>
      </div>
      <div class="action-container">
        <button type="submit" class="btn btn-success" (click)="showApprovePlanDialog()" [disabled]="!canApprove">Approve</button>&nbsp;
        <button type="submit" class="btn btn-danger" (click)="showRejectPlanDialog()" [disabled]="!canReject">Reject</button>
      </div>
    </div>
  </div>
  <div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
    <div class="landing-main">
      <div class="top-panel" style="height: fit-content">
        <ul class="list-container col-md-12" *ngIf="pfPlan">
          <li class="flex-box mb-3">
            <div class="col-md-2 label">Service Team</div>
            <div class="col-md-10 value">{{ pfPlan.ServiceTeam }}&nbsp;({{ pfPlan.ServiceTreeId }})</div>
          </li>
          <li class="flex-box mb-3">
            <div class="col-md-2 label">Ring</div>
            <div class="col-md-10 value">{{ pfPlan.Ring }}</div>
          </li>
          <li class="flex-box mb-3">
            <div class="col-md-2 label">Version</div>
            <div class="col-md-10 value">
              {{ pfPlan.Version }} &nbsp;&nbsp;(
              <b *ngIf="pfPlan.Version != latestVersion; else userTemplate">
                Latest version is
                <a href="{{ pfPlanLatestVersionReviewLink }}"
                   class="topbar-item"
                   target="_blank">
                  {{ latestVersion }}
                </a>
              </b>
              <ng-template #userTemplate>Latest version</ng-template>)
            </div>
          </li>
          <li class="flex-box mb-3">
            <div class="col-md-2 label">Status</div>
            <div class="col-md-10 value">
              {{ showStatus(pfPlan) }}
              <span *ngIf="showRejectComment" [ngbTooltip]="rejectComment"><i class="fa fa-info-circle"></i></span>
            </div>
          </li>
          <li class="flex-box mb-3">
            <div class="col-md-2 label">Submitted</div>
            <div class="col-md-10 value">{{ showSubmit(pfPlan) }}</div>
          </li>
        </ul>
      </div>
      <div class="card mb-2 mt-4" style="clear: both">
        <div class="card-header flex-row">
          <div class="float-left">
            <span class="bold-font">default.json Diff</span>
          </div>
        </div>
        <div class="modal-body editor-container">
            <monaco-diff-editor theme="vs" (ready)="onEditorReady($event)"></monaco-diff-editor>
        </div>
      </div>
    </div>
  </div>
  