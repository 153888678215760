<div class="title">
  <div class="container">
    <h4>Product Onboard Status</h4>
    <p>Use this form to view Product Onboard status</p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isUserAuthorized()">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && isUserAuthorized() && !isUserPermitted()">
  <h5>Permission is required!</h5>
  <h5>Please Contact <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add the permission </h5>
</div>
<div class="container container-content" *ngIf="isLoadingFinished && isUserPermitted()">
  <div class="main-content">
    <h4>Review Services</h4>
    <p style="margin-bottom: 0px">
      Filter by:&nbsp;
      <a *ngIf="filterByLatest" href="javascript:void(0)" (click)="onLatestFilterSwitched()"> All </a>
      {{ filterByLatest ? "" : "All" }}
      &nbsp;|&nbsp;
      <a *ngIf="!filterByLatest" href="javascript:void(0)" (click)="onLatestFilterSwitched()"> Latest </a>
      {{ filterByLatest ? "Latest" : "" }}
    </p>
    <div style="width: 100%; text-align: right">
      <a
        href="javascript:void(0)"
        class="topbar-item"
        (click)="onExportExcel()"
        placement="top-right"
        container="body"
        ngbTooltip="Export to Excel"
        aria-label="Export to Excel"
      >
        <span class="far fa-file-excel fa-lg"></span>
      </a>
    </div>
    <!--form #reqStatusForm="ngForm" autocomplete="off" style="height: 650px" novalidate-->
    <div class="nofloat" style="width: 100%">
      <ag-grid-angular
        style="height: 540px; margin-bottom: 10px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowSelection]="rowSelection"
        [rowMultiSelectWithClick]="true"
        [rowData]="status"
        [columnDefs]="columnDefs"
        (gridReady)="onInScopeServicesGridReady($event)"
        [masterDetail]="true"
        [detailRowHeight]="145"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>
