<div class="modal-header flex-box" style="align-items: baseline">
  <h2 class="modal-title h4">{{ ffpPlanFile.FileName }}</h2>
  <span><a target="_blank">Learn about the schema</a></span>
</div>
<form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
  <div class="modal-body editor-container">
    <monaco-editor theme="vs" (ready)="onEditorReady($event)"></monaco-editor>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid || options.isReadonly" data-test="SaveJson">Save</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  </div>
</form>
