import { Injectable } from "@angular/core";
import { AgsSideNavItem } from "@ags/common-ui";
import { Subscription } from "rxjs";
import { SharedDataService } from "../services/sharedDataService";
import { AppComponent as BetApp } from "../bet/app.component";
import { IsServiceAdmin, IsServiceUser } from "../utility/userprofile-helper";
import { ModuleService } from "./module.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  lionrockNavItems: AgsSideNavItem[] = [];
  plannedNavItems: AgsSideNavItem[] = [];
  adminNavItems: AgsSideNavItem[] = [];
  prepNavItems: AgsSideNavItem[] = [];
  selfNavItems: AgsSideNavItem[] = [];
  ffpNavItems: AgsSideNavItem[] = [];
  pfNavItems: AgsSideNavItem[] = [];
  subscription: Subscription;

  constructor(private moduleService: ModuleService, private sharedDataService: SharedDataService) {
    this.lionrockNavItems = [
      {
        name: "Region Access and Quota",
        children: [
          {
            name: "New Request",
            url: "/create-request",
          },
          {
            name: "My Requests",
            url: "/request-status",
          },
          {
            name: "All Requests",
            url: "/requests",
          },
        ],
      },
    ];

    this.plannedNavItems = [
      {
        name: "Plans",
        children: [
          {
            name: "Region Agnostic Plans",
            url: "/plans/all",
          },
        ],
      },
      {
        name: "Requests",
        children: [
          {
            name: "My Requests",
            url: "/planned/my-requests",
          },
          {
            name: "All Requests",
            url: "/planned/requests",
          },
        ],
      },
    ];

    this.selfNavItems = [
      {
        name: "Extended Information",
        url: "/self-service/region-information",
      },
      {
        name: "VM Sizes",
        url: "/self-service/vm-sizes",
      },
      {
        name: "Arm Resource Type Enablement",
        url: "/self-service/arm-resource-type",
      }
    ];

    this.ffpNavItems = [
      {
        name: "FFP Plans",
        children: [
          {
            name: "Project Agnostic Plans",
            url: "/ffp/all",
          },
          {
            name: "Buildout",
            url: "/ffp/buildout",
          }
        ],
      },
    ];

    this.pfNavItems = [
      {
        name: "PF Plans",
        children: [
          {
            name: "Region Agnostic Plans",
            url: "/pf/all",
          },
        ],
      },
    ];

    const approverAdmin = {
      name: "Approvers",
      url: "/approver-admin",
    };

    this.subscription = this.sharedDataService.getUserProfile().subscribe((res) => {
      const userProfile = res;

      // Region Access and Quota menu
      if (userProfile?.IsApprover) {
        this.lionrockNavItems.push({
          name: "Approve Requests",
          url: "/approver-review",
        });

        this.lionrockNavItems.push({
          name: "All Requests - Legacy View",
          url: "/admin/operator-review",
        });
      }

      if (userProfile?.IsAdmin) {
        this.adminNavItems.push(
          {
            name: "Management",
            children: [
              approverAdmin,
              { name: "Regions", url: "/admin-view/region" },
              { name: "Edge Zones", url: "/admin-view/edgezone" },
              { name: "User Roles", url: "/admin-view/userrole" },
              { name: "Team Config", url: "/admin-view/teams/config" },
              { name: "Service Handling", url: "/admin-view/servicehandling" },
              { name: "Subscription Bindings", url: "/admin-view/bindings/subscription" },
              { name: "Property Bindings", url: "/admin-view/bindings/property" },
              { name: "Manatree Config", url: "/admin-view/manatree" }
            ],
          },
          {
            name: "Auto Approval",
            children: [
              { name: "Basic Rules", url: "/admin-view/autoapprovalrule" },
              { name: "Hobo Subscription Rules", url: "admin-view/autoapprovalrule-hobo" },
              { name: "Regional Quota Limit", url: "/admin-view/regionalQuota" },
              { name: "Subscription Limit", url: "/admin-view/subscriptionlimit" }
            ]
          },
          {
            name: "Views",
            children: [
              { name: "Capacity Order", url: "/admin-view/capacity-order-view" },
              { name: "Emails", url: "/admin-view/emails-view" },
              { name: "Raw Tables", url: "/admin-view/table-view" }
            ],
          },
          {
            name: "Incidents",
            children: [
              { name: "Active Sub Requests", url: "/incidents-view/active-subrequests" },
              { name: "Active Parent Requests", url: "/incidents-view/active-requests" },
              { name: "Incomplete Tickets", url: "/incidents-view/incomplete-tickets" },
            ]
          }
        );
      } else if (userProfile?.IsApproverAdmin) {
        this.adminNavItems.push({
          name: "Management",
          children: [approverAdmin],
        });
      }

      // Planned Quota menu
      const isServiceAdmin = IsServiceAdmin(userProfile);
      const isServiceUser = isServiceAdmin || IsServiceUser(userProfile);
      const plansSection = this.plannedNavItems.find((i) => i.name == "Plans");
      if (isServiceUser || isServiceAdmin) {
        const children = [
          {
            name: "Assign Plan to Regions",
            url: "/plans/regional/assign",
          },
        ];

        if (isServiceAdmin) {
          this.selfNavItems.push({
            name: "My Service Team Users",
            url: "/self-service/mine/users",
          });
          this.selfNavItems.push({
            name: "Revoke access and quota",
            url: "/self-service/revoke-access-quota",
          });
        }

        if (isServiceUser) {
          children.push(
            {
              name: "My Regional Plans",
              url: "/plans/regional/mine",
            },
            // {
            //   name: "Sign-off Regional Plans",
            //   url: "/plans/regional/signoff",
            // },
            {
              name: "Bindings",
              url: "/plans/bindings",
            }
          );
        }

        plansSection.children.push({
          name: "Service Owner",
          children: children,
        });
      }

      if (userProfile?.IsAdmin || userProfile?.IsGETApprover) {
        plansSection.children.push({
          name: "Buildout Owner",
          children: [
            {
              name: "Assign Plan to Region",
              url: "/plans/regions",
            },
            {
              name: "All Regional Plans",
              url: "/plans/regional/all",
            },
            {
              name: "Fulfillment Status",
              url: "/plans/region",
            },
            {
              name: "Plans Risks",
              url: "/plans/plans-risks",
            },
          ],
        });
      }

      if (userProfile?.IsApprover) {
        const plans = this.plannedNavItems.find((i) => i.name == "Plans");
        plans.children.push({
          name: "Approver",
          children: [
            {
              name: "Approve Regional Plans",
              url: "/plans/regional/approvable",
            },
            {
              name: "Quota Allocation Summary",
              url: "/plans/approval-info",
            },
          ],
        });
      }

      if (userProfile?.IsAdmin || !environment.production) {
        const plans = this.plannedNavItems.find((i) => i.name == "Requests");
        plans.children.unshift({
          name: "Create Request",
          url: "/planned/create-request",
        });
      }

      // PF menu
      if (userProfile?.IsAdmin || userProfile?.IsPfAdmin) {
        const pfPlans = this.pfNavItems.find((i) => i.name == "PF Plans");
        pfPlans.children.push({
          name: "Approver",
          children: [
            {
              name: "Approve PF Plans",
              url: "/pf/approvable",
            }
          ]
        })
      }

      // FFP menu
      const ffpPlansSection = this.ffpNavItems.find((i) => i.name == "FFP Plans");
      if (isServiceUser) {
        const children = [
          /*{
            name: "Assign FFP Plan to Projects",
            url: "/ffp/plans/regional/assign",
          },*/
          {
            name: "My FFP Instances",
            url: "/ffp/instances/mine",
          },
        ];

        ffpPlansSection.children.push({
          name: "Service Owner",
          children: children,
        });
      }

      if (userProfile?.IsAdmin || userProfile?.IsFfpAdmin) {
        ffpPlansSection.children.push({
          name: "Lionrock Admin",
          children: [
            {
              name: "Assign FFP Plans To Project",
              url: "/ffp/plans/projects",
            },
          ],
        });
      }

      this.prepNavItems = [
        {
          name: "Billing Meter",
          children: BetApp.prepNavItems[0].children.filter((child: any) => {
            // all the people can access if the menu not set the roles
            if (!child.Roles) {
              return true;
            }

            // check whether user profile contains the role required
            return userProfile?.UserRoles.some((i) => child.Roles.includes(i.Role));
          }),
        },
      ];
    });
  }

  getModuleNavItems(): AgsSideNavItem[] {
    switch (this.moduleService.module) {
      case "Billing Meter":
        return this.prepNavItems;
      case "Lionrock":
        return this.lionrockNavItems;
      case "Planned":
        return this.plannedNavItems;
      case "Administrator":
        return this.adminNavItems;
      case "SelfService":
        return this.selfNavItems;
      case "FFP":
        return this.ffpNavItems;
      case "PF":
        return this.pfNavItems;
    }
  }
}
