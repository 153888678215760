import { AfterViewInit, Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { ModalService } from "src/app/shared/modal.service";
import { ColumnApi, GridOptions } from "ag-grid-community";
import { IUserProfile } from "src/app/npr-request.model";
import { PfPlan } from "src/app/generated-models/PfPlan";
import { PfPlanOperationTypeEnum, PfPlanStatusEnum } from "src/app/shared/enums/pf-enums";
import { PfPlanRejectData } from "src/app/generated-models/PfPlanRejectData";
import { PfPlanFile } from "src/app/generated-models/PfPlanFile";

@Component({
  templateUrl: "./pf-plan-review.component.html",
  styleUrls: ["../../styles.scss", "./pf.scss"],
})
export class PfPlanReviewComponent extends BaseComponent implements AfterViewInit, OnInit {
  gridColumnApi: ColumnApi;

  editor: monaco.editor.IStandaloneDiffEditor;
  code: string;
  currentData: PfPlanFile;
  lastApprovedData: PfPlanFile;
  serviceTreeId: string;
  version: number;
  latestVersion: number = null;
  pfPlan: PfPlan;
  rejectComment: string = "";
  pfPlanLatestVersionReviewLink: string;
  message: string;
  canApprove = false;
  canReject = false;
  showRejectComment = false;
  userProfile: IUserProfile;

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
  }

  async ngOnInit() {
    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];
    this.version = this.route.snapshot.params["version"];
    this.title.setTitle(`${this.route.snapshot.data.title} for ${this.serviceTreeId} v${this.version}`);

    this.userProfile = await this.apiService.getUserProfile();
    await this.loadPfPlan();
  }

  onGridReady(params: GridOptions) {
    this.gridColumnApi = params.columnApi;
  }

  async onEditorReady(editor: monaco.editor.IStandaloneDiffEditor) {
    await this.getPfPlanFiles();
    this.editor = editor;

    var originalModel = monaco.editor.createModel(this.lastApprovedData?.Content, "application/json");
    var modifiedModel = monaco.editor.createModel(this.currentData.Content, "application/json");

    this.editor.setModel({
      original: originalModel,
      modified: modifiedModel,
    });

    // delay 1sec for formatting the content
    setTimeout(() => {
      this.editor
        .getModifiedEditor()
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          this.editor.updateOptions({ readOnly: true });
        });
    }, 1000);
  }

  async loadPfPlan() {
    await this.apiService
      .getPfPlan(this.serviceTreeId, this.version)
      .toPromise()
      .then((response) => {
        this.pfPlan = response;
        if (this.pfPlan.Status === PfPlanStatusEnum.Approvable) {
          this.canApprove = this.userProfile?.IsApprover;
          this.canReject = true;
        }
        this.rejectComment = this.pfPlan.Operations.find(_ => _.Operation == PfPlanOperationTypeEnum.Remove)?.Comment;
        if (this.pfPlan.Status == PfPlanStatusEnum.Removed && this.rejectComment) {
          this.showRejectComment = true;
          
        }
      })
      .catch((e) => {
        this.notificationService.error(e);
      });

    const latestVersionPfPlan = await this.apiService
      .getPfPlanLatestVersion(this.serviceTreeId)
      .toPromise();
    this.latestVersion = latestVersionPfPlan.Version;
    if (this.latestVersion != null) {
      this.pfPlanLatestVersionReviewLink = `/quota/pf/plans/services/${this.serviceTreeId}/versions/${this.latestVersion}/review`
    }
  }

  async showApprovePlanDialog() {
    this.approvePfPlan();
  }

  approvePfPlan() {

    this.loadingService.setLoading(true);

    this.apiService.approvePfPlan(this.pfPlan.ServiceTreeId, this.pfPlan.Version).subscribe(
      (response) => {
        if (response) {
          this.message = response.Message;
        } else {
          this.message = `Plan have been approved successfully.`;
        }

        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to approve plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadPfPlan();
        this.loadingService.setLoading(false);
      }
    );
  }

  async showRejectPlanDialog() {
    const comment = await this.modalService.planRejectModal();
    this.rejectPfPlan(comment);
  }

  rejectPfPlan(comment: string) {
    const pfPlanRejectData: PfPlanRejectData = {
      Version: this.pfPlan.Version,
      Comment: comment,
    } as PfPlanRejectData;

    this.loadingService.setLoading(true);

    this.apiService.rejectPfPlan(this.pfPlan.ServiceTreeId, pfPlanRejectData).subscribe(
      () => {
        this.message = `Plan have been rejected.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to reject plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadPfPlan();
        this.loadingService.setLoading(false);
      }
    );
  }

  async getPfPlanFiles() {
    this.loadingService.setLoading(true);

    await this.apiService.getPfPlanFiles(this.serviceTreeId, this.version)
        .toPromise()
        .then((response) => {
            response.forEach((plan) => {
              if (plan.FileName === "default.json") {
                this.currentData = plan;
              } 
            });
          })
          .catch((e: unknown) => {
            this.notificationService.error(e as string);
          });

    await this.apiService.getLatestApprovedPfPlanFiles(this.serviceTreeId)
        .toPromise()
        .then((response) => {
            if (response != null) {
                response.forEach((plan) => {
                    if (plan.FileName === "default.json") {
                    this.lastApprovedData = plan;
                    } 
                });
            }
            this.loadingService.setLoading(false);
        })
        .catch((e: unknown) => {
            this.loadingService.setLoading(false);
            this.notificationService.error(e as string);
          });
        
  }
  
  showStatus(pfPlan: PfPlan) {
    if (pfPlan.Status === PfPlanStatusEnum.Approved) {
      var approveOperation = pfPlan.Operations.find(_ => _.Operation == PfPlanOperationTypeEnum.Approve);
      return `Approved by ${approveOperation.By} at ${PreprocessDateTime(approveOperation.At)}`;
    } else if (pfPlan.Status === PfPlanStatusEnum.Removed) {
        var removeOperation = pfPlan.Operations.find(_ => _.Operation == PfPlanOperationTypeEnum.Remove);
      return `Removed by ${removeOperation.By} at ${PreprocessDateTime(removeOperation.At)}`;
    } else {
      return pfPlan.Status;
    }
  }

  showSubmit(pfPlan: PfPlan) {
    var createOperation = pfPlan.Operations.find(_ => _.Operation == PfPlanOperationTypeEnum.Create);
    if (createOperation) {
      return `by ${createOperation.By} at ${PreprocessDateTime(createOperation.At)}`;
    } else {
      return `N/A`;
    }
  }
}
