import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  template: `<div class="modal-header">
      <h4 class="modal-title">Information</h4>
    </div>
    <div class="modal-body">
      <p [innerHtml]="message"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">
        Clean
      </button>
      <button type="button" class="btn btn-primary" (click)="activeModal.close(false)">
        Ok
      </button>
    </div>`,
  styles: [".modal-body { height: 650px; overflow: scroll; }"],
})
export class FilterModalComponent {
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) {}
}
