export enum PlanValidityEnum {
  GA = 1,
  NeverExpire = 2,
  Custom = 3,
}

export enum PlanPurposeEnum {
  RegionBuildout = 1,
  RingPromotion = 2,
  Test = 3,
}

export enum PlanRegionStatusEnum {
  Created = "Created",
  NeedSignOff = "NeedSignOff",
  Approvable = "Approvable",
  Approved = "Approved",
  Error = "Error",
  Rejected = "Rejected",
  Removed = "Removed",
  /**
   * @deprecated This status has been deprecated and should not be used.
   */
  FulfillmentStarted = "FulfillmentStarted",
}

export enum PlannedQuotaUserRoleEnum {
  User = "User",
  Administrator = "Administrator",
}

export enum PlannedQuotaRequestStatus {
  Created = "Created",
  Processed = "Processed",
  Error = "Error",
  Completed = "Completed",
  ValidationFailed = "ValidationFailed",
}

export enum RegionStatusEnum {
  Approved = 1,
  Buildout = 2,
  Ring0Complete = 3,
  ProductionSLA = 4,
}

export enum PlanFileTypeEnum {
  UserAdded = "UserAdded",
  Generated = "Generated",
}

export enum RingLevel {
  Other = "Other",
  Ring0 = "Ring0",
  Ring1 = "Ring1",
  Ring2 = "Ring2",
  Ring3 = "Ring3",
  Limited = "Limited",
  Unknown = "Unknown",
}

export enum LionrockEmailType {
  ActionRequired = "ActionRequired",
  ApprovalReminder = "ApprovalReminder",
  AdoTicketCreate = "AdoTicketCreate",
  AdoTicketUpdate = "AdoTicketUpdate",
  BillingMeterJobStatusNotification = "BillingMeterJobStatusNotification",
  BillingMeterRequestsStatusNotification = "BillingMeterRequestsStatusNotification",
  BillingMeterRequestsNeedAttention = "BillingMeterRequestsNeedAttention",
  Completion = "Completion",
  Cancellation = "Cancellation",
  DiscussionUpdate = "DiscussionUpdate",
  DuplicateSubRequestAlert = "DuplicateSubRequestAlert",
  FieldFormFailed = "FieldFormFailed",
  ProcessedApi = "ProcessedApi",
  PlanSignOff = "PlanSignOff",
  PlanStatusChange = "PlanStatusChange",
  PremierCustomer = "PremierCustomer",
  Rejection = "Rejection",
  RegionalPlanExpiryNotification = "RegionalPlanExpiryNotification",
  SetPriority = "SetPriority",
  SetQuota = "SetQuota",
  SetUtilizeDate = "SetUtilizeDate",
  SetStorageQuota = "SetStorageQuota",
  Submitted = "Submitted",
  RegionalPlanApprovalNotification = "RegionalPlanApprovalNotification",
  FfpPlanStatusChange = "FfpPlanStatusChange",
  FfpRegionalPlanApprovalNotification = "FfpRegionalPlanApprovalNotification"
}

export enum CapacityOrderRequestStateEnum {
  Accepted = "Accepted",
  InProgress = "InProgress",
  Expired = "Expired",
  Approved = "Approved",
  Failed = "Failed",
}

export enum GCTStateEnum {
  Buildout = "buildout",
  Growth = "growth",
}

export enum FfpPlanInstanceStatusEnum {
  Created = "Created",
  Approvable = "Approvable",
  Approved = "Approved",
  Error = "Error",
  Rejected = "Rejected",
  Removed = "Removed",
}

export enum PlanRegionApprovalStatusEnum {
  Pending = "Pending",
  Approved = "Approved",
}
