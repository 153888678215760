<div class="modal-header flex-box" style="align-items: baseline">
  <h2 class="modal-title h4">{{ planFile.FileName }} diff</h2>
</div>
<form #ngForm="ngForm">
  <div class="modal-body editor-container">
    <monaco-diff-editor theme="vs" (ready)="onEditorReady($event)"></monaco-diff-editor>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">OK</button>
  </div>
</form>
