<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<form #requestInputForm="ngForm" autocomplete="off" novalidate>
  <div class="modal-body">
    <p [innerHtml]="message"></p>
    <input class="form-control" name="inputText" id="inputText" type="text" ng-required="required" [(ngModel)]="returnText" />
    <em *ngIf="requestInputForm.controls.inputText?.invalid && requestInputForm.controls.inputText?.dirty">Required</em>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close(this.returnText)" [disabled]="requestInputForm.invalid">
      Yes
    </button>
    <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()">No</button>
  </div>
</form>
