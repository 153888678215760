import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class ErrorTelemetryService {
  constructor(private httpClient: HttpClient) {}

  private hashCode(str: string) {
    return str.split("").reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
  }

  public ingestError(message: string, eventId?: string): Observable<void> {
    console.log("ingest error message =", message);
    const url = `${environment.lionrockApiEndpoint}/api/error`;
    return this.httpClient.post<void>(url, { Message: message, EventId: Math.abs(this.hashCode(eventId || message)) });
  }
}
