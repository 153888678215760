<div class="modal-header">
  <p [innerHtml]="message"></p>
</div>
<form #approverEditForm="ngForm" autocomplete="off" novalidate>
  <div class="modal-body" style="height: 370px">
    User Account:
    <input class="form-control" name="inputUpn" id="inputText" type="text" readonly="true" required [(ngModel)]="returnUpnText" />
    <br />
    Role Type:
    <input class="form-control" name="inputRole" id="inputText" type="text" readonly="true" required [(ngModel)]="returnRoleText" />
    <br />
    Region(s):
    <div>
      <label>
        <input type="checkbox" name="allRegions" [(ngModel)]="isAllRegionsSelected" />
        Select all regions
      </label>
    </div>
    <ng-select
      *ngIf="!isAllRegionsSelected"
      name="inputRegions"
      labelForId="inputRegions"
      [items]="regionsList"
      bindLabel="region"
      bindValue="region"
      groupBy="cloud"
      required
      [multiple]="true"
      [(ngModel)]="selectedRegions"
    >
    </ng-select>
    <em *ngIf="approverEditForm.controls.inputRegions?.invalid && approverEditForm.controls.inputRegions?.touched">Required</em>

    Types(s):
    <div>
      <label>
        <input type="checkbox" name="allTypes" [(ngModel)]="isAllTypesSelected" />
        Select all types
      </label>
    </div>
    <ng-select
      *ngIf="!isAllTypesSelected"
      name="inputTypes"
      labelForId="inputTypes"
      [items]="typesList"
      required
      [multiple]="true"
      [(ngModel)]="selectedTypes"
    >
    </ng-select>
    <em *ngIf="approverEditForm.controls.inputTypes?.invalid && approverEditForm.controls.inputTypes?.touched">Required</em>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="approverEditForm.invalid" (click)="save()">Yes</button>
    <button type="button" class="btn btn-warning" (click)="activeModal.close()">No</button>
  </div>
</form>
