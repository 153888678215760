<h4>Basic Info</h4>
<ul class="list-container">
  <!--choose Customer or Internal Form-->
  <li class="flex-box mb-3">
    <div class="col-md-3 required">Subscription type</div>
    <div class="col-md-9">
      <label class="mr-2">
        <input class="mr-1" name="IsExternal" [(ngModel)]="basicInfo.isExternal" (change)="changeSubType()" type="radio" [value]="false" />
        <span>Internal</span>
      </label>
      <label data-test="CustomerSubscription">
        <input class="mr-1" name="IsExternal" [(ngModel)]="basicInfo.isExternal" (change)="changeSubType()" type="radio" [value]="true" />
        <span>Customer</span>
      </label>
    </div>
  </li>
  <!--If Internal Request, type in Requesting Service Team-->
  <li *ngIf="!basicInfo.isExternal" class="flex-box mb-3">
    <div class="col-md-3 required">Requesting Service Team</div>
    <label class="col-md-9">
      <ng-select
        name="TeamName"
        [(ngModel)]="basicInfo.team"
        [items]="service.teamList"
        groupBy="ring"
        placeholder="Start typing"
        [virtualScroll]="true"
        required
        data-test="TeamName"
        #teamName="ngModel"
      >
        <ng-template ng-option-tmp let-item="item">
          <ng-container>
            <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
              {{ item.label }}
            </label>
          </ng-container>
        </ng-template>
      </ng-select>
      <label class="info-box">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span>This name is from your associated Service Tree. If your service is not listed, please select Other</span>
      </label>
      <em *ngIf="isTouchedOrDirty(teamName) && teamName.errors?.required">Required</em>
      <label *ngIf="isOtherTeam">
        <label class="info-box">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <p class="hint">
            Requests submitted using Team Name "Other" will go through Manual approval. For auto approval of requests, please update
            <a href="https://aka.ms/pamurl" target="_blank">PAM</a> with your capacity needs and use the same Team Name for request
            submission
          </p>
        </label>
        <input
          class="form-control"
          name="otherTeamName"
          [(ngModel)]="basicInfo.otherTeam"
          placeholder="type your Service Name"
          required
          data-test="OtherTeamName"
          #otherTeamName="ngModel"
        />
        <em *ngIf="isTouchedOrDirty(otherTeamName) && otherTeamName.errors?.required">Required</em>
          <input class="form-control"
                 name="otherTeamOid"
                 title="otherTeamOid"
                 [(ngModel)]="basicInfo.otherTeamOid"
                 placeholder="type your Service Tree ID"
                 data-test="OtherTeamOid"
                 #otherTeamOid="ngModel"
                 [pattern]="guidRegExpression"/>
          <ng-container *ngIf="isTouchedOrDirty(otherTeamOid)">
            <em *ngIf="otherTeamOid.errors?.pattern">Please enter a valid service tree ID.</em>
          </ng-container>
      </label>
    </label>
  </li>
  <!--If Customer Request, Type in or Query Customer Name-->
  <li *ngIf="basicInfo.isExternal" class="flex-box mb-3">
    <div class="col-md-3" [class.required]="true">Customer Name</div>
    <div class="col-md-9">
      <input
        class="form-control"
        [(ngModel)]="basicInfo.customer"
        name="CustomerName"
        placeholder="type your customer name"
        required
        #customerName="ngModel"
        data-test="CustomerName"
      />
      <em *ngIf="isTouchedOrDirty(customerName) && customerName.errors?.required">Required</em>
    </div>
  </li>
  <!--Email Address-->
  <li class="flex-box mb-3">
    <div class="col-md-3 required">Contact Email</div>
    <div class="col-md-9">
      <input title="Email" class="form-control" [(ngModel)]="basicInfo.email" name="Email" required #email="ngModel" />
      <ng-container *ngIf="isTouchedOrDirty(email)">
        <em *ngIf="email.errors?.required">Required</em>
        <em *ngIf="!isEmailValid">Enter email address in this format: alias1@contoso.com,alias2@contoso.com</em>
      </ng-container>
    </div>
  </li>
  <!--Region-->
  <li class="flex-box mb-3">
    <div class="col-md-3 required">Region</div>
    <label class="col-md-9">
      <ng-select
        [(ngModel)]="basicInfo.region"
        name="Region"
        groupBy="cloud"
        placeholder="Enter region name"
        required
        data-test="Region"
        #region="ngModel"
      >
      <ng-option *ngFor="let option of regionList" [value]="option">
        {{ option.label }}
      </ng-option>
      </ng-select>
      <em *ngIf="isTouchedOrDirty(region) && region.errors?.required">Required</em>
      <label *ngIf="isRegionGaAndPublic" class="info-box">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span>
          Refer to the
          <a target="_blank" href="https://microsoft.sharepoint.com/teams/AzureServiceExperience/Pages/Capacity.aspx"
            >CSCP reliability portal</a
          >
          for latest information on restrictions for live regions.
        </span>
      </label>
      <label *ngIf="constrainedMessage" class="info-box">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span class="highlight" [innerHtml]="constrainedMessage"></span>
      </label>
      <label *ngIf="!IsRegionEnabledInUI" class="info-box">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span class="highlight"
          >Per request from the Global Capacity Organization, the Access and Quota tool in Lionrock is not accepting requests for this
          region. Please navigate to <a href="https://ms.portal.azure.com/" target="_blank">portal.azure.com</a> to make resource
          requests.</span
        >
      </label>
    </label>
  </li>
  <!--Subscription ID-->
  <li class="flex-box mb-3">
    <div class="col-md-3 required">Subscription</div>
    <div class="col-md-9">
      <ng-container>
        <input
          class="form-control"
          [(ngModel)]="basicInfo.subId"
          name="SubId"
          placeholder="Enter in this format: 987e33bf-8713-45cf-93c5-7055c9cca413"
          required
          data-test="SubId"
          #subId="ngModel"
          [pattern]="guidRegExpression"
        />
        <ng-container *ngIf="isTouchedOrDirty(subId)">
          <em *ngIf="subId.errors?.required">Required</em>
          <em *ngIf="subId.errors?.pattern">Please enter one valid subscription ID.</em>
        </ng-container>
      </ng-container>
    </div>
  </li>
</ul>
