import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

export interface ViewCellComponentParams<T> {
  viewFunc: (data: T) => Promise<any>;
}

@Component({
  templateUrl: "./view-cell.component.html",
})
export class ViewCellComponent<T> implements AgRendererComponent {
  params: ViewCellComponentParams<T>;
  data: T;

  constructor() { }

  refresh(): boolean {
    throw new Error("Method not implemented.");
  }

  agInit(params: ICellRendererParams & ViewCellComponentParams<T>) {
    this.params = params;
    this.data = params.data;
  }

  ngOnInit() { }

  view() {
    this.params.viewFunc(this.data);
  }
}
