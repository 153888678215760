import { Component, OnInit } from "@angular/core";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import {
  IRequestRegion,
  ISelfServiceInfoInput,
  RequestServiceType,
  IRequestServiceType,
  allowedCommonRegionStatuses,
} from "../../npr-request.model";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { NprConfiguration } from "../../app.configuration";
import { BaseComponent } from "../../shared/base.component";
import { Region, VmSku } from "../../generated-models";
import { ToastrService } from "ngx-toastr";

@Component({
  templateUrl: "./self-service.component.html",
  styleUrls: ["../../styles.scss"],
})
export class SelfServiceComponent extends BaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  regionList: IRequestRegion[] = [];
  serviceTypeList = [];
  vmSkuList: string[] = [];
  region: IRequestRegion;
  subscriptionId = "";
  serviceType: IRequestServiceType;
  sku = "";
  quota: number;
  subValidationError = "";

  constructor(private apiService: ApiService, private loadingService: LoadingScreenService, private notificationService: ToastrService) {
    super();
  }

  ngOnInit() {
    this.loadingService.setLoading(true);

    // Service Type List
    this.serviceTypeList = [
      { label: "AZ Access", value: "AZ" },
      { label: "ARM Access", value: "ARM" },
      { label: "ARM VM Quota", value: "ArmVmQuota" },
      { label: "RDFE VM Quota", value: "RdfeVmQuota" },
      { label: "ARM Storage Quota", value: "ArmStorageQuota" },
      { label: "RDFE Storage Quota", value: "RdfeStorageQuota" },

      //TODO(kezha): extend this list to support more service types
    ];

    // Region List
    this.apiService?.getRegionList().subscribe(
      (response: Region[]) => {
        if (!response) {
          console.warn("No region is obtained from api server.");
        } else {
          this.regionList = response
            .filter(region => allowedCommonRegionStatuses.includes(region.Status))
            .map((r, i) => {
              return ApiService.createRequestRegionFromRegion(r, i + 1);
            });
        }
        this.loadingService.setLoading(false);
      },
      () => {
        this.loadingService.setLoading(false);
      }
    );
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  async submitRequest() {
    const newInput: ISelfServiceInfoInput = {} as ISelfServiceInfoInput;
    newInput.SubscriptionId = this.subscriptionId;
    newInput.Region = this.region.label;
    newInput.ServiceType = RequestServiceType[this.serviceType.value];
    newInput.SKU = this.sku;
    newInput.Quota = this.quota;

    // Subscription id regular expression check
    this.subValidationError = "";
    const regexp = new RegExp(NprConfiguration.guidRegExpression);
    if (!regexp.test(newInput.SubscriptionId)) {
      this.subValidationError = "You should enter valid subscription ID.";
      this.loadingService.setLoading(false);
      return;
    }
    this.loadingService.setLoading(true);

    return await this.apiService
      .revokeAccessOrQuota(newInput)
      .toPromise()
      .then(() => {
        const message = "Revoke access or quota was successfully.";
        this.notificationService.info(message);
        this.loadingService.setLoading(false);
      })
      .catch((error) => {
        const message = `Failed to revoke access or quota, error: ${error}`;
        this.notificationService.error(message);
        this.loadingService.setLoading(false);
      });
  }

  get isSkuVisible(): boolean {
    return this.serviceType?.value == RequestServiceType.ArmVmQuota;
  }

  get isQuotaVisible(): boolean {
    return (
      this.serviceType?.value == RequestServiceType.ArmVmQuota ||
      this.serviceType?.value == RequestServiceType.RdfeVmQuota ||
      this.serviceType?.value == RequestServiceType.ArmStorageQuota ||
      this.serviceType?.value == RequestServiceType.RdfeStorageQuota
    );
  }

  get isQuotaValid(): boolean {
    return this.quota && this.quota >= 1;
  }

  onRegionChanged(): void {
    this.apiService?.getVmSkusByRegion(this.region.label).subscribe((response: VmSku[]) => {
      if (!response) {
        console.warn("No sku is obtained from api server.");
      } else {
        this.vmSkuList = response.map((r) => r.PortalVmSku).filter(r => r);
      }
    });
  }
}
