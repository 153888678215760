import { ActivatedRoute } from "@angular/router";
import { Component } from '@angular/core';
import { BaseComponent } from "../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { DataService } from "../shared/data.service";
import { ModuleService } from "../../shared/module.service";
import { AuthService } from "../../auth/auth.service";
import { ApiService } from "../shared/api.service";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { GridApi } from "ag-grid-community";
import { ErrorBillingMeterRequest, AckErrorBillingMeterRequest } from "./model";
import { ModalService } from "../shared/modal.service";

@Component({
  selector: 'app-error-billing-meter-list',
  templateUrl: './error-billing-meter-list.component.html',
  styleUrls: ['./error-billing-meter-list.component.scss']
})

export class ErrorBillingMeterListComponent extends BaseComponent {

  errorBillingMeterRequests: ErrorBillingMeterRequest[];
  rowSelection = "multiple";
  private gridApi: GridApi;
  private gridColumnApi;

  gridOptions = extendDefaultOptions({
    getRowHeight: (params) => {
      return this.onGetRowHeight(params);
    },
  });

  constructor(
    apiService: ApiService,
    private modalService: ModalService,
    dataService: DataService,
    loadingScreenService: LoadingScreenService,
    auth: AuthService,
    route: ActivatedRoute,
    private moduleService: ModuleService,
  ) {
    super(moduleService, auth, apiService, dataService, route, loadingScreenService);
  }
  

  onInit(): void {
    this.fetchErrorBillingMeterRequests();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.gridColumnApi;
    console.log("grid ready");
  }


  fetchErrorBillingMeterRequests(): void {
    this.loadingScreenService.setLoading(true);
    this.apiService
      .GetErrorBillingMeterRequests()
      .subscribe(
        (resp) => {
          this.errorBillingMeterRequests = <ErrorBillingMeterRequest[]>resp;
          this.loadingScreenService.setLoading(false, true);
        },
        err => {
          console.error(err);
          this.loadingScreenService.setLoading(false, true);
        }
      );
  }

  getErrorBillingMeterRequestsColumns(): any[] {
    return [
      {
        checkboxSelection: true,
        field: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: "",
        lockPinned: true,
        width: 40,
      },
      {headerName: "RegionName", field: "RegionName"},
      {headerName: "ProductOid", field: "ProductOid"},
      {headerName: "ProductName", field: "ProductName"},
      {headerName: "CaymanProductId", field: "CaymanProductId"},
      {headerName: "CaymanProductName", field: "CaymanProductName"},
      {headerName: "JobId", field: "JobId"},
      {headerName: "Status", field: "Status"},
      {headerName: "Message", field: "Message"},
      {headerName: "IcmId", field: "IcmId"},
      {headerName: "CreatedBy", field: "CreatedBy"},
      {headerName: "Created", field: "Created"},
      {headerName: "Last Updated", field: "Updated"}
    ];
  }

  isUserAuthorized(): boolean {
    return this.isAuthorizedUser;
  }

  disableAckButton(): boolean {
    return false;
  }

  ackErrorBillingMeterRequests(): void {
    if (this.gridApi.getSelectedRows().length == 0) {
      alert("Please select the requests you want to ack.");
      return;
    }

    const confirmation = this.modalService.onAckErrorBillingMeterConfirmModal(this.gridApi.getSelectedRows.length);
    console.log(confirmation);
    confirmation.then((reason) => {
      console.log("Acked Reason:", reason);

      if (reason != "cancel" && reason != "") {
        this.loadingScreenService.setLoading(true, true);
        var data = {} as Map<string, AckErrorBillingMeterRequest[]>;

        this.gridApi.getSelectedRows().forEach(row => {
          var errorBillingMeterRequest = <ErrorBillingMeterRequest>row;
          if (data[errorBillingMeterRequest.JobId] == null) {
            data[errorBillingMeterRequest.JobId] = [] as AckErrorBillingMeterRequest[];
          }
          var ack = {
            "CaymanProductId": errorBillingMeterRequest.CaymanProductId,
            "AckedReason": reason,
          } as AckErrorBillingMeterRequest;
          data[errorBillingMeterRequest.JobId].push(ack);
        });

        this.apiService.AckErrorBillingMeterRequests(data).subscribe((_) => {
          this.gridApi.getSelectedRows().forEach(row => {
            var errorBillingMeterRequest = <ErrorBillingMeterRequest>row;
            var index = this.errorBillingMeterRequests.indexOf(errorBillingMeterRequest);
            console.log(index);
            if (index > -1) {
              this.errorBillingMeterRequests.splice(index, 1);
            }
          })
          this.gridApi.setRowData(this.errorBillingMeterRequests);
          this.loadingScreenService.setLoading(false, true);
        });
      }
    });
  }
}
