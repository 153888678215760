<div class="title">
  <div class="container">
    <h4>Skip List</h4>
    <p>Use this form to view Skip List</p>
  </div>
</div>
<div class="container container-content">
  <div class="main-content">
    <div class="nofloat" style="width: 100%">
      <ag-grid-angular
        style="height: 540px; margin-bottom: 10px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="skipList"
        [columnDefs]="columnDefs"
        (gridReady)="onGridReady($event)"
        [detailRowHeight]="145"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
    <div>
      <div class="form-group">
        <button type="button" class="btn btn-primary mr-1" (click)="onSave()" [disabled]="!change">
          Save
        </button>
        <button type="button" class="btn btn-primary mr-1" (click)="onReset()" [disabled]="!change">
          Reset
        </button>
      </div>
    </div>
  </div>
</div>
