<div class="modal-header">
  <h4 class="modal-title">Please confirm</h4>
</div>
<div class="modal-body">
  <p [innerHtml]="message"></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" data-test="Yes" (click)="activeModal.close()">Yes</button>
  <button type="button" class="btn btn-warning" data-test="No" (click)="activeModal.dismiss()">No</button>
</div>
