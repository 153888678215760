<div class="title" #title>
  <div class="container">
    <h3>New Product Region Buildout</h3>
    <p>Portal with tools for your region buildout process</p>
  </div>
</div>

<div *ngIf="!isUserAuthorized()">
  <h3>Please login</h3>
</div>
<div class="container container-content" *ngIf="isUserAuthorized()" (window:resize)="onResize()" [style.height]="containerHeight">
  <div>
    <h6>You login in as {{ this.user.username }}</h6>
  </div>
  <div *ngIf="this.userProfile != null">
    <h6 *ngIf="this.userProfile?.IsApprover">You are approvers</h6>
  </div>
</div>
