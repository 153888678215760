<div class="modal-header">
  <h4 class="modal-title">Please select</h4>
</div>
<div class="modal-body" style="height: 200px;">
  <p [innerHtml]="message"></p>

  <em-datepicker class="col-md-5 pl-0" [(ngModel)]="date" name="UtilizeDate"> </em-datepicker>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close(this.date)">
    Yes
  </button>
  <button type="button" class="btn btn-warning" (click)="activeModal.close(null)">
    No
  </button>
</div>
