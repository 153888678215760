import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ApiService } from "../../services";
import { DataTypes } from "./admin-view-data-types";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { CapacityOrder } from "src/app/generated-models/CapacityOrder";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { CapacityOrderRegionalPlanViewColumnDefinition, CapacityOrderViewColumnDefinition } from "../plans/ag-grid-column-definition";

@Component({
  selector: "app-admin-view-capacity-order-view",
  templateUrl: "./admin-view-capacity-order-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewCapacityOrderViewComponent extends GridViewBaseComponent implements OnInit {
  type = "";
  gridOptions: GridOptions;
  regionalPlanRowData: PlanRegion[] = [];
  capacityOrderRowData: CapacityOrder[] = [];
  tableName = "AZ";
  initPageSize: 15;
  gridTheme = "Default";
  message = "";
  keyFilterStats: string;

  public gridApi: GridApi;
  public columnApi: ColumnApi;
  public context: any;

  constructor(
    protected apiService: ApiService,
    protected notificationService: ToastrService,
    protected loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    protected dataTypes: DataTypes
  ) {
    super(apiService, sharedDataService);
    this.context = { componentParent: this };
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);
    this.getAllCapacityOrders();
    this.getAllRegionalPlans();

    this.loadingService.setLoading(false);
    this.gridOptions = extendDefaultOptions({
      columnDefs: CapacityOrderViewColumnDefinition,
      detailRowAutoHeight: true,
      context: this.context,
      detailCellRendererParams: {
        detailGridOptions: {
          detailRowAutoHeight: true,
          columnDefs: CapacityOrderRegionalPlanViewColumnDefinition,
        },
        getDetailRowData: (params) => {
          params.data.requestData = [];
          this.regionalPlanRowData.forEach((data) => {
            if (
              data.ServiceTreeId === params.data.ServiceTreeId &&
              data.Stage === params.data.Stage &&
              data.Region === params.data.Region &&
              data.Version === params.data.Version
            ) {
              params.data.requestData.push(data);
            }
          });
          params.successCallback(params.data.requestData);
        },
      },
    });
  }

  refreshData(): void {}

  refreshSingleEntry(orderId: string) {
    this.loadingService.setLoading(true);
    this.apiService.refreshCapacityOrder(orderId).subscribe(
      (response) => {
        this.capacityOrderRowData.forEach((data) => {
          if (data.CapacityOrderId === response.Id) {
            data.Status = response.Status;
          }
        });

        this.loadingService.setLoading(false);
        this.notificationService.info(`Refresh capacity order ${orderId} successfully!`);
        this.gridApi.refreshCells();
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  getAllCapacityOrders() {
    this.apiService.getAllCapacityOrders().subscribe(
      (response) => {
        this.capacityOrderRowData = response;
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.capacityOrderRowData = [];
        this.notificationService.error(e);
      }
    );
  }

  getAllRegionalPlans() {
    this.apiService.getRegionalPlans().subscribe(
      (response) => {
        this.regionalPlanRowData = response;
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.regionalPlanRowData = [];
        this.notificationService.error(e);
      }
    );
  }

  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.resetColumnState();
  }
}
