<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>Sign-off Regional Plans</h4>
      <p>All plans with region information that need your sign-off</p>
    </div>
    <div class="action-container">
      <button type="submit" class="btn btn-primary" [disabled]="!isSignOffable()" (click)="signOff()">Sign-off</button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main mt-3">
    <ag-grid-angular
      #agGrid
      id="ViewGrid"
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      pagination="true"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanged($event)"
    >
    </ag-grid-angular>
  </div>
</div>
