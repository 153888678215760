import { Component } from "@angular/core";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { PlansNeedSignoffColumnDefinition } from "./ag-grid-column-definition";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import { IUserProfile } from "src/app/npr-request.model";
import { IsServiceUser } from "src/app/utility/userprofile-helper";
import { PlanRegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";

@Component({
  templateUrl: "./plans-signoff.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansSignOffComponent extends GridViewBaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize: number = 15;
  keyFilterStats = "filter:sign-off-regional-plans";
  rowData: PlanRegion[] = [];
  message: string;
  userProfile: IUserProfile;

  constructor(
    protected apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    protected sharedDataService: SharedDataService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    super.InitAgGrid(PlansNeedSignoffColumnDefinition);
    this.refreshData();
  }

  refreshData() {
    this.loadingService.setLoading(true);

    this.apiService.getRegionalPlans(PlanRegionStatusEnum.NeedSignOff).subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  signOff() {
    const selectedRows = this.gridOptions.api.getSelectedRows() as PlanRegion[];

    this.loadingService.setLoading(true);
    const payload: PlanSubmission[] = selectedRows.map((r) => {
      return {
        ServiceTreeId: r.ServiceTreeId,
        Region: r.Region,
        Version: r.Version,
        Purpose: r.Purpose,
      } as PlanSubmission;
    });

    this.apiService.signOffPlanRegions(payload).subscribe(
      () => {
        this.message = `Regional Plan(s) has been sign-offed successfully.`;
        this.notificationService.info(this.message);
      },
      () => {
        this.message = `Regional Plan(s) failed to sign-off.`;
        this.notificationService.error(this.message);
      },
      () => {
        this.loadingService.setLoading(false);
        this.refreshData();
      }
    );
  }

  isSignOffable() {
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows() as PlanRegion[];
      for (const row of selectedRows) {
        if (!IsServiceUser(this.userProfile, row.ServiceTreeId)) {
          return false;
        }
      }
      return selectedRows.length > 0;
    }
    return false;
  }
}
