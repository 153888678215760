import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { StatusComponent } from "./status/status.component";
import { OnDemandComponent } from "./ondemand/ondemand.component";
import { IncrementalBuildoutComponent } from "./incremental-buildout/incremental-buildout.component";
import { BuildoutStatusComponent } from "./buildout-status/buildout-status.component";
import { ProductOnboardStatusComponent } from "./product-onboard-status/product-onboard-status.component";
import { ProductStatusTrackComponent } from "./product-status-track/product-status-track.component";
import { ErrorBillingMeterListComponent } from "./error-billing-meter-list/error-billing-meter-list.component";
import { CisComponent } from "./cis/cis.component";
import { Roles, LionrockUserRoles } from "../npr-request.model";
import { PslComponent } from "./psl/psl.component";
import { SkipListComponent } from "./skip-list/skip-list.component";

const billingMeterRoutes = [
  {
    path: "prep/psl",
    url: "/prep/psl",
    component: PslComponent,
    name: "PSL Billing Meter",
    data: { title: "PSL Billing Meter" },
    Roles: [Roles.BetAdmin],
  },
  {
    path: "prep/home",
    url: "/prep/home",
    component: HomeComponent,
    name: "New Region Billing Meter",
    data: { title: "New Region Billing Meter" },
    Roles: [Roles.BetAdmin],
  },
  {
    path: "prep/incremental",
    url: "/prep/incremental",
    component: IncrementalBuildoutComponent,
    name: "Incremental Billing Meter",
    data: { title: "Incremental Billing Meter" },
    hidden: true,
    Roles: [Roles.BetAdmin],
  },
  {
    path: "prep/ondemand",
    url: "/prep/ondemand",
    component: OnDemandComponent,
    name: "On-Demand Billing Meter",
    data: { title: "On-Demand Billing Meter" },
    Roles: [Roles.BetAdmin, Roles.BetServiceOwner, LionrockUserRoles.RolePrefix + LionrockUserRoles.Administrator],
  },
  {
    path: "prep/status",
    url: "/prep/status",
    component: StatusComponent,
    name: "Billing Meter Status",
    data: { title: "Billing Meter Status" },
  },
  {
    path: "product-onboard-status",
    url: "/product-onboard-status",
    component: ProductOnboardStatusComponent,
    name: "New Product Launch Meter Status",
    data: { title: "New Product Launch Meter Status" },
    Roles: [Roles.BetAdmin, Roles.BetServiceOwner],
  },
  {
    path: "prep/error-billing-meter",
    url: "/prep/error-billing-meter",
    component: ErrorBillingMeterListComponent,
    name: "Error Billing Meter Requests",
    data: { title: "Error Billing Meter Requests" },
    hidden: true,
  },
  {
    path: "prep/product-status-track",
    url: "/prep/product-status-track",
    component: ProductStatusTrackComponent,
    name: "Product Status Track",
    data: { title: "Product Status Track" },
  },
  {
    path: "prep/skip-list",
    url: "/prep/skip-list",
    component: SkipListComponent,
    name: "Skip List",
    data: { title: "Skip List" },
    Roles: [Roles.BetAdmin],
  },
];

const routes: Routes = [...billingMeterRoutes];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  static routes = routes;
  static billingMeterRouters = billingMeterRoutes;
}
