import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-table-confirm-modal",
  templateUrl: "./table-confirm-modal.component.html",
  styleUrls: ["./table-confirm-modal.component.scss"],
})
export class TableConfirmModalComponent {
  @Input() title: string;
  @Input() message: string;

  @Input() columnDefs: any;
  @Input() rowData: any;

  @Input() yesButtonMessage: string;
  @Input() noButtonMessage: string;

  gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
  };

  constructor(public activeModal: NgbActiveModal) {}
}
