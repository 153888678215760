import { Component } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { Roles } from "../../npr-request.model";
import { PlanRegionStatusEnum } from "../../shared/enums/plan-enums";
import { FfpMyRegionalPlanColumnDefinition } from "./ag-grid-column-definition";

interface RowDataForRegionalPlan {
  ServiceTeam: string;
  ServiceTreeId: string;
  ProjectOid: string;
  Submitter: string;
  SubmitTime: Date;
  Version: number;
  RegionalPlanStatus: string;
  Ring: string;
  ServiceOwnerApproved: boolean
  FfpAdminApproved: boolean
}

@Component({
  templateUrl: "./ffp-plan-my-regional-plans.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpMyRegionalPlansComponent extends GridViewBaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize: number = 15;
  keyFilterStats = "filter:my-regional-plans";
  rowData: RowDataForRegionalPlan[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  constructor(
    protected apiService: ApiService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    super.InitAgGrid(FfpMyRegionalPlanColumnDefinition);
    this.refreshData();
  }

  refreshData() {
    this.loadingService.setLoading(true);
    this.apiService.getFfpMyInstances().subscribe(
      (response) => {
        const rowDataMap = new Map<string, RowDataForRegionalPlan>();
        response.forEach((e) => {
          const key = `${e.ServiceTreeId}-${e.ProjectOid}-${e.Version}`;
          let rowData = rowDataMap.get(key);

          if (!rowData) {
            rowData = {
              ServiceTeam: e.ServiceTeam,
              ServiceTreeId: e.ServiceTreeId,
              ProjectOid: e.ProjectOid,
              Submitter: e.Submitter,
              SubmitTime: e.SubmitTime,
              Version: e.Version,
              RegionalPlanStatus: e.Status,
              Ring: e.Ring,
              ServiceOwnerApproved: false,
              FfpAdminApproved: false,
            };
            rowDataMap.set(key, rowData);
          }

          if (e.ApprovalType === Roles.ServiceOwner && e.ApprovalStatus === PlanRegionStatusEnum.Approved) {
            rowData.ServiceOwnerApproved = true;
          } else if (e.ApprovalType === Roles.FfpAdmin && e.ApprovalStatus === PlanRegionStatusEnum.Approved) {
            rowData.FfpAdminApproved = true;
          }
        });

        this.rowData = Array.from(rowDataMap.values());
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  exportExcel() {
    var fileName = "MyRegionalPlans-" + getExportedExcelFileNameSuffix();
    var sheetName = "My Regional Plans";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
