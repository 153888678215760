<div role="dialog" aria-modal="true">
    <div class="modal-header">
      <h2 class="modal-title h4">Plan Assignment Reconfirmation</h2>
    </div>
    <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
      <div class="modal-body">
        <div>
            You have already assigned a plan with service <b>{{ planSubmission.ServiceTreeId }}</b> and region <b>{{ planSubmission.Region }}</b> 
            in <b>Minimum</b> stage which is not approved. Do you want to assign the plan with same service and region 
            in <b>GA</b> stage with version <b>{{ planSubmission.Version }}</b> and <b>remove the old plan</b> in Minimum stage?
        </div> 
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" data-test="Submit">OK</button>
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
      </div>
    </form>
  </div>
  