<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4" data-test="CommentTitle">Comment</h2>
  </div>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body" style="height: 108px">
      <div>
        <textarea rows="3" style="width: 100%" placeholder="Optional" [(ngModel)]="comment" name="comment" id="comment"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid" data-test="CommitComment">Commit</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  </form>
</div>
