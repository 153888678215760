import { Component, OnInit, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { IGenericListRecord } from "../../npr-request.model";

@Component({
  templateUrl: "./select-option-modal.component.html",
})
export class SelectOptionModalComponent implements OnInit {
  @Input() message: string;
  @Input() options: string[];
  @Output() selectedOption = "";
  optionList: IGenericListRecord[] = [];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    for (let i = 0; i < this.options.length; i++) {
      let rec: IGenericListRecord = <IGenericListRecord>{};
      rec.item_id = i + 1;
      rec.item_text = this.options[i];
      this.optionList.push(rec);
    }
  }
}
