/// <reference path="../../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../../shared/base.component";
import { ApiService } from "src/app/services";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { Message } from "../../../generated-models/Message";
import { IUserProfile } from "../../../npr-request.model";
import { CapacitySubOrderDetails } from "../../../generated-models/CapacitySubOrderDetails";
import { PreprocessDateTime } from "../../../utility/view-field-mapping";

@Component({
  templateUrl: "./capacity-suborder-detail.component.html",
  styleUrls: ["../../../styles.scss", "../plan.scss"],
})
export class CapacitySubOrderDetailComponent extends BaseComponent implements OnInit {
  orderId: string;
  subOrderId: string;
  subOrderDetail: CapacitySubOrderDetails;
  newMessage: string;
  messages: Message[];
  placeHolder: string;
  userProfile: IUserProfile = null;
  haveMessage: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);
    this.userProfile = await this.apiService.getUserProfile();
    this.orderId = this.route.snapshot.params["capacityOrderId"];
    this.subOrderId = this.route.snapshot.params["subOrderId"];
    if (!this.subOrderId || this.subOrderId.length < 20) {
      this.notificationService.info("suborderId is waiting sync, please wait for a while.");
      return;
    }
    this.apiService.getCapacitySubOrderDetails(this.orderId, this.subOrderId).subscribe(
      (response) => {
        if (response) {
          this.subOrderDetail = response;
          this.haveMessage = this.subOrderDetail.Messages.length > 0;
          if (this.haveMessage) {
            this.messages = this.subOrderDetail.Messages.map(message => ({
              ...message,
              CreatedTime: PreprocessDateTime(message.CreatedTime)
            }));
          }
          else {
            if (this.subOrderDetail.Status == "InProgress") {
              this.notificationService.info("SubOrder is in review, please wait for approval's feedback");
              this.loadingService.setLoading(false);
              return;
            }
            else if (this.isTerminalState(this.subOrderDetail.Status)) {
              this.notificationService.info("SubOrder has reached the terminal state. No message on this suborder.");
              this.loadingService.setLoading(false);
              return;
            }
          }
          this.placeHolder = this.messages.length > 0 ? "Add a comment here." : "If you have any questions, you can add a comment here.";
        }
        else {
          this.notificationService.error(`The subOrder(${this.orderId}-${this.subOrderId}) does not exist.`);
        }
      },
      (error) => {
        const message = `Failed to get capacity subOrder details for order ${this.orderId}-${this.subOrderId}, error: ${error}.`;
        this.notificationService.error(message);
      }
    );
    this.loadingService.setLoading(false);
  }

  isValidMessage() {
    if (this.newMessage) {
      return this.newMessage.trim().length != 0;
    }
    // undefined return false
    return false;
  }

  saveNewMessage(): void {
    const message = {} as Message;
    message.Content = this.newMessage;
    message.CreatedBy = this.userProfile?.Name;
    message.CreatedTime = PreprocessDateTime(new Date());
    this.apiService.addCapacityOrderMessage(this.orderId, this.subOrderId, message).subscribe(
      () => {
        this.messages.push(message);
        this.newMessage = "";
      },
      (error: unknown) => {
        const message = `Failed to update the new message for order ${this.orderId}-${this.subOrderId}, error: ${error}.`;
        this.notificationService.error(message);
      }
    );
  }

  isTerminalState(status: string) {
    if (status == "Approved" || status == "Rejected" || status == "Failed" || status == "UserCancelled") {
      return true;
    }
    return false;
  }
}
