import { PreprocessDateTime } from "../../utility/view-field-mapping";

export const OperationLogColumnDefinition = [
  {
    headerName: "Timestamp",
    field: "Timestamp",
    valueFormatter: (params) => PreprocessDateTime(params.value),
  },
  { headerName: "Operation Type", field: "OperationType" },
  { headerName: "By", field: "Operator" },
  {
    headerName: "Details",
    field: "Details",
    cellRenderer: (param) => {
      var res = "";
      if (param.data.Details != null) {
        param.data.Details.split("\n").forEach((element: string) => {
          if (element) {
            res += element + "<br/>";
          }
        });
      }
      return res;
    },
  },
];
