import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "../../environments/environment";

@Injectable()
export class AppInsightService {
  private appInsights: ApplicationInsights = null;
  private appInsightsUIError: ApplicationInsights = null;

  constructor() {
    if (environment.appInsightsInstrumentationKey !== "") {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
          /* ...Other Configuration Options... */
        },
      });
      this.appInsights.loadAppInsights();
    }

    if (environment.appInsightsInstrumentationKeyUIError !== "") {
      this.appInsightsUIError = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsInstrumentationKeyUIError,
          /* ...Other Configuration Options... */
        },
      });
      this.appInsightsUIError.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string): void {
    if (this.appInsights !== null) {
      this.appInsights.trackPageView({ name: name, uri: url });
    }
  }

  logException(exception: Error, url?: string): void {
    if (this.appInsightsUIError !== null) {
      this.appInsightsUIError.trackException({ exception: exception }, { uri: url });
    }
  }
}
