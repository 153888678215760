<div class="modal-header">
  <h4 class="modal-title">Commit Plan</h4>
</div>

<div class="modal-body">
  <p [innerHtml]="warningMessage"></p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="close()">Close</button>
</div>
