import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { FfpPlanInstance } from "../../../../generated-models/FfpPlanInstance";
import { FfpPlanInstanceOverallApproval } from "../../../../generated-models/FfpPlanInstanceOverallApproval";

@Component({
  templateUrl: "./ffp-plan-batch-approval.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class FfpPlanBatchApprovalComponent implements OnInit {
  @Input() title: string;
  @Input() ffpPlanInstances: FfpPlanInstance[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) { }
  async ngOnInit(): Promise<void> {
    await this.batchApprovalFfpPlanInstances();
  }

  close(): void {
    this.activeModal.close(true);
  }

  async batchApprovalFfpPlanInstances() {
    await Promise.all(
      this.ffpPlanInstances.map(async (planProject) => {
        try {
          const ffpPlanInstanceOverallApproval: FfpPlanInstanceOverallApproval = {
            ApprovedVersion: planProject.Version,
          } as FfpPlanInstanceOverallApproval;

          await this.apiService.approveFfpPlanInstance(planProject.ServiceTreeId, planProject.ProjectOid, ffpPlanInstanceOverallApproval).toPromise();
          this.aggregatedResult.push(`Approve plan instance <b>${planProject.ServiceTreeId}</b> and <b>${planProject.ProjectOid}</b> successfully`);
        } catch (ex) {
          this.aggregatedResult.push(`Approve plan instance <b>${planProject.ServiceTreeId}</b> failed with error: ${ex}`);
        }
      })
    );

    this.isCompleted = true;
  }
}
