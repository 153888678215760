<div class="title">
  <div class="container">
    <h4>Error Billing Meter Requests</h4>
    <p>This page shows the billing meter reqeusts which are failed or out of SLA.</p>
    <p>Click <a target="_blank" href="https://aka.ms/billingmeterfaq">here</a> for FAQ’s.</p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isUserAuthorized()">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && isUserAuthorized() && !userCanViewStatus">
  <h5>Permission is required!</h5>
  <h5>Please Contact <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add the permission </h5>
</div>
<div class="container track-container-content" *ngIf="isLoadingFinished && userCanViewStatus">
  <div class="main-content">
    <div style="margin-top: 20px;">
        <h5>Error Billing Meter Requests</h5>
        <p>Error billing meter requests are the requests which status are "Failed" or "Out of SLA"</p>
        <div class="nofloat" style="width: 100%">
            <ag-grid-angular
                style="height: 800px; margin-top: 20px"
                class="ag-theme-balham"
                [rowSelection]="rowSelection"
                [rowMultiSelectWithClick]="true"
                [gridOptions]="gridOptions"
                [rowData]="errorBillingMeterRequests"
                [columnDefs]="getErrorBillingMeterRequestsColumns()"
                (gridReady)="onGridReady($event)"
                [masterDetail]="true"
                [detailRowHeight]="145"
                pagination="true"
                paginationPageSize="50"
            >
            </ag-grid-angular>
        </div>
        <div>
          <hr />
          <button type="button" class="btn btn-primary mr-1" [disabled]="disableAckButton()" (click)="ackErrorBillingMeterRequests()">
            Ack Error Billing Meter Requests
          </button>
    </div>
    </div>
  </div>
</div>