import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PlanPurposeEnum } from "src/app/shared/enums/plan-enums";
import { getKeyValuePairs } from "src/app/utility/enums";
import { environment } from "src/environments/environment";

@Component({
  templateUrl: "./plan-assignment-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanAssignmentModalComponent implements OnInit {
  purpose: string;
  minDate: NgbDateStruct;
  startDate: Date = new Date();
  readonly planPurposeEnumMap = getKeyValuePairs(PlanPurposeEnum);
  hasPurpose = false;
  constructor(public activeModal: NgbActiveModal, private notificationService: ToastrService) { }

  ngOnInit() {
    this.hasPurpose = !!this.purpose;
    var dateNow: Date = new Date();
    this.startDate.setDate(dateNow.getDate() + environment.neededByDateDelayDates);
    this.minDate = {
      year: this.startDate.getFullYear(),
      month: this.startDate.getMonth() + 1, // getMonth returns 0-11
      day: this.startDate.getDate(),
    };
  }

  submit(): void {
    if (!this.purpose) {
      this.notificationService.warning(`Please select a purpose.`);
      return;
    } else if (!this.startDate) {
      this.notificationService.warning(`Please select a date.`);
      return;
    } else {
      try {
        this.activeModal.close({
          purpose: this.purpose,
          startDate: this.startDate,
        });
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }
}
