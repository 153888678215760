import { ApiService } from "src/app/services";
import * as CDs from "src/app/utility/commonColDef";
import { getRequestStatusDescription } from "../../utility/common-helper";

export const AllRequestedItemsColumnDefinition = [
  {
    ...CDs.RequestId,
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      suppressCount: true, // turn off the row count
      suppressDoubleClickExpand: true, // turn off double click for expand
      innerRenderer: (params) => params.data.RequestLink, // provide an inner renderer
    },
  },
  CDs.Requestor,
  CDs.RingLevel,
  CDs.Submitter,
  CDs.RegionColDef("Region"),
  CDs.Cloud,
  CDs.SubscriptionId("SubscriptionId", { cellRenderer: "subscriptionRenderer" }),
  CDs.IsExternalDisplay,
  CDs.IsHoboDisplay,
  {
    ...CDs.Status,
    valueGetter: (params) => {
      let status = "";
      if (params.data.Status === "InProgress" && params.data.FulfillChannel === "RDQuota") {
        status = "Awaiting RDQuota";
      } else {
        status = getRequestStatusDescription(params.data.Status)?.name || params.value;
      }
      return status;
    },
    cellRenderer: (params) => {
      return params.value;
    },
    keyCreator: (param) => {
      return param.value;
    },
    tooltipValueGetter: (params) => {
      return getRequestStatusDescription(params.data.Status)?.description || params.data.Status;
    },
  },
  CDs.FulfillChannel,
  CDs.RequestDisplayServiceType,
  CDs.SKU,
  CDs.Quota,
  CDs.AdditionalParams,
  CDs.ApprovedBy,
  CDs.PriorityString,
  CDs.CreatedTime,
  CDs.CompletedTime,
  CDs.DeploymentScope,
  CDs.Email,
  CDs.Dependencies,
  {
    ...CDs.Justification,
    cellRenderer: (param) => {
      return ApiService.generateParentRequestHyperLink(param.data.ParentRequestId, "View in new tab");
    }
  },
  CDs.RequestSource,
  CDs.Notes,
];
