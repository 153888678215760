import { Component, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "./request-input-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class RequestInputModalComponent {
  @Input() message: string;
  @Input() required: boolean;
  @Input() title: string = "";
  @Output() returnText = "";

  constructor(public activeModal: NgbActiveModal) {}
}
