import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services";
import { PlanAssignResultEnum } from "src/app/npr-request.model";
import { ModalService } from "src/app/shared/modal.service";
import { ToastrService } from "ngx-toastr";
import { FfpPlanSubmission } from "../../../../generated-models/FfpPlanSubmission";

@Component({
  templateUrl: "./ffp-plan-assignment-response-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class FfpPlanAssignmentResponseModalComponent implements OnInit {
  @Input() ffpPlanSubmissions: FfpPlanSubmission[];

  public aggregatedResult: string[] = [];
  public isCompleted = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected apiService: ApiService,
    protected modalService: ModalService,
    private notificationService: ToastrService,
  ) { }

  async ngOnInit() {
    await this.assignFfpPlans();
  }

  async assignFfpPlans() {
    if (this.ffpPlanSubmissions.length === 0) {
      this.aggregatedResult.push(
        `No Service Assign, Please check PSL if is correct.`
      );
    }
    else {
      await Promise.all(
        this.ffpPlanSubmissions.map(async (ffpPlanSubmissions) => {
          try {
            var response = await this.apiService.assignFfpPlanToProject(ffpPlanSubmissions).toPromise();
            if (response?.Result == PlanAssignResultEnum.Warning) {
              this.notificationService.warning("Warning: " + response.Message);
            }
            else {
              this.aggregatedResult.push(
                `Assign plan for <b>${ffpPlanSubmissions.ServiceTreeId}</b> to project <b>${ffpPlanSubmissions.ProjectOid}</b> successfully with message: <br/>${response?.Message ?? ''}`
              );
            }
          } catch (ex) {
            var warningMessage = "";
            if (ex?.error.Message != null) {
              warningMessage = ex?.error.Message;
            }
            this.aggregatedResult.push(
              `Assign plan for <b>${ffpPlanSubmissions.ServiceTreeId}</b> to project <b>${ffpPlanSubmissions.ProjectOid}</b> failed with error:<br/> <font color="red">${warningMessage}</font>`
            );
          }
        })
      );
    }
    this.isCompleted = true;
  }

  close() {
    this.activeModal.close(true);
  }
}
