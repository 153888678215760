<div class="title">
  <div class="container">
    <h4>PSL Billing Meters</h4>
    <p>
      This UI is used only by Region Buildout PM's for meter submission during region buildouts. Contact <a href="mailto:bowloper@microsoft.com">Lionrock Support</a> to request access if you are a Region Buildout PM. All other requests will be rejected.
    </p>
    <p>
      Service owners: Click <a href="/quota/prep/ondemand">here</a> to submit service level meter requests. Click <a target="_blank" href="https://aka.ms/billingmeterfaq">here</a> for FAQ’s.
    </p>
  </div>
</div>
<div *ngIf="isLoadingFinished && !isAuthorizedUser">
  <h2>Please login</h2>
</div>
<div *ngIf="isLoadingFinished && isAuthorizedUser && !userCanInitialBuildout">
  <h5>Permission is required!</h5>
  <h5>Please Contact <a href="mailto:LionrockBET@microsoft.com">Lionrock/BET Support</a> to add the permission </h5>
</div>
<div class="container container-content" *ngIf="isLoadingFinished && userCanInitialBuildout">
  <div class="main-content">
    <div class="form-group">
      <h4>Select Cloud and Region to get started</h4>
    </div>
    <div class="region-select">
      <div class="form-group" style="float: left">
        <h6>Cloud</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.clouds"
            bindLabel="CloudName"
            placeholder="Select Cloud"
            (change)="onCloudChange($event)"
            [(ngModel)]="dataService.selectedCloud"
            name="CloudName"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Cloud not found</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Region</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.initialRegions"
            bindLabel="Name"
            placeholder="Select Region"
            (change)="onRegionChange()"
            [(ngModel)]="dataService.selectedRegion"
            [disabled]="dataService.selectedCloud == null"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Region not found</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
      <div class="form-group">
        <h6>Psl</h6>
        <label style="width: 100%">
          <ng-select
            [items]="dataService.pslList"
            bindLabel="Name"
            placeholder="Select Psl"
            (change)="onPslChange()"
            [(ngModel)]="dataService.psl"
            name="Psl Name"
            [disabled]="dataService.pslList.length == 0 || dataService.availabilityRegionOrEdgeZone()"
          >
            <ng-template ng-notfound-tmp>
              <label class="ng-option disabled">Psl not found</label>
            </ng-template>
          </ng-select>
        </label>
      </div>
    </div>
    <div class="form-group">
      <button
        type="button"
        class="btn btn-primary mr-1"
        [disabled]="!dataService.psl || dataService.availabilityRegionOrEdgeZone()"
        (click)="onPslProductsFetched()"
      >
        Fetch products
      </button>
    </div>

    <p *ngIf="dataService.selectedRegion && dataService.availabilityRegionOrEdgeZone() && !dataService.selectedRegionIsInitialized()">
      The selected region is not a buildout region.
    </p>

    <hr />
    <h4>Review Services</h4>
    <p style="margin-bottom: 0">
      To make any modifications to the list please visit
      <a href="https://aka.ms/pamurl">aka.ms/pamurl</a>
    </p>
    <div style="width: 100%; text-align: right">
      <button type="button" class="btn btn-primary mr-1 export" (click)="onExportExcel()">Export Request Details</button>
    </div>
    <div class="nofloat">
      <ag-grid-angular
        style="height: 540px"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="pslProducts"
        [columnDefs]="columnDefs"
        (gridReady)="onPslProductsGridReady($event)"
        pagination="true"
      >
      </ag-grid-angular>
    </div>
    <div>
      <hr />
      <button type="button" class="btn btn-primary mr-1" [disabled]="disableNewRegionBillingMeter()" (click)="onPslRegionBillingMeter()">
        Submit Billing Meter Requests
      </button>
    </div>
    <!--/form-->
  </div>
</div>
