/// <reference path="../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Email } from "src/app/generated-models/Email";

export interface EmailDetailModalOptions {
  model: Email;
  isReadonly: boolean;
}

@Component({
  templateUrl: "./email-detail-modal.component.html",
  styleUrls: ["../../styles.scss", "./email-detail-modal.component.scss"],
})
export class EmailDetailModalComponent implements OnInit {
  @Input() options: EmailDetailModalOptions;
  editor: monaco.editor.IStandaloneCodeEditor;
  email: Email;

  constructor(public activeModal: NgbActiveModal) {}

  async ngOnInit() {
    this.email = this.options.model;
  }

  onEditorReady(editor: monaco.editor.IStandaloneCodeEditor) {
    this.editor = editor;
    const obj = JSON.parse(this.email.Details);
    this.editor.setValue(JSON.stringify(obj, null, 2));
    monaco.editor.setModelLanguage(this.editor.getModel(), "json");
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      allowComments: true,
    });

    this.editor.updateOptions({ readOnly: this.options.isReadonly });
  }
}
