<a [routerLink]="commands" [queryParams]="queryParams" [replaceUrl]="replaceUrl" *ngIf="commands; else url_template" [title]="value">
    {{
    value
    }}
</a>
<ng-template #url_template>
    <a [href]="url" [target]="target" *ngIf="url; else other_content" [title]="value">
        {{ value }}
    </a>
</ng-template>

<ng-template #other_content>
    <span>
        {{ value }}
    </span>
</ng-template>

<i *ngIf="hasIcon" class="fa fa-info-circle" placement="right" [ngbPopover]="Popover" popoverClass="my-popover"> </i>
<ng-template #Popover> &nbsp;&nbsp;&nbsp;{{ iconDescription }}</ng-template>
