/// <reference path="../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "src/app/shared/modal.service";
import { CellClickedEvent, ColDef, ColumnApi, GridApi, GridOptions, ICellRendererParams } from "ag-grid-community";
import { PlanFile } from "src/app/generated-models/PlanFile";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { EditDeleteCellComponent, EditDeleteCellComponentParams } from "src/app/shared/grid/edit-delete-cell.component";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { PlanEditorModalOptions, PlanJsonSchema } from "./modals/plan-editor-modal/plan-editor-modal.component";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ViewCellComponent, ViewCellComponentParams } from "src/app/shared/grid/view-cell.component";
import { IsServiceUser } from "src/app/utility/userprofile-helper";
import { PlanCommit } from "src/app/generated-models/PlanCommit";
import { PlanFileTypeEnum } from "src/app/shared/enums/plan-enums";
import { PlanNewFileModalOptions } from "./modals/plan-new-file-modal/plan-new-file-modal.component";
import { ActionCellComponent, ActionCellComponentParams } from "src/app/shared/grid/action-cell.component";
import { PlanDiffModalOptions } from "./modals/plan-diff-modal/plan-diff-modal.component";

@Component({
  templateUrl: "./plan-editor.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlanEditorComponent extends BaseComponent implements OnInit {
  editorModal: NgbModalRef;
  serviceTreeId: string;
  serviceTeam: string;
  latestVersion: number;
  allPlanFiles: PlanFile[] = [];
  rowSourceData: PlanFile[] = [];
  gridOptions: GridOptions;
  gridApi: GridApi;
  initPageSize: number = 15;
  isReadonly: boolean;
  ifFilesChanged: boolean = false;
  canUndoOnboarding = false;
  rowMergedData: PlanFile[] = [];
  gridOptionsMerged: GridOptions;

  message: string;

  jsonSchema: PlanJsonSchema = { FullSchema: "", MergeSchema: "" };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
  }

  async ngOnInit() {
    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];

    this.apiService
      .getUserProfile()
      .then((userProfile) => {
        if (userProfile) {
          this.isReadonly = !IsServiceUser(userProfile, this.serviceTreeId);
          if (this.isReadonly) {
            const viewCellComponentParams: ViewCellComponentParams<PlanFile> = {
              viewFunc: this.viewPlanFile.bind(this),
            };
            const columnDefs = [];
            this.gridOptions.columnDefs.map((map) => {
              if (map.headerName === "Action") {
                columnDefs.push({
                  headerName: map.headerName,
                  sortable: false,
                  suppressMenu: true,
                  cellRendererFramework: ViewCellComponent,
                  cellRendererParams: viewCellComponentParams,
                });
              } else {
                columnDefs.push(map);
              }
            });

            this.gridOptions.api.setColumnDefs(columnDefs);
          }
          // If user is admin, then undo onboarding button is visible.
          if (userProfile.IsAdmin) {
            this.canUndoOnboarding = true;
          }
        } else {
          this.notificationService.error("user profile returns null, no data was loaded.");
        }
      })
      .catch((error) => {
        this.notificationService.error(`unable to get user profile, error: ${error.message}`);
      });

    this.apiService.getPlan(this.serviceTreeId).subscribe(
      (response) => {
        this.latestVersion = response.Version;
        this.serviceTeam = response.ServiceTeam;
        this.title.setTitle(`${this.route.snapshot.data.title} - ${this.serviceTeam}`);
      },
      (e: unknown) => {
        this.notificationService.error(`Failed to find plan for ServiceTree Id ${this.serviceTreeId}. Error: ${e.toString()}`);
      }
    );

    this.apiService.getPlanJsonSchema().subscribe(
      (response) => {
        this.jsonSchema.FullSchema = response.FullSchema;
        this.jsonSchema.MergeSchema = response.MergeSchema;
        setTimeout(function () {
          if (!response) {
            this.notificationService.error("Editor json schema returns null, no data was loaded.");
          }
        }, 200);
      },
      (e: unknown) => {
        this.notificationService.error(`Editor json schema returns null, no data was loaded. Error: ${e.toString()}`);
      }
    );

    const planFilesColumnDefinition: ColDef[] = [
      {
        headerName: "Action",
        sortable: false,
        suppressMenu: true,
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.data?.PlanFileStatus !== "Deleted") {
            return {
              frameworkComponent: EditDeleteCellComponent,
              params: {
                editFunc: this.editPlanFile.bind(this),
                deleteFunc: this.deletePlanFile.bind(this),
              } as EditDeleteCellComponentParams<PlanFile>,
            };
          }

          return undefined;
        },
      },
      {
        headerName: "Name",
        field: "FileName",
      },
      {
        headerName: "Last Modified",
        field: "LastModified",
        cellRenderer: (params) => PreprocessDateTime(params.value),
      },
      {
        headerName: "File Status",
        field: "PlanFileStatus",
        cellRendererFramework: ActionCellComponent,
        cellRendererParams: {
          actionFunc: this.diffPlanFile.bind(this),
        } as ActionCellComponentParams<PlanFile>,
      },
      {
        headerName: "Last Modified By",
        field: "LastModifiedBy",
      },
    ];

    const planFilesMergedColumnDefinition: ColDef[] = [
      {
        headerName: "Action",
        colId: "action",
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.data?.PlanFileStatus !== "Deleted") {
            return {
              component: () => `
                <button type="button" class="btn btn-link">
                  <i class="fas fa-sm fa-code"></i>
                </button>`,
            };
          }

          return undefined;
        },
      },
      {
        headerName: "Name",
        field: "FileName",
      },
      {
        headerName: "Last Modified",
        field: "LastModified",
        cellRenderer: (params) => PreprocessDateTime(params.value),
      },
      {
        headerName: "File Status",
        field: "PlanFileStatus",
        cellRendererFramework: ActionCellComponent,
        cellRendererParams: {
          actionFunc: this.diffPlanFile.bind(this),
        } as ActionCellComponentParams<PlanFile>,
      },
      {
        headerName: "File Type",
        field: "PlanFileType",
      },
    ];

    this.gridOptions = extendDefaultOptions({
      columnDefs: planFilesColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      sideBar: null,
      rowClassRules: {
        "ag-row-added": (params) => {
          return params.data.PlanFileStatus === "Added";
        },
        "ag-row-modified": (params) => {
          return params.data.PlanFileStatus === "Modified";
        },
        "ag-row-deleted": (params) => {
          return params.data.PlanFileStatus === "Deleted";
        },
      },
    });

    this.gridOptionsMerged = extendDefaultOptions({
      onCellClicked: this.onCellClicked.bind(this),
      columnDefs: planFilesMergedColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      sideBar: null,
      rowClassRules: {
        "ag-row-added": (params) => {
          return params.data.PlanFileStatus === "Added";
        },
        "ag-row-modified": (params) => {
          return params.data.PlanFileStatus === "Modified";
        },
        "ag-row-deleted": (params) => {
          return params.data.PlanFileStatus === "Deleted";
        },
      },
    });

    this.getPlanFiles();
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
  }

  protected async onCellClicked(event: CellClickedEvent) {
    if (event.colDef.colId === "action") {
      this.viewPlanFile(event.data as PlanFile);
    }
  }

  getPlanFiles() {
    this.loadingService.setLoading(true);

    this.apiService.getLatestPlanFiles(this.serviceTreeId).subscribe(
      (response) => {
        const original: PlanFile[] = [];
        const generated: PlanFile[] = [];
        response.forEach((plan) => {
          if (plan.FileName === "default.json") {
            original.push(plan);
            generated.push(plan);
          } else if (plan.FileName.includes("override")) {
            original.push(plan);
          } else {
            if (plan.PlanFileType === PlanFileTypeEnum.Generated) {
              generated.push(plan);
            } else {
              original.push(plan);
              generated.push(plan);
            }
          }
        });
        this.rowSourceData = original;
        this.rowMergedData = generated;
        this.allPlanFiles = response;
        this.ifFilesChanged = this.rowSourceData.filter((row) => row.PlanFileStatus != "NoChange").length > 0;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridOptions.columnApi.autoSizeAllColumns();
          this.gridOptionsMerged.columnApi.autoSizeAllColumns();
        }, 100);
      },
      (e: unknown) => {
        this.rowSourceData = [];
        this.rowMergedData = [];
        this.allPlanFiles = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e.toString());
      }
    );
  }

  async viewPlanFile(planFile: PlanFile) {
    await this.showPlanEditorDialog(planFile, true);
  }

  async editPlanFile(planFile: PlanFile) {
    await this.showPlanEditorDialog(planFile);
  }

  async diffPlanFile(planFile: PlanFile) {
    await this.showPlanDiffDialog(planFile);
  }

  async deletePlanFile(planFile: PlanFile) {
    try {
      await this.modalService.confirmationModal(`Are you sure to delete ${planFile.FileName}?`);
    } catch {
      // For the model dialog dimiss
      return;
    }

    this.loadingService.setLoading(true);

    this.apiService.deletePlanFile(this.serviceTreeId, planFile.FileName).subscribe(
      () => {
        this.message = `Plan file has been deleted successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (error) => {
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      },
      () => {
        this.getPlanFiles();
      }
    );
  }

  async showPlanNewFileDialog() {
    const cloudOverrideExist = this.rowSourceData.some((file) => file.FileName.includes("cloud"));
    const regionTypeOverrideExist = this.rowSourceData.some((file) => file.FileName.includes("type"));
    const overrideNameList = this.rowSourceData.map((file) => file.FileName.split(".")[0]);
    const options: PlanNewFileModalOptions = {
      cloudOverrideExist: cloudOverrideExist,
      regionTypeOverrideExist: regionTypeOverrideExist,
      overrideNameList: overrideNameList,
    };
    const fileName: string = await this.modalService.planNewFileModal(options);

    if (fileName) {
      const planFile: PlanFile = this.rowSourceData.find((p) => p.FileName === `${fileName}.json`);
      if (planFile) {
        await this.showPlanEditorDialog(planFile);
      } else {
        const emptyPlanFile = {
          FileName: `${fileName}.json`,
          Content: "{}",
          Format: "json",
        } as PlanFile;

        if (fileName.includes("override")) {
          // partial mode
          const cloudOrRegionName = fileName.split(".")[0];
          await this.checkOverrideExist(cloudOrRegionName, emptyPlanFile);
        } else {
          // fully mode
          const planTemplate = await this.apiService.getPlanFileTemplate(this.serviceTreeId).toPromise();
          const cloudOrRegionName = fileName;
          emptyPlanFile.Content = planTemplate.Content;
          await this.checkOverrideExist(cloudOrRegionName, emptyPlanFile);
        }
      }
    }
  }

  async checkOverrideExist(cloudOrRegionName: string, emptyPlanFile: PlanFile) {
    const planFile: PlanFile = this.allPlanFiles.find((p) => p.FileName === `${cloudOrRegionName}.json`);
    if (planFile) {
      this.notificationService.error(`An override plan file for ${cloudOrRegionName} alread exists.`);
    } else {
      await this.showPlanEditorDialog(emptyPlanFile);
    }
  }

  async showPlanEditorDialog(planFile: PlanFile, isReadonly: boolean = false) {
    const options: PlanEditorModalOptions = {
      model: planFile,
      save: this.savePlanFile.bind(this),
      isReadonly: isReadonly,
      schema: planFile.FileName.includes("override") ? this.jsonSchema.MergeSchema : this.jsonSchema.FullSchema,
    };
    this.editorModal = await this.modalService.planEditorModal(options);
  }

  async showPlanDiffDialog(planFile: PlanFile) {
    const options: PlanDiffModalOptions = {
      model: planFile,
      isReadonly: true,
    };
    this.editorModal = await this.modalService.planDiffModal(options);
  }

  savePlanFile(planFile: PlanFile) {
    this.loadingService.setLoading(true);

    this.apiService.savePlanFile(this.serviceTreeId, planFile).subscribe(
      () => {
        this.message = `Plan file has been saved successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
        this.editorModal.close();
      },
      (error) => {
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      },
      () => {
        this.getPlanFiles();
      }
    );
  }

  async undoOnboarding() {
    try {
      await this.modalService.confirmationModal(
        `Are you sure to undo onboarding for service ${this.serviceTreeId}? This will delete all plan files and service owner need to re-onboard again.`
      );
    } catch {
      // For the model dialog dimiss
      return;
    }

    this.loadingService.setLoading(true);

    this.apiService.undoOnboardPlan(this.serviceTreeId).subscribe(
      () => {
        this.message = `All plan files for service ${this.serviceTreeId} are removed.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);

        // delay and redirect to plan list page
        setTimeout(() => {
          this.router.navigateByUrl("/plans/all");
        }, 1000);
      },
      (error) => {
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      }
    );
  }

  async showPlanHistoryDialog() {
    await this.modalService.planHistoryModal(this.serviceTreeId);
  }

  async showCommitPlanDialog() {
    try {
      const comment = await this.modalService.planCommitModal();
      await this.commitPlan(comment);
    } catch {
      // For the model dialog dismiss
    }
  }

  async commitPlan(comment: string) {
    this.loadingService.setLoading(true);

    const payload: PlanCommit = {
      Comment: comment,
    };

    this.apiService.commitPlan(this.serviceTreeId, payload).subscribe(
      async (commitPlanResult) => {
        this.message = `Successfully committed the plan version(<b>${commitPlanResult.Version}</b>). Auto assign to regions with active regional plans.</br>`;
        if (commitPlanResult.AutoAssignedRegions != null) {
          let regions = commitPlanResult.AutoAssignedRegions.join(", ");
          this.message += `Auto assigned to <b>{${regions}}</b> successfully.</br>`;
        }

        if (commitPlanResult.ValidateFailedRegionsOfCrp != null) {
          for (let region in commitPlanResult.ValidateFailedRegionsOfCrp) {
            let familyOrSizes = commitPlanResult.ValidateFailedRegionsOfCrp[region].join(", ");
            this.message += `Skip auto assigning to <b>{${region}}</b> because VM SKUs <b>{${familyOrSizes}}</b> may not be available in the region. You can try assigning the plan manually.</br>`;
          }
        }

        if (commitPlanResult.RegionsFailedToAssign != null) {
          for (let region in commitPlanResult.RegionsFailedToAssign) {
            let errorMessage = commitPlanResult.RegionsFailedToAssign[region];
            this.message += `Failed to assign to <b>{${region}}</b>. Error: {${errorMessage}} .</br>`;
          }
        }

        if (commitPlanResult.ValidateFailedRegionsOfNamespaces != null) {
          for (let region in commitPlanResult.ValidateFailedRegionsOfNamespaces) {
            let namespaces = commitPlanResult.ValidateFailedRegionsOfNamespaces[region].join(", ");
            this.message += `Skip auto assigning to <b>{${region}}</b> because namespace <b>{${namespaces}}</b> may not be available in the region. You can try assigning the plan manually.</br>`;
          }
        }

        this.loadingService.setLoading(false);
        await this.modalService.planCommitResponseModal(this.message);
        this.getPlanFiles();
      },
      (error) => {
        if (!error) {
          error = `Failed to commit plan.`;
        }
        this.notificationService.error(error);
        this.loadingService.setLoading(false);
      }
    );
  }
}
