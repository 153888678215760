<div
  class="ag-cell-label-container"
  [ngClass]="{
    'ag-header-cell-sorted-none': !sort,
    'ag-header-cell-sorted-asc': sort === 'asc',
    'ag-header-cell-sorted-desc': sort === 'desc'
  }">
  <span #menu class="ag-header-icon ag-header-cell-menu-button" *ngIf="params.enableMenu" (click)="onMenuClick()">
    <span class="ag-icon ag-icon-menu"></span>
  </span>
  <div class="ag-header-cell-label" (click)="params.enableSorting && onHeaderClick($event)">
    <span class="ag-header-cell-text">
      <ng-template #placeholder></ng-template>
    </span>
    <span class="ag-header-icon ag-filter-icon ag-hidden" [class.ag-hidden]="!filterActive">
      <span class="ag-icon ag-icon-filter"></span>
    </span>
    <span class="ag-header-icon ag-sort-order" [class.ag-hidden]="!sortOrder" [innerHTML]="sortOrder"></span>
    <span class="ag-header-icon ag-sort-ascending-icon" [class.ag-hidden]="sort !== 'asc'" [innerHTML]="sortAscIcon"> </span>
    <span class="ag-header-icon ag-sort-descending-icon" [class.ag-hidden]="sort !== 'desc'" [innerHTML]="sortDescIcon"> </span>
  </div>
</div>
