import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminViewComponent } from "./admin-view.component";
import { TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { ServiceHandlingColumnDefinitionForAdmin } from "./ag-grid-column-definition";
import { DataTypes } from "./admin-view-data-types";
import { Config } from "../../generated-models";
import { SharedDataService } from "../../services/sharedDataService";

@Component({
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewServiceHandlingComponent extends AdminViewComponent<Config> implements OnInit {
  mainTitle = "Service Handling";
  subTitle = "Configure how we handle requests per region per service type";
  type = "Service Handling";
  tableName = TableName.Config;
  columnDef = ServiceHandlingColumnDefinitionForAdmin;
  keyFilterStats = "filter:admin-view-svc-handling";

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    dataTypes: DataTypes,
    sharedDataService: SharedDataService
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.addRowEnable = true;
    this.addRowFunc = (data: Config) => apiService.insertServiceHandling(data).toPromise();
    this.updateRowFunc = (data: Config) => apiService.updateServiceHandling(data).toPromise();
    this.deleteRowEnable = true;
    this.deleteRowFunc = (data: Config) => apiService.deleteServiceHandling(data.Id).toPromise();
  }

  refreshData() {
    this.apiService.getServiceHandlingList().subscribe((response: Config[]) => {
      this.rowData = response.filter((r) => r.ConfigType !== "VMQuotaDefaultQuota" && !r.ConfigType.startsWith("SubscriptionCount-"));
      super.setupFilterByLocalData();
    });
  }
}
