import { ExcelExportParams, GridApi, GridOptions } from "ag-grid-community";
import { environment } from "src/environments/environment";
import { CisJob } from "../generated-models";
import { RequestServiceType, DeploymentSystem, ResourceType, RequestStatusDescription, PlanRegionStatusDescription, SubOrderStatusDescription } from "../npr-request.model";
import { PreprocessDateTime } from "./view-field-mapping";
import { HttpErrorResponse } from "@angular/common/http";

export function ParseRequestId(requestId: string) {
  const lastIndex = requestId.lastIndexOf("-");
  const parentRequestId = requestId.substring(0, lastIndex);
  const subRequestId = requestId.substring(lastIndex + 1);
  return {
    parentRequestId,
    subRequestId,
  };
}

export function GenerateCISJobBaseUrl(cloud: string, endpoint?: string, jobType?: string) {
  endpoint = endpoint || environment.cisEndpoint;
  // To be compatible with old CIS jobs by using the default job type "OnDemandProvision" here.
  jobType = jobType || "OnDemandProvision";
  return `${endpoint}/${cloud}/${jobType}/JobDetails/`;
}

export function GenerateCISJobUrl(cisJob: CisJob): string {
  const baseUrl: string = GenerateCISJobBaseUrl(cisJob.Cloud, cisJob.Endpoint, cisJob.JobType);
  return `${baseUrl}/${cisJob.JobId}`;
}

export function GenerateCISTaskUrl(cisJob: CisJob): string {
  const jobUrl: string = GenerateCISJobUrl(cisJob);

  // The specific CIS task can be highlighted on UI by accessing task url with pattern - <jobUrl>#<jobId><taskDisplayName> starting from the latest CIS frontend release
  return encodeURI(`${jobUrl}#${cisJob.JobId}${cisJob.TaskDisplayName}`);
}

export function GetSubRequestDetailsPageLink(requestId: string, name: string) {
  name = name || "";
  try {
    const req = ParseRequestId(requestId);
    const href = `${environment.baseUrl}/requests/${req.parentRequestId}/sub/${req.subRequestId}`;
    return `<a href="${href}" rel="noopener noreferrer" target="_blank" title="${req.parentRequestId}-${req.subRequestId}">${name}</a>`;
  } catch (err) {
    console.error(err);
    return name;
  }
}

export function GetParentRequestDetailsPageLink(requestId: string, name: string) {
  const href = `${environment.baseUrl}/requests/${requestId}`;
  return `<a href="${href}" rel="noopener noreferrer" target="_blank" title="${requestId}">${name}</a>`;
}

export function GetCustomerRequestDetailsPageLink(billingAccountId: string, region: string) {
  const href = `${environment.baseUrl}/customer-request/customers/${billingAccountId}/regions/${region}`;
  return `<a href="${href}" rel="noopener noreferrer" target="_blank" title="${billingAccountId}-${region}">${billingAccountId}</a>`;
}

export function GetSubRequestDetailsPageWithCommentLink(requestId: string, name: string, showComment: boolean) {
  try {
    const req = ParseRequestId(requestId);
    const href = `${environment.baseUrl}/requests/${req.parentRequestId}/sub/${req.subRequestId}?showComment=${showComment}`;
    return `<a href="${href}" rel="noopener noreferrer" target="_blank" style='color: red;' title="${req.parentRequestId}-${req.subRequestId}">${name}</a>`;
  } catch (err) {
    console.error(err);
    return name;
  }
}

export function GetDependencyLinks(dependencies: string): string {
  if (!dependencies) {
    return null;
  }
  const ids = dependencies.split(";");
  const links = ids.map((id) => {
    if (id.indexOf("-") > 0) {
      // this dependency is a sub request
      return GetSubRequestDetailsPageLink(id, id);
    } else {
      // this dependency is a parent request
      return GetParentRequestDetailsPageLink(id, id);
    }
  });

  const res = links.join(";");
  return res;
}

export function deepCopy(obj) {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" !== typeof obj) {
    return obj;
  }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = deepCopy(obj[attr]);
      }
    }
    return copy;
  }
}

export function GetDeploymentTypeByService(serviceType: string) {
  switch (serviceType) {
    case RequestServiceType.ArmVmQuota:
    case RequestServiceType.ArmStorageQuota:
      return DeploymentSystem.ARM;
    case RequestServiceType.RdfeVmQuota:
    case RequestServiceType.RdfeStorageQuota:
      return DeploymentSystem.RDFE;
    default:
      return "";
  }
}

export function GetResourceTypeByService(serviceType: string) {
  switch (serviceType) {
    case RequestServiceType.ArmVmQuota:
    case RequestServiceType.RdfeVmQuota:
      return ResourceType.Compute;
    case RequestServiceType.ArmStorageQuota:
    case RequestServiceType.RdfeStorageQuota:
      return ResourceType.Storage;
    default:
      return "";
  }
}

export function saveFile(content: Blob, fileName: string) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(content);
  link.download = fileName;
  link.click();
}

export function isNonEmptyString(input: string) {
  return !!input && input.trim().length > 0;
}

export function getExportedExcelFileNameSuffix() {
  return `${PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm", "en-US")}.xlsx`;
}

export const waitingDescription =
  "The request is waiting on some prerequisites. If it stays in this state for a long time, the corresponding service is likely not ready yet in the region.";
export const requestStatusMapping = [
  { status: "None", name: "None", description: "" },
  { status: "Created", name: "Pending Approval", description: "The request is pending approval by Azure Global and GCT approvers." },
  { status: "Approved", name: "Approved", description: "The request has been approved and will transit to other status soon." },
  {
    status: "Rejected",
    name: "Rejected",
    description: "The request has been rejected. See the request details page for more information.",
  },
  { status: "InProgress", name: "In Progress", description: "The request is being processed. Please wait." },
  { status: "Completed", name: "Completed", description: "The request is completed." },
  { status: "CompletedWithNote", name: "Completed", description: "The request is completed." },
  {
    status: "Error",
    name: "Error",
    description:
      "The request has encountered some internal errors. We are working on it to get it fixed. No action is required from the submitter.",
  },
  {
    status: "Waiting",
    name: "Waiting",
    description: waitingDescription,
  },
  {
    status: "WaitingDependency",
    name: "WaitingDependency",
    description: "The request is waiting for the dependent requests to be completed",
  },
  { status: "Cancelled", name: "Cancelled", description: "The request has been cancelled." },
  {
    status: "ActionRequired",
    name: "Action Required",
    description:
      "The RDQuota ticket of this request is blocked and requires manual intervention from the submitter. See the request details page for more information.",
  },
  {
    status: "InQueue",
    name: "Waiting",
    description: waitingDescription,
  },
  {
    status: "Throttled",
    name: "Waiting",
    description: waitingDescription,
  },
  {
    status: "ErrorAndRetrying",
    name: "Retrying",
    description: "There has been some transient errors and we are retrying.",
  },
  {
    status: "Backlogged",
    name: "Backlogged",
    description: "The request has been put on backlog due to capacity constraint, please contact CM24x7 via RDQuota link in sub-request detail page.",
  },
  {
    status: "Forelogged",
    name: "Forelogged",
    description: "The request has been put on forelog due to capacity constraint, please contact CM24x7 via RDQuota link in sub-request detail page.",
  },
];

export function getRequestStatusDescription(requestStatus: string): RequestStatusDescription {
  return requestStatusMapping.find((m) => m.status === requestStatus);
}

export const plannedQuotaRequestStatusMapping = [
  { status: "ValidationFailed", name: "ValidationFailed", description: "Validate the regional plan failed." },
  { status: "Created", name: "Created", description: "The request has been created successfully, waiting to be processed." },
  { status: "Processed", name: "Processed", description: "The request is being processed. Waiting for quota fulfillment." },
  { status: "Error", name: "Error", description: "The request has encountered some internal errors. We are working on it to get it fixed. No action is required from the submitter." },
  { status: "Completed", name: "Completed", description: "The request is completed." },
];

export const PlanRegionStatusMapping = [
  { status: "Created", name: "Created", description: "The plan is in initial status." },
  { status: "NeedSignOff", name: "NeedSignOff", description: "The plan need sign-off by Service team" },
  { status: "Approvable", name: "Approvable", description: "The plan is waiting for approval." },
  { status: "Approved", name: "Approved", description: "The plan has been approved. Service owners can start to send quota requests from Ev2 or CIS now." },
  { status: "Error", name: "Error", description: "Error occurred when processing the plan files." },
  { status: "Rejected", name: "Rejected", description: "The plan has been rejected." },
  { status: "Removed", name: "Removed", description: "The plan has been removed, service owners can't use this version for quota fulfillment any longer." },
];

export const SubOrderStatusMapping = [
  { status: "Accepted", name: "Accepted", description: "The SubOrder is accepted but has not yet been created." },
  { status: "InProgress", name: "InProgress", description: "The SubOrder has been created and is under review." },
  { status: "CommunicationNeeded", name: "CommunicationNeeded", description: "The SubOrder requires clarification. Please see the suborder page to respond to the approver." },
  { status: "Approved", name: "Approved", description: "Your SubOrder has been approved. Please note, all suborders need to be approved before you can proceed." },
  { status: "Failed", name: "Failed", description: "An error occurred while processing your SubOrder." },
  { status: "Rejected", name: "Rejected", description: "The suborder has been rejected." },
  { status: "UserCancelled", name: "UserCancelled", description: "The SubOrder has been cancelled by the user. You can re-request by updating your plan." }
];

export function getPlannedQuotaRequestStatusDescription(requestStatus: string): RequestStatusDescription {
  return plannedQuotaRequestStatusMapping.find((m) => m.status === requestStatus);
}

export function getPlanRegionStatusDescription(planRegionStatus: string): PlanRegionStatusDescription {
  return PlanRegionStatusMapping.find((m) => m.status === planRegionStatus);
}

export function getSubOrderStatusDescription(subOrderStatus: string): SubOrderStatusDescription {
  return SubOrderStatusMapping.find((m) => m.status === subOrderStatus);
}

export function setColumnState(params: GridOptions, item: string): void {
  var columnState = JSON.parse(localStorage.getItem(item));
  if (columnState) {
    params.columnApi.applyColumnState(columnState);
  }
}

export function OnColumnMoved(params: GridOptions, item: string): void {
  var columnState = JSON.stringify(params.columnApi.getColumnState());
  localStorage.setItem(item, columnState);
}

export function exportExcel(gridApi: GridApi, fileName: string, sheetName: string) {
  const params = {
    exportMode: "xlsx",
    skipHeader: false,
    columnGroups: false,
    skipGroups: false,
    skipFooters: false,
    skipPinnedTop: false,
    skipPinnedBottom: false,
    allColumns: false,
    onlySelected: false,
    onlySelectedAllPages: false,
    fileName: fileName,
    sheetName: sheetName,
  } as ExcelExportParams;

  gridApi.exportDataAsExcel(params);
}

export function fillPlanAssignmentWarningMessageFromException(ex: HttpErrorResponse, warningMessage: string = "") {
  for (let instance of ex?.error?.CrpSkuNotAvailableInstances) {
    warningMessage += `VM size '${instance}' and its alternative sizes may not be available in the region<br/>`;
  }

  for (let family of ex?.error?.CrpSkuNotAvailableFamilies) {
    warningMessage += `VM family '${family}' and its alternative families may not be available in the region<br/>`;
  }

  for (let namespace of ex?.error?.NamespacesNotAvailable) {
    warningMessage += `Namespaces '${namespace}' may not be available in the region<br/>`;
  }

  for (let vmSku of ex?.error?.BatchVMSkuNotAvailable) {
    warningMessage += `Batch VmSku '${vmSku}' may not be available in the region<br/>`
  }

  for (let vmSku of ex?.error?.HDInsightVMSkuNotAvailable) {
    warningMessage += `HDInsight VmSku '${vmSku}' may not be available in the region<br/>`
  }

  return warningMessage;
}
