<div class="modal-header">
  <h4 class="modal-title">Validating</h4>
</div>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
<div class="modal-body">
  <ul *ngFor="let item of aggregatedResult">
    <li [innerHtml]="item"></li>
  </ul>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()" *ngIf="closeBtnVisible">Close</button>
</div>
