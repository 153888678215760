import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminViewComponent } from "./admin-view.component";
import { TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { DataTypes } from "./admin-view-data-types";
import { HoboSubscriptionAutoApprovalRule } from "src/app/generated-models/HoboSubscriptionAutoApprovalRule";
import * as CDs from "src/app/utility/commonColDef";
import { SharedDataService } from "../../services/sharedDataService";

@Component({
  selector: "admin-view/autoapprovalrule",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class HoboSubscriptionAutoApprovalRulesComponent extends AdminViewComponent<HoboSubscriptionAutoApprovalRule> implements OnInit {
  keyFilterStats = "filter:hobo-sub-auto-approval-roles";
  tableName = TableName.HoboSubscriptionAutoApprovalRule;
  columnDef = [
    { headerName: "Id", field: "Id", valueType: "number", hide: true },
    {
      headerName: "Requestor",
      field: "Requestor",
      valueType: "requestor",
      cellRenderer: (params) => {
        return this.dataTypes.teamNameOidMap[params.value];
      },
    },
    { headerName: "RegionType", field: "RegionType", wildcard: true, valueType: "regionType" },
    { ...CDs.RequestServiceType, valueType: "hoboAutoApprovalSupportedServiceTypes" },
    {
      ...CDs.Sku,
      wildcard: true,
      valueType: "sku",
      cellRenderer: (params) => {
        if (params.value in this.dataTypes.skuMap) {
          return this.dataTypes.skuMap[params.value];
        } else {
          return params.value;
        }
      },
    },
    { ...CDs.Quota, isEditable: true, valueType: "number" },
  ];

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    dataTypes: DataTypes,
    sharedDataService: SharedDataService
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.type = "Auto Approval Rule";
    this.addRowEnable = true;
    this.updateRowFunc = (data: HoboSubscriptionAutoApprovalRule) => apiService.updateHoboAutoApprovalRule(data).toPromise();
    this.addRowFunc = (data: HoboSubscriptionAutoApprovalRule) => apiService.insertHoboAutoApprovalRule(data).toPromise();
    this.deleteRowEnable = true;
    this.deleteRowFunc = (data: HoboSubscriptionAutoApprovalRule) => apiService.deleteHoboAutoApprovalRule(data.Id).toPromise();
  }

  refreshData() {
    this.apiService.getHoboAutoApprovalRuleList().subscribe((p) => {
      this.rowData = p;
      super.setupFilterByLocalData();
    });
  }
}
