import { ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppInsightService } from "../shared/appinsight.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private appInsightService: AppInsightService,
    private router: Router) {
  }

  handleError(error): void {
    console.error(error);
    this.appInsightService.logException(error, this.router.url);
  }
}
