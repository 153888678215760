import { Component } from "@angular/core";
import { ITooltipAngularComp } from "ag-grid-angular";

@Component({
  selector: "tooltip-component",
  template: `
    <div class="custom-tooltip">
      <p *ngIf="hasBacklogged()">One or more sub requests are backlogged</p>
      <p *ngIf="hasForelogged()">One or more sub requests are forelogged</p>
      <p *ngIf="hasActionRequired()">One or more sub requests are action required</p>
    </div>
  `,
  styles: [
    `
      :host {
        position: absolute;
        overflow: hidden;
        pointer-events: none;
        transition: opacity 1s;
        width: max-content;
        background-color: #d3d3d3;
      }

      .custom-tooltip p {
        margin: 5px;
      }
    `,
  ],
})
export class StatusTooltipComponent implements ITooltipAngularComp {
  data: any;
  agInit(params): void {
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
  }

  hasBacklogged(): boolean {
    return this.data.HasBacklogged;
  }

  hasForelogged(): boolean {
    return this.data.HasForelogged;
  }

  hasActionRequired(): boolean {
    return this.data.HasActionRequired;
  }
}
