<div class="title" #title>
  <div class="container">
    <h4>Create Planned Quota Request</h4>
    <p>
      Use this tool to submit planned quota requests for your subscriptions.
      <br />
      For automation options and questions, please refer to our
      <a href="https://aka.ms/catseye-docs" target="_blank">wiki</a>.
    </p>
  </div>
</div>
<div class="container container-content mt-3" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main">
    <form #form="ngForm" autocomplete="off" novalidate>
      <ul class="list-container">
        <!--If Internal Request, type in Requesting Service Team-->
        <li class="flex-box mb-3">
          <div class="col-md-3 required">Requesting Service Team</div>
          <label class="col-md-9">
            <ng-select
              name="ServiceTreeId"
              [(ngModel)]="request.ServiceTreeId"
              [items]="teamList"
              groupBy="ring"
              placeholder="Start typing"
              [virtualScroll]="true"
              required
              (change)="onTeamSelected($event)"
              #teamName="ngModel">
              <ng-template ng-option-tmp let-item="item">
                <ng-container>
                  <div title="{{ item.label }}">
                    {{ item.label }}
                  </div>
                </ng-container>
              </ng-template>
            </ng-select>
            <em *ngIf="isTouchedOrDirty(teamName) && teamName.errors?.required">Required</em>
          </label>
        </li>
        <!--Email Address-->
        <li class="flex-box mb-3">
          <div class="col-md-3 required">Contact Email</div>
          <div class="col-md-9">
            <input class="form-control" [(ngModel)]="request.ContactEmail" name="Email" required #email="ngModel" title="email" />
            <ng-container *ngIf="isTouchedOrDirty(email)">
              <em *ngIf="email.errors?.required">Required</em>
              <em *ngIf="!isEmailValid">Enter email address in this format: alias1@contoso.com,alias2@contoso.com</em>
            </ng-container>
          </div>
        </li>
        <!--Region-->
        <li class="flex-box mb-3">
          <div class="col-md-3 required">Region</div>
          <label class="col-md-9">
            <ng-select
              [(ngModel)]="request.Region"
              name="Region"
              bindLabel="RegionName"
              [items]="regionList"
              groupBy="Status"
              placeholder="Enter region name"
              required
              (change)="onRegionSelected($event)"
              #region="ngModel">
            </ng-select>
            <em *ngIf="isTouchedOrDirty(region) && region.errors?.required">Required</em>
          </label>
        </li>
        <!--Subscription ID-->
        <li class="flex-box mb-3">
          <div class="col-md-3 required">Subscription</div>
          <div class="col-md-9">
            <ng-container>
              <input
                class="form-control"
                [(ngModel)]="request.SubscriptionId"
                name="SubId"
                title="SubId"
                placeholder="Enter in this format: 987e33bf-8713-45cf-93c5-7055c9cca413"
                required
                #subId="ngModel"
                [pattern]="guidRegExpression" />
              <ng-container *ngIf="isTouchedOrDirty(subId)">
                <em *ngIf="subId.errors?.required">Required</em>
                <em *ngIf="subId.errors?.pattern">Please enter one valid subscription ID.</em>
              </ng-container>
            </ng-container>
          </div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-3 required">Subscription Name In Plan</div>
          <div class="col-md-9">
            <ng-container>
              <input
                class="form-control"
                [(ngModel)]="request.SubscriptionKey"
                name="SubKey"
                title="SubKey"
                placeholder="Enter the subscription name in Plan"
                required
                #subKey="ngModel" />
              <ng-container *ngIf="isTouchedOrDirty(subKey)">
                <em *ngIf="subKey.errors?.required">Required</em>
              </ng-container>
            </ng-container>
          </div>
        </li>
        <li class="flex-box mb-3">
          <div class="col-md-3">Skip Unsupported Silently</div>
          <div class="col-md-9">
            <input type="checkbox" [(ngModel)]="request.SkipUnsupportedSilently" name="SkipUnsupportedSilently" />
          </div>
        </li>
        <div class="flex-box mb-3">
          <div class="col-md-9">
            <button type="submit" class="btn btn-primary mr-1" [disabled]="!isReadyToCreate()" (click)="createPlannedQuotaRequest()">
              Create
            </button>
          </div>
        </div>
      </ul>
    </form>
  </div>
</div>
