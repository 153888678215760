<div class="modal-header">
    <h4 class="modal-title" style="text-transform: capitalize;">{{ title }}</h4>
  </div>
  
  <div>
    <mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
    <div class="modal-body">
      <p>
        In progress {{ this.aggregatedResult.length }}/{{ this.pfPlans.length }} :
      </p>
      <ul *ngFor="let item of aggregatedResult">
        <li [innerHtml]="item"></li>
      </ul>
    </div>
  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="close()">
      Close
    </button>
  </div>
  