import { Component } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions, RowNode } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { PlansAllRegionalPlanColumnDefinition } from "./ag-grid-column-definition";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { PlanRegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { ModalService } from "src/app/shared/modal.service";

@Component({
  templateUrl: "./plan-all-regional-plans.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansAllRegionalPlansComponent extends GridViewBaseComponent {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize: number = 15;
  isAdmin: boolean = false;
  rowData: PlanRegion[] = [];
  hasRowSelected = false;
  keyFilterStats: string = "filter:all-regional-plans";
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  constructor(
    apiService: ApiService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private modalService: ModalService,
    private notificationService: ToastrService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit() {
    this.gridOptions = extendDefaultOptions({
      columnDefs: PlansAllRegionalPlanColumnDefinition,
      paginationPageSize: this.initPageSize,
      isRowSelectable: (node: RowNode) => {
        const row = node.data;
        return row.Status === PlanRegionStatusEnum.Approvable;
      },
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
    });
    this.apiService.getUserProfile().then((response) => {
      this.userProfile = response;
      this.loadingService.setLoading(true);

      if (this.userProfile?.IsAdmin) {
        this.isAdmin = true;
        PlansAllRegionalPlanColumnDefinition[0].checkboxSelection = (params) => params.data.Status === PlanRegionStatusEnum.Approvable;
        PlansAllRegionalPlanColumnDefinition[0].headerCheckboxSelection = true;
        const colDefs = PlansAllRegionalPlanColumnDefinition;
        this.gridOptions.api.setColumnDefs(colDefs);
      }

      this.getAllRegionalPlans();
    });
  }

  getAllRegionalPlans() {
    this.apiService.getRegionalPlans().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  onSelectionChanged(params: GridOptions): void {
    this.hasRowSelected = params.api.getSelectedRows().length > 0;
  }

  refreshData() {
    this.loadingService.setLoading(true);
    this.getAllRegionalPlans();
  }

  exportExcel() {
    var fileName = "AllRegionalPlans-" + getExportedExcelFileNameSuffix();
    var sheetName = "All Regional Plans";
    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }

  async batchRemovePlans() {
    const selectedRows = this.gridApi.getSelectedRows() as PlanRegion[];
    await this.modalService.planSetRemovedModal(selectedRows);
    this.refreshData();
    this.hasRowSelected = false;
  }
}
