<div class="modal-header">
    <h4 class="modal-title" style="text-transform: capitalize;">{{ confirmInfo }}</h4>
  </div>
  
  <div *ngIf="inProgress">
    <mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
    <div class="modal-body">
      <p>
        In progress {{ this.aggregatedResult.length }}/{{ this.planRegions.length }} :
      </p>
      <ul *ngFor="let item of aggregatedResult">
        <li [innerHtml]="item"></li>
      </ul>
    </div>
  </div>
  
  <div *ngIf="confirmation">
    <div class="modal-body">
      <p>
        Are you sure to set following {{ this.planRegions.length }} plan(s) to <b>Removed</b> status?
      </p>
      <ul *ngFor="let item of planRegions">
        <li>
            Regional plan ({{ item.ServiceTreeId }}, {{ item.Region }}, {{ item.Version}}, {{ item.Stage }})
        </li>
      </ul>
    </div>
  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" *ngIf="confirmation" (click)="submit()">
      Yes
    </button>
    <button type="button" class="btn btn-warning" (click)="close()">
      {{ cancelButtonMsg }}
    </button>
  </div>