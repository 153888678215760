import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OndemandPrebuildoutConfirmDialogComponent } from "../ondemand-prebuildout-confirm-dialog/ondemand-prebuildout-confirm-dialog.component";
import { InitalBulkSubmitConfirmDialogComponent } from "../initial-bulk-submit-confirm-dialog/initial-bulk-submit-confirm-dialog.component";
import { Status } from "../status/model";
import { Product } from "../ondemand/model";
import { ResubmitPrebuildoutConfirmDialogComponent } from "../resubmit-prebuildout-confirm-dialog/resubmit-prebuildout-confirm-dialog.component";
import { TableConfirmModalComponent } from "./table-confirm-modal/table-confirm-modal.component";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { RegionNamesModalComponent } from "./region-names-modal/region-names-modal.component";
import { AckErrorBillingMeterDialogComponent } from "../ack-error-billing-meter-dialog/ack-error-billing-meter-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private ngbModal: NgbModal) {}

  onAckErrorBillingMeterConfirmModal(ackErrorBillingMeterRequestsCount: number) {
    console.log("onAckErrorBillingMeter");
    const ngbModalRef = this.ngbModal.open(AckErrorBillingMeterDialogComponent, {
      backdrop: "static",
      size: "lg",
    })
    const component = ngbModalRef.componentInstance as AckErrorBillingMeterDialogComponent;
    component.errorBillingMeterRequestsCount = ackErrorBillingMeterRequestsCount;

    return ngbModalRef.result;
  }

  onDemandPrebuildoutConfirmModal(regions: string[], products: Product[]) {
    console.log("onDemandPrebuildoutConfirmModal");
    const ngbModalRef = this.ngbModal.open(OndemandPrebuildoutConfirmDialogComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as OndemandPrebuildoutConfirmDialogComponent;
    component.regions = regions;
    component.products = products;
    return ngbModalRef.result;
  }

  onBulkSubmitConfirmModal(regions: string[], products: Product[]) {
    console.log("onBulkSubmitConfirmModal");
    const ngbModalRef = this.ngbModal.open(InitalBulkSubmitConfirmDialogComponent, {
      backdrop: "static",
      size: "xl",
    });
    const component = ngbModalRef.componentInstance as InitalBulkSubmitConfirmDialogComponent;
    component.regions = regions;
    component.products = products;
    return ngbModalRef.result;
  }

  onResubmitPrebuildoutConfirmModal(regions: string[], status: Status[]) {
    console.log("onDemandPrebuildoutConfirmModal");
    const ngbModalRef = this.ngbModal.open(ResubmitPrebuildoutConfirmDialogComponent, {
      backdrop: "static",
      size: "lg",
    });
    const component = ngbModalRef.componentInstance as ResubmitPrebuildoutConfirmDialogComponent;
    component.regions = regions;
    component.status = status;
    return ngbModalRef.result;
  }

  RegionNamesModal(regionNames: string[]) {
    console.log("RegionNamesModal");
    const ngbModalRef = this.ngbModal.open(RegionNamesModalComponent);
    const component = ngbModalRef.componentInstance as RegionNamesModalComponent;
    component.regionNames = regionNames;
    return ngbModalRef.result;
  }
  InitTableConfirmModal(
    title: string,
    message: string,
    columnDefs: any,
    rowData: any,
    yesButtonMessage: string = "yes",
    noButtonMessage: string = "no"
  ) {
    console.log("InitTableConfirmModal");
    const ngbModalRef = this.ngbModal.open(TableConfirmModalComponent, {
      backdrop: "static",
      size: "lg",
    });

    const component = ngbModalRef.componentInstance as TableConfirmModalComponent;
    component.title = title;
    component.message = message;
    component.columnDefs = columnDefs;
    component.rowData = rowData;
    component.yesButtonMessage = yesButtonMessage;
    component.noButtonMessage = noButtonMessage;

    return ngbModalRef.result;
  }
}
