
import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import { PlanValidationErrorCode } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { fillPlanAssignmentWarningMessageFromException } from "../../../../utility/common-helper";

@Component({
  templateUrl: "./plan-assignment-reconfirm-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanAssignmentReconfirmModalComponent implements OnInit {
  @Input() planSubmission: PlanSubmission;
  @Output() aggregatedResult: string = "";

  constructor(public activeModal: NgbActiveModal, protected apiService: ApiService) { }

  ngOnInit() { }

  async submit() {
    try {
      await this.apiService.assignPlanAndSoftDeleteOldVersions(this.planSubmission).toPromise();
      this.activeModal.close("successful");
    } catch (ex) {
      if (ex?.error?.Code == PlanValidationErrorCode.PlanValidationCrpSkuNotAvailable) {
        this.aggregatedResult = fillPlanAssignmentWarningMessageFromException(ex, "Plan validation failed with crp sku not available : ");
        this.aggregatedResult += `<b>Close</b> and add some alternatives available in the region before re-assigning.<br/> Or <b>[Proceed]</b> to acknowledge the risk and continue assigning with the current plan.<br/>`;
      } else if (ex?.error.message != null) {
        this.aggregatedResult = ex?.error.message;
      }
      this.activeModal.close(this.aggregatedResult);
    }
  }

  cancel() {
    this.activeModal.close("cancelled");
  }
}
