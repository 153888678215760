import {
  Component,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  InjectionToken,
  Type,
  Injector,
} from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { Events, IHeaderParams } from "ag-grid-community";

export interface DynamicHeaderComponentParams {
  component: Type<any>;
}

export interface DynamicHeaderComponentColDef {
  headerComponentParams?: DynamicHeaderComponentParams;
}

export const paramsToken = new InjectionToken("params");

@Component({
  templateUrl: "./dynamic-header.component.html",
  styleUrls: ["./dynamic-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicHeaderComponent implements IHeaderAngularComp {
  @ViewChild("menu") private menuRef: ElementRef;
  @ViewChild("placeholder", { static: true, read: ViewContainerRef }) placeholderRef: ViewContainerRef;

  params: IHeaderParams & DynamicHeaderComponentParams;
  sort: "asc" | "desc";
  sortOrder: number;
  filterActive: boolean;

  // eslint-disable-next-line @typescript-eslint/ban-types
  sortAscIcon: string | Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  sortDescIcon: string | Function;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly componentFactoryResolver: ComponentFactoryResolver) {}

  agInit(params: IHeaderParams & DynamicHeaderComponentParams) {
    this.params = params;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(params.component);
    const injector = Injector.create({
      providers: [{ provide: paramsToken, useValue: params }],
      parent: this.placeholderRef.injector,
    });
    this.placeholderRef.createComponent(componentFactory, 0, injector);

    this.params.api.addEventListener(Events.EVENT_SORT_CHANGED, this.onSortChanged.bind(this));
    this.params.column.addEventListener(Events.EVENT_FILTER_CHANGED, this.onFilterChanged.bind(this));
    this.onSortChanged();
    this.onFilterChanged();

    this.sortAscIcon =
      (this.params.column.getColDef().icons && this.params.column.getColDef().icons["sortAscending"]) ||
      `<span class="ag-icon ag-icon-asc"></span>`;
    this.sortDescIcon =
      (this.params.column.getColDef().icons && this.params.column.getColDef().icons["sortDescending"]) ||
      `<span class="ag-icon ag-icon-desc"></span>`;
  }

  onMenuClick() {
    this.params.showColumnMenu(this.menuRef.nativeElement);
  }

  onHeaderClick($event: MouseEvent) {
    this.params.progressSort($event.shiftKey);
  }

  refresh() {
    return true;
  }

  private onSortChanged() {
    this.sort = this.params.column.getSort() as "asc" | "desc";
    this.sortOrder = this.params.column.getSortIndex() + 1;
    this.changeDetectorRef.markForCheck();
  }

  private onFilterChanged() {
    this.filterActive = this.params.column.isFilterActive();
    this.changeDetectorRef.markForCheck();
  }
}
