import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "region-names-modal",
  templateUrl: "./region-names-modal.component.html",
})
export class RegionNamesModalComponent implements OnInit {
  @Input() regionNames: string[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
