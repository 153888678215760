import { ColDef } from "ag-grid-community";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import * as CDs from "src/app/utility/commonColDef";
import { FfpPlan } from "src/app/generated-models/FfpPlan";
import { FfpPlanInstance } from "../../generated-models/FfpPlanInstance";

// TODO: Should confirm
export const ffpPlanEditorCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<FfpPlan, string>) => `/quota/ffp/plans/services/${params.data.ServiceTreeId}/types/${params.data.Type}/editor`,
}

export const FfpPlansColumnDefinition: ColDef[] = [
  {
    ...CDs.ServiceTreeId,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: ffpPlanEditorCellComponentParams,
  },
  CDs.ServiceTeam,
  CDs.Ring,
  CDs.FfpPlanType,
  CDs.LatestCommitVersion,
  CDs.SubmitTime("Submit Time", "CreatedTime"),
  CDs.CreatedBy,
  CDs.CustomFilter
];

export const ffpPlanVersionCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<FfpPlanInstance, string>) =>
    `/quota/ffp/instances/services/${params.data.ServiceTreeId}/projects/${params.data.ProjectOid}/versions/${params.data.Version}/review`,
};

export const viewFfpPlanCellComponentParams = {
  value: "Review",
  target: "_blank",
  url: (params: ColParams<FfpPlanInstance, string>) =>
    `/quota/ffp/instances/services/${params.data.ServiceTreeId}/projects/${params.data.ProjectOid}/versions/${params.data.Version}/review`,
};

export const FfpMyRegionalPlanColumnDefinition: ColDef[] = [
  CDs.ServiceTreeId,
  CDs.ServiceTeam,
  CDs.ProjectOidColDef("ProjectOid"),
  CDs.Ring,
  {
    ...CDs.Version,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: ffpPlanVersionCellComponentParams,
  },
  CDs.Submitter,
  CDs.SubmitTime(),
  CDs.RegionalPlanStatus,
  CDs.ServiceOwnerApproved,
  CDs.FfpAdminApproved,
];

export const FfpProjectBuildoutCellComponentParams = {
  target: "_blank",
  url: (params: ColParams<FfpPlanInstance, string>) =>
    `/quota/ffp/buildout/projects/${params.data.ProjectOid}/plans`,
}
export const FfpProjectBuildoutColumnDefinition: ColDef[] = [
  {
    ...CDs.ProjectOidColDef("ProjectOid"),
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: FfpProjectBuildoutCellComponentParams,
  },
  CDs.ProjectName,
  CDs.BusinessScenario,
  CDs.BuildoutPSL,
  CDs.Status,
];

export const BuildoutPlansColumnDefinition: ColDef[] = [
  {
    headerName: "Action",
    colId: "action",
    sortable: false,
    suppressMenu: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRendererFramework: LinkCellComponent,
    cellRendererParams: viewFfpPlanCellComponentParams,
  },
  CDs.ProjectOidColDef("ProjectOid"),
  CDs.Ring,
  CDs.ServiceTeam,
  CDs.ServiceTreeId,
  CDs.Submitter,
  CDs.SubmitTime("SubmitTime"),
  CDs.LatestVersion,
  CDs.Status,
];
