import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { CreateRequestComponent } from "./quota/create-request/create-request.component";
import { ApproverReviewComponent } from "./quota/approver-review/approver-review.component";
import { OperatorReviewComponent } from "./quota/operator-review/operator-review.component";
import { RequestStatusComponent } from "./quota/request-status/request-status.component";
import {
  IsAdmin,
  IsApprover,
  IsApproverAdmin,
  IsGETApprover,
  LandingGuard,
  IsTestOrDevEnvironment,
  IsPfAdmin,
} from "./core/landing.guard";
import { AgsAadCallbackComponent } from "@ags/core";
import { SupportInformationComponent } from "./quota/region-information/region-information.component";
import { AdminViewRegionComponent } from "./quota/admin-view/admin-view-region.component";
import { AdminViewUserRoleComponent } from "./quota/admin-view/admin-view-userrole.component";
import { ApproverAdminComponent } from "./quota/approver-admin/approver-admin.component";
import { AdminViewAutoApprovalComponent } from "./quota/admin-view/admin-view-autoapprovalrule.component";
import { AppRoutingModule as BetAppRoutingModule } from "./bet/app-routing.module";
import { ActiveParentRequestsComponent } from "./quota/incidents-view/error-requests/active-parentrequests.component";
import { IncompleteWorkItemsComponent } from "./quota/incidents-view/incomplete-tickets/incomplete-workitems.component";
import { RequestDetailComponent } from "./quota/request-detail/request-detail.component";
import { ActiveSubrequestsComponent } from "./quota/incidents-view/active-subrequests/active-subrequests.component";
import { AdminViewServiceHandlingComponent } from "./quota/admin-view/admin-view-service-handling.component";
import { AdminViewSubscriptionLimitComponent } from "./quota/admin-view/admin-view-subscription-limit.component";
import { AdminViewTableViewComponent } from "./quota/admin-view/admin-view-table-view.component";
import { AdminViewEmailsViewComponent } from "./quota/admin-view/admin-view-emails-view.component";
import { ParentRequestDetailComponent } from "./quota/parent-request-detail/parent-request-detail.component";
import { PlannedQuotaRequestsComponent } from "./quota/planned-quota-requests/planned-quota-requests.component";
import { HoboSubscriptionAutoApprovalRulesComponent } from "./quota/admin-view/admin-view-hobo-subscription-autoapprovalrule.component";
import { SelfServiceComponent } from "./quota/self-service/self-service.component";
import { RequestsWithTicketsInfoComponent } from "./quota/requests-tickets-info/requestsWithTicketsInfo";
import { PlansApprovableComponent } from "./quota/plans/plans-approvable.component";
import { PlanReviewComponent } from "./quota/plans/plan-review.component";
import { PlanDetailComponent } from "./quota/plans/plan-detail.component";
import { PlanEditorComponent } from "./quota/plans/plan-editor.component";
import { PlanHistoryComponent } from "./quota/plans/plan-history.component";
import { PlansComponent } from "./quota/plans/plans.component";
import { PlansAssignmentComponent } from "./quota/plans/plans-assignment.component";
import { PlansMyRegionalPlansComponent } from "./quota/plans/plan-my-regional-plans.component";
import { PlansSignOffComponent } from "./quota/plans/plans-signoff.component";
import { ServiceTeamUserManagement } from "./shared/service-team-user-management/service-team-user-management.component";
import { PlansRegionalQuotaInformationComponent } from "./quota/plans/plans-regional-quota-information.component";
import { AdminViewRegionalQuotaLimitComponent } from "./quota/admin-view/admin-view-regional-quota.component";
import { PlansAllRegionalPlansComponent } from "./quota/plans/plan-all-regional-plans.component";
import { CreatePlannedQuotaRequestComponent } from "./quota/planned-quota-requests/create-planned-quota-request.component";
import { PlansBindingsComponent } from "./quota/plans/plan-bindings.component";
import { PlansBindingsSearchComponent } from "./quota/plans/plan-bindings-search.component";
import { PlannedQuotaFulfillmentStatusComponent } from "./quota/plans/plan-status.component";
import { PlannedQuotaRequestDetailComponent } from "./quota/planned-quota-request-detail/planned-quota-request-detail.component";
import { PlannedQuotaRegionStatusComponent } from "./quota/plans/plan-region-status.component";
import { PlannedQuotaMyRequestsComponent } from "./quota/planned-quota-requests/planned-quota-my-requests.component";
import { PlansAssignmentServiceOwnerComponent } from "./quota/plans/plans-assignment-service-owner.component";
import { PlansInformationVmSizesComponent } from "./quota/plans-information/plans-information-vm-sizes.component";
import { AdminViewSubscriptionBindingsComponent } from "./quota/admin-view/admin-view-bindings-sub";
import { AdminViewPropertyBindingsComponent } from "./quota/admin-view/admin-view-bindings-prop";
import { AdminViewEdgeZoneComponent } from "./quota/admin-view/admin-view-edgezone.component";
import { AdminViewCapacityOrderViewComponent } from "./quota/admin-view/admin-view-capacity-order-view.component";
import { PlansRisksComponent } from "./quota/risks/plans-risks.component";
import { AdminViewTeamConfigComponent } from "./quota/admin-view/admin-view-team-config.component";
import { PageNotFoundComponent } from "./shared/page-not-found/page-not-found.component";
import { ArmResourceTypeEnablement } from "./shared/arm-resource-type-details/arm-resource-type-details.component";
import { FfpPlansComponent } from "./quota/ffp/ffp-plans.component";
import { FfpPlanEditorComponent } from "./quota/ffp/ffp-plan-editor.component";
import { FfpPlanHistoryComponent } from "./quota/ffp/ffp-plan-history.component";
import { PfPlansComponent } from "./quota/pf/pf-plans.component";
import { PfPlanEditorComponent } from "./quota/pf/pf-plan-editor.component";
import { PfPlanHistoryComponent } from "./quota/pf/pf-plan-history.component";
import { PfPlansApprovableComponent } from "./quota/pf/pf-plans-approvable.component";
import { PfPlanReviewComponent } from "./quota/pf/pf-plan-review.component";
import { FfpPlansAssignmentServiceOwnerComponent } from "./quota/ffp/ffp-plans-assignment-service-owner.component";
import { FfpPlanReviewComponent } from "./quota/ffp/ffp-plan-review.component";
import { FfpPlansAssignmentComponent } from "./quota/ffp/ffp-plans-assignment.component";
import { FfpMyRegionalPlansComponent } from "./quota/ffp/ffp-plan-my-regional-plans.component";
import { FfpRegionBuildoutComponent } from "./quota/ffp/ffp-buildout.component";
import { FfpBuildoutPlansComponent } from "./quota/ffp/ffp-buildout-plans.component";
import { CapacitySubOrderDetailComponent } from "./quota/plans/capacity-suborder-detail/capacity-suborder-detail.component";
import { CapacityOrderDetailComponent } from "./quota/plans/capacity-order-detail/capacity-order-detail.component";
import { CapacityOrderDetailV2Component } from "./quota/plans/capacity-order-detail/capacity-order-detail-v2.component";
import { AdminViewManatreeComponent } from "./quota/admin-view/admin-view-manatree";

const routes: Routes = [
  ...BetAppRoutingModule.routes,
  ...[
    { path: "", pathMatch: "full", redirectTo: "plans/all" },
    {
      path: "home",
      component: HomeComponent,
    },
    {
      path: "admin-view/region",
      component: AdminViewRegionComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Region Config" },
    },
    {
      path: "admin-view/edgezone",
      component: AdminViewEdgeZoneComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "EdgeZone Config" },
    },
    {
      path: "admin-view/userrole",
      component: AdminViewUserRoleComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "User Roles" },
    },
    {
      path: "admin-view/autoapprovalrule",
      component: AdminViewAutoApprovalComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Auto Approval Rules" },
    },
    {
      path: "admin-view/autoapprovalrule-hobo",
      component: HoboSubscriptionAutoApprovalRulesComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Auto Approval Rules for Hobo Subscriptions" },
    },
    {
      path: "admin-view/servicehandling",
      component: AdminViewServiceHandlingComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Service Handling" },
    },
    {
      path: "admin-view/regionalQuota",
      component: AdminViewRegionalQuotaLimitComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Regional Quota" },
    },
    {
      path: "admin-view/subscriptionlimit",
      component: AdminViewSubscriptionLimitComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Subscription Limits" },
    },
    {
      path: "admin-view/table-view",
      component: AdminViewTableViewComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Table View" },
    },
    {
      path: "admin-view/capacity-order-view",
      component: AdminViewCapacityOrderViewComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Capacity Order View" },
    },
    {
      path: "admin-view/emails-view",
      component: AdminViewEmailsViewComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Emails View" },
    },
    {
      path: "admin-view/bindings/subscription",
      component: AdminViewSubscriptionBindingsComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Subscription Bindings" },
    },
    {
      path: "admin-view/bindings/property",
      component: AdminViewPropertyBindingsComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Property Bindings" },
    },
    {
      path: "admin-view/teams/config",
      component: AdminViewTeamConfigComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Team Config" },
    },
    {
      path: "admin-view/manatree",
      component: AdminViewManatreeComponent,
      canActivate: [LandingGuard, IsAdmin],
      data: { title: "Manatree Config" },
    },
    {
      path: "incidents-view/active-subrequests",
      component: ActiveSubrequestsComponent,
      canActivate: [LandingGuard],
      data: { title: "Active Sub Requests" },
    },
    {
      path: "incidents-view/active-requests",
      component: ActiveParentRequestsComponent,
      canActivate: [LandingGuard],
      data: { title: "Active Parent Requests" },
    },
    {
      path: "incidents-view/incomplete-tickets",
      component: IncompleteWorkItemsComponent,
      canActivate: [LandingGuard],
      data: { title: "Incomplete Workitems" },
    },
    {
      path: "create-request",
      component: CreateRequestComponent,
      canActivate: [LandingGuard],
      data: { title: "New Request" },
    },
    {
      path: "request-status",
      component: RequestStatusComponent,
      canActivate: [LandingGuard],
      data: { title: "My Requests" },
    },
    {
      path: "request-status/:requestId/:ticketId/:region",
      component: RequestStatusComponent,
      canActivate: [LandingGuard],
      data: { title: "My Requests" },
    },
    {
      path: "approver-review",
      component: ApproverReviewComponent,
      canActivate: [LandingGuard, IsApprover],
      data: { title: "Approve Requests" },
    },
    {
      path: "approver-admin",
      component: ApproverAdminComponent,
      canActivate: [LandingGuard, IsApproverAdmin],
      data: { title: "Approver Admin" },
    },
    {
      path: "admin/operator-review",
      component: OperatorReviewComponent,
      canActivate: [LandingGuard, IsApprover],
      data: { title: "All Requests" },
    },
    {
      path: "admin/operator-review/:requestId/:ticketId/:region",
      component: OperatorReviewComponent,
      canActivate: [LandingGuard, IsApprover],
      data: { title: "All Requests" },
    },
    {
      path: "self-service/revoke-access-quota",
      component: SelfServiceComponent,
      canActivate: [LandingGuard],
      data: { title: "Self Service" },
    },
    {
      path: "self-service/region-information",
      component: SupportInformationComponent,
      canActivate: [LandingGuard],
      data: { title: "Extended Information" },
    },
    {
      path: "self-service/vm-sizes",
      component: PlansInformationVmSizesComponent,
      canActivate: [LandingGuard],
      data: { title: "VM Size Table" },
    },
    {
      path: "support-information",
      component: SupportInformationComponent,
      canActivate: [LandingGuard],
      data: { title: "Extended Information" },
    },
    {
      path: "aad-callback",
      pathMatch: "full",
      component: AgsAadCallbackComponent,
    },
    {
      path: "requests/:parentReqId/sub/:subReqId",
      component: RequestDetailComponent,
      canActivate: [LandingGuard],
      data: { title: "Request Detail" },
    },
    {
      path: "requests/:parentReqId/sub/:subReqId/:ticketId/:region",
      component: RequestDetailComponent,
      canActivate: [LandingGuard],
      data: { title: "Request Detail" },
    },
    {
      path: "requests/:parentReqId",
      component: ParentRequestDetailComponent,
      canActivate: [LandingGuard],
      data: { title: "Request Detail" },
    },
    {
      path: "planned",
      children: [
        {
          path: "my-requests",
          component: PlannedQuotaMyRequestsComponent,
          canActivate: [LandingGuard],
          data: { title: "Planned Quota - My Requests" },
        },
        {
          path: "requests",
          component: PlannedQuotaRequestsComponent,
          canActivate: [LandingGuard],
          data: { title: "Planned Quota Requests" },
        },
        {
          path: "create-request",
          component: CreatePlannedQuotaRequestComponent,
          canActivate: [LandingGuard],
          data: { title: "Create Planned Quota Request" },
        },
      ],
    },
    {
      path: "plans",
      children: [
        {
          path: "all",
          component: PlansComponent,
          canActivate: [LandingGuard],
          data: { title: "Plans" },
        },
        {
          path: "regions",
          component: PlansAssignmentComponent,
          canActivate: [LandingGuard, IsGETApprover],
          data: { title: "Plan Assignment" },
        },
        {
          path: "regional/assign",
          component: PlansAssignmentServiceOwnerComponent,
          canActivate: [LandingGuard],
          data: { title: "Assign Plan to Regions" },
        },
        {
          path: "regional/signoff",
          component: PlansSignOffComponent,
          canActivate: [LandingGuard],
          data: { title: "Plan Sign-off" },
        },
        {
          path: "regional/mine",
          component: PlansMyRegionalPlansComponent,
          canActivate: [LandingGuard],
          data: { title: "My Regional Plans" },
        },
        {
          path: "regional/all",
          component: PlansAllRegionalPlansComponent,
          canActivate: [LandingGuard],
          data: { title: "All Regional Plans" },
        },
        {
          path: "bindings",
          component: PlansBindingsSearchComponent,
          canActivate: [LandingGuard],
          data: { title: "Subscription & Resource Bindings" },
        },
        {
          path: "regions/:region/services/:serviceTreeId/stages/:stage/status",
          component: PlannedQuotaFulfillmentStatusComponent,
          canActivate: [LandingGuard],
          data: { title: "Planned Quota Fulfillment Status" },
        },
        {
          path: "region",
          component: PlannedQuotaRegionStatusComponent,
          canActivate: [LandingGuard],
          data: { title: "Planned Quota Fulfillment Status" },
        },
        {
          path: "regions/:region/status",
          component: PlannedQuotaRegionStatusComponent,
          canActivate: [LandingGuard],
          data: { title: "Planned Quota Fulfillment Status" },
        },
        {
          path: "plans-risks",
          component: PlansRisksComponent,
          canActivate: [LandingGuard],
          data: { title: "Plans Risks Table" },
        },
        {
          path: "regional/approvable",
          component: PlansApprovableComponent,
          canActivate: [LandingGuard, IsApprover],
          data: { title: "Approvable Regional Plans" },
        },
        {
          path: "approval-info",
          component: PlansRegionalQuotaInformationComponent,
          canActivate: [LandingGuard, IsApprover],
          data: { title: "Quota Allocation Summary" },
        },
        {
          path: "services/:serviceTreeId/regions/:region/versions/:version/stages/:stage/review",
          component: PlanReviewComponent,
          canActivate: [LandingGuard, IsApprover],
          data: { title: "Review and Approve Regional Plans" },
        },
        {
          path: "services/:serviceTreeId/regions/:region/versions/:version/stages/:stage/detail",
          component: PlanDetailComponent,
          canActivate: [LandingGuard],
          data: { title: "Plan Detail" },
        },
        {
          path: "services/:serviceTreeId/editor",
          component: PlanEditorComponent,
          canActivate: [LandingGuard],
          data: { title: "Plan Editor" },
        },
        {
          path: "services/:serviceTreeId/versions/:version",
          component: PlanHistoryComponent,
          canActivate: [LandingGuard],
          data: { title: "Plan History" },
        },
        {
          path: "requests/:requestId",
          component: PlannedQuotaRequestDetailComponent,
          canActivate: [LandingGuard],
          data: { title: "Planned Quota Request Detail" },
        },
        {
          path: "services/:serviceTreeId/bindings",
          component: PlansBindingsComponent,
          canActivate: [LandingGuard],
          data: { title: "Subscription & Resource Bindings" },
        },
        {
          path: "capacityOrder/:capacityOrderId",
          component: CapacityOrderDetailComponent,
          canActivate: [LandingGuard],
          data: { title: "Capacity Order Detail" },
        },
        {
          path: "capacityOrder/v2/:capacityOrderId",
          component: CapacityOrderDetailV2Component,
          canActivate: [LandingGuard],
          data: { title: "Capacity Order Detail" },
        },
        {
          path: "capacityOrder/orders/:capacityOrderId/subOrders/:subOrderId",
          component: CapacitySubOrderDetailComponent,
          canActivate: [LandingGuard],
          data: { title: "Capacity SubOrder Detail" },
        },
        {
          path: "**",
          component: PageNotFoundComponent,
          data: { title: "Resource not found" },
        },
      ],
    },
    {
      path: "ffp",
      children: [
        {
          path: "all",
          component: FfpPlansComponent,
          canActivate: [LandingGuard, IsAdmin || IsTestOrDevEnvironment],
          data: { title: "FFP Plans" },
        },
        {
          path: "plans/services/:serviceTreeId/types/:type/editor",
          component: FfpPlanEditorComponent,
          canActivate: [LandingGuard, IsAdmin || IsTestOrDevEnvironment],
          data: { title: "FFP Plan Editor" },
        },
        {
          path: "plans/services/:serviceTreeId/types/:type/versions/:version",
          component: FfpPlanHistoryComponent,
          canActivate: [LandingGuard, IsAdmin || IsTestOrDevEnvironment],
          data: { title: "FFP Plan History" },
        },
        {
          path: "plans/assign",
          component: FfpPlansAssignmentServiceOwnerComponent,
          canActivate: [LandingGuard],
          data: { title: "Assign FFP Plan to Projects" },
        },
        {
          path: "instances/services/:serviceTreeId/projects/:projectOid/versions/:version/review",
          component: FfpPlanReviewComponent,
          canActivate: [LandingGuard, IsApprover],
          data: { title: "Review and Approve FFP Instances" },
        },
        {
          path: "plans/projects",
          component: FfpPlansAssignmentComponent,
          canActivate: [LandingGuard, IsGETApprover],
          data: { title: "FFP Plan Assignment" },
        },
        {
          path: "instances/mine",
          component: FfpMyRegionalPlansComponent,
          canActivate: [LandingGuard],
          data: { title: "My FFP Instances" },
        },
        {
          path: "buildout",
          component: FfpRegionBuildoutComponent,
          canActivate: [LandingGuard],
          data: { title: "FFP Region Buildout" },
        },
        {
          path: "buildout/projects/:projectOid/plans",
          component: FfpBuildoutPlansComponent,
          canActivate: [LandingGuard],
          data: { title: "FFP Buildout Plans" },
        },
      ],
    },
    {
      path: "pf",
      children: [
        {
          path: "all",
          component: PfPlansComponent,
          canActivate: [LandingGuard, IsPfAdmin || IsTestOrDevEnvironment],
          data: { title: "PF Plans" },
        },
        {
          path: "services/:serviceTreeId/editor",
          component: PfPlanEditorComponent,
          canActivate: [LandingGuard, IsPfAdmin || IsTestOrDevEnvironment],
          data: { title: "PF Plan Editor" },
        },
        {
          path: "services/:serviceTreeId/versions/:version",
          component: PfPlanHistoryComponent,
          canActivate: [LandingGuard, IsPfAdmin || IsTestOrDevEnvironment],
          data: { title: "PF Plan History" },
        },
        {
          path: "approvable",
          component: PfPlansApprovableComponent,
          canActivate: [LandingGuard, IsPfAdmin || IsTestOrDevEnvironment],
          data: { title: "Approve PF Plans" },
        },
        {
          path: "services/:serviceTreeId/versions/:version/review",
          component: PfPlanReviewComponent,
          canActivate: [LandingGuard, IsPfAdmin],
          data: { title: "Review and Approve PF Plan" },
        },
      ],
    },
    {
      path: "self-service/mine/users",
      component: ServiceTeamUserManagement,
      canActivate: [LandingGuard],
      data: { title: "Manage my service team's users" },
    },
    {
      path: "self-service/arm-resource-type",
      component: ArmResourceTypeEnablement,
      canActivate: [LandingGuard],
      data: { title: "View Supported Arm Resource Type Enablement" },
    },
    {
      path: "requests",
      component: RequestsWithTicketsInfoComponent,
      canActivate: [LandingGuard],
      data: { title: "Requests" },
    },
    {
      path: "404",
      component: PageNotFoundComponent,
      data: { title: "Resource not found" },
    },
    {
      path: "**",
      component: PageNotFoundComponent,
      data: { title: "Resource not found" },
    },
  ],
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
