<div class="modal-header">
    <h4 class="modal-title" style="text-transform: capitalize;">{{ title }}</h4>
  </div>
  
  <div *ngIf="inProgress">
    <mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
    <div class="modal-body">
      <p [innerHTML] = completeMessage></p>
    </div>
  </div>
  
  <div *ngIf="confirmation">
    <div class="modal-body">
      <p>
        {{ bodyMessage }}
      </p>
      <li>{{ plannedQuotaRequest.RequestId }}</li>
    </div>
  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" *ngIf="confirmation" (click)="start()">
      Yes
    </button>
    <button type="button" class="btn btn-warning" (click)="close()">
      Close
    </button>
  </div>
  