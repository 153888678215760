<div class="modal-header">
  <h4 class="modal-title" style="text-transform: capitalize;">{{ title }}</h4>
</div>

<div *ngIf="inProgress">
  <mat-progress-bar [mode]="'indeterminate'" *ngIf="!isCompleted"> </mat-progress-bar>
  <div class="modal-body">
    <p>
      In progress {{ this.aggregatedResult.length }}/{{ this.subRequests.length }} :
    </p>
    <ul *ngFor="let item of aggregatedResult">
      <li [innerHtml]="item"></li>
    </ul>
  </div>
</div>

<div *ngIf="confirmation">
  <div class="modal-body">
    <p>
      {{ message }}
    </p>
    <ul *ngFor="let subRequest of subRequests">
      <li>{{ this.subRequest.RequestId }}</li>
    </ul>
    <table style="text-align: right; width: 80%;" *ngIf="requestOperation === 'cancel'">
      <tr>
        <td>Comments</td>
        <td style="text-align: left; padding-left: 3%;">
          <input type="text" [(ngModel)]="comment" size="30" />
        </td>
      </tr>
      <tr>
        <td>Send Email Notification</td>
        <td style="text-align: left; padding-left: 3%;">
          <input type="checkbox" [(ngModel)]="sendEmail" />
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" *ngIf="confirmation" (click)="start()">
    Yes
  </button>
  <button type="button" class="btn btn-warning" (click)="close()">
    Close
  </button>
</div>
