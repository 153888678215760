import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminViewComponent } from "./admin-view.component";
import { TableName } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { ModalService } from "src/app/shared/modal.service";
import { DataTypes } from "./admin-view-data-types";
import { SubscriptionThreshold } from "src/app/generated-models/SubscriptionThreshold";
import * as CDs from "src/app/utility/commonColDef";
import { SharedDataService } from "../../services/sharedDataService";

const additionalMessage = `\
The subscription limit has 3 dimensions: Region, Service Type, and Requestor. \
Wildcard value * can be used on each dimension to match any values. \
When there are multiple matches, the row with more exact matches will take priority. \
On tie, the maximal value among the tied rows will be used.`;

@Component({
  selector: "admin-view/config",
  templateUrl: "./admin-view.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AdminViewSubscriptionLimitComponent extends AdminViewComponent<SubscriptionThreshold> implements OnInit {
  mainTitle = "Subscription Limit";
  subTitle = "Configure how many subscriptions can be auto approved for access only requests per region per service type";
  type = "Subscription Limit";
  tableName = TableName.Config;
  keyFilterStats = "filter:admin-view-sub-limit";
  columnDef = [
    { ...CDs.Id, valueType: "number", hide: true },
    { ...CDs.RegionColDef("Region"), valueType: "region", wildcard: true },
    { ...CDs.RequestServiceType, valueType: "accessServiceType", wildcard: true },
    {
      headerName: "Requestor",
      field: "TeamOid",
      valueType: "requestor",
      wildcard: true,
      cellRenderer: (params) => {
        return this.dataTypes.teamNameOidMap[params.value];
      },
    },
    { headerName: "Limit", field: "SubscriptionThreshold", isEditable: true, valueType: "number", filter: "agNumberColumnFilter" },
    { headerName: "Flighting Value", field: "FlightingValue", isEditable: true, valueType: "number", filter: "agNumberColumnFilter" },
  ];

  constructor(
    apiService: ApiService,
    modalService: ModalService,
    notificationService: ToastrService,
    dataTypes: DataTypes,
    sharedDataService: SharedDataService
  ) {
    super(apiService, modalService, notificationService, sharedDataService, dataTypes);
    this.additionalMessage = additionalMessage;
    this.addRowEnable = true;
    this.updateRowFunc = (data: SubscriptionThreshold) => {
      if (data.Region && data.RequestServiceType && data.TeamOid && data.SubscriptionThreshold) {
        const payload = data;
        return apiService.updateSubscriptionThreshold(payload).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.addRowFunc = (data: SubscriptionThreshold) => {
      if (data.Region && data.RequestServiceType && data.TeamOid && data.SubscriptionThreshold) {
        const payload = data;
        data.Id = null;
        return apiService.insertSubscriptionThreshold(payload).toPromise();
      }
      this.notificationService.error("Not all fields filled out, submit failed.");
    };
    this.deleteRowEnable = true;
    this.deleteRowFunc = (data: SubscriptionThreshold) => {
      return apiService.deleteSubscriptionThreshold(data.Id).toPromise();
    };
  }

  refreshData() {
    this.apiService.getSubscriptionThresholdList().subscribe((response: SubscriptionThreshold[]) => {
      this.rowData = response;
      super.setupFilterByLocalData();
    });
  }
}
