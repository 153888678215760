import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ColDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { PfPlan } from "src/app/generated-models/PfPlan";
import { IPfCapacityPlan } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { PfPlanOperationTypeEnum } from "src/app/shared/enums/pf-enums";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import * as CDs from "src/app/utility/commonColDef";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";

@Component({
  templateUrl: "./pf-plan-history-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../pf.scss"],
})
export class PfPlanHistoryModalComponent implements OnInit {
  @Input() serviceTreeId: string;
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  rowData: IPfCapacityPlan[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private notificationService: ToastrService,
    private apiService: ApiService,
    private loadingService: LoadingScreenService
  ) {
    this.loadingService.setLoading(true);
  }

  ngOnInit() {
    const planHistoryCellComponentParams = {
      target: "_blank",
      url: (params: ColParams<PfPlan, string>) => `/quota/pf/services/${params.data.ServiceTreeId}/versions/${params.data.Version}`,
    };

    const columnDefinition: ColDef[] = [
      {
        ...CDs.Version,
        width: 80,
        cellRendererFramework: LinkCellComponent,
        cellRendererParams: planHistoryCellComponentParams,
      },
      {
        ...CDs.CreatedTime,
        cellRenderer: (params) => PreprocessDateTime(params.value),
        width: 120,
      },
      {
        ...CDs.CreatedBy,
        width: 80,
      },
      CDs.Comment,
      CDs.Status,
    ];

    this.gridOptions = extendDefaultOptions({
      columnDefs: columnDefinition,
      suppressPaginationPanel: true,
      sideBar: null,
      animateRows: true,
    });

    this.getPfPlanHistory(this.serviceTreeId);
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getPfPlanHistory(serviceTreeId: string) {
    this.apiService.getPfPlansHistory(serviceTreeId).subscribe(
      (data) => {
        const plans: IPfCapacityPlan[] = [];
        data.forEach((plan) => {
          var row: IPfCapacityPlan = {
            ServiceTeam: plan.ServiceTeam,
            ServiceTreeId: plan.ServiceTreeId,
            Ring: plan.Ring,
            Version: plan.Version,
            Status: plan.Status,
            CreatedBy: "",
            CreatedTime: new Date(0),
            Comment: ""
          };
          var createOperation = plan.Operations.find(p => p.Operation == PfPlanOperationTypeEnum.Create);
          if (createOperation) {
            row.CreatedBy = createOperation.By;
            row.CreatedTime = createOperation.At;
            row.Comment = createOperation.Comment;
          }
          plans.push(row);
        })
        this.rowData = plans;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          //this.gridColumnApi.autoSizeAllColumns();
          this.gridApi.sizeColumnsToFit();
        }, 100);
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }
}
