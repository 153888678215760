import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ColDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { FfpPlan } from "src/app/generated-models/FfpPlan";
import { ApiService } from "src/app/services";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import * as CDs from "src/app/utility/commonColDef";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";

@Component({
  templateUrl: "./ffp-plan-history-modal.component.html",
  styleUrls: ["../../../../styles.scss", "../../ffp.scss"],
})
export class FfpPlanHistoryModalComponent implements OnInit {
  @Input() serviceTreeId: string;
  @Input() type: string;
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  rowData: FfpPlan[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private notificationService: ToastrService,
    private apiService: ApiService,
    private loadingService: LoadingScreenService
  ) {
    this.loadingService.setLoading(true);
  }

  ngOnInit() {
    const planHistoryCellComponentParams = {
      target: "_blank",
      url: (params: ColParams<FfpPlan, string>) => `/quota/ffp/plans/services/${this.serviceTreeId}/types/${this.type}/versions/${params.data.Version}`,
    };

    const columnDefinition: ColDef[] = [
      {
        ...CDs.Version,
        width: 80,
        cellRendererFramework: LinkCellComponent,
        cellRendererParams: planHistoryCellComponentParams,
      },
      {
        ...CDs.CreatedTime,
        cellRenderer: (params) => PreprocessDateTime(params.value),
        width: 120,
      },
      {
        ...CDs.CreatedBy,
        width: 80,
      },
      CDs.Comment,
    ];

    this.gridOptions = extendDefaultOptions({
      columnDefs: columnDefinition,
      suppressPaginationPanel: true,
      sideBar: null,
      animateRows: true,
    });

    this.getFfpPlanHistory(this.serviceTreeId, this.type);
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getFfpPlanHistory(serviceTreeId: string, type: string) {
    this.apiService.getFfpPlansHistory(serviceTreeId, type).subscribe(
      (data) => {
        this.rowData = data;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          //this.gridColumnApi.autoSizeAllColumns();
          this.gridApi.sizeColumnsToFit();
        }, 100);
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }
}
