import { Component, OnInit } from "@angular/core";
import { ColumnApi, ExcelExportParams, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { ISubRequest, ITeamListRecord, TicketPriorityEnum, allowedCommonRegionStatuses } from "src/app/npr-request.model";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { OnColumnMoved, setColumnState } from "src/app/utility/common-helper";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import { SearchRequests } from "../../generated-models/SearchRequests";
import { Region, Team } from "../../generated-models";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApiService } from "../../services";
import { SubRequestWithTicketInfoColumnDefinition } from "./ag-grid-column-definition";

@Component({
  templateUrl: "./requestsWithTicketsInfo.component.html",
  styleUrls: ["../../styles.scss"],
})
export class RequestsWithTicketsInfoComponent extends BaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  regions: string[] = [];
  regionList: string[];
  requestors: ITeamListRecord[] = [];
  requestorList: ITeamListRecord[] = [];
  submitter = "";
  sub = "";
  subRequestsWithTickets: ISubRequest[] = [];
  other = false;
  otherRequestor = "";

  constructor(
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.gridOptions = extendDefaultOptions({
      columnDefs: SubRequestWithTicketInfoColumnDefinition,
      paginationPageSize: this.initPageSize,
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
        window.setTimeout(() => {
          const colIds = params.columnApi.getAllColumns().map((c) => c.getColId());
          params.columnApi.autoSizeColumns(colIds);
        }, 50);
      },
      onColumnMoved: (params) => OnColumnMoved(params, "allRequestColumnState")

    });

    this.loadingService.setLoading(true);
    forkJoin([this.apiService.getRegionList(true, false), this.apiService.getTeamList()]).subscribe(([regions, teams]: [Region[], Team[]]) => {
      // Region List
      if (!regions) {
        console.warn("No region is obtained from api server.");
      } else {
        this.regionList = regions
          .filter(region => allowedCommonRegionStatuses.includes(region.Status))
          .map(r => r.RegionName);
      }

      // Team List
      if (!teams) {
        this.requestorList = [];
      } else {
        const regionlessTeam = [];
        teams.map((r) => {
          this.requestorList.push({
            label: `${r.Name}(${r.Oid})`,
            value: 0,
            ring: r.Ring ?? "No Ring",
            oid: r.Oid,
          } as ITeamListRecord);
        });
        this.requestorList = this.requestorList
          .sort((a, b) => {
            return a.ring.localeCompare(b.ring) || a.label.localeCompare(b.label);
          })
          .concat(regionlessTeam.sort((a, b) => a.label.localeCompare(b.label)));
      }

      this.loadingService.setLoading(false);
    }, (error: unknown) => {
      this.notificationService.error(error as string);
      this.loadingService.setLoading(false);
    });
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setColumnState(params, "allRequestColumnState");
  }

  submitRequest(): void {
    this.loadingService.setLoading(true);
    const oids = this.requestors.map(r => r.oid);
    const searchRequests = {} as SearchRequests;
    searchRequests.Regions = this.regions;
    searchRequests.TeamOids = oids;
    searchRequests.Submitter = this.submitter;
    searchRequests.SubscriptionId = this.sub;

    if (this.isOtherRequestor) {
      searchRequests.TeamOids.push(`Other-${this.otherRequestor}`);
    }

    this.apiService.getSubRequestsWithTicketsInfo(searchRequests).subscribe(
      (response) => {
        this.subRequestsWithTickets = [];
        this.gridApi.setRowData(this.subRequestsWithTickets);
        if (response && response.length > 0) {
          response.map(r => {
            if (r) {
              r.RequestLink = ApiService.generateSubRequestHyperLink(r.ParentRequestId, r.SubRequestId.toString());
              r.RequestDisplayServiceType = ApiService.fetchServiceTypeName(r.RequestServiceType, r.SKU);
              r.IsExternalDisplay = r.IsExternal ? "Yes" : "No";
              r.IsHoboDisplay = r.IsHobo ? "Yes" : "No";
              r.RingLevel = r.IsExternal ? "External" : r.RingLevel;
              let priorityNumber = r.Priority;
              priorityNumber = priorityNumber === 0 ? 3 : priorityNumber;
              r.PriorityString = `${priorityNumber} - ${TicketPriorityEnum[priorityNumber]}`;
              r.CreatedTime = PreprocessDateTime(r.CreatedTime);
              r.TicketCreatedTime = PreprocessDateTime(r.TicketCreatedTime);
              r.TicketFinishedTime = PreprocessDateTime(r.TicketFinishedTime);
              r.CompletedTime = PreprocessDateTime(r.CompletedTime);
              this.subRequestsWithTickets.push(r);
            }
          });
        }
        this.loadingService.setLoading(false);
      }, (error: unknown) => {
        const message = `Failed to get any tickets or CIS jobs, error: ${error}`;
        this.notificationService.error(message);
        this.loadingService.setLoading(false);
      });
  }

  get submitDisabled(): boolean {
    return this.requestors.length === 0 && !this.submitter && !this.sub && this.regions.length === 0 && !this.isOtherRequestor;
  }

  get isOtherRequestor(): boolean {
    return this.other && !_.isEmpty(this.otherRequestor);
  }

  async export(): Promise<void> {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "Requests-" + PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm", "en-US"),
      sheetName: "Requests",
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }
}
