import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { IUserProfile } from "../npr-request.model";
import { SubRequestApprovalData } from "../generated-models/SubRequestApprovalData";
import { SubRequestBatchApproval } from "../generated-models/SubRequestBatchApproval";
import { ISubRequest, RequestSource } from "../npr-request.model";
import { LoadingScreenService } from "../shared/loading-screen.service";
import { ModalService } from "../shared/modal.service";
import { ApiService } from "./api.service";

@Injectable()
export class ApproveRequestService {
  private refresh = false;

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService
  ) { }

  async approveRequest(selectedSubRequests: ISubRequest[], message?: string): Promise<boolean> {
    this.refresh = false;
    if (selectedSubRequests.length === 0) {
      const message = "Please select request(s) to approve";
      this.notificationService.warning(message);
      return this.refresh;
    }

    this.loadingService.setLoading(true);
    const checkDuplicate = await this.checkDuplicationTasks(selectedSubRequests, message, false);
    if (checkDuplicate === true) {
      // error return
      return;
    }

    const approveRequestsTask = this.approveRequestsAsync(selectedSubRequests);
    this.loadingService.setLoading(true);
    await approveRequestsTask;
    this.loadingService.setLoading(false);
    return this.refresh;
  }

  async rejectRequest(selectedSubRequests: ISubRequest[], userProfile: IUserProfile): Promise<boolean> {
    this.refresh = false;
    let message = "";
    if (selectedSubRequests.length === 0) {
        message = "Please select request(s) to reject";
      this.notificationService.warning(message);
      return this.refresh;
    }
    
    // Get the reject reason from the modal dialog
    await this.modalService
      .requestInputModal(
        `Are you sure you want to reject ${selectedSubRequests.length} request(s)? <p></p> Please enter the reason.`,
        false,
        "Please confirm"
      )
      .then(
        async (rejectReason: string) => {
          if (userProfile.IsApprover) {
            this.loadingService.setLoading(true);
            const checkDuplicate = await this.checkDuplicationTasks(selectedSubRequests, null, true);
            if (checkDuplicate === true) {
              // error return
              return;
            }

            const rejectRequestsTask = this.rejectRequestsAsync(selectedSubRequests, rejectReason);
            this.loadingService.setLoading(true);
            
            await rejectRequestsTask;

            this.loadingService.setLoading(false);
            message = "Selected request(s) have been rejected.";
            this.notificationService.info(message);
          } else {
            message = "You are not approver, you cannot reject request.";
            this.notificationService.error(message);
          }
        },
        () => {
          this.notificationService.warning("You clicked No button, no change has been applied", `Hey ${userProfile.Name},`);
        }
      );
    return this.refresh;
  }

  private async checkDuplicationTasks(selectedSubRequests: ISubRequest[], message: string, isReject: boolean) : Promise<boolean> {
    const operation = isReject ? "reject" : "approve";
    const checkDuplicationTasks: Promise<ISubRequest[]>[] = selectedSubRequests.map(
      async (subRequest): Promise<ISubRequest[]> => {
        if (subRequest.RequestSource === RequestSource.LionrockUI) {
          return await this.apiService
            .getDuplicatedSubRequestBySubRequestId(subRequest.ParentRequestId, subRequest.SubRequestId)
            .toPromise()
            .catch((e) => {
              console.log(e);
              return [] as ISubRequest[];
            });
        } else {
          return [] as ISubRequest[];
        }
      }
    );
    const duplicates = await Promise.all(checkDuplicationTasks);
    const repeatRequestCount = duplicates
      .map((d) => (d !== null && d.length > 0 ? 1 : 0))
      .reduce((previous, current) => previous + current, 0);
    if (repeatRequestCount > 0) {
      let confirmationMessage = `${repeatRequestCount} of ${selectedSubRequests.length} selected request(s) are duplicated of existing ones. Are you sure you want to ${operation} all the ${selectedSubRequests.length} selected request(s)?<br \\><br \\>`;
      for (let i = 0; i < selectedSubRequests.length; i++) {
        if (duplicates[i].length <= 0) {
          continue;
        }
        confirmationMessage += `<b>${selectedSubRequests[i].RequestId}</b> is duplicated of: `;
        duplicates[i].forEach((duplicate) => (confirmationMessage += duplicate.RequestId + "; "));
        confirmationMessage += "<br \\>";
      }
      this.loadingService.setLoading(false);
      try {
        await this.modalService.confirmationModal(confirmationMessage, "lg");
      } catch {
        return true;
      }
    } else {
      this.loadingService.setLoading(false);
      try {
        await this.modalService.confirmationModal(
          message ? message : `Are you sure you want to ${operation} the selected ${selectedSubRequests.length} request(s)?`
        );
      } catch {
        // For the model dialog dimiss
        return true;
      }
    }
    return false;
  }


  private async approveRequestsAsync(selectedSubRequests: ISubRequest[]) : Promise<void> {
    return this.approveOrRejectRequestsAsync(selectedSubRequests, false);
  }

  private async rejectRequestsAsync(selectedSubRequests: ISubRequest[], reason: string) {
    return this.approveOrRejectRequestsAsync(selectedSubRequests, true, reason);
  }

  private async approveOrRejectRequestsAsync(selectedSubRequests: ISubRequest[], isReject: boolean, reason?: string) : Promise<void> {
    const requestsInfo = [];
    const operation = isReject ? "reject" : "approve";
    const operationParticiple = isReject ? "rejected" : "approved";
    selectedSubRequests.forEach((e) => {
      const approvalData = {} as SubRequestApprovalData;
      approvalData.IsReject = isReject;
      if (isReject) {
        approvalData.Comment = reason;  
      }

      const batchApprovalData = {} as SubRequestBatchApproval;
      batchApprovalData.ParentRequestId = e.ParentRequestId;
      batchApprovalData.SubRequestId = e.SubRequestId;
      batchApprovalData.SubRequestApprovalData = approvalData;
      
      requestsInfo.push(batchApprovalData);
    })

    const approveRequestsTask = this.apiService
      .batchSetApprovalLevel(requestsInfo)
      .toPromise()
      .then((approvedList) => {
        let message = '';
        if (approvedList.length == 1) {
          message = `Selected request(s) have been ${operationParticiple}.`;
        }
        else {
          message = `Selected request(s) and its dependencies: ${approvedList.map(e => e.RequestId).join()} have been ${operationParticiple}.`;
        }
        this.notificationService.info(message);
        this.refresh = true;
      })
      .catch((error) => {
        const message = `Failed to ${operation} or its dependency, error: ${error}`;
        this.notificationService.error(message);
      });

      return approveRequestsTask;
  }
}
